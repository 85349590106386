import { ASYNC_START, ASYNC_END } from "../constants/actionTypes";
import { Reducer } from "redux";

const defaultState = {};

type ProgressState = {
 [key: string]: boolean
}


// This assumes one of any kind of task, can make it better later
const progressReducer: Reducer<ProgressState, any> = (state = defaultState, action) => {
  switch (action.type) {
    case ASYNC_START:
      return {
        ...state,
        [action.subtype]: true,
      };
    case ASYNC_END:
      const { [action.subtype]: value, ...newState } = state;
      return newState;
    default:
      return state;
  }
};

export default progressReducer