import React from "react";
import { Row, Col, Button } from "reactstrap";
import ErrorGuy from "../images/ErrorGuy.svg";
import styles from "./ErrorScreenStyle.scss";

const ErrorScreen = ({}) => {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Row className="d-flex justifyContent">
        <Col md="1">
          <div></div>
        </Col>
        <Col md="4">
          <div className={styles.textSection}>
            <h1>OOPS</h1>
            <h2 className={styles.subHead}>Something went wrong!</h2>
            <p>
              We've experienced an error. Please try refreshing the page. If you continue to have
              issues, please contact our support team at{" "}
              <span className={styles.email}>support@mdbox.com</span>
            </p>
            <Button onClick={reloadPage} color="primary" className={styles.refresh}>
              Refresh Page
            </Button>
          </div>
        </Col>
        <Col md="2">
          <div></div>
        </Col>
        <div className={styles.errorGuy}>
          <Col md="2">
            <div>
              <ErrorGuy />
            </div>
          </Col>
        </div>
      </Row>
    </>
  );
};
export default ErrorScreen;
