import React, { useEffect, useState, useLayoutEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./FullScreenImage.scss";
import Spinner from "../images/Spinner.svg";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'url' implicitly has an 'any' type... Remove this comment to see the full error message
const FullScreenImage = ({ url, onClose, angle }) => {
  const [loaded, setLoaded] = useState(false);
  const fullImg = React.createRef();

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    img.src = url;
  }, []);

  useLayoutEffect(() => {
    // The view should be rendered first before this line is called. Otherwise it returns null.
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    fullImg.current.style.transform = `rotate(${angle}deg)`;
  }, [angle]);

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className="d-flex justify-content-center">
        <img
          className={classNames(`${styles.fullImage}`, {
            [styles.hidden]: !loaded,
          })}
          src={url}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message
          ref={fullImg}
          onClick={onClose}
        />
        <div
          className={classNames(`${styles.spinner}`, {
            [styles.hidden]: loaded,
          })}
        >
          <Spinner />
        </div>
      </div>
    </div>
  );
};

FullScreenImage.propTypes = {
  url: PropTypes.string,
  onClose: PropTypes.func,
  angle: PropTypes.number,
};

export default FullScreenImage;
