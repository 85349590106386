import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./chatHistory.scss";
import moment from "moment";

import momentz from "moment-timezone";
import { PROVIDER_TIME_ZONE } from "../../constants/Providers";
import { Button, Input } from "reactstrap";
import api from "../../api";
import { IChatHistoryItem, IPrevEncountersChat } from "../../constants/Types";

const chatHeight = {
  height: "100%",
};
type Props = {
  chatHistoryItems: IChatHistoryItem[];
  prevEncountersMessages?: IPrevEncountersChat[];
  encounterID?: string;
  updateChat?: Function;
  setIdleTime?: Function;
  location: string;
  message?: string;
  isInEncounter?: boolean;
  visitClosed?: boolean;
  onOpenImageModal: (rawpath: string) => void;
};

const ChatHistory: FC<Props> = ({
  encounterID,
  chatHistoryItems,
  prevEncountersMessages,
  location,
  isInEncounter = false,
  updateChat,
  visitClosed,
  message,
  onOpenImageModal,
  ...props
}) => {
  const patientTimeZone = momentz.tz(PROVIDER_TIME_ZONE[location]).zoneAbbr();
  const messagesEndRef: any = useRef(null);
  const [chatMessage, setChatMessage] = useState(message || "");
  const [arrOfMessages, setArrOfMessages] = useState<IChatHistoryItem[]>([]);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const providerTimeZone = momentz.tz(timezone).zoneAbbr();

  const scrollToBottom = () => {
    const scroll = messagesEndRef.current.scrollHeight - messagesEndRef.current.clientHeight;
    messagesEndRef.current?.scrollTo(0, scroll);
  };
  const onSendMessage = async () => {
    if (chatMessage) {
      await api.Encounters.sendMessage({ message: chatMessage }, encounterID!);
      updateChat && updateChat();

      setChatMessage("");
      scrollToBottom();
    }
  };

  const onMessageChange = (message) => {
    setChatMessage(message);
  };
  const collectAllMessages = () => {
    if (!prevEncountersMessages || prevEncountersMessages.length < 1) {
      setArrOfMessages(chatHistoryItems);
      return;
    }
    let newArr: IChatHistoryItem[] = [];
    for (let i = 0; i < prevEncountersMessages.length; i++) {
      newArr.push(...prevEncountersMessages[i].chatHistoryItems);
    }
    newArr[newArr.length - 1].endOfPrevMessages = true;
    newArr.push(...chatHistoryItems);
    setArrOfMessages(newArr);
  };
  const handleEnterPress = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();

      setChatMessage(chatMessage + "\n");
      props.setIdleTime && props.setIdleTime();

      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      onSendMessage();
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [arrOfMessages.length]);

  useEffect(() => {
    collectAllMessages();
  }, [chatHistoryItems.length]);

  const showDate = (item, index) => {
    if (
      arrOfMessages[index - 1] &&
      moment(arrOfMessages[index].createdAt).format("ll") ===
        moment(arrOfMessages[index - 1].createdAt).format("ll")
    ) {
      return;
    } else {
      return (
        <div className={styles.chatItemDate}>
          <span>- {moment(item.createdAt).format("ll")} -</span>
        </div>
      );
    }
  };

  return (
    <div className={styles.chatHistoryContainer}>
      <div className={`${styles.headerWrapper} ${isInEncounter && styles.shadows}`}>
        <div className="d-flex justify-content-between mt-2">
          <span className={styles.headerText}>Patient Messaging</span>
          {chatHistoryItems[0] && (
            <span className={`mt-auto ${styles.sendingTime}`}>
              Encounter Started: {moment(chatHistoryItems[0].createdAt).format("llll")}
            </span>
          )}
        </div>
        {!isInEncounter && (
          <div className={styles.chatInfo}>
            <span>
              Patient local time: {momentz.tz(PROVIDER_TIME_ZONE[location]).format("LT")}{" "}
              {patientTimeZone}
            </span>
          </div>
        )}
      </div>
      <div
        ref={messagesEndRef}
        className={styles.chatItemsWrapper}
        style={isInEncounter ? chatHeight : {}}
      >
        {arrOfMessages.map((item, index) => {
          return (
            <div
              key={`message-item-${index}`}
              id={`qa-chatHistoryItem-${index}`}
              className={styles.chatElement}
            >
              {item.createdAt && showDate(item, index)}
              {item.writtenBy ? (
                <>
                  <div
                    className={`${styles.chatHeader} ${
                      item.patientMessage ? styles.patientHeader : styles.providerHeader
                    }`}
                  >
                    <span className={styles.headerText}>
                      {item.writtenBy} {item.patientMessage && "- Patient"}
                      {item.autoGenerated && (
                        <span className={`${styles.autogeneratedText} ml-2`}>Auto Generated</span>
                      )}
                    </span>
                    <span className={styles.sendingTime}>
                      {momentz.tz(item.createdAt, timezone).format("LT").toLowerCase()}{" "}
                      {providerTimeZone.toLowerCase()}
                    </span>
                  </div>
                  <div
                    className={`${styles.chatContent} ${
                      item.autoGenerated && styles.autogeneratedText
                    }`}
                  >
                    {!!item.isImage ? (
                      <img
                        src={item.textContent}
                        className={styles.image}
                        onClick={() => onOpenImageModal(item.rawImgPath || "")}
                      />
                    ) : (
                      <span>{item.textContent}</span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.chatHeader}>
                    <span />
                    <span className={styles.sendingTime}>
                      {momentz.tz(item.createdAt, timezone).format("LT").toLowerCase()}{" "}
                      {providerTimeZone.toLowerCase()}
                    </span>
                  </div>
                  <div className={`${styles.chatContent} ${styles.autogeneratedText}`}>
                    {!!item.isImage ? (
                      <img src={item.textContent} />
                    ) : (
                      <span>{item.textContent}</span>
                    )}
                  </div>
                  {item.endOfPrevMessages && (
                    <div className={styles.endOfPrevMessages}>
                      <span className={styles.sendingTime}>
                        Previous visit messages are not included in the current visit notes
                      </span>
                      <div className={styles.blueBox}>
                        <div className={styles.blueBorder} />
                        <span>Start of current follow-up visit</span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
      {isInEncounter &&
        (visitClosed ? (
          <div className={styles.chatClosed} onKeyPress={handleEnterPress}>
            <div className="d-flex w-100 align-items-center justify-content-between">
              <span className={styles.headerText}>Visit Closed</span>
              <div className={`${styles.chatInfo} mt-0`}>
                <span>
                  Patient local time: {momentz.tz(PROVIDER_TIME_ZONE[location]).format("LT")}{" "}
                  {patientTimeZone}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.chatInputContainer} onKeyPress={handleEnterPress}>
            <Input
              value={chatMessage}
              type="textarea"
              id="consentText"
              className={styles.chatInput}
              placeholder=""
              onChange={(e) => onMessageChange(e.target.value)}
            />
            <div className="d-flex align-items-end justify-content-between">
              <div className={styles.chatInfo}>
                <span>
                  Patient local time: {momentz.tz(PROVIDER_TIME_ZONE[location]).format("LT")}{" "}
                  {patientTimeZone}
                </span>
              </div>
              <Button type={"submit"} onClick={onSendMessage} className={styles.sendButton}>
                Send
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ChatHistory;
