import React, { Component } from "react";
import { connect } from "react-redux";
import * as tv from "twilio-video";
import { Button, Form, FormGroup, Input, Label, Row, Col } from "reactstrap";

import ContentRow from "../ContentRow";
import NavBar from "../Provider/NavBar";
import { COMPONENT_LOAD, VIDEO_CONNECT, VIDEO_ROOM_TOKEN } from "../../constants/actionTypes";
import api from "../../api";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    ...state.common,
    inProgress: state.progress[VIDEO_CONNECT],
    roomToken: state.componentData[VIDEO_ROOM_TOKEN],
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  onGetToken: (payload) => dispatch({ type: COMPONENT_LOAD, subtype: VIDEO_ROOM_TOKEN, payload }),
});

class VideoChat extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = {
      loaded: false,
      name: "beardo",
      roomName: "encounters-0OLlD8jMp7cVE7KLegqu0VIgW26dMrDv9isK2ZnhGqw",
    };

    this.startVideo = this.startVideo.bind(this);
    this.stopVideo = this.stopVideo.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  componentDidMount() {
    // this.props.onGetToken(api.Twilio.getRoomToken(this.state.name))
    tv.createLocalTracks({
      audio: true,
      video: { height: 768, width: 384 },
    }).then((localTracks) => {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'localTracks' implicitly has an 'any' ty... Remove this comment to see the full error message
      const localMediaContainer = document.getElementById("local-media");
      console.log("Localtracks", localTracks);
      localTracks.forEach(function(track) {
        localMediaContainer.appendChild(track.attach());
      });
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'track' implicitly has an 'any' type.
      this.setState({ localTracks: localTracks });
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.state.loaded &&
      this.props.roomToken &&
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
      !this.props.roomToken.inProgress &&
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'snapshot' implicitly has an 'any' type.
      this.props.roomToken.data
    ) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loaded' does not exist on type 'Readonly... Remove this comment to see the full error message
      console.log("room token", this.props.roomToken);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'roomToken' does not exist on type 'Reado... Remove this comment to see the full error message
      this.setState({ loaded: true, token: this.props.roomToken.data });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'roomToken' does not exist on type 'Reado... Remove this comment to see the full error message
      this.connectToRoom.bind(this)(this.props.roomToken.data.token);
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'roomToken' does not exist on type 'Reado... Remove this comment to see the full error message
  }

  handleFormChange(event) {
    this.setState({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'roomToken' does not exist on type 'Reado... Remove this comment to see the full error message
      [event.target.name]: event.target.value,
    });
  }

  connectToRoom(token) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'roomToken' does not exist on type 'Reado... Remove this comment to see the full error message
    console.log("Yo, we are connecting...");
    let that = this;
    tv.connect(token, {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
      name: this.state.roomName,
      audio: true,
      video: { height: 768, width: 384 },
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'token' implicitly has an 'any' type.
      tracks: this.state.localTracks,
    }).then(
      (room) => {
        console.log(`Successfully joined a Room: ${room}`);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'roomName' does not exist on type 'Readon... Remove this comment to see the full error message
        that.setState({ connected: true, room: room });
        room.participants.forEach((participant) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'localTracks' does not exist on type 'Rea... Remove this comment to see the full error message
          participant.tracks.forEach((publication) => {
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'room' implicitly has an 'any' type.
            if (publication.isSubscribed) {
              const track = publication.track;
              document.getElementById("remote-media").appendChild(track.attach());
            }
          });

          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'participant' implicitly has an 'any' ty... Remove this comment to see the full error message
          participant.on("trackSubscribed", (track) => {
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'publication' implicitly has an 'any' ty... Remove this comment to see the full error message
            document.getElementById("remote-media").appendChild(track.attach());
          });
        });

        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        room.on("participantConnected", (participant) => {
          participant.tracks.forEach((publication) => {
            if (publication.isSubscribed) {
              const track = publication.track;
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'track' implicitly has an 'any' type.
              document.getElementById("remote-media").appendChild(track.attach());
            }
          });

          participant.on("trackSubscribed", (track) => {
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'participant' implicitly has an 'any' ty... Remove this comment to see the full error message
            document.getElementById("remote-media").appendChild(track.attach());
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'publication' implicitly has an 'any' ty... Remove this comment to see the full error message
          });
        });
      },
      (error) => {
        console.error(`Unable to connect to Room: ${error.message}`);
      },
    );
  }

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  startVideo() {
    if (this.state.token) {
      this.connectToRoom(this.state.token.token);
    } else {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'track' implicitly has an 'any' type.
      this.props.onGetToken(api.Twilio.getRoomToken(this.state.name, this.state.roomName));
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    }
  }

  // Disconnect on unmount
  // Disconnect on window close

  stopVideo() {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
    if (this.state.connected && this.state.room) {
      this.state.room.disconnect();
      this.setState({ connected: false, room: null });
    }
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'token' does not exist on type 'Readonly<... Remove this comment to see the full error message
  componentWillUnmount() {
    if (this.state.connected && this.state.room) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'token' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.state.room.disconnect();
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onGetToken' does not exist on type 'Read... Remove this comment to see the full error message
    console.log("Video unmounting...");
    if (this.state.localTracks) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      console.log("Removing localtracks", this.state.localTracks);
      this.state.localTracks.forEach(function(track) {
        track.stop();
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'connected' does not exist on type 'Reado... Remove this comment to see the full error message
        track.detach().forEach(function(detachedElement) {
          detachedElement.remove();
        });
      });
    }
  }

  render() {
    console.log("Props", this.props, this.state);
    return (
      <div>
        <NavBar />
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'connected' does not exist on type 'Reado... Remove this comment to see the full error message */}
        <ContentRow>
          <h4> Now this is pod racing </h4>
          <Form inline>
            <FormGroup className="mr-3">
              <Label> Name &nbsp;</Label>
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'localTracks' does not exist on type 'Rea... Remove this comment to see the full error message */}
              <Input name="name" value={this.state.name} onChange={this.handleFormChange} />
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'localTracks' does not exist on type 'Rea... Remove this comment to see the full error message */}
            </FormGroup>
            <FormGroup className="mr-3">
              {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'track' implicitly has an 'any' type. */}
              <Label> Room </Label>
              {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'detachedElement' implicitly has an 'any... Remove this comment to see the full error message */}
              <Input name="roomName" value={this.state.roomName} onChange={this.handleFormChange} />
            </FormGroup>
            <FormGroup className="mr-3">
              <Button
                onClick={this.startVideo}
                disabled={this.state.connected || !this.state.name}
                // @ts-expect-error ts-migrate(2746) FIXME: This JSX tag's 'children' prop expects a single ch... Remove this comment to see the full error message
                color="info"
              >
                Connect to demo room
              </Button>
            </FormGroup>
            <FormGroup className="mr-3">
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
              <Button onClick={this.stopVideo} disabled={!this.state.connected} color="info">
                Disconnect
              </Button>
            </FormGroup>
          </Form>
          <Row>
            <Col md="8">
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'roomName' does not exist on type 'Readon... Remove this comment to see the full error message */}
              <div className="dashboard-component">
                <h5> Remote </h5>
                <div id="remote-media" />
              </div>
            </Col>
            <Col md="4">
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'connected' does not exist on type 'Reado... Remove this comment to see the full error message */}
              <div className="dashboard-component">
                <h5> Local Preview </h5>
                <div id="local-media" />
              </div>
            </Col>
          </Row>
        </ContentRow>
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'connected' does not exist on type 'Reado... Remove this comment to see the full error message
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoChat);
