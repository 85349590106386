// I copied this from stackoverflow. No shame.
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
}

export function showPushNotification(data) {
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    new Notification(data.title, data);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      // Whatever the user answers, we make sure we store the information
      if(!('permission' in Notification)) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'permission' does not exist on type 'neve... Remove this comment to see the full error message
        Notification.permission = permission;
      }
      // If the user is okay, let's create a notification
      if (permission === "granted") {
        new Notification(data.title, data);
      }
    });
  }
}
export const openInNewTab = (url: string) => {
  let win = window.open(url, "_blank");
  if (win) {
    win.focus();
  }
};
export const checkIsJSON = (errorBody) => {
  try {
    JSON.parse(errorBody)
    return true
  }
  catch (e) {
    return
  }
}
