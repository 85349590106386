import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import PropTypes from "prop-types";

import api from "../../api";
import bugsnagClient from "../../services/bugsnag";
import "./MedicalPracticeSelect.scss";
import Arrow from "../../images/menuarrow.svg";
import CheckToggle from "../CheckToggle";

interface IStates {
  label:string,
  value:string
}
export const STATES:IStates[] = [
  { label: "AL - Alabama", value: "AL" },
  { label: "AK - Alaska", value: "AK" },
  { label: "AZ - Arizona", value: "AZ" },
  { label: "AR - Arkansas", value: "AR" },
  { label: "CA - California", value: "CA" },
  { label: "CO - Colorado", value: "CO" },
  { label: "CT - Connecticut", value: "CT" },
  { label: "DC - District of Columbia", value: "DC" },
  { label: "DE - Delaware", value: "DE" },
  { label: "FL - Florida", value: "FL" },
  { label: "GA - Georgia", value: "GA" },
  { label: "HI - Hawaii", value: "HI" },
  { label: "ID - Idaho", value: "ID" },
  { label: "IL - Illinois", value: "IL" },
  { label: "IN - Indiana", value: "IN" },
  { label: "IA - Iowa", value: "IA" },
  { label: "KS - Kansas", value: "KS" },
  { label: "KY - Kentucky", value: "KY" },
  { label: "LA - Louisiana", value: "LA" },
  { label: "ME - Maine", value: "ME" },
  { label: "MD - Maryland", value: "MD" },
  { label: "MA - Massachusetts", value: "MA" },
  { label: "MI - Michigan", value: "MI" },
  { label: "MN - Minnesota", value: "MN" },
  { label: "MS - Mississippi", value: "MS" },
  { label: "MO - Missouri", value: "MO" },
  { label: "MT - Montana", value: "MT" },
  { label: "NE - Nebraska", value: "NE" },
  { label: "NV - Nevada", value: "NV" },
  { label: "NH - New Hampshire", value: "NH" },
  { label: "NJ - New Jersey", value: "NJ" },
  { label: "NM - New Mexico", value: "NM" },
  { label: "NY - New York", value: "NY" },
  { label: "NC - North Carolina", value: "NC" },
  { label: "ND - North Dakota", value: "ND" },
  { label: "OH - Ohio", value: "OH" },
  { label: "OK - Oklahoma", value: "OK" },
  { label: "OR - Oregon", value: "OR" },
  { label: "PA - Pennsylvania", value: "PA" },
  { label: "RI - Rhode Island", value: "RI" },
  { label: "SC - South Carolina", value: "SC" },
  { label: "SD - South Dakota", value: "SD" },
  { label: "TN - Tennessee", value: "TN" },
  { label: "TX - Texas", value: "TX" },
  { label: "UT - Utah", value: "UT" },
  { label: "VT - Vermont", value: "VT" },
  { label: "VA - Virginia", value: "VA" },
  { label: "WA - Washington", value: "WA" },
  { label: "WV - West Virginia", value: "WV" },
  { label: "WI - Wisconsin", value: "WI" },
  { label: "WY - Wyoming", value: "WY" },
];

const getState = (value) => {
  const state = STATES.find((v) => v.value === value);
  return state && state.label
};

const getPracticeLabel = (option) => option.name;
const MultiValueRemove = (props) => {
  const onClick = (value) => {
    props.selectProps.onMenuRemove(value);
  };
  return (
    <components.MultiValueRemove {...props}>
      <span onClick={() => onClick(props.data)} style={{ cursor: "pointer" }}>
        X
      </span>
    </components.MultiValueRemove>
  );
};

const ClearIndicator = (props) => {
  const onClick = () => {
    props.selectProps.onMenuRemoveAll();
  };
  return (
    <components.ClearIndicator {...props}>
      <span onClick={onClick}>X</span>
    </components.ClearIndicator>
  );
};

const getOtherSites = (practices = []) => {
  let sites = [];
  const other = practices.other || {};

  for (const property in other) {
    sites = [...sites, ...other[property]];
  }

  return sites;
};

const PracticesListMenu = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const selectedSites = props.selectedSites;

  const handleOnClick = (item) => {
    const idx = selectedSites.findIndex((i) => i.id === item.id);
    if (idx > -1) {
      props.onMenuRemove(item);
    } else {
      props.onPracticeChange(item);
    }
    setDropdownOpen(true);
  };

  const isAllSelected = (sites) => {
    const selectedSiteNames = selectedSites.map((s) => s.name);
    return sites.every((site) => {
      return selectedSiteNames.includes(site.name);
    });
  };

  const isChecked = (practice) => {
    const idx = selectedSites.findIndex((i) => i.id === practice.id);
    if (idx < 0) {
      return false;
    }
    return true;
  };

  const handleSelectAll = () => {
    if (isAllSelected(props.sites)) {
      props.onUnselectAll(props.sites);
    } else {
      props.onMenuSelectAll(props.sites);
    }
  };

  return (
    <Dropdown
      id="qa-sub-practices-dropdown"
      isOpen={props.isOpen}
      toggle={toggle}
      direction="right"
      style={{ width: "100%", padding: 0 }}
    >
      <DropdownToggle tag="div" className="d-flex w-100" id="qa-sub-practices-toggle">
        <span className="title">{getState(props.name)}</span>
        <span className="icon">
          <Arrow />
        </span>
      </DropdownToggle>
      <DropdownMenu
        style={{
          marginLeft: "40px",
        }}
        className="menu"
        id="qa-sub-practices-menu"
      >
        <div className="header" onClick={handleSelectAll} id="qa-sub-practices-header">
          <CheckToggle checked={isAllSelected(props.sites)} />
          Select All
        </div>
        <DropdownItem divider />

        {props.sites.map((item, idx) => (
          <DropdownItem
            key={`site-${idx}`}
            toggle={false}
            tag="div"
            className="submenu-item"
            onClick={() => handleOnClick(item)}
            id={`qa-sub-practices-sites-${idx}`}
          >
            <CheckToggle checked={isChecked(item)} />
            {item.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const SubMenuDropDown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const nursingHomes = props.nursingHomes;
  const states = Object.keys(nursingHomes);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const handleMenuClick = () => {
    props.onMenuChange();
  };

  return (
    <Dropdown
      id="qa-sub-menuDropdown-wrapper-select"
      isOpen={dropdownOpen || props.isDropDownOpen}
      toggle={toggle}
      direction="right"
      className="w-100"
    >
      <DropdownToggle
        tag="div"
        className="d-flex w-100"
        onClick={handleMenuClick}
        id="qa-sub-dropdownToggle"
      >
        <span className="title">{props.name} </span>
        <span className="icon">
          <Arrow />
        </span>
      </DropdownToggle>
      <DropdownMenu style={{ marginLeft: "40px" }} className="menu" id="qa-sub-dropdownMenu" >
        {states.map((state, idx) => (
          <DropdownItem
            id={`qa-sub-dropdownItem-${idx}`}
            key={`state-${idx}`}
            className="submenu-item"
            onClick={() => props.setSelectedSubMenu(state)}
            active={props.selectedSubMenu === state}
            toggle={false}
            tag="div"
          >
            <PracticesListMenu
              name={state}
              onPracticeChange={props.onPracticeChange}
              items={props.items}
              isOpen={props.selectedSubMenu === state}
              sites={nursingHomes[state]}
              selectedSites={props.selectedSites}
              onMenuRemove={props.onMenuRemove}
              onMenuSelectAll={props.onMenuSelectAll}
              onUnselectAll={props.onUnselectAll}
            />
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default function MedicalPracticeSelect({ values, onChange }) {
  const [practices, setPractices] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const nursingHomeOption = "Skilled nursing facilities";

  const toggle = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await api.MedicalPractices.list();
        setPractices(result);
      } catch (err) {
        bugsnagClient.notify(err);
      }
    };
    fetchData();
  }, []);

  const onPracticeChange = (items) => {
    const idx = values.findIndex((v) => v === items.id);
    let nv = null;
    if (idx < 0) {
      nv = [...values, items.id];
      onChange(nv);
    }
  };

  const onMenuRemove = (value) => {
    const idx = values.findIndex((v) => v === value.id);
    values.splice(idx, 1);
    let nv = [...values];
    onChange(nv);
    //setDropdownOpen(false);
  };

  const onMenuRemoveAll = () => {
    onChange([]);
  };

  const onUnselectAll = (sites) => {
    sites.forEach((s) => {
      const idx = values.findIndex((v) => v === s.id);
      if (idx > -1) {
        values.splice(idx, 1);
      }
    });
    const nv = [...values];
    onChange(nv);
  };

  const onMenuChange = (menu) => () => {
    setSelectedMenu(menu);
  };

  const onMenuSelectAll = (items) => {
    const nv =
      items.filter((item) => {
        const idx = values.findIndex((v) => v === item.id);
        return idx < 0;
      }) || [];

    const practicesIDs = nv.map((i) => i.id);
    const np = [...values, ...practicesIDs];
    onChange(np);
  };

  const handleMenuItemClick = (practice) => () => {
    onPracticeChange(practice);
    setSelectedMenu(practice.name);
    setSelectedSubMenu("");
  };

  const getNHPractices = (practices) => {
    const nursingHomes = practices.nursingHomes || {};
    let v = [];
    for (const state in nursingHomes) {
      v = [...v, ...nursingHomes[state]];
    }

    return v;
  };

  const otherSites = getOtherSites(practices) || [];
  const nhPractices = getNHPractices(practices);
  const hasNursingHomeSites = practices.nursingHomes ? true : false;
  const nursingHomes = practices.nursingHomes || [];

  const allPractices = [...otherSites, ...nhPractices];

  // extract the selected option ids, filtering undefined pratices
  const selectedOptions = values
    .map((v) => allPractices.find((ap) => ap.id == v))
    .filter((item) => !!item);

  return (
    <div className="main-select">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" id="qa-dropdown-wrapper-practice">
        <DropdownToggle tag="div">
          <Select
            id="qa-practice-select"
            value={selectedOptions}
            getOptionLabel={getPracticeLabel}
            components={{ MultiValueRemove, ClearIndicator }}
            isSearchable={false}
            isMulti
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            noOptionsMessage={() => null}
            onMenuRemove={onMenuRemove}
            onMenuRemoveAll={onMenuRemoveAll}
            className={"qa-practice-dropdown"}
          />
        </DropdownToggle>
        <DropdownMenu
          className="menu w-100"
          tag="div"
          id="qa-dropdown-menu-select"
        >
          {hasNursingHomeSites && (
            <DropdownItem
              id="qa-dropdown-item-select"
              className="menu-item"
              toggle={false}
              tag="div"
              active={selectedMenu === nursingHomeOption}
            >
              <SubMenuDropDown
                name={nursingHomeOption}
                onPracticeChange={onPracticeChange}
                onMenuChange={onMenuChange(nursingHomeOption)}
                isDropDownOpen={selectedMenu === nursingHomeOption}
                setSelectedSubMenu={setSelectedSubMenu}
                selectedSubMenu={selectedSubMenu}
                nursingHomes={nursingHomes}
                selectedSites={selectedOptions}
                onMenuRemove={onMenuRemove}
                onMenuSelectAll={onMenuSelectAll}
                onUnselectAll={onUnselectAll}
                className={"qa-practice-dropdown-2"}
              />
            </DropdownItem>
          )}

          {otherSites.map((practice, idx) => (
            <DropdownItem
              id={`qa-otherSites-select-${idx}`}
              key={`practice-${idx}`}
              className="menu-item"
              toggle={false}
              tag="div"
              onClick={handleMenuItemClick(practice)}
              active={selectedMenu === practice.name}
              
            >
              {practice.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

MedicalPracticeSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChang: PropTypes.func,
};
