import React, { useState, useEffect } from "react";
import "../../stylesheets/encounterMonitor.scss";
import { Button } from "reactstrap";
import ReactPaginate from "react-paginate";

const PaginationAE = ({ totalCount, pageSize, pageStart, onChange }) => {
  const handlePageChange = (e) => {
    let nextPageStart = e.selected * pageSize;
    let nextPageSize = nextPageStart < totalCount ? pageSize : totalCount - nextPageStart;

    onChange(nextPageStart, nextPageSize);
  };

  const endCount = pageStart + pageSize > totalCount ? totalCount : pageStart + pageSize;

  const pageCount = Math.ceil(totalCount / pageSize);

  if (totalCount < 1) {
    return null;
  }

  return (
    <>
      <span className="paginationKey">{`Displaying items: ${pageStart}-${endCount}`}</span>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={(e) => handlePageChange(e)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        className="monitorPagination"
      />
    </>
  );
};

export default PaginationAE;
