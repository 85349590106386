import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import styled, { css } from "styled-components";
import Warning from "./icons/warning.svg";

import { DeleteButton } from "./IconButton";

const BoldHeader = styled.h3`
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  margin-top: 15px;
`;

const Message = styled.p`
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  margin-top: 25px;
`;

const ConfirmationModal = ({ showModal, onCancel, onDelete }) =>
  showModal ? (
    <div className="modal-overlay d-flex flex-column">
      <div
        className="ml-auto mr-auto provider-message-container d-flex flex-column justify-content-between"
        style={{ padding: 20 }}
      >
        <Warning style={{ marginLeft: "auto", marginRight: "auto" }} />
        <BoldHeader>Delete patient image?</BoldHeader>
        <Message>
          All instances of this image will be removed.
          <br />
          This cannot be undone.
        </Message>
        <div className="mx-auto d-flex align-items-end" style={{ height: 80 }}>
          <Button color="outline" onClick={onCancel} style={{ color: "#4a8fe7", marginRight: 20 }}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={onDelete}
            style={{ borderRadius: 6, paddingLeft: 40, paddingRight: 40 }}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  ) : null;

interface IProps {
  onDelete: () => void;
}

interface IState {
  showModal: boolean;
}

export default class ConfirmedDelete extends PureComponent<IProps, IState> {
  state = {
    showModal: false,
  };

  render() {
    return (
      <>
        <DeleteButton
          onClick={() => this.setState({ showModal: true })}
          style={{ position: "absolute", right: 20, bottom: 5 }}
        />
        <ConfirmationModal
          showModal={this.state.showModal}
          onCancel={() => {
            this.setState({ showModal: false });
          }}
          onDelete={() => {
            this.setState({ showModal: false });
            this.props.onDelete();
          }}
        />
      </>
    );
  }
}
