import React, { Component, FC } from "react";
import BlockSection from "./BlockSection";
import { boolToString } from "./LungAllergyHistory";
import AllergyEnvironmentalEffectEditor from "./AllergyEnvironmentalEffectEditor";
import { IAllergyEnvironmentalEffect } from "../../constants/Types";

type ViewProps = {
  allergyEnv: IAllergyEnvironmentalEffect;
  title: string;
};

const View: FC<ViewProps> = ({ allergyEnv, title }) => {
  return (
    <div>
      <h1 className="medical-history-title">{title}</h1>
      <BlockSection title={"Environmental Factors"}>
        <ul>
          <li>When do you have symptoms? {allergyEnv.whenSymptoms}</li>
          <li>Pets cause symptoms: {boolToString(allergyEnv.petsCauseSymptoms)}</li>
          {allergyEnv.petsCauseSymptoms && (
            <li>Pets causing symptoms: {(allergyEnv.pets || []).join(", ")}</li>
          )}
        </ul>
      </BlockSection>
      <BlockSection title={"Other Factors"}>
        <ul>
          {allergyEnv.otherFactors &&
            Object.keys(allergyEnv.otherFactors).map((key, idx) => (
              <li key={idx}>
                {key}: {allergyEnv.otherFactors[key]}
              </li>
            ))}
          {!allergyEnv.otherFactors && <li>None given</li>}
        </ul>
      </BlockSection>
    </div>
  );
};

type Props = {
  onChanged: (value: object) => void;
  allergyEnv: IAllergyEnvironmentalEffect;
  title?: string;
  visitType: string;

  restoreData?: any; // -
  isEditing?: boolean; // -
};

class AllergyEnvironmentalEffect extends Component<Props> {
  onFieldChange = (newValue: object) => {
    this.props.onChanged({ allergyEnvironmentalEffect: newValue });
  };
  render() {
    const allergyEnv = this.props.allergyEnv;
    if (!this.props.isEditing) {
      return <View allergyEnv={allergyEnv} title={this.props.title || ""} />;
    } else {
      return (
        <AllergyEnvironmentalEffectEditor
          allergyEnv={allergyEnv}
          oldAllergyEnv={this.props.restoreData.intakeHistory.allergyEnvironmentalEffect}
          onChange={this.onFieldChange}
        />
      );
    }
  }
}

export default AllergyEnvironmentalEffect;
