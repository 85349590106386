import React, { useEffect, useState, FC } from "react";
import { Input } from "reactstrap";

import PDFDownloadButton from "./PDFDownloadButton";
import Contraindications from "./Contraindications";
import BlockSection from "./BlockSection";

import { RequisitionVisitTypes } from "../../constants/RequisitionTypes";

import { IReviewOfSystems, IUserDetails, IUserMedicalIntake } from "../../constants/Types";
import api from "../../api";
export interface ChiefComplaintProps {
  intake: IUserMedicalIntake;
  catalog?: Partial<IReviewOfSystems>;
  patient?: IUserDetails;
  onChange: Function;
  visitType: string;
  value?: string;
  encounterID: string;
}

const ChiefComplaint: FC<ChiefComplaintProps> = (props) => {
  const {
    intake = {} as Partial<IUserMedicalIntake>,
    catalog,
    patient = {} as Partial<IUserDetails>,
    visitType,
  } = props;
  const { complaint = "" } = intake.chiefComplaint || {};
  const [symptoms, setSymptoms] = useState<string[]>([]);
  const [referenceID, setReferenceID] = useState<string>();

  const currentSymptomsBySystem = intake.currentSymptomsBySystem || {};
  const reviewOfSystemsKeys: Partial<typeof intake.reviewOfSystemsKeys> =
    intake.reviewOfSystemsKeys || {};

  useEffect(() => {
    const CS = getSymptoms(currentSymptomsBySystem.systems);
    const ROS = getSymptoms(reviewOfSystemsKeys.systems);
    setSymptoms(CS.concat(ROS));
    if (!referenceID) {
      api.Encounters.engagementStatus().then((data) => {
        setReferenceID(data.referenceID);
      });
    }
  }, [catalog, currentSymptomsBySystem, reviewOfSystemsKeys]);

  const getSymptoms = (systems: any): string[] => {
    const tempSymptoms: string[] = [];
    // don't index the catalog if it's undefined or empty
    if (!catalog || Object.keys(catalog).length === 0) return [];
    const systemKeys = systems ? Object.keys(systems) : [];
    systemKeys.forEach((systemKey) => {
      const systemName = catalog.systems ? catalog.systems[Number(systemKey)] : "";
      systems[Number(systemKey)] &&
        systems[Number(systemKey)].forEach((symptom: string) => {
          catalog.items![systemName].find((catalogSymptom: any) => {
            if (symptom === catalogSymptom.key && catalogSymptom.coronavirus) {
              tempSymptoms.push(catalogSymptom.label);
            }
          });
        });
    });
    return tempSymptoms;
  };

  if (visitType === "nursing_home" && !props.readOnly) {
    return (
      <BlockSection title="Chief Complaint">
        <Input
          style={{ height: "70px" }}
          type="textarea"
          disabled={false}
          name="chiefComplaint"
          onChange={(e) => !!props.onChange && props.onChange(e)}
          value={props.value}
          id="qa-encounter-chiefcomplaint-freetext"
        />
      </BlockSection>
    );
  }

  if (RequisitionVisitTypes.includes(visitType)) {
    return (
      <BlockSection title="Chief Complaint">
        <span> {complaint || "-"} </span>
        <div className="mt-3">
          <PDFDownloadButton visitType={visitType} encounterID={referenceID} />
        </div>
      </BlockSection>
    );
  }

  const coronavirus: Partial<typeof intake.coronavirus> = intake.coronavirus || {};
  return (
    <BlockSection title="Chief Complaint">
      <span> {complaint || "-"} </span>
      {(!!coronavirus.coronavirusContact ||
        !!intake.contraindications ||
        !!patient.covidMonitoring) && (
        <Contraindications intake={intake} symptoms={symptoms} patient={patient} />
      )}
    </BlockSection>
  );
};

export default ChiefComplaint;
