import React, { useState } from "react";
import { Button, Row, Col, FormGroup, ButtonGroup, Input } from "reactstrap";
import Select from "react-select";
import moment from "moment";

import api from "../../api";
import "./add-patient-modal.scss";
import ErrorList from "../ErrorList";
import StateSelector from "../StateSelector";

const languageChoices = [
  { value: "English", label: "English" },
  { value: "Spanish", label: "Spanish" },
];

export default ({ setModal }) => {
  const [selectedSex, setSelectedSex] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [mon, setMon] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [phoneLine, setPhoneLine] = useState("");
  const [language, setLanguage] = useState("");
  const [dobError, setDobError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [patientSexErr, setPatientSexErr] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [saveError, setSaveError] = useState();

  const validData = () => {
    let locMon = mon;
    let locDay = day;

    if (locMon.length === 1) {
      locMon = `0${locMon}`;
      setMon(locMon);
    }
    if (locDay.length === 1) {
      locDay = `0${locDay}`;
      setDay(locDay);
    }

    if (!selectedSex.trim()) {
      setPatientSexErr(true);
    } else {
      setPatientSexErr(false);
    }

    if (
      locDay.length < 2 ||
      locMon.length < 2 ||
      year.length < 4 ||
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      isNaN(locDay) ||
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      isNaN(locMon) ||
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      isNaN(year) ||
      !moment(`${year}-${locMon}-${locDay}`).isValid() ||
      moment(`${year}-${locMon}-${locDay}`).utc() > moment(new Date()).utc()
    ) {
      setDobError(true);
    } else {
      setDobError(false);
    }

    if (first.length < 1 || last.length < 1) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    if (isNaN(zip) || zip.length < 1) {
      setZipError(true);
    } else {
      setZipError(false);
    }

    if (city.length < 1) {
      setCityError(true);
    } else {
      setCityError(false);
    }

    if (street.length < 1) {
      setStreetError(true);
    } else {
      setStreetError(false);
    }
    if (state.length != 2) {
      setStateError(true);
    } else {
      setStateError(false);
    }

    if (!!!language) {
      setLanguageError(true);
    } else {
      setLanguageError(false);
    }

    if (
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      isNaN(areaCode) ||
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      isNaN(phonePrefix) ||
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      isNaN(phoneLine) ||
      areaCode.length != 3 ||
      phonePrefix.length != 3 ||
      phoneLine.length != 4
    ) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }

    return (
      first.length > 0 &&
      last.length > 0 &&
      (selectedSex === "M" || selectedSex === "F") &&
      year.length === 4 &&
      locDay.length === 2 &&
      !!language &&
      locMon.length === 2 &&
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      !isNaN(locDay) &&
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      !isNaN(locMon) &&
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      !isNaN(year) &&
      moment(`${year}-${locMon}-${locDay}`).isValid() &&
      moment(`${year}-${locMon}-${locDay}`).utc() <= moment(new Date()).utc() &&
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      !isNaN(zip) &&
      city.length > 0 &&
      street.length > 0 &&
      state.length == 2 &&
      zip.length > 0 &&
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      !isNaN(areaCode) &&
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      !isNaN(phonePrefix) &&
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      !isNaN(phoneLine) &&
      areaCode.length == 3 &&
      phonePrefix.length == 3 &&
      phoneLine.length == 4
    );
  };

  const onSubmit = () => {
    if (!validData()) {
      return;
    }

    const newPatient = {
      firstName: first,
      LastName: last,
      dob: `${year}-${mon}-${day}`,
      sex: selectedSex,
      street,
      city,
      state,
      postalCode: zip,
      language,
      phone: `${areaCode}${phonePrefix}${phoneLine}`,
    };

    api.UserRecords.createUser(newPatient)
      .then(setModal)
      .catch((error) => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Patient not saved please try ag... Remove this comment to see the full error message
        setSaveError("Patient not saved please try again.");
      });
  };
  return (
    <div className="modal-overlay">
      <div className="ml-auto mr-auto add-patient-modal">
        <h4>New Patient Chart</h4>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        {saveError && <ErrorList errors={[saveError]} />}
        <FormGroup>
          <Row>
            <Col>
              <label>Patient First Name</label>
              <Input
                className="input-field"
                placeholder="Enter First Name"
                onChange={(e) => setFirst(e.target.value)}
              />
            </Col>
            <Col>
              <label>Patient Last Name</label>
              <Input
                className="input-field"
                placeholder="Enter Last Name"
                onChange={(e) => setLast(e.target.value)}
              />
            </Col>
          </Row>
          {nameError && (
            <div className="d-flex justify-content-center error-message">
              * Patient Name required
            </div>
          )}
          <Row className="mt-4">
            <Col>
              <label>Date of Birth</label>
              <div className="d-flex">
                <Input
                  type="text"
                  maxLength="2"
                  placeholder="MM"
                  onChange={(e) => setMon(e.target.value)}
                  className="input-field"
                  style={{ width: "3.5rem" }}
                />
                <span className="dob-separator"> /</span>
                <Input
                  type="text"
                  maxLength="2"
                  placeholder="DD"
                  onChange={(e) => setDay(e.target.value)}
                  className="input-field"
                  style={{ width: "3.5rem" }}
                />
                <span className="dob-separator"> /</span>
                <Input
                  type="text"
                  maxLength="4"
                  placeholder="YYYY"
                  onChange={(e) => setYear(e.target.value)}
                  className="input-field"
                  style={{ width: "4.5rem" }}
                />
              </div>
              {dobError && <span className="error-message">* valid DOB is required</span>}
            </Col>
            <Col>
              <label>Patient Sex</label>
              <ButtonGroup>
                <Input
                  type="button"
                  value="Male"
                  onClick={() => setSelectedSex("M")}
                  className={selectedSex === "M" ? "selectButtonActive" : "selectButton"}
                />
                <Input
                  type="button"
                  value="Female"
                  onClick={() => setSelectedSex("F")}
                  className={selectedSex === "F" ? "selectButtonActive" : "selectButton"}
                />
              </ButtonGroup>
              <br />
              {patientSexErr && <span className="error-message">* Patient Sex required</span>}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-6">
              <label>Language</label>
              <Select
                options={languageChoices}
                onChange={(option) => setLanguage(option.value)}
                backspaceRemovesValue={false}
              />
              {languageError && (
                <span className="error-message">* Patient Language is required</span>
              )}
            </Col>
            <Col className="col-6">
              <label>Phone Number</label>
              <div className="d-flex">
                <span className="dob-separator">(</span>
                <Input
                  className="input-field"
                  type="text"
                  maxLength="3"
                  style={{ width: "3.5rem" }}
                  onChange={(e) => setAreaCode(e.target.value)}
                />
                <span className="dob-separator">) </span>
                <Input
                  className="input-field"
                  type="text"
                  maxLength="3"
                  style={{ width: "3.5rem" }}
                  onChange={(e) => setPhonePrefix(e.target.value)}
                />
                <span className="dob-separator">-</span>
                <Input
                  className="input-field"
                  type="text"
                  maxLength="4"
                  style={{ width: "4.5rem" }}
                  onChange={(e) => setPhoneLine(e.target.value)}
                />
              </div>
              {phoneError && (
                <span className="error-message">* Please enter a valid phone number</span>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-12">
              <label>Street</label>
              <Input
                className="input-field"
                placeholder="Enter Street"
                onChange={(e) => setStreet(e.target.value)}
              />
              {streetError && <span className="error-message">* Please enter a valid street</span>}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-4">
              <label>City</label>
              <Input
                className="input-field"
                placeholder="Enter City"
                onChange={(e) => setCity(e.target.value)}
              />
              {cityError && <span className="error-message">* Please enter a valid city</span>}
            </Col>

            <Col className="col-4">
              <label>State</label>
              <StateSelector onChange={(e) => setState(e.target.value)} value={state} />
              {stateError && <span className="error-message">* Please enter a valid state</span>}
            </Col>
            <Col className="col-4">
              <label>Postal Code</label>
              <Input
                className="input-field"
                placeholder="Enter Code"
                onChange={(e) => setZip(e.target.value)}
              />
              {zipError && (
                <span className="error-message">* Please enter a valid postal code</span>
              )}
            </Col>
          </Row>
        </FormGroup>
        <div className="d-flex mt-4 align-items-center">
          <div className="cancelButton" onClick={setModal}>
            Cancel
          </div>
          <Button className="saveButton" onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
