import React from "react";
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module './Success.scss' or its corresp... Remove this comment to see the full error message
import styles from "./Success.scss";
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../images/CheckMarkWhite.sv... Remove this comment to see the full error message
import CheckMarkWhite from "../../images/CheckMarkWhite.svg";
import Proptypes from "prop-types";

const Success = ({ message }) => {
  return (
    <div className="modal-overlay d-flex flex-column">
      <div className={styles.successDialog}>
        <div className={styles.checkMark}>
          <CheckMarkWhite />
        </div>
        <h1>
          Success
          <br />
          {message}
        </h1>
      </div>
    </div>
  );
};

Success.propTypes = {
  message: Proptypes.string,
};

export default Success;
