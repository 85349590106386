import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";

import InfoSelect from "../../../containers/InfoSelect";
import YesNoToggle from "../../../containers/YesNoToggle";
import CheckToggle from "../../../containers/CheckToggle";
import { womensHealthHistory } from "../../../constants/Encounter";
import ListEditor from "./ListEditor";
import {
  ethnicityOptions,
  raceOptions,
  marriageOptions,
  tobaccoOptions,
  tobaccoFrequency,
  packsPerDayOptions,
  yearsUsedOptions,
  alcoholUseOptions,
} from "../../../constants/MedicalProfile";

export const Card = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;
  padding: 20px;
`;

const SocialHistoryWrapper = styled(Card)`
  h2 {
    color: #4a8fe7;
    font-weight: bold;
    font-size: 18px;
  }
`;

const SocialHistoryHeader = styled.div`
  h2 {
    color: #4a8fe7;
    font-weight: bold;
    font-size: 18px;
  }
`;
const SocialHistoryBody = styled.div``;

const CheckBoxGroup = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
`;

const FormGroup = styled.div`
  position: relative;
  display: flex;
  font-size: 12px;
  margin-top: 1rem;

  justify-content: space-between;
  flex-direction: ${(props) => (props.column ? "column" : "row")};

  h6 {
    font-weight: 600;
    margin-top: 8px;
    font-size: 12px;
    color: #000000;
    line-height: 14px;
  }
`;

const FormGroupSelect = styled(FormGroup)`
  flex-direction: column;
  align-items: flex-start;
`;

const Form = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 20px;
`;

const InfoSelectWrapper = styled.div`
  width: 150px;
`;

const CorrectiveLenses = (props) => {
  const sh = props.correctiveLenses;
  const oh = props.oldCorrectiveLenses;

  const onFieldChange = (field) => (newValue) => {
    props.onChange({ ...sh, [field]: newValue });
  };

  return (
    <>
      <FormGroup>
        <h6>Corrective Lenses</h6>
        <YesNoToggle
          value={sh.correctiveLenses}
          oldValue={oh.correctiveLenses}
          onChange={onFieldChange("correctiveLenses")}
          className="qa-socialHistoryEditorLenses"
        />
      </FormGroup>
      <FormGroup>
        <h6>Contact Lenses</h6>
        <YesNoToggle
          value={sh.contacts}
          oldValue={oh.contacts}
          onChange={onFieldChange("contacts")}
          className="qa-socialHistoryEditorContactLenses"
        />
      </FormGroup>
      <FormGroup>
        <h6>Glasses?</h6>
        <YesNoToggle
          value={sh.glasses}
          oldValue={oh.glasses}
          onChange={onFieldChange("glasses")}
          className="qa-socialHistoryEditorGlasses"
        />
      </FormGroup>
    </>
  );
};

const WomenHealth = ({ profile, oldProfile, ...props }) => {
  const { timesPregnantOptions, timesDelivered, timeOnOralBC, contraceptionOptions } =
    womensHealthHistory;

  useEffect(() => {
    if (profile.numberOfPregnancies < profile.numberOfDeliveries) {
      props.onProfileFieldChange("numberOfDeliveries")(0);
    }
  }, [profile]);

  const searchableItemListSource = (text) => {
    let filter = contraceptionOptions.filter(
      (item) => item.toLowerCase().indexOf(text.toLowerCase()) > -1,
    );
    return { items: filter };
  };

  const numberOfDeliveriesOptions = timesDelivered.slice(
    0,
    timesDelivered.indexOf(profile.numberOfPregnancies) + 1,
  );
  return (
    <>
      <FormGroup>
        <h6>Has been pregnant?</h6>
        <YesNoToggle
          onChange={props.onPregnancyChange}
          value={profile.hasBeenPregnant}
          oldValue={oldProfile.hasBeenPregnant}
          className="qa-socialHistoryEditorWomensHealthPregnant"
        />
      </FormGroup>

      {profile.hasBeenPregnant && (
        <>
          <FormGroup column>
            <h6>Number of pregnancies:</h6>
            <InfoSelectWrapper>
              <InfoSelect
                value={profile.numberOfPregnancies}
                options={timesPregnantOptions}
                oldValue={oldProfile.numberOfPregnancies}
                onChange={props.onProfileFieldChange("numberOfPregnancies")}
                className="qa-socialHistoryEditorWomensHealthNumberOfPregnancies"
              />
            </InfoSelectWrapper>
          </FormGroup>
          <FormGroup column>
            <h6>Number of deliveries:</h6>
            <InfoSelectWrapper>
              <InfoSelect
                value={profile.numberOfDeliveries}
                options={numberOfDeliveriesOptions}
                oldValue={oldProfile.numberOfDeliveries}
                onChange={props.onProfileFieldChange("numberOfDeliveries")}
                className="qa-socialHistoryEditorWomensHealthNumberOfDeliveries"
              />
            </InfoSelectWrapper>
          </FormGroup>
        </>
      )}

      <FormGroup column>
        <h6>Birth control method:</h6>
        <ListEditor
          items={profile.birthControlMethod}
          oldItems={oldProfile.birthControlMethod}
          itemListSource={searchableItemListSource}
          onChange={props.onContraceptionChange}
          pillList
          md={4}
          sm={12}
          className="qa-socialHistoryEditorWomensHealthBirthControlMethod"
        />
      </FormGroup>
      {profile.birthControlMethod &&
        profile.birthControlMethod.indexOf("Birth control pills") > -1 && (
          <FormGroup column>
            <h6>How long on birth control pills?</h6>
            <InfoSelectWrapper>
              <InfoSelect
                value={profile.birthControlDuration}
                options={timeOnOralBC}
                oldValue={oldProfile.birthControlDuration}
                onChange={props.onProfileFieldChange("birthControlDuration")}
                className="qa-socialHistoryEditorWomensHealthBirthControlDuration"
              />
            </InfoSelectWrapper>
          </FormGroup>
        )}
    </>
  );
};

type SocialHistoryEditProps = {
  correctiveLenses?: any;
  oldCorrectiveLenses?: any;
  oldSocialHistory?: any;
  oldWomensHealthProfile?: any;
  onChange?: (...args: any[]) => any;
  onCorrectiveLensesChange?: (...args: any[]) => any;
  onProfileChange?: (...args: any[]) => any;
  onTobaccoTypeChange?: (...args: any[]) => any;
  patient?: any;
  socialHistory?: any;
  womensHealthProfile?: any;
  className: string;
};

export default function SocialHistoryEdit(props: SocialHistoryEditProps) {
  const onFieldChange = (field) => (newValue) => {
    if (field === "currentTobaccoList" || field === "pastTobaccoList") {
      newValue = [newValue];
    }
    if (field === "tobaccoNow") {
      let toFalse = "tobaccoEver";
      props.onChange({ ...sh, [field]: newValue, [toFalse]: false });
    } else {
      props.onChange({ ...sh, [field]: newValue });
    }
  };

  const onEthnicityRaceChange = ({ field, value }) => {
    props.onChange({ ...sh, [field]: value });
  };

  const getValueOrPlaceHolder = (property) => {
    return property ? property : "Select Option";
  };

  const onTobaccoTypeChange = (field, oldValue) => (newValue) => {
    const sh = props.socialHistory;
    const oh = props.oldSocialHistory;
    newValue = [newValue];
    if (oldValue && oldValue.indexOf("Cigarette") > -1 && newValue[0] !== "Cigarette") {
      let currentToNull = "currentTobaccoCount";
      let pastToNull = "pastTobaccoCount";

      props.onChange({
        ...sh,
        [field]: newValue,
        [pastToNull]: null,
        [currentToNull]: null,
      });
    } else if (oldValue && oldValue.indexOf("Cigarette") < 0 && newValue[0] === "Cigarette") {
      let currentToNull = "currentTobaccoFrequency";
      let pastToNull = "pastTobaccoFrequency";

      props.onChange({
        ...sh,
        [field]: newValue,
        [pastToNull]: null,
        [currentToNull]: null,
      });
    } else {
      props.onChange({ ...sh, [field]: newValue });
    }
  };

  const getToggleEthnicity = (key) => {
    return (checked) => {
      if (checked) {
        ethnicitySelected.push(key);
      } else {
        let index = ethnicitySelected.indexOf(key);
        if (index > -1) {
          ethnicitySelected.splice(index, 1);
        }
      }

      onEthnicityRaceChange({
        field: "ethnicity",
        value: ethnicitySelected,
      });
    };
  };

  const getToggleRace = (key) => {
    const raceselectedcopy = raceSelected.slice();
    return (checked) => {
      if (checked) {
        raceselectedcopy.push(key);
      } else {
        let index = raceselectedcopy.indexOf(key);
        if (index > -1) {
          raceselectedcopy.splice(index, 1);
        }
      }
      onEthnicityRaceChange({
        field: "race",
        value: raceselectedcopy,
      });
    };
  };

  const handleProfileFieldChange = (field) => (newValue) => {
    props.onProfileChange({ ...props.womensHealthProfile, [field]: newValue });
  };

  const handleContraceptionChange = (newValue) => {
    const indexOfNone = newValue.indexOf("None");
    if (indexOfNone > -1) {
      newValue.splice(indexOfNone, 1);
    }
    if (newValue && newValue.indexOf("Birth control pills") > -1) {
      props.onProfileChange({ ...props.womensHealthProfile, birthControlMethod: newValue });
    } else {
      let value = newValue.length ? newValue : ["None"];
      props.onProfileChange({
        ...props.womensHealthProfile,
        birthControlMethod: value,
        birthControlDuration: null,
      });
    }
  };

  const handlePregnancyChange = (newValue) => {
    props.onProfileChange({
      ...props.womensHealthProfile,
      hasBeenPregnant: newValue,
      numberOfPregnancies: 0,
      numberOfDeliveries: 0,
    });
  };

  const sh = props.socialHistory;
  const oh = props.oldSocialHistory;
  let raceSelected = props.socialHistory.race || [];
  let ethnicitySelected = props.socialHistory.ethnicity || [];

  return (
    <SocialHistoryWrapper>
      <SocialHistoryHeader>
        <h2>Social History</h2>
      </SocialHistoryHeader>
      <SocialHistoryBody>
        <Row>
          <Col md={{ size: 7 }}>
            <FormGroupSelect>
              <h6>Marital Status</h6>
              <InfoSelectWrapper>
                <InfoSelect
                  value={sh.maritalStatus}
                  options={marriageOptions}
                  oldValue={oh.maritalStatus}
                  onChange={onFieldChange("maritalStatus")}
                  className="qa-socialHistoryEditorMaritalStatus"
                />
              </InfoSelectWrapper>
            </FormGroupSelect>
            <FormGroup>
              <h6>Works in healthcare?</h6>
              <YesNoToggle
                value={sh.workInHealthcare}
                oldValue={oh.workInHealthcare}
                onChange={onFieldChange("workInHealthcare")}
                className="qa-socialHistoryEditorHealthcare"
              />
            </FormGroup>

            <CorrectiveLenses
              oldCorrectiveLenses={props.oldCorrectiveLenses}
              correctiveLenses={props.correctiveLenses}
              onChange={props.onCorrectiveLensesChange}
              className="qa-socialHistoryEditorLenses"
            />

            <FormGroup>
              <h6>Current Tobacco Smoker</h6>
              <YesNoToggle
                value={sh.tobaccoNow}
                oldValue={oh.tobaccoNow}
                onChange={onFieldChange("tobaccoNow")}
                className="qa-socialHistoryEditorTobaccoNow"
              />
            </FormGroup>

            {!sh.tobaccoNow && (
              <FormGroup>
                <h6>Former Tobacco User</h6>
                <YesNoToggle
                  value={sh.tobaccoEver}
                  oldValue={oh.tobaccoEver}
                  onChange={onFieldChange("tobaccoEver")}
                  className="qa-socialHistoryEditorTobaccoEver"
                />
              </FormGroup>
            )}

            {sh.tobaccoNow && (
              <>
                <div className="d-flex justify-content-between">
                  <FormGroupSelect>
                    <h6>What do you use?</h6>
                    <InfoSelectWrapper>
                      <InfoSelect
                        value={getValueOrPlaceHolder(sh.currentTobaccoList)}
                        options={tobaccoOptions}
                        oldValue={oh.currentTobaccoList}
                        onChange={onTobaccoTypeChange("currentTobaccoList", oh.currentTobaccoList)}
                        className="qa-socialHistoryEditorTobaccoType"
                      />
                    </InfoSelectWrapper>
                  </FormGroupSelect>
                  <div className="ml-1">
                    {!sh.currentTobaccoList || sh.currentTobaccoList.indexOf("Cigarette") > -1 ? (
                      <FormGroupSelect>
                        <h6>How many packs per day?</h6>
                        <InfoSelectWrapper>
                          <InfoSelect
                            value={getValueOrPlaceHolder(sh.currentTobaccoCount)}
                            options={packsPerDayOptions}
                            oldValue={oh.currentTobaccoCount}
                            onChange={onFieldChange("currentTobaccoCount")}
                            className="qa-socialHistoryEditorTobaccoCount"
                          />
                        </InfoSelectWrapper>
                      </FormGroupSelect>
                    ) : (
                      <FormGroupSelect>
                        <h6>How often?</h6>
                        <InfoSelectWrapper>
                          <InfoSelect
                            value={getValueOrPlaceHolder(sh.currentTobaccoFrequency)}
                            options={tobaccoFrequency}
                            oldValue={oh.currentTobaccoFrequency}
                            onChange={onFieldChange("currentTobaccoFrequency")}
                            className="qa-socialHistoryEditorTobaccoFrequency"
                          />
                        </InfoSelectWrapper>
                      </FormGroupSelect>
                    )}
                  </div>
                </div>
              </>
            )}

            {!sh.tobaccoNow && sh.tobaccoEver && (
              <>
                <div className="d-flex justify-content-between">
                  <FormGroup column>
                    <h6>What did you use?</h6>
                    <InfoSelectWrapper>
                      <InfoSelect
                        value={getValueOrPlaceHolder(sh.pastTobaccoList)}
                        options={tobaccoOptions}
                        oldValue={oh.pastTobaccoList}
                        onChange={onTobaccoTypeChange("pastTobaccoList", oh.pastTobaccoList)}
                        className="qa-socialHistoryEditorTobaccoType"
                      />
                    </InfoSelectWrapper>
                  </FormGroup>
                  <div className="ml-1">
                    {!sh.pastTobaccoList || sh.pastTobaccoList.indexOf("Cigarette") > -1 ? (
                      <FormGroup column>
                        <h6>How many packs per day?</h6>
                        <InfoSelectWrapper>
                          <InfoSelect
                            value={getValueOrPlaceHolder(sh.pastTobaccoCount)}
                            options={packsPerDayOptions}
                            oldValue={oh.pastTobaccoCount}
                            onChange={onFieldChange("pastTobaccoCount")}
                            className="qa-socialHistoryEditorTobaccoCount"
                          />
                        </InfoSelectWrapper>
                      </FormGroup>
                    ) : (
                      <FormGroup column>
                        <h6>How often?</h6>
                        <InfoSelectWrapper>
                          <InfoSelect
                            value={getValueOrPlaceHolder(sh.pastTobaccoFrequency)}
                            options={tobaccoFrequency}
                            oldValue={oh.pastTobaccoFrequency}
                            onChange={onFieldChange("pastTobaccoFrequency")}
                            className="qa-socialHistoryEditorTobaccoFrequency"
                          />
                        </InfoSelectWrapper>
                      </FormGroup>
                    )}
                  </div>
                </div>
                <FormGroupSelect>
                  <h6>For how many years?</h6>
                  <InfoSelectWrapper>
                    <InfoSelect
                      value={getValueOrPlaceHolder(sh.pastTobaccoYears)}
                      options={yearsUsedOptions}
                      oldValue={oh.pastTobaccoYears}
                      onChange={onFieldChange("pastTobaccoYears")}
                      className="qa-socialHistoryEditorTobaccoDuration"
                    />
                  </InfoSelectWrapper>
                </FormGroupSelect>
              </>
            )}
            <FormGroup>
              <h6>Drinks alcohol?</h6>
              <YesNoToggle
                value={sh.alcoholConsumption}
                oldValue={oh.alcoholConsumption}
                onChange={onFieldChange("alcoholConsumption")}
                className="qa-socialHistoryEditorAlcohol"
              />
            </FormGroup>
            {sh.alcoholConsumption && (
              <FormGroupSelect>
                <h6>Frequency of alcohol consumption?</h6>
                <InfoSelectWrapper>
                  <InfoSelect
                    value={sh.alcoholConsumptionFrequency}
                    options={alcoholUseOptions}
                    oldValue={sh.alcoholConsumptionFrequency}
                    onChange={onFieldChange("alcoholConsumptionFrequency")}
                    className="qa-socialHistoryEditorAlcoholFrequency"
                  />
                </InfoSelectWrapper>
              </FormGroupSelect>
            )}

            {props.patient.sex === "F" && (
              <WomenHealth
                profile={props.womensHealthProfile}
                oldProfile={props.oldWomensHealthProfile}
                onProfileChange={props.onProfileChange}
                onProfileFieldChange={handleProfileFieldChange}
                onContraceptionChange={handleContraceptionChange}
                onPregnancyChange={handlePregnancyChange}
                className="qa-socialHistoryEditorWomensHealth"
              />
            )}
          </Col>
          <div className="d-flex flex-column align-items-center col-md-5 col-sm-12">
            <div className="d-flex flex-column align-items-center w-75">
              <h6 className="align-self-center">Ethnicity</h6>
              <CheckBoxGroup>
                {ethnicityOptions.map((eo, idx) => (
                  <div className="mb-3">
                    <CheckToggle
                      key={idx}
                      checked={ethnicitySelected.includes(eo.key)}
                      onChange={getToggleEthnicity(eo.key)}
                      className="qa-socialHistoryEditorEthnicity check-toggle-wrapper"
                    >
                      {eo.label}
                    </CheckToggle>
                  </div>
                ))}
              </CheckBoxGroup>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex flex-column mt-5">
                <h6 className="align-self-center">Race</h6>
                <CheckBoxGroup>
                  {raceOptions.map((ro, idx) => (
                    <div className="mb-3">
                      <CheckToggle
                        key={idx}
                        checked={raceSelected.includes(ro.key)}
                        onChange={getToggleRace(ro.key)}
                        className="qa-socialHistoryEditorRace check-toggle-wrapper"
                      >
                        {ro.label}
                      </CheckToggle>
                    </div>
                  ))}
                </CheckBoxGroup>
              </div>
            </div>
          </div>
        </Row>
      </SocialHistoryBody>
    </SocialHistoryWrapper>
  );
}
