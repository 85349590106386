import React, { Component, Dispatch, FC } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import LeftArrow from "../../images/leftArrow.svg";

import "./NoteReview.scss";
import api from "../../api";
import { ENCOUNTER_LOAD_NOTE_PDF, ENCOUNTER_UNLOAD_NOTE_PDF } from "../../constants/actionTypes";
import { IAppState } from "../../reducer";

const mapStateToProps = (
  state: IAppState,
  ownProps: { encounterFinishError: string; onCancel: () => void; onComplete: () => void },
) => {
  return {
    ...ownProps,
    currentUser: state.common.currentUser,
    encounterID: state.encounter.encounterID,
    noteURL: state.encounter.noteURL,
    requisitionURL: state.encounter.requisitionURL,
    loading: state.progress[ENCOUNTER_LOAD_NOTE_PDF],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  loadNotePDF: (
    payload: Promise<{
      url: string;
      requisitionURL?: string;
    }>,
  ) => dispatch({ type: ENCOUNTER_LOAD_NOTE_PDF, payload }),
  unloadNotePDF: () => dispatch({ type: ENCOUNTER_UNLOAD_NOTE_PDF }),
});

const NotePDF: FC<{ pdfURL?: string }> = ({ pdfURL }) => {
  return (
    <div className="note-review-content flex-grow-1">
      <div className="">
        {pdfURL && (
          <object data={pdfURL} type="application/pdf">
            alt : <a href={pdfURL}>Note.</a>
          </object>
        )}
      </div>
    </div>
  );
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = {
  requested: boolean;
};

class NoteReview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { requested: false };
  }

  componentDidMount() {
    if (this.props.encounterID && !this.state.requested && !this.props.requisitionURL) {
      this.props.loadNotePDF(api.Encounters.notePDF(this.props.encounterID));
      this.setState({ requested: true });
    }
  }

  componentDidUpdate() {
    if (
      this.props.encounterID &&
      !this.props.noteURL &&
      !this.state.requested &&
      !this.props.requisitionURL
    ) {
      this.props.loadNotePDF(api.Encounters.notePDF(this.props.encounterID));
      this.setState({ requested: true });
    }
  }

  componentWillUnmount() {
    if (!this.props.requisitionURL) {
      this.props.unloadNotePDF();
    }
  }

  render() {
    return (
      <div className="dialog-container save-note-dialog">
        <div className="note-review">
          <div className="header-note-save">
            <div className="header-note-side">
              <Button className="edit-profile" onClick={this.props.onCancel}>
                <LeftArrow />
                <span>Back</span>
              </Button>
            </div>
            <div className="review-message"> Review notes before submitting </div>
            <div className="header-note-side">
              {this.props.encounterFinishError && (
                <div className="encounter-error-message">
                  <span className="ml-3">{this.props.encounterFinishError}</span>
                </div>
              )}
              <Button
                className="save-sign-note"
                disabled={this.props.loading}
                onClick={this.props.onComplete}
              >
                Save Note &amp; Complete Encounter
              </Button>
            </div>
          </div>
          <div className="d-flex flex-column align-items-stretch justify-content-start h-100">
            <NotePDF pdfURL={this.props.noteURL} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteReview);
