import React from "react";
import { Label } from "reactstrap";
import PropTypes from "prop-types";

import BlockSection from "./BlockSection";
import YesNoToggle from "./YesNoToggle";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'oldValue' implicitly has an 'any'... Remove this comment to see the full error message
export const Editor = ({ currentValue, oldValue, ...props }) => {
  const onSetToggle = (ev) => {
    props.handleToggle(ev);
  };
  return (
    <div className="d-flex">
      <div className="mr-3">
        <YesNoToggle value={currentValue} oldValue={oldValue} onChange={onSetToggle} />
      </div>
    </div>
  );
};

export default function TravelHistory(props) {
  const travelHistory = props.travelHistory;
  const oldTravelHistory =
    props.restoreData && props.restoreData.intakeHistory
      ? props.restoreData.intakeHistory.travelHistory
      : {};
  const handleToggle = (field) => (val) => {
    props.onChanged({ travelHistory: { ...travelHistory, [field]: val } });
  };
  const foreignTravel =
    "Has the patient traveled anywhere outside of the U.S. within the past 30 days?";
  let content;

  if (props.isEditing) {
    content = (
      <>
        <div>
          <Label>{foreignTravel}</Label>
          <Editor
            currentValue={travelHistory.recentlyTraveled}
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
            oldValue={oldTravelHistory.recentlyTraveled}
            handleToggle={handleToggle("recentlyTraveled")}
          />
        </div>
      </>
    );
  } else {
    content = (
      <>
        <div>
          <Label className="mr-1">{foreignTravel}</Label>
          {travelHistory.recentlyTraveled ? "Yes" : "No"}
        </div>
      </>
    );
  }
  return <BlockSection title={props.title}>{content}</BlockSection>;
}

TravelHistory.propTypes = {
  data: PropTypes.object,
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  onChanged: PropTypes.func,
  onSave: PropTypes.func,
  readOnly: PropTypes.bool,
  requiredFields: PropTypes.array,
  restoreData: PropTypes.object,
  setRequiredFields: PropTypes.func,
  showRequiredFieldsError: PropTypes.bool,
  title: PropTypes.string,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  travelHistory: PropTypes.object,
  unsavedSections: PropTypes.object,
  visitTypes: PropTypes.string,
};
