import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@git... Remove this comment to see the full error message
import Octicon, { ChevronDown, ChevronUp } from "@githubprimer/octicons-react";

import CovidZoneDropdown from "./CovidZoneDropdown";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const TableRow = (props) => {
  const dob = moment(props.dateOfBirth)
    .utc()
    .format("MM/DD/YYYY");
  const age = new Date().getFullYear() - new Date("" + props.dateOfBirth).getFullYear();
  return (
    <tr>
      <td>
        <Button
          className="btn-delete-cross m-right"
          size="sm"
          outline
          color="white"
          onClick={() => props.removeFromList(props.userID)}
        >
          X
        </Button>{" "}
        {props.firstName} {props.lastName}
      </td>
      <td>
        {" "}
        <div className="roundingStatusZone">
          <CovidZoneDropdown
            status={props.covidZone}
            userID={props.userID}
            setPatientZone={props.setPatientZone}
          />
        </div>
      </td>
      <td>{props.PCCMRN}</td>
      <td>{props.sex}</td>
      <td>
        {dob} ({age} years old)
      </td>
      <td className="d-flex">
        <Button className="btn-view" onClick={() => props.viewPatient(props.userID)}>
          View Patient
        </Button>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  dateOfBirth: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userID: PropTypes.string,
  sex: PropTypes.string,
  PCCMRN: PropTypes.string,
  setPatientZone: PropTypes.func,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  covidZone: PropTypes.string,
  viewPatient: PropTypes.func,
};

const SortHeader = (props) => {
  const handleClick = () => {
    props.onClick(props.sortType, props.activeSortDirection);
  };
  return (
    <div className="sortHeader">
      <a
        style={{
          color: "inherit",
        }}
        {...(props.onClick
          ? {
              href: "#",
              onClick: handleClick,
            }
          : null)}
      >
        {props.children}
      </a>
      {!!props.onClick && props.sortType === props.activeSortType && (
        <span className="arrow active">
          <a href="#" onClick={handleClick}>
            <Octicon
              // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'currentSortType' implicitly has a... Remove this comment to see the full error message
              icon={props.activeSortDirection === "ascending" ? ChevronDown : ChevronUp}
              size={18}
            />
          </a>
        </span>
      )}
    </div>
  );
};

export const TableHeader = ({ currentSortType, currentSortDirection, onSortChange }) => {
  return (
    <thead>
      <tr>
        <th>
          <div style={{ verticalAlign: "bottom" }}>
            <SortHeader
              sortType="FirstName"
              activeSortType={currentSortType}
              activeSortDirection={currentSortDirection}
              onClick={onSortChange}
            >
              Patient Name
            </SortHeader>
          </div>
        </th>
        <th>
          <SortHeader
            sortType="CovidZone"
            activeSortType={currentSortType}
            activeSortDirection={currentSortDirection}
            onClick={onSortChange}
          >
            COVID-19 Zone
          </SortHeader>
        </th>
        <th>Patient ID</th>
        <th>Sex</th>
        <th>DOB</th>
        <th> </th>
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  currentSortType: PropTypes.string,
  currentSortDirection: PropTypes.string,
  onSortChange: PropTypes.func,
};
