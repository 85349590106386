import { Component } from "react";
import { connect } from "react-redux";

import api from "../../api";
import {
  COMPONENT_LOAD,
  COMPONENT_UNLOAD,
  ENCOUNTER_EJECTED,
  ENCOUNTER_POLLER_DATA,
} from "../../constants/actionTypes";
import { IN_ENCOUNTER } from "../../constants/Encounter";
import { IAppState } from "../../reducer";

const mapStateToProps = (state: IAppState) => {
  return {
    currentTime: state.time.currentTime,
    response: state.componentData[ENCOUNTER_POLLER_DATA],
    referenceID: state.encounter.referenceID,
    asyncVisit: state.encounter.details.asyncChat,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ejectedEncounter: (payload) =>
    dispatch({ type: ENCOUNTER_EJECTED, targetPath: "/waiting-room", payload }),
  onPoll: (payload) => dispatch({ type: COMPONENT_LOAD, subtype: ENCOUNTER_POLLER_DATA, payload }),
  onUnload: () => dispatch({ type: COMPONENT_UNLOAD, subtype: ENCOUNTER_POLLER_DATA }),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type State = {
  polling: boolean;
  nextTime: number | null;
};

class EncounterPoller extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { polling: false, nextTime: null };
  }

  poll() {
    // 5 seconds plus or minus timer resolution, but timer resolution is currently 15 s
    const nextTime = new Date().getTime() + 5000;
    this.setState({ polling: true, nextTime: nextTime });
    this.props.onPoll(api.Encounters.engagementStatus());
  }

  componentDidMount() {
    if (!this.state.polling) {
      this.poll();
    }
  }

  componentDidUpdate() {
    if (!this.state.polling) {
      this.poll();
    }

    if (this.state.nextTime && this.props.currentTime > this.state.nextTime) {
      this.poll();
    }

    if (
      this.props.response &&
      this.props.response.data &&
      this.props.response.data.status !== IN_ENCOUNTER
    ) {
      // This can happen for some number of reasons, we don't know which one.
      this.props.ejectedEncounter({
        referenceID: this.props.referenceID,
        message: "The encounter has been lost; you may accept another patient.",
      });
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EncounterPoller);
