import React, { FC } from "react";
import { Button } from "reactstrap";
import ButtonSpinner from "../ButtonSpinner";
import CheckCircle from "../../images/CheckCircle.svg";

type Props = {
  onBtnClick: () => void;
  classes: string;
  inProgress: boolean;
  children: React.ReactNode;
  isAlreadyExist: boolean
}

const ButtonWithLoader: FC<Props> = (props) => {
  return (
    <Button
      onClick={props.onBtnClick}
      className={props.classes}
      disabled={props.inProgress}
      color="primary"
      id="buttonWithLoader"
    >
      <ButtonSpinner inProgress={props.inProgress} />
      {props.isAlreadyExist && !props.inProgress && <CheckCircle className="mr-1" />}
      {props.children}
    </Button>
  );
};


export default ButtonWithLoader;
