import React, { useEffect, useState } from "react";
import ContentBLock from "../Encounter/ContentBlock";
import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import update from "immutability-helper";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";
import HeadEntry from "./HeadEntry";
import ButtonGroupEntry from "./ButtonGroupEntry";

const sampleNote = "Describe all abnormalities";

const HeartSoundsEntries = ({ onChange, features, unselectedFields, handleNote }) => {
  const heartSounds = findFeature(features, "heartSounds", "Heart Sounds", "heart");

  const s1 = findFeature(features, "s1", "s1", "heart");
  const s2 = findFeature(features, "s2", "s2", "heart");
  const s3 = findFeature(features, "s3", "s3", "heart");
  const s4 = findFeature(features, "s4", "s4", "heart");
  const mumors = findFeature(features, "Mumors", "Mumors", "heart");
  const rubs = findFeature(features, "Rubs", "Rubs", "heart");
  const Gallops = findFeature(features, "Gallops", "Gallops", "heart");
  const showCategories = heartSounds.attributes.includes("Abnormal");
  const message = "*Please describe all abnormalities";

  const onYesNoChange = (feature, newValues) => {
    if (newValues.includes("Abnormal")) {
      handleNote(true);
    } else {
      handleNote(false);
    }
    onChange(feature, newValues);
  };

  return (
    <>
      <FeatureEntry
        feature={heartSounds}
        values={["Normal", "Abnormal"]}
        onChange={onYesNoChange}
        id="qa-heart-entry-heartSounds"
      />
      {unselectedFields && showCategories && <h1 className="input-error">{message}</h1>}
      {showCategories && (
        <>
          <FeatureEntry
            feature={s1}
            values={["Yes", "No"]}
            onChange={onChange}
            id="qa-heart-entry-s1"
          />
          <FeatureEntry
            feature={s2}
            values={["Yes", "No"]}
            onChange={onChange}
            id="qa-heart-entry-s2"
          />
          <FeatureEntry
            feature={s3}
            values={["Yes", "No"]}
            onChange={onChange}
            id="qa-heart-entry-s3"
          />
          <FeatureEntry
            feature={s4}
            values={["Yes", "No"]}
            onChange={onChange}
            id="qa-heart-entry-s4"
          />
          <FeatureEntry
            feature={mumors}
            values={["Yes", "No"]}
            onChange={onChange}
            id="qa-heart-entry-mumors"
          />
          <FeatureEntry
            feature={rubs}
            values={["Yes", "No"]}
            onChange={onChange}
            id="qa-heart-entry-rubs"
          />
          <FeatureEntry
            feature={Gallops}
            values={["Yes", "No"]}
            onChange={onChange}
            id="qa-heart-entry-Gallops"
          />
        </>
      )}
    </>
  );
};

const HeartEntry = ({
  features,
  onChange,
  note,
  onNoteUpdate,
  unselectedFields,
  usedTytoCare = false,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [generateNote, setGenerateNote] = useState(false);
  const [edemaTrue, setEdemaTrue] = useState(false);
  const [pittingTrue, setPittingTrue] = useState(false);
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    if (features) {
      const ed = features.find((feat) => feat.key === "edema");
      if (!!ed && ed?.attributes?.includes("Yes")) {
        setEdemaTrue(true);
      } else {
        setEdemaTrue(false);
      }

      const pit = features.find((feat) => feat.key === "pitting");
      if (!!pit && pit?.attributes?.includes("Pitting")) {
        setPittingTrue(true);
      } else {
        setPittingTrue(false);
      }
    }
  }, [features]);

  const handleNote = (flag) => {
    if (flag && usedTytoCare) {
      setGenerateNote(true);
    } else {
      onNoteUpdate("Heart", "");
      setGenerateNote(false);
    }
  };
  const rhythm = findFeature(features, "heartRhythm", "Rhythm regular", "Heart");
  const rate = findFeature(features, "heartRate", "Rate regular", "Heart");
  const edema = findFeature(features, "edema", "Edema", "Heart");
  const capRefill = findFeature(features, "capRefill", "Cap Refill", "Heart");
  const pitting = findFeature(features, "pitting", "", "Heart");
  const pittingValues = findFeature(features, "pittingValues", "", "Heart");
  const distalPulses = findFeature(features, "distalPulse", "Distal Pulse", "Heart");
  const pulseStrength = findFeature(features, "pulseStrength", "", "Heart");

  const setAttribute = featureSetAttributes({ onChange });
  const placeholder = generateNote ? sampleNote : "Note";

  const rhythmNegativeAttribute = rhythm?.attributes?.includes("No");
  const rateNegativeAttribute = rate?.attributes?.includes("No");
  const onYesNoChange = (feature, newValues) => {
    if (newValues.includes("Yes")) {
      const ns = update(feature, { negativeAttributes: { $set: [] } });
      setAttribute(ns, ["Yes"]);
    } else {
      setAttribute(feature, newValues);
    }
  };

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Heart", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Heart");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Heart");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Heart");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Heart"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Heart"  className="large">
      <div>
        <FeatureEntry
          {...{
            feature: rhythm,
            values: ["Yes", "No"],
            onChange: onYesNoChange,
            id: "qa-heart-entry-rhythm-yesNo",
          }}
        />
        {rhythmNegativeAttribute && usedTytoCare && (
          <FeatureEntry
            {...{
              feature: rhythm,
              omitTitle: true,
              values: ["Regularly irregular", "Irregularly  irregular"],
              onChange: setAttribute,
              id: "qa-heart-entry-rhythm",
            }}
          />
        )}
        <FeatureEntry
          {...{
            feature: rate,
            values: ["Yes", "No"],
            onChange: onYesNoChange,
            id: "qa-heart-entry-rate-yesNo",
          }}
        />
        {rateNegativeAttribute && usedTytoCare && (
          <FeatureEntry
            {...{
              feature: rate,
              omitTitle: true,
              values: ["Tachycardia", "Bradycardia "],
              onChange: setAttribute,
              id: "qa-heart-entry-rate-tachycardia",
            }}
          />
        )}
        {isNursingHomeSite && (
          <FeatureEntry
            {...{
              feature: edema,
              values: ["No", "Yes"],
              onChange: setAttribute,
              id: "qa-heart-entry-edema",
            }}
          />
        )}
        {!!edemaTrue && (
          <FeatureEntry
            {...{
              feature: pitting,
              values: ["Non-Pitting", "Pitting"],
              onChange: setAttribute,
              id: "qa-heart-entry-pitting",
            }}
          />
        )}
        {!!edemaTrue && !!pittingTrue && (
          <FeatureEntry
            {...{
              feature: pittingValues,
              values: ["+1", "+2", "+3", "+4"],
              onChange: setAttribute,
              id: "qa-heart-entry-pittingValues",
            }}
          />
        )}
        {isNursingHomeSite && (
          <>
            <FeatureEntry
              {...{
                feature: capRefill,
                values: ["< 3 sec", "> 3 sec"],
                onChange: setAttribute,
                id: "qa-heart-entry-capRefill",
              }}
            />

            <FeatureEntry
              {...{
                feature: distalPulses,
                values: ["Present", "Absent"],
                onChange: setAttribute,
                id: "qa-heart-entry-distalPulses",
              }}
            />

            <ButtonGroupEntry
              {...{
                feature: pulseStrength,
                attributes: ["Strong", "Weak", "Thready"],
                onChange: setAttribute,
              }}
            />
          </>
        )}

        {usedTytoCare && (
          <HeartSoundsEntries
            onChange={setAttribute}
            features={features}
            unselectedFields={unselectedFields}
            handleNote={handleNote}
          />
        )}
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{
            groupName: "Heart",
            onChange: onNoteUpdate,
            note,
            placeholder,
            generateNote,
            sampleNote,
            id: "qa-heart-group-entry",
          }}
        />
      </div>
    </EntryBlock>
  );
};

HeartEntry.propTypes = {
  features: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  unselectedFields: PropTypes.bool,
  usedTytoCare: PropTypes.bool,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default HeartEntry;
