import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Input } from "reactstrap";

import BlockSection from "./BlockSection";
import debounce from "lodash.debounce";

const message = "*Please enter the HPI";

type Props = {
  onProviderNoteUpdate: (val: string) => void;
  prevProfile?: string;
  validateHPI?: boolean;
  onSave: () => void;
  providerNotes?: string;
};

const HPI: FC<Props> = ({
  onProviderNoteUpdate,
  prevProfile,
  validateHPI,
  providerNotes,
  onSave,
}) => {
  const [isNotesChanged, setIsNotesChanged] = useState(true);

  const onHpiChange = (event) => {
    onSave();
  };
  const debouncedChangeHandler = useCallback(debounce(onHpiChange, 500), []);

  const onChangeHandler = (value) => {
    onProviderNoteUpdate(value);
    debouncedChangeHandler([]);
  };

  useEffect(() => {
    const isNotesChanged = prevProfile !== providerNotes;
    setIsNotesChanged(isNotesChanged);
  }, [providerNotes, prevProfile]);

  return (
    <div className="d-flex">
      <BlockSection
        childClassName="pr-3"
        bodyClassName="w-50 "
        title="HPI"
        error={!validateHPI && !isNotesChanged ? message : ""}
      >
        <Input
          placeholder="Enter new or import previous HPI"
          type="textarea"
          name="providerNotes"
          // in new encounter prevNote and newNote are equal but we need to display empty field
          value={providerNotes}
          onChange={(e) => onChangeHandler(e.target.value)}
          className="line-breaks hpi-textarea"
          id="qa-encounter-hpi-freetext"
        />
      </BlockSection>

      <div className="w-50 pl-2">
        <Button
          onClick={() => onChangeHandler(prevProfile + " ")} // adding a space makes the prevNote and the newNote not equal
          color="link"
          className="linkButton p-0 h-auto"
          disabled={!prevProfile}
        >
          import and edit previous HPI
        </Button>
        <p className="line-breaks mt-1">Previous HPI: {prevProfile || "None recorded"}</p>
      </div>
    </div>
  );
};

export default HPI;
