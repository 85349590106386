export const ethnicityOptions = [
  {
    label: "Mexican",
    key: "Mexican",
  },
  {
    label: "Mexican American",
    key: "Mexican American",
  },
  {
    label: "Chicano/a",
    key: "Chicano/a",
  },
  {
    label: "Puerto Rican",
    key: "Puerto Rican",
  },
  {
    label: "Cuban",
    key: "Cuban",
  },
  {
    label: "Unknown",
    key: "Unknown",
  },
  {
    label: "Other",
    key: "Other",
  },
  {
    label: "Not of Hispanic, Latino/a, or Spanish origin",
    key: "Not of Hispanic, Latino/a, or Spanish origin",
  },
];

export const raceOptions = [
  {
    label: "White",
    key: "White",
  },
  {
    label: "Black or African American",
    key: "Black or African American",
  },
  {
    label: "American Indian or Alaska Native",
    key: "American Indian or Alaska Native",
  },
  {
    label: "Other",
    key: "Other",
  },
  {
    label: "Chinese",
    key: "Chinese",
  },
  {
    label: "Filipino",
    key: "Filipino",
  },
  {
    label: "Asian Indian",
    key: "Asian Indian",
  },
  {
    label: "Vietnamese",
    key: "Vietnamese",
  },
  {
    label: "Korean",
    key: "Korean",
  },
  {
    label: "Japanese",
    key: "Japanese",
  },
  {
    label: "Other Asian",
    key: "Other Asian",
  },
  {
    label: "Native Hawaiian",
    key: "Native Hawaiian",
  },
  {
    label: "Samoan",
    key: "Samoan",
  },
  {
    label: "Chamorro",
    key: "Chamorro",
  },
  {
    label: "Other Pacific Islander",
    key: "Other Pacific Islander",
  },
];

export const marriageOptions = ["Single", "Married", "Divorced"];

export const tobaccoOptions = [
  "Cigarette",
  "Pipe",
  "Vape",
  "Juul",
  "Dab",
  "Drip",
  "Snuff",
  "Chewing Tobacco",
  "Other",
];

export const tobaccoFrequency = [
  "Rarely",
  "Occasionally",
  "Once a week",
  "Once every couple days",
  "Once a day",
  "Multiple times a day",
];

export const packsPerDayOptions = [...Array(40).keys()];
export const yearsUsedOptions = [...Array(120).keys()];

export const alcoholUseOptions = [
  "Every day",
  "5 to 6 times a week",
  "3 to 4 times a week",
  "Twice a week",
  "Once a week",
  "2 to 3 times a month",
  "Once a month",
  "3 to 11 times in the past year",
  "1 or 2 times in the past year",
];

export const gender = {
  U:"Unknown",
  M:"Male",
  F:"Female",
}

export const maternalOrPaternal = ["Maternal", "Paternal", "N/A", "Unknown"];
