import React, { Component } from "react";
import { connect } from "react-redux";
import { PROVIDER_CLOCK_IN, PROVIDER_CLOCK_OUT, PROVIDER_PRESENCE } from "../constants/actionTypes";
import api from "../api";

import { Button } from "reactstrap";

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    ...state.presence,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: PROVIDER_PRESENCE, payload: api.Availability.presence() }),
  onClockIn: () => dispatch({ type: PROVIDER_CLOCK_IN, payload: api.Availability.clockIn() }),
  onClockOut: () => dispatch({ type: PROVIDER_CLOCK_OUT, payload: api.Availability.clockOut() }),
});

class ClockIn extends Component {
  componentDidMount() {
    this.props.onLoad();
  }
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onLoad' does not exist on type 'Readonly... Remove this comment to see the full error message

  render() {
    let text = "Clock in";
    let action = this.props.onClockIn;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClockIn' does not exist on type 'Reado... Remove this comment to see the full error message
    var className = "btn-clock-in";

    if (this.props.clockedIn) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'clockedIn' does not exist on type 'Reado... Remove this comment to see the full error message
      text = "Clock out";
      action = this.props.onClockOut;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClockOut' does not exist on type 'Read... Remove this comment to see the full error message
      className = "btn-clock-in clocked-in";
    }

    return (
      <Button onClick={action} color="primary" className={`${className} qa-clockinandout-btn`}>
        {text}
      </Button>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClockIn);
