import React from "react";
import { connect } from "react-redux";

import Spinner from "../../images/Spinner.svg";

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.common.currentUser,
  ...ownProps,
});

function isBoolean(value: string | boolean) {
  return typeof value === "boolean";
}

function filterByName(i: any, name: string) {
  return !!name ? i.fullName.includes(name) : true;
}

function filterByLocation(i: any, location: string) {
  return !!location
    ? i.capabilities.geographic && i.capabilities.geographic.includes(location)
    : true;
}

function filterByStatus(i: any, status: string) {
  //Sometimes its a boolean and other times its a string. But it should always be a boolean
  return status === "Active"
    ? isBoolean(i.active)
      ? i.active
      : i.active.includes("active")
    : true;
}

class List extends React.Component {
  renderChildren() {
    const { name, location, status } = this.props.filters;
    const listItems = this.props.listItems
      .slice()
      .filter(
        (i) => filterByName(i, name) && filterByLocation(i, location) && filterByStatus(i, status),
      );

    return React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        items: listItems,
        currentUser: this.props.currentUser,
        inProgress: this.props.inProgress,
        onLoadMore: this.handleLoadMore,
      });
    });
  }

  render() {
    if (!!this.props.inProgress) {
      return (
        <div className="d-flex flex-column w-100 align-items-center">
          <Spinner />
        </div>
      );
    }

    return this.renderChildren();
  }
}

export default connect(mapStateToProps, {})(List);
