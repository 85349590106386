import React, { FC, useState } from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import "./DatePicker.scss";
import Calendar from "../../images/CalendarIcon.svg";

type Props = {
  handleDateChange: (data: any) => void;
  startDate: any;
};
const DatePicker: FC<Props> = ({ handleDateChange, startDate }) => {
  const [focused, setIsFocused] = useState(false);

  return (
    <div className="DateRangePickerWrap">
      <SingleDatePicker
        placeholder="Enter date"
        noBorder
        readOnly
        customInputIcon={<Calendar />}
        isOutsideRange={() => false}
        date={startDate}
        onDateChange={handleDateChange}
        focused={focused}
        onFocusChange={() => setIsFocused(!focused)}
        displayFormat="MM/DD/YYYY"
        hideKeyboardShortcutsPanel
        keepOpenOnDateSelect
        id="singleDatePicker"
      />
    </div>
  );
};

export default DatePicker;
