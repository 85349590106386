import React, { useEffect } from "react";
import { Input } from "reactstrap";
import { kebabCase } from "lodash";

const NoteEntry = ({
  note,
  onChange,
  inputClass,
  sampleNote,
  generateNote = false,
  placeholder,
  type,
  id,
  className,
}) => {
  const noteChange = (event) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    if (generateNote && !placeholder) {
      note = sampleNote;
      onChange(note);
    }
  }, [generateNote]);

  const placeholderNote = placeholder ? placeholder : "Note";
  const inputType = type === "" ? "" : "textarea";

  return (
    <div className={className}>
      <Input
        className={`${inputClass || ""} qa-encounter-phys-note`}
        type={inputType}
        value={note}
        placeholder={placeholderNote}
        onChange={noteChange}
        id={id}
      />
    </div>
  );
};

const GroupNoteEntry = ({
  groupName,
  note,
  onChange,
  inputClass,
  generateNote,
  sampleNote = "",
  placeholder = null,
  type,
  id,
}) => {
  if (!groupName) {
    return null;
  }
  const noteChange = (newValue) => {
    onChange(groupName, newValue);
  };

  const className = `qa-groupnote-${kebabCase(groupName)}`;
  return (
    <NoteEntry
      {...{
        onChange: noteChange,
        sampleNote,
        note,
        inputClass,
        generateNote,
        placeholder,
        type,
        id,
        className,
      }}
    />
  );
};

export { NoteEntry, GroupNoteEntry };
