import { COMPONENT_UNLOAD } from "../constants/actionTypes";
import { Reducer } from "redux";

const defaultState = {
  errors: null,
};

type errorsState = {
  [key: string]: string[] | null;
}

function extractErrors(payload) {
  let e: string[] = [];
  if (!payload) {
    e.push("Error: No response body");
  } else if (payload.errors) {
    e.push(...payload.errors);
  } else if (payload.message) {
    e.push(payload.message);
  }
  return e;
}

const errorsReducer: Reducer<errorsState, any> = (state = defaultState, action) => {
  const key = action.subtype || action.type;
  if (action.error) {
    return {
      ...state,
      [key]: extractErrors(action.payload),
    };
  }

  const { [key]: value, ...newState } = state;
  return newState;
};

export default errorsReducer;
