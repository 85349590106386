import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import {
  COMPONENT_LOAD,
  COMPONENT_UNLOAD,
  PRESCRIPTION_NOTIFICATIONS,
  PRESCRIPTION_NOTIFICATION_LINK,
} from "../../constants/actionTypes";
import api from "../../api";
import SwoopyArrow from "../../images/SwoopyArrow.svg";
import Exclamation from "../../images/Exclamation.svg";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    currentTime: state.time.currentTime,
    notifications: state.componentData[PRESCRIPTION_NOTIFICATIONS] || {},
    refillLink: state.componentData[PRESCRIPTION_NOTIFICATION_LINK],
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  loadNotifications: (payload) =>
    dispatch({
      type: COMPONENT_LOAD,
      subtype: PRESCRIPTION_NOTIFICATIONS,
      payload,
    }),
  getLink: (payload) =>
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
    dispatch({
      type: COMPONENT_LOAD,
      subtype: PRESCRIPTION_NOTIFICATION_LINK,
      payload,
    }),
  unloadLink: () =>
    dispatch({
      type: COMPONENT_UNLOAD,
      subtype: PRESCRIPTION_NOTIFICATION_LINK,
    }),
  unload: () => dispatch({ type: COMPONENT_UNLOAD, subtype: PRESCRIPTION_NOTIFICATIONS }),
});

const Bubble = ({ show }) => {
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'show' implicitly has an 'any' typ... Remove this comment to see the full error message
  if (!show) {
    return null;
  }
  return (
    <div className="exclamation-bubble">
      <Exclamation />
    </div>
  );
};

const HighlightedNumber = ({ number }) => {
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'number' implicitly has an 'any' t... Remove this comment to see the full error message
  const n = number || 0;
  if (n > 0) {
    return <span className="warning-number">{n}</span>;
  }
  return <span className="okay-number">{n}</span>;
};

const openInNewTab = (url) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
  var win = window.open(url, "_blank");
  if (win) {
    win.focus();
  }
};

class PrescriptionErrors extends Component {
  constructor(props) {
    super(props);
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    this.state = { nextTime: null };
    this.linkClick = this.linkClick.bind(this);
  }

  poll() {
    if (!this.props.currentUser) {
      return;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
    const nextTime = new Date().getTime() + 1000 * 60 * 15;
    this.setState({ nextTime: nextTime });
    this.props.loadNotifications(api.Notifications.get());
  }

  linkClick() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadNotifications' does not exist on typ... Remove this comment to see the full error message
    this.setState({ openLink: true });
    this.props.getLink(api.Notifications.refillLink());
  }

  componentWillMount() {
    this.poll();
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'getLink' does not exist on type 'Readonl... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.poll();
    }
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
    if (this.state.nextTime && this.props.currentTime > this.state.nextTime) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
      this.poll();
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextTime' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.refillLink !== prevProps.refillLink && this.state.openLink) {
      if (this.props.refillLink && this.props.refillLink.data && this.props.refillLink.data.link) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'refillLink' does not exist on type 'Read... Remove this comment to see the full error message
        this.setState({ openLink: false });
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'openLink' does not exist on type 'Readon... Remove this comment to see the full error message
        openInNewTab(this.props.refillLink.data.link);
      }
    }
  }

  componentWillUnmount() {
    this.props.unload();
    this.props.unloadLink();
  }

  render() {
    const { inProgress } = this.props.notifications || {};
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'refillLink' does not exist on type 'Read... Remove this comment to see the full error message
    const ns = this.props.notifications.data ? this.props.notifications.data : { dataFound: false };
    const errors =
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'unload' does not exist on type 'Readonly... Remove this comment to see the full error message
      !!ns.transactionErrorsCount || !!ns.pendingPrescriptionsCount || !!ns.refillRequestsCount;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'notifications' does not exist on type 'R... Remove this comment to see the full error message
    const unavailable = !inProgress && this.props.notifications.data && !ns.dataFound;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'notifications' does not exist on type 'R... Remove this comment to see the full error message
    const cl = unavailable || !errors ? "dashboard-component disabled" : "dashboard-component";
    return (
      <div>
        <div className={cl}>
          <h5>
            Prescription Notifications <Bubble show={errors} />
          </h5>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'notifications' does not exist on type 'R... Remove this comment to see the full error message */}
          {inProgress && <p>Loading... </p>}
          {!inProgress && !unavailable && (
            <div>
              <Button
                className="dashboard-button prescript-fix-button qa-prescriptionNotificationsButton"
                onClick={this.linkClick}
              >
                <SwoopyArrow />
              </Button>

              <ul className="list-unstyled">
                <li>
                  {" "}
                  <HighlightedNumber number={ns.transactionErrorsCount} /> errors to address{" "}
                </li>
                <li>
                  {" "}
                  <HighlightedNumber number={ns.refillRequestsCount} /> refill requests{" "}
                </li>
                <li>
                  {" "}
                  <HighlightedNumber number={ns.pendingPrescriptionsCount} /> pending prescriptions{" "}
                </li>
              </ul>
            </div>
          )}
          {unavailable && <p>Prescription notifications unavailable</p>}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrescriptionErrors);
