import React from "react";
import { Button, Row, Col } from "reactstrap";

import styles from "./needReviewModal.scss";

export default function NeedReviewModal({ setNeedReviewModal }) {
  return (
    <div className="modal-overlay">
      <div className={styles.modalContainer}>
        <h1>Unreviewed Documents</h1>
        <Row>
          <Col className="d-flex justify-content-center col-12 mt-4 mb-2">
            <p>
              This patient has outstanding notes that need to be reviewed. Please view documents and
              mark them as “reviewed”.
            </p>
          </Col>
        </Row>
        <div className={styles.buttonContainer}>
          <Button className="mr-2" onClick={() => setNeedReviewModal(false)} color="primary">
            OK
          </Button>
        </div>
      </div>
    </div>
  );
}
