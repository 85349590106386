export const NOT_ENGAGED = "NotEngaged";
export const WAITING_ON_USER = "WaitingOnUser";
export const NOT_CLOCKED_IN = "NotClockedIn";
export const IN_ENCOUNTER = "InEncounter";

export const USER_VIDEO_NOT_STARTED = "not-started";
export const USER_VIDEO_STARTED = "started";
export const USER_VIDEO_FINISHED = "finished";

const range = (start: any, stop: any, step: any) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const visitNameLookup = {
  strep: "Strep",
  uti: "UTI",
  flu: "Flu",
  conjunctivitis: "Conjunctivitis",
  gastroenteritis: "Gastroenteritis",
  sinusitis: "Sinusitis",
  contraceptives: "Contraceptives",
  rash_and_allergy: "Rash_and_allergy",
  herpes: "Herpes",
  coronavirus: "Coronavirus",
  covid_drive_through: "Coronavirus",
  covid_at_home_pcr: "Coronavirus",
};

export const waitingRoomVisitTypeLookup = {
  strep: "Sore throat",
  uti: "Urinary symptoms",
  flu: "Cold/Flu symptoms",
  conjunctivitis: "Pink eye symptoms",
  gastroenteritis: "Vomiting & diarrhea",
  sinusitis: "Sinus pain & pressure",
  contraceptives: "Birth control",
  rash_and_allergy: "Rash & allergy",
  herpes: "Herpes symptoms",
  coronavirus: "Coronavirus (COVID-19) consult",
  covid_drive_through: "COVID-19 Drive Thru Testing",
  covid_at_home_pcr: "SARS-CoV2 at home PCR consult",
};

// Info Edit Section Titles

export const sectionTitles = {
  currentSymptomsSection: "Current Symptoms",
  eyeAllergySymptomsSection: "Eye Allergy Symptoms",
  noseAllergySymptomsSection: "Nose Allergy Symptoms",
  skinAllergySymptomsSection: "Skin Allergy Symptoms",
  LungAllergySymptomsSection: "Lung Allergy Symptoms",
  illContactsSection: "Ill Contacts",
  travelHistorySection: "Travel History",
  testingReasonSection: "Reason for Testing",
  conditionHistorySection: "Condition History",
  medicationsTriedSection: "Medications Tried",
  eyeAllergyMedicationsTriedSection: "Eye Allergy Medications Tried",
  noseAllergyMedicationsTriedSection: "Nose Allergy Medications Tried",
  skinAllergyMedicationsTriedSection: "Skin Allergy Medications Tried",
  lungAllergyMedicationsTriedSection: "Lung Allergy Medications Tried",
  reviewOfSystemsSection: "ROS",
  allergyEnvironmentalEffectsSection: "Allergy Environmental Effects",
  allergySocialHistorySection: "Allergy Social History",
  medicalHistorySection: "Medical History",
  conditionSpecificIntakeSection: "Condition-Specific Intake",
  pertinentMedicationsSection: "Pertinent Medications",
  providerNotesSection: "providerNotesSection",
  FamilyMedicalHistory: "FamilyMedicalHistory",
};

// Intake Fields
export const medicalHistoryFields = [
  "allergies",
  "medicalHistoryKeys",
  "surgicalHistoryKeys",
  "medications",
  "socialHistoryV2",
  "correctiveLenses",
  "womensHealth",
  "providerNotes",
  "FamilyMedicalHistory",
];

export const medicalIntakeFields = [
  "reviewOfSystemsKeys",
  "symptomDetailsKeys",
  "currentSymptomsBySystem",
  "currentSymptoms",
  "pertinentMedications",
  "reviewOfSystemsWritten",
  "reviewOfSystemsGotSSC",
];

export const eyeAllergyIntakeFields = ["allergyEyesSymptoms", "allergyEyesSymptomsBySystem"];
export const noseAllergyIntakeFields = ["allergyNoseSymptoms", "allergyNoseSymptomsBySystem"];
export const skinAllergyIntakeFields = ["allergySkinSymptoms", "allergySkinSymptomsBySystem"];
export const LungAllergyIntakeFields = ["allergyLungsSymptoms", "allergyLungsSymptomsBySystem"];

export const allergyVisitType = "rash_and_allergy";

let days = Array.from({ length: 6 }, (x, i) => ({
  value: i + " Days",
  label: i + " Days",
}));
let weeks = Array.from({ length: 51 }, (x, i) => ({
  value: i + " Weeks",
  label: i + " Weeks",
}));
let years = Array.from({ length: 10 }, (x, i) => ({
  value: i + " Years",
  label: i + " Years",
}));

export const symptomDurationMap = {
  0: years,
  1: weeks,
  2: days,
};

// Allergy Environmental Effects Constants
export const allergyEnvironmentalEffects = {
  allergyEffectTimes: [
    "Intermittent",
    "Seasonal-Fall",
    "Seasonal-Winter",
    "Seasonal-Spring",
    "Seasonal-Summer",
    "Year-round",
  ],
  pets: ["Dogs", "Cats", "Birds", "Rabbits", "Horses", "Rodents"],
};

// Alergy History Constants
export const allergyHistory = {
  pets: ["Dog", "Cat", "Bird", "Horse", "Mouse", "Rat", "Gerbil"],
  childrenInDaycare: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  missedDays: Array.from({ length: 36 }, (v, k) => k),
  residenceBuildingTypes: ["House", "Townhouse", "Condominium", "Apartment"],
  residenceLocations: ["City", "Suburb", "Countryside"],
  residenceConstructionYears: Array.from({ length: 150 }, (v, k) => k + 1869 + ""),
  numberOfPlant: Array.from({ length: 100 }, (v, k) => k),
  residenceHeatingTypes: [
    "Forced hot air",
    "Hot water",
    "Steam space heater",
    "Baseboard",
    "Electric ceiling",
    "None",
  ],
  residenceACTypes: ["Central air conditioning", "Individual unit air conditioning", "None"],
  flooringTypes: ["Wall-to-wall carpet", "Wood", "Ceramic", "Synthetic", "Tile"],
  bedTypes: ["Innerspring", "Foam rubber", "Cotton", "Waterbed", "Airbed", "Other"],
  beddingAge: Array.from({ length: 50 }, (v, k) => k),
  pillowTypes: ["Fiber", "Foam", "Feather", "Dacron", "Other"],
  hobbyMaterials: [
    ["Chemicals", "Clay", "Mold"],
    ["Paints", "Metals", "Wood"],
    ["Ceramic", "None"],
  ],
};

// Women's Health History
export const womensHealthHistory = {
  timesPregnantOptions: range(1, 20, 1),
  timesDelivered: range(0, 20, 1),
  timeOnOralBC: ["One month", "Several months", "Months", "One year", "More than one year"],
  contraceptionOptions: [
    "Condoms",
    "Diaphragm",
    "Spermicide",
    "Abstinence",
    "Natural family planning",
    "IUD",
    "Birth control patch",
    "Birth control pills",
    "Birth control vaginal ring",
    "Depoprovera injections",
    "Sterilization of partner",
    "N/A",
  ],
};

// vitals
export const saO2Options = {
  "Room Air": "room air",
  "____ L/min of oxygen": "L/min of oxygen",
};

export const BPPositionOptions = {
  Sitting: "Sitting",
  "Laying Down": "Laying Down",
  Standing: "Standing",
};

export const BPArmOptions = { "Right Arm": "right", "Left Arm": "left" };

export const BPArmReadOptions = { right: "Right Arm", left: "Left Arm" };

export const heightFTOptions = [
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
];

export const heightINOptions = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
];

export const litersPerMinOxygenOptions = [
  { label: "0", value: "0" },
  { label: "0.5", value: "0.5" },
  { label: "1", value: "1" },
  { label: "1.5", value: "1.5" },
  { label: "2", value: "2" },
  { label: "2.5", value: "2.5" },
  { label: "3", value: "3" },
  { label: "3.5", value: "3.5" },
  { label: "4", value: "4" },
  { label: "4.5", value: "4.5" },
  { label: "5", value: "5" },
  { label: "5.5", value: "5.5" },
  { label: "6", value: "6" },
  { label: "6.5", value: "6.5" },
  { label: "7", value: "7" },
  { label: "7.5", value: "7.5" },
  { label: "8", value: "8" },
  { label: "8.5", value: "8.5" },
  { label: "9", value: "9" },
  { label: "9.5", value: "9.5" },
  { label: "10", value: "10" },
  { label: "10.5", value: "10.5" },
  { label: "11", value: "11" },
  { label: "11.5", value: "11.5" },
  { label: "12", value: "12" },
  { label: "12.5", value: "12.5" },
  { label: "13", value: "13" },
  { label: "13.5", value: "13.5" },
  { label: "14", value: "14" },
  { label: "14.5", value: "14.5" },
  { label: "15", value: "15" },
];

// Ill contacts

export const relevantDiseaseLookup = {
  uti: "STD",
  flu: "flu",
  strep: "strep",
  conjunctivitis: "conjunctivitis",
  sinusitis: "sinusitis",
  gastroenteritis: "gastroenteritis",
  herpes: "Herpes",
  coronavirus: "flu",
  covid_drive_through: "flu",
  coronavirus_consult_and_testing: "coronavirus",
  covid_at_home_pcr: "coronavirus",
};

export const userVisibleDiseaseNames = {
  uti: "an STD",
  flu: "the flu",
  strep: "Strep",
  conjunctivitis: "pink eye symptoms",
  sinusitis: "a cold, respiratory infection, or the flu",
  gastroenteritis: "vomiting and diarrhea",
  herpes: "Herpes",
  coronavirus: "the flu",
  covid_drive_through: "the flu",
  coronavirus_consult_and_testing: "COVID-19",
  covid_at_home_pcr: "COVID-19",
};

// additionalStatus constants
export const STOP_PRESCRIPTION = "Stop Prescription";
export const STOP_PRESCRIPTION_NO = "Stop Prescription + No";
export const STOP_PRESCRIPTION_NEW = "Stop Prescription + New";
export const DECLINED = "declined";
export const NO_PRESCRIPTION = "No Prescription";
export const PRESCRIPTION_WRITTEN = "Prescription Written";
export const NEW_PRESCRIPTION = "New Prescription";

export const initialPresStatus = [
  { label: "No Rx - Patient Denied", value: "No Prescription - Patient Denied" },
  { label: "No Rx - Provider Denied", value: "No Prescription - Provider Denied" },
];
export const followUpPresStatus = [{ label: "No Rx this visit", value: "No Prescription" }];

export function GetStatusString(status: string): { statusString: string; error: boolean } {
  let statusString: string;
  let error = false;
  switch (status) {
    case "Entered":
      statusString = "Entered in Dosespot";
      break;
    case "Printed":
      statusString = "Printed in Dosespot";
      break;
    case "Sending":
      statusString = "Sending in Dosespot";
      break;
    case "eRxSent":
      statusString = "eRx Sent in Dosespot";
      break;
    case "EpcsError":
    case "EpcsSigned":
    case "Error":
      error = true;
      statusString = "Dosespot encountered an error in submitting. Please try again.";
      break;
    case "Deleted":
      statusString = "Deleted in Dosespot";
      break;
    case "Requested":
      statusString = "Deleted in Dosespot";
      break;
    case "Edited":
      statusString = "Edited in Dosespot";
      break;
    case "ReadyToSign":
      statusString = "Ready To Sign in Dosespot";
      break;
    case "PharmacyVerified":
      statusString = "Pharmacy Verified in Dosespot";
      break;
    default:
      error = true;
      statusString = "Unknown Status";
  }

  return { statusString, error };
}
export const statuses = [
  { label: "Visit created", value: "visit-created" },
  { label: "Patient in waiting room", value: "patient-in-waiting-room" },
  { label: "Patient exited waiting room", value: "patient-exited-waiting-room" },
  { label: "Patient canceled visit", value: "patient-canceled-visit" },
  { label: "Provider assigned", value: "provider-assigned" },
  { label: "Patient ended video", value: "patient-ended-video" },
  { label: "Provider ended video", value: "provider-ended-video" },
  { label: "Visit finished", value: "visit-finished" },
];
