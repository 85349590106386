import React, { Component, FC } from "react";
import BlockSection from "./BlockSection";
import { boolToString, possiblyNullIntToString } from "./LungAllergyHistory";
import AllergySocialHistoryEditor from "./AllergySocialHistoryEditor";
import {
  IAllergyChildrenHistory,
  IAllergyHobbiesHistory,
  IAllergyLivingHistory,
  IAllergyMissedDaysHistory,
  IAllergyOccupationalHistory,
  IAllergyPetsHistory,
  IAllergySocialHistory,
} from "../../constants/Types";

const SocialHistoryPets: FC<{ history: IAllergyPetsHistory }> = ({ history }) => {
  return (
    <div>
      <p></p>
      Pets
      <ul>
        <li>Current pets: {(history.pets || []).join(", ")}</li>
      </ul>
    </div>
  );
};

const SocialHistoryOccupation: FC<{ history: IAllergyOccupationalHistory }> = ({ history }) => {
  return (
    <div>
      <p></p>
      Occupation
      <ul>
        <li>Primary Occupation: {history.primaryOccupation}</li>
        <li>Work outside: {boolToString(history.workOutside)}</li>
        <li>Exposed to dust at work: {boolToString(history.dustExposure)}</li>
        <li>Exposed to mold at work: {boolToString(history.moldExposure)}</li>
        <li>Work with chemicals: {boolToString(history.chemicalExposure)}</li>
        {history.chemicalExposure && (
          <ul>
            <li>Chemicals: {(history.chemicalList || []).join(", ")}</li>
          </ul>
        )}
      </ul>
    </div>
  );
};

const SocialHistoryHobbies: FC<{ history: IAllergyHobbiesHistory }> = ({ history }) => {
  return (
    <div>
      <p></p>
      Hobbies
      <ul>
        <li>Hobbies outside: {boolToString(history.hobbiesOutside)}</li>
        {history.hobbiesMaterials && (
          <li>Hobby materials: {(history.hobbiesMaterials || []).join(", ")}</li>
        )}
        <li>Hobbies insects: {boolToString(history.hobbiesInsects)}</li>
      </ul>
    </div>
  );
};

const SocialHistoryChildren: FC<{ history: IAllergyChildrenHistory }> = ({ history }) => {
  return (
    <div>
      <p></p>
      Children
      <ul>
        <li>
          Number of children in daycare: {possiblyNullIntToString(history.numberSchoolChildren)}
        </li>
      </ul>
    </div>
  );
};

const SocialHistoryMissedDays: FC<{ history: IAllergyMissedDaysHistory }> = ({ history }) => {
  return (
    <div>
      <p></p>
      Missed Days
      <ul>
        <li>Number missed days due to allergies: {possiblyNullIntToString(history.allergyDays)}</li>
        <li>Number missed days due to illness: {possiblyNullIntToString(history.sickDays)}</li>
      </ul>
    </div>
  );
};

const SocialHistoryLiving: FC<{ history: IAllergyLivingHistory }> = ({ history }) => {
  return (
    <div>
      <p></p>
      Living
      <ul>
        <li>Residence building type: {history.residenceBuildingType}</li>
        <li>Residence location: {history.residenceLocation}</li>
        <li>Residence construction year: {history.residenceConstructionYear}</li>
        <li>Number living plants in home: {possiblyNullIntToString(history.plantCount)}</li>
        <li>Home has mold, mildew, or dampness: {boolToString(history.moldEtcPresence)}</li>
        <li>Residence heating: {history.residenceHeatingType}</li>
        <li>Residence AC: {history.residenceACType}</li>
        <li>Home has air filter/cleaner: {boolToString(history.airFilter)}</li>
        <li>Residence flooring main areas: {history.flooringMainArea}</li>
        <li>Residence flooring bedroom: {history.flooringBedroom}</li>
        <li>Bed type: {history.bedType}</li>
        <li>
          Mattress age:{" "}
          {history.mattressAgeKnown ? possiblyNullIntToString(history.mattressAge) : "Unknown"}
        </li>
        <li>Pillow type: {history.pillowType}</li>
        <li>
          Pillow age:{" "}
          {history.pillowAgeKnown ? possiblyNullIntToString(history.pillowAge) : "Unknown"}
        </li>
        <li>Feather comforter: {boolToString(history.featherComforter)}</li>
      </ul>
    </div>
  );
};

type ViewProps = {
  socialHistory: IAllergySocialHistory;
  title: string;
};

const View: FC<ViewProps> = ({ socialHistory, title }) => {
  return (
    <BlockSection title={title}>
      {socialHistory.allergyPetsHistory && socialHistory.allergyPetsHistory.pets && (
        <SocialHistoryPets history={socialHistory.allergyPetsHistory} />
      )}
      {socialHistory.allergyOccupationalHistory && (
        <SocialHistoryOccupation history={socialHistory.allergyOccupationalHistory} />
      )}
      {socialHistory.allergyHobbiesHistory && (
        <SocialHistoryHobbies history={socialHistory.allergyHobbiesHistory} />
      )}
      {socialHistory.allergyChildrenHistory && (
        <SocialHistoryChildren history={socialHistory.allergyChildrenHistory} />
      )}
      {socialHistory.allergyMissedDaysHistory && (
        <SocialHistoryMissedDays history={socialHistory.allergyMissedDaysHistory} />
      )}
      {socialHistory.allergyLivingHistory && (
        <SocialHistoryLiving history={socialHistory.allergyLivingHistory} />
      )}
    </BlockSection>
  );
};

type Props = {
  onChanged: Function;
  allergySocialHistory: IAllergySocialHistory;
  title?: string;
  restoreData?: any;
  visitType?: string;
  isEditing?: boolean;
};

class AllergySocialHistory extends Component<Props> {
  onFieldChange = (newValue) => {
    this.props.onChanged({ allergySocialHistory: newValue });
  };

  render() {
    const socialHistory = this.props.allergySocialHistory;
    if (!this.props.isEditing) {
      return <View socialHistory={socialHistory} title={this.props.title || ""} />;
    } else {
      return (
        <AllergySocialHistoryEditor
          socialHistory={socialHistory}
          oldSocialHistory={this.props.restoreData.intakeHistory.allergySocialHistory}
          onChange={this.onFieldChange}
        />
      );
    }
  }
}

export default AllergySocialHistory;
