import React, { Component } from "react";
import ReviewOfSystemsEditor from "./ReviewOfSystemsEditor";
import { visitNameLookup } from "../../constants/Encounter";
import CurrentSymptomsView from "./CurrentSymptomsView";
import PropTypes from "prop-types";

const GetItemOrder = (visitType, catalog) => {
  if (!catalog || !catalog.relevantSystemsKeys) {
    return [];
  }
  const { relevantSystemsKeys } = catalog;
  const visitNameKey = visitNameLookup[visitType] || "other";
  const order = relevantSystemsKeys[visitNameKey];

  const flattenItem = (systemKey) => {
    return {
      systemKey: systemKey,
      systemName: catalog.systems[systemKey],
      items: catalog.items[catalog.systems[systemKey]],
      visitNameKey: visitNameKey,
    };
  };
  return order.map(flattenItem);
};
const getRelevantSymptomsKeys = (visitType, catalog) => {
  if (!catalog || !catalog.relevantSymptomsKeys || visitType === "contraceptives") {
    return [];
  }
  const { relevantSymptomsKeys } = catalog;
  const visitNameKey = visitNameLookup[visitType] || "other";
  if (visitType === "rash_and_allergy") {
    return relevantSymptomsKeys["Allergy-eyes"]
      .concat(relevantSymptomsKeys["Allergy-lungs"])
      .concat(relevantSymptomsKeys["Allergy-nose"])
      .concat(relevantSymptomsKeys["Allergy-skin"]);
  } else {
    return relevantSymptomsKeys[visitNameKey];
  }
};

class ReviewOfSystems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChanged = async (value: any) => {
    await this.props.onChanged(value);
    // await this.props.onSave()
    console.log("handleChanged:::", value);
  };

  render() {
    const ros =
      this.props.reviewOfSystems.systems !== undefined
        ? this.props.reviewOfSystems
        : { systems: { 0: [] } };
    const symptomDetails = this.props.symptomDetails.symptoms
      ? this.props.symptomDetails
      : { symptoms: [] };
    const visitType = this.props.visitType;
    const catalog = this.props.catalog || {};
    let itemOrder = GetItemOrder(visitType, catalog);
    const data = this.props.restoreData || {};
    const restoreData = data.intakeHistory || {};
    const oros = restoreData.reviewOfSystemsKeys || {};
    const relevantSymptomsKeys = getRelevantSymptomsKeys(visitType, catalog);

    if (this.props.readOnly) {
      return (
        <CurrentSymptomsView
          ros={ros}
          pertinentNegatives={this.props.pertinentNegatives}
          itemOrder={itemOrder}
          details={symptomDetails}
          catalog={catalog}
          title={"ROS"}
          perSystemTitles={"ROS"}
        />
      );
    }

    return (
      <ReviewOfSystemsEditor
        additionalRos={this.props.additionalRos}
        ROSGotSSC={this.props.ROSGotSSC}
        ros={ros}
        itemOrder={itemOrder}
        oldRos={oros}
        details={symptomDetails}
        onChange={this.handleChanged}
        addSymptom={this.add}
        catalog={catalog}
        visitType={itemOrder[0] && itemOrder[0].visitNameKey}
        symptomsDetailField={this.props.symptomsDetailField}
        symptomByStystemField={this.props.symptomByStystemField}
        relevantSymptomsKeys={relevantSymptomsKeys}
      />
    );
  }
}

ReviewOfSystems.propTypes = {
  catalog: PropTypes.object,
  data: PropTypes.object,
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  onChanged: PropTypes.func,
  onSave: PropTypes.func,
  perSystemTitles: PropTypes.bool.isRequired,
  pertinentNegatives: PropTypes.object,
  readOnly: PropTypes.bool,
  requiredFields: PropTypes.array,
  restoreData: PropTypes.object,
  reviewOfSystems: PropTypes.object,
  setRequiredFields: PropTypes.func,
  showRequiredFieldsError: PropTypes.bool,
  symptomByStystemField: PropTypes.string,
  symptomDetails: PropTypes.object,
  symptomsDetailField: PropTypes.string,
  title: PropTypes.string,
  unsavedSections: PropTypes.object,
  visitType: PropTypes.string,
};
export default ReviewOfSystems;
