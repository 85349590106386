import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Input, Table, Button } from "reactstrap";
import debounce from "lodash.debounce";
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@git... Remove this comment to see the full error message
import Octicon, { ChevronRight } from "@githubprimer/octicons-react";
import PropTypes from "prop-types";

import { SET_IDLE_LOGOUT_TIME, NAVIGATE_TO } from "../../constants/actionTypes";
import api from "../../api";
import { store } from "../../store";
import styles from "./search.scss";
import Magnifying from "../../images/SearchMagnifying.svg";
import Spinner from "../../images/Spinner.svg";
import Select from "react-select";
import { hasPermission, PERMISSION_MANAGE } from "../../constants/Permissions";

const activeChoices = [
  { value: "active", label: "Active" },
  { value: "disabled", label: "Disabled" },
];
const EmptyComponent = () => null;

const regexpPhone = /[\s)(-]/g;

const ResultTableItem = ({ provider, onChange, index, currentUser, canManage, hasEdit, hasAudit , edit, audit}) => {
  const [status,setStatus] = useState(activeChoices.find((i) => i.value === provider.active))
  const activeChange = (id: string, index: number,) => (newValue: any) => {
    onChange(id, index, newValue.value === "active");
    setStatus(newValue)

  };
  return (
    <>
      <tr key={provider.id}>
        <td>{provider.fullName}</td>
        <td>
          {provider.capabilities.geographic
            ? provider.capabilities.geographic.join(", ")
            : ""}
        </td>
        <td>{provider.phone}</td>
        <td>
          {canManage ? (
            <Select
              isSearchable={false}
              value={status}
              components={{ IndicatorSeparator: EmptyComponent }}
              onChange={(item)=>activeChange(provider.id, index)(item)}
              options={activeChoices}
              classNamePrefix="td-select"
              className="td-select w-50"
              id={`qa-searchItem-select-${index}`}
            />
          ) : (
            provider.active && <div className="mt-1"> {provider.active} </div>
          )}
        </td>
        <td>
          {hasEdit && (
            <Button
              color="link"
              size="sm"
              onClick={edit(provider.id)}
              style={{ color: "#4889DE" }}
            >
              Edit
            </Button>
          )}

          {hasAudit && (
            <Button
              disabled={provider.id === currentUser.providerID}
              color="primary"
              className={styles.auditButton}
              onClick={audit(provider.id)}
            >
              {`Audit  `}
              <Octicon size="small" icon={ChevronRight} className="chevron"/>
            </Button>
          )}
        </td>
      </tr>
    </>
  );
};


const ResultTable = ({ items, hasAudit, hasEdit, currentUser, onChange, canManage }) => {
  items = items || [];

  const audit = (id) => () => {
    store.dispatch({ type: NAVIGATE_TO, targetPath: `/providers/audit/${id}` });
  };
  const edit = (id) => () => {
    store.dispatch({ type: NAVIGATE_TO, targetPath: `/providers/edit/${id}` });
  };
  return (
    <>
      <Table responsive className={styles.providerTable}>
        <thead>
        <tr>
          <th>Provider</th>
          <th>Location</th>
          <th>Phone</th>
          <th>Status</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {items.map((provider) => (
          <ResultTableItem
            currentUser={currentUser}
            onChange={onChange}
            hasAudit={hasAudit}
            hasEdit={hasEdit}
            canManage={canManage}
            provider={provider}
            audit={audit}
            edit={edit}
          />
        ))}
        </tbody>
      </Table>
    </>
  );
};

const Search = ({ currentUser, onChange, ...props }) => {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [searchResults, setSearchResult] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const hasAudit = props.permissions.indexOf("medicalDirector") !== -1;
  const hasEdit = props.permissions.indexOf("manage") !== -1;
  const canManage = props.permissions && hasPermission(props.permissions, PERMISSION_MANAGE);

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchValue.length < 2) {
        setSearchResult(null);
        return;
      }
      setInProgress(true);

      try {
        let result = await api.Providers.list(debouncedSearchValue);
        newList(result.items, debouncedSearchValue.toLowerCase());
      } finally {
        setInProgress(false);
      }
    };

    fetchData();
  }, [currentUser, debouncedSearchValue]);

  const newList = (items, value) => {
    let providerList = items.filter(
      (i) =>
        i.firstName.toLowerCase().includes(value) ||
        i.lastName.toLowerCase().includes(value) ||
        i.fullName.toLowerCase().includes(value) ||
        i.phone.replace(regexpPhone, "").includes(value.replace(regexpPhone, "")) ||
        (i.capabilities.geographic && i.capabilities.geographic.includes(value.toUpperCase())),
    );
    setSearchResult(providerList);
  };

  const debouncedSetSearchValue = useCallback(debounce(setDebouncedSearchValue, 375), []);

  function handleSearchChange(event) {
    const { value } = event.target;
    setSearchValue(value);
    debouncedSetSearchValue(value);
  }

  return (
    <div className="dashboard-component">
      <FormGroup className="d-flex">
        <Label className="mr-3 mt-2 text-nowrap">Provider Search:</Label>
        <Input
          placeholder="Provider name, state, phone number…"
          onChange={handleSearchChange}
          value={searchValue}
        />
        <Magnifying className={styles.magnifying}/>
      </FormGroup>
      {inProgress && (
        <div className={styles.spinnerContainer}>
          <Spinner/>
        </div>
      )}
      {!inProgress && searchValue.length > 3 && !searchResults && (
        <p className={styles.noResults}>No matching providers found.</p>
      )}
      {!inProgress && searchResults && (
        <ResultTable
          items={searchResults}
          hasAudit={hasAudit}
          hasEdit={hasEdit}
          currentUser={currentUser}
          canManage={canManage}
          onChange={onChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  permissions: state.common.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  setIdleTime: () => dispatch({ type: SET_IDLE_LOGOUT_TIME }),
});

Search.propTypes = {
  currentUser: PropTypes.object,
  permissions: PropTypes.array,
  setIdleTime: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);
