import React, { FC, useState } from "react";
import { Button, Input } from "reactstrap";

import api from "../../api";
import ErrorList from "../ErrorList";

type ConsentStatusModalProps = {
  setModal: () => void;
  userID: string;
  consent: Boolean | null;
  setConsent: (consent: boolean) => void;
  setConsentDate: Function;
};

const ConsentStatusModal: FC<ConsentStatusModalProps> = ({
  setModal,
  userID,
  consent,
  setConsent,
  setConsentDate,
}) => {
  const [content, setContent] = useState("");
  const [error, setError] = useState("");

  const updateConsentStatus = async () => {
    if (content.length == 0) {
      setError("Please enter a reason for status change.");
    } else {
      try {
        await api.Providers.changeConsent({
          consentStatus: !consent,
          consentReason: content,
          userID: userID,
        });
        setModal();
        setConsent(!consent);
        updateDate();
      } catch (error) {
        setError("Error saving changes");
      }
    }
  };

  const updateDate = async () => {
    try {
      let result = await api.UserRecords.profile(userID);
      setConsentDate(result.patient.consentDetails.consentStatusLastUpdate);
    } catch (error) {}
  };

  return (
    <div className="modal-overlay">
      <div className="ml-auto mr-auto consent-modal">
        <div className={!!error ? "errorTitle" : "titleContainer"}>
          <ErrorList errors={[error]} className="consentError" />
          <h1 className="title">Change in Consent</h1>
        </div>
        <div className="textContainer">
          <Input
            type="textarea"
            id="consentText"
            className="textEntry"
            placeholder="Please include reason for change"
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <div className="buttonContainer">
          <Button className="cancelEntry" onClick={setModal}>
            Cancel Change
          </Button>
          <Button className="confirmChange" onClick={updateConsentStatus}>
            Confirm Change
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConsentStatusModal;
