import React, { PureComponent } from "react";
import styled from "styled-components";

import Thumbnail from "./Thumbnail";
import { ArrowButton, ZoomInButton, ZoomOutButton } from "./IconButton";

let imagesToShow = 3;

const StyledThumbnails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

interface Props {
  currentImage: number;
  images: string[];
  onClick: (currentImage: number) => void;
}

interface State {
  hasCustomPage: boolean;
  first: number;
}

export default class Thumbnails extends PureComponent<Props, State> {
  state = {
    hasCustomPage: false,
    first: 0,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentImage !== this.props.currentImage) {
      this.setState({
        hasCustomPage: false,
      });
    }
  }

  // ====================
  // Methods
  // ====================
  getFirst() {
    const { currentImage } = this.props;
    if (this.state.hasCustomPage) {
      return this.clampFirst(this.state.first);
    }
    return this.clampFirst(currentImage - imagesToShow);
  }

  setFirst(event, newFirst) {
    const { first } = this.state;

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (first === newFirst) return;

    this.setState({
      hasCustomPage: true,
      first: newFirst,
    });
  }

  gotoPrev = (event) => {
    this.setFirst(event, this.getFirst() - imagesToShow);
  };

  gotoNext = (event) => {
    this.setFirst(event, this.getFirst() + imagesToShow);
  };

  clampFirst(value) {
    const { images } = this.props;

    const totalCount = 2 * imagesToShow + 1; // show $offset extra thumbnails on each side

    if (value < 0) {
      return 0;
    } else if (value + totalCount > images.length) {
      // Too far
      return images.length - totalCount;
    } else {
      return value;
    }
  }

  // ====================
  // Renderers
  // ====================
  renderArrowPrev() {
    if (this.getFirst() <= 0) return null;

    return <ArrowButton direction="left" onClick={this.gotoPrev} />;
  }

  renderArrowNext() {
    const { images } = this.props;
    const totalCount = 2 * imagesToShow + 1;
    if (this.getFirst() + totalCount >= images.length) return null;

    return <ArrowButton direction="right" onClick={this.gotoNext} />;
  }

  render() {
    const { images, currentImage, onClick } = this.props;

    const totalCount = 2 * imagesToShow + 1; // show $offset extra thumbnails on each side
    let thumbnails = [];
    let baseOffset = 0;
    if (images.length <= totalCount) {
      thumbnails = images;
    } else {
      // Try to center current image
      baseOffset = this.getFirst();
      thumbnails = images.slice(baseOffset, baseOffset + totalCount);
    }

    return (
      <StyledThumbnails>
        {this.renderArrowPrev()}
        {thumbnails.map((src, idx) => (
          <Thumbnail
            key={baseOffset + idx}
            src={src}
            index={baseOffset + idx}
            onClick={onClick}
            active={baseOffset + idx === currentImage}
          />
        ))}
        {this.renderArrowNext()}
      </StyledThumbnails>
    );
  }
}
