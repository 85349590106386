import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PropTypes from "prop-types";

import MonotonicClock from "../MonotonicClock";
import { USER_VIDEO_STARTED, USER_VIDEO_FINISHED } from "../../constants/Encounter";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    currentTime: state.time.currentTime,
    acceptedAt: state.encounter.acceptedAt,
    details: state.encounter.details,
    userVideoState: state.encounter.userVideoState || "",
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  tick: () => dispatch({ type: UPDATE_CURRENT_TIME }),
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'UPDATE_CURRENT_TIME'.
});

class StartTimer extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = { timer: null };
  }

  componentDidMount() {}
  render() {
    var time = this.props.acceptedAt;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'acceptedAt' does not exist on type 'Read... Remove this comment to see the full error message
    if (this.props.details && this.props.details.userStartedAt) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'details' does not exist on type 'Readonl... Remove this comment to see the full error message
      time = moment(this.props.details.userStartedAt).valueOf();
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'details' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.userVideoState === USER_VIDEO_FINISHED) {
      return null;
    }
    if (!time) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'userVideoState' does not exist on type '... Remove this comment to see the full error message
      return null;
    }
    const timeout = this.props.timeout || 120.0;
    const now = new Date().getTime();
    const delta = (now - time) / 1000.0;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'Readonl... Remove this comment to see the full error message
    let left = Math.floor(timeout - delta);
    if (left < 0) {
      left = 0;
    }
    const percentage = Math.floor(100.0 * (1.0 - left / timeout));

    const mins = Math.floor(left / 60);
    left = (left % 60) + "";
    if (left.length < 2) {
      left = "0" + left;
    }
    const userString =
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
      this.props.userVideoState === USER_VIDEO_STARTED
        ? "User is waiting..."
        : // @ts-expect-error ts-migrate(2339) FIXME: Property 'length' does not exist on type 'number'.
          "User is connecting...";

    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'userVideoState' does not exist on type '... Remove this comment to see the full error message
      <div className="mb-4 start-warning d-flex justify-content-between">
        <MonotonicClock frequency={1} />
        <CircularProgressbar
          className="countdown-progress"
          percentage={percentage}
          text={`0${mins}:${left}`}
        />
        {!this.props.onlyProgress && (
          <div className="d-flex flex-column ml-4 w-75 justify-content-between">
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyProgress' does not exist on type 'Re... Remove this comment to see the full error message */}
            <h4>{userString}</h4>
            <h5 className="">
              Start video before countdown expires or the patient will be given to another provider
            </h5>
          </div>
        )}
      </div>
    );
  }
}
StartTimer.propTypes = {
  startedAt: PropTypes.number,
  currentTime: PropTypes.number.isRequired,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
  details: PropTypes.object.isRequired,
  tick: PropTypes.func.isRequired,
  timeOut: PropTypes.number,
  userVideoState: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StartTimer);
