export const PERMISSION_MANAGE = "manage";
export const PERMISSION_SEE_PATIENTS = "seePatients";
export const PERMISSION_MEDICAL_DIRECTOR = "medicalDirector";
export const PERMISSION_MONITOR = "monitor";
export const PERMISSION_PRESCRIBING_AGENT = "prescribingAgent";
export const PERMISSION_BILLING = "billing";
export const PERMISSION_TELEMEDICINE_EXTENDER = "telemedExtender";
export const PERMISSION_ADMIN = "admin";
export const PERMISSION_SUPERVISING_PHYSICIAN = "supervisingPhysician";
export const PERMISSION_SCRIBE = "scribe";

export function hasPermission(list: any, perm: any) {
  const l = list || [];
  return l.includes(perm);
}

export function hasAnyPermission(list: any, ...perms: any[]) {
  return perms.some((r) => list.includes(r));
}
