import React, { FC } from "react";

import { IAllergy, ICondition, IPccPatientProfile } from "../../constants/Types";

type Props = {
  allergies: IAllergy[];
  diagnosis: ICondition[];
  patient: Partial<IPccPatientProfile>;
};

const PCCMedicalHistory: FC<Props> = ({ allergies, diagnosis, patient }) => {
  return (
    <>
      <h1 style={{ paddingTop: "10px" }}>Allergies</h1>
      {allergies.length > 0 ? (
        allergies.map((item) => (
          <div>
            <p>{item.allergen}</p>
            <ul style={{ marginBottom: "15px" }}>
              <li key={item.allergyIntoleranceId}>
                {item.severity !== null ? `Severity: ${item.severity}` : "Severity: Not Recorded"}
              </li>
              <li key={item.allergyIntoleranceId + 1}>
                {item.reactionNote !== null
                  ? `Reaction: ${item.reactionNote}`
                  : "Reaction: Not Recorded"}
              </li>
            </ul>
          </div>
        ))
      ) : (
        <p>None recorded</p>
      )}

      <h1>Diagnosis</h1>
      {diagnosis.length > 0 ? (
        diagnosis.map((item) => (
          <div className="d-flex" key={item.conditionId}>
            <div className="diagnosis-item">
              <p>
                {item.icd10
                  ? `${item.icd10} ${item.icd10Description.toLowerCase()}`
                  : "None Recorded"}
              </p>
            </div>
           <div className="ml-3">
             <p>
               {item.icd10 && item.rankDescription && `Rank: ${item.rankDescription}`}
             </p>
           </div>
          </div>
        ))
      ) : (
        <p>None recorded</p>
      )}

      <h1>Social History</h1>
      <ul style={{listStyle: "none",paddingLeft:5}}>
        <li>Marital Status: {patient.maritalStatus || "n/a"}</li>
        <li>Current Smoker: {patient.smokingStatusDesc || "n/a"}</li>
        <li>Ethnicity: {patient.ethnicityDesc || "n/a"}</li>
        <li>Religion: {patient.religion || "n/a"}</li>
      </ul>
    </>
  );
};

PCCMedicalHistory.defaultProps = {
  allergies: [],
  diagnosis: [],
  patient: {},
};

export default PCCMedicalHistory;
