import React, { FC } from "react";
import styles from "./Patients/PatientSideBar.scss";

type Props = {
  covidZone?: string | null;
  rpmConsent: boolean | null;
  ccmConsent: boolean | null;
};
const covidZoneColors = {
  Green: "#4B8C33",
  Blue: "#3678CC",
  Red: "#DC4545",
  Yellow: "#F4B740",
  null: "N/A",
};

const PatientServices: FC<Props> = ({ covidZone, rpmConsent, ccmConsent }) => (
  <div className="patientServices">
    {covidZone && covidZone !== "N/A" && covidZone !== "no zone" && (
      <div
        style={{
          borderColor: covidZoneColors[covidZone],
          color: covidZoneColors[covidZone],
        }}
        className="consentItem covidZone"
      >
        <span>COVID {covidZone.toUpperCase()}</span>
      </div>
    )}

    {ccmConsent && (
      <div className="consentItem ccmItem">
        <span>CCM</span>
      </div>
    )}
    {rpmConsent && (
      <div className="consentItem rpmItem ">
        <span>RPM</span>
      </div>
    )}
  </div>
);

export default PatientServices;
