import React from "react";
import { Form, Input, FormGroup, Label } from "reactstrap";

import { RESULT_ID_STREP_A, strepLabel } from "../../constants/TestConstants";

const positiveValue = {
  set: true,
  invalid: false,
  results: [{ name: RESULT_ID_STREP_A, value: true }],
};
const negativeValue = {
  set: true,
  invalid: false,
  results: [{ name: RESULT_ID_STREP_A, value: false }],
};
const inconclusiveValue = {
  set: true,
  invalid: false,
  results: [{ name: RESULT_ID_STREP_A, inconclusive: true }],
};
const invalidValue = { set: true, invalid: true, results: [] };

export default (props) => {
  // return null
  const algValue = strepLabel(props.result);
  const onChange = (value) => () => {
    props.onChange(value);
  };
  return (
    <div className="test-confirmation-form">
      {props.showAlgorithmResult && (
        <div className="algorithm-result">
          <div className="alg-title">Algorithm returned:</div>
          <div className="alg-value">{algValue}</div>
        </div>
      )}
      <div>
        <Form className="">
          <FormGroup>
            <legend>Strep</legend>
            <div className="test-confirmation-options">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="strepResult" onChange={onChange(positiveValue)} />
                  &nbsp; Positive
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="strepResult" onChange={onChange(negativeValue)} />
                  &nbsp; Negative
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="strepResult" onChange={onChange(inconclusiveValue)} />
                  &nbsp; Inconclusive
                </Label>
              </FormGroup>
              <FormGroup check className="invalid-option">
                <Label check>
                  <Input type="radio" name="strepResult" onChange={onChange(invalidValue)} />
                  &nbsp; Image Invalid
                </Label>
              </FormGroup>
            </div>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};
