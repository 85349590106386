import { combineReducers } from "redux";
import common from "./reducers/common";
import errors from "./reducers/errors";
import flash from "./reducers/flash";
import progress from "./reducers/progress";
import componentData from "./reducers/componentData";
import presence from "./reducers/presence";
import encounter from "./reducers/encounter";
import waitingRoom from "./reducers/waitingRoom";
import monitoring from "./reducers/monitoring";
import rtc from "./reducers/rtc";
import unsignedEncounters from "./reducers/unsignedEncounters";
import time from "./reducers/time";
import createHistory from "history/createBrowserHistory";
import patientsTab from "./reducers/patientstab";

const rootReducer = combineReducers({
  common,
  errors,
  flash,
  progress,
  componentData,
  presence,
  encounter,
  waitingRoom,
  rtc,
  unsignedEncounters,
  time,
  monitoring,
  patientsTab,
});

export type IAppState = ReturnType<typeof rootReducer> & {
  router: ReturnType<typeof createHistory>;
};

export default rootReducer;
