import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

import CheckMark from "../images/CheckMarkWhite.svg";

const CheckToggle = ({ checked, children, onChange, disabled, className }) => {
  const onClick = () => {
    if (onChange) {
      onChange(!checked);
    }
  };
  return (
    <div className={className}>
      <div style={{width:40}}>
        <Button className="check-toggle-button btn-primary p-0 w-100" disabled={!!disabled} onClick={onClick}>
          {checked && <CheckMark />}
        </Button>
      </div>
      <div className="check-label">{children}</div>
    </div>
  );
};

CheckToggle.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CheckToggle;
