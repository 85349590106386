import React, { Component, Dispatch } from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";

import api from "../../api";
import CenteredForm from "../CenteredForm";
import ErrorList from "../ErrorList";
import { INVITE_ACCEPTED, NAVIGATE_TO } from "../../constants/actionTypes";
import { IAppState } from "../../reducer";

const mapStateToProps = (state: IAppState, ownProps: RouteComponentProps<{ inviteID: string }>) => {
  return {
    inProgress: state.progress[INVITE_ACCEPTED],
    currentUser: state.common.currentUser,
    errors: state.errors[INVITE_ACCEPTED],
    inviteID: ownProps.match.params.inviteID,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onSubmit: (inviteID: string, password: string, passwordConfirmation: string) =>
    dispatch({
      type: INVITE_ACCEPTED,
      payload: api.Invitations.accept(inviteID, password, passwordConfirmation),
      targetPath: "/login",
      flashMessage: "Invitation accepted, please login",
    }),
  onLoggedIn: (destination: null | string) =>
    dispatch({
      type: NAVIGATE_TO,
      targetPath: destination,
      flashMessage: "Cannot accept invitation, you are already logged in.",
    }),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = {
  password: string;
  passwordConfirmation: string;
};

class AcceptInvite extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirmation: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.currentUser) {
      this.props.onLoggedIn("/");
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      [event.target.name]: event.target.value,
    } as Pick<State, keyof State>);
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.props.onSubmit(this.props.inviteID, this.state.password, this.state.passwordConfirmation);
  }

  render() {
    return (
      <CenteredForm>
        <h4>Please choose a password</h4>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Input
              type="password"
              name="password"
              value={this.state.password}
              disabled={this.props.inProgress}
              onChange={this.handleChange}
              id="password"
            />
          </FormGroup>
          <FormGroup>
            <Label for="passwordConfirmation">Confirm password</Label>
            <Input
              type="password"
              name="passwordConfirmation"
              value={this.state.passwordConfirmation}
              disabled={this.props.inProgress}
              onChange={this.handleChange}
              id="passwordConfirmation"
            />
          </FormGroup>
          <ErrorList errors={this.props.errors} />
          <Button disabled={this.props.inProgress} color="primary">
            Create Account
          </Button>
        </Form>
      </CenteredForm>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcceptInvite);
