import React, { FC, useState } from "react";
import { Button } from "reactstrap";
import cn from "classnames";

import PDFView from "./Provider/PDFView";
import NoteFooter from "./NoteFooter";

type Props = {
  url?: string;
  pdfRequisitionURL?: string | null;
  encounterKey?: string;
  style?: string;
  showEditButton?: boolean;
  className?: string;
  id?: string;
  visitType?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
};

const PDFButton: FC<Props> = ({
  url,
  encounterKey,
  style,
  children,
  showEditButton,
  className,
  id,
  visitType,
  width,
  height,
  pdfRequisitionURL,
  disabled,
}) => {
  const [viewOpen, setViewOpen] = useState(false);

  const handleClose = () => {
    setViewOpen(false);
  };
  return (
    <>
      {viewOpen && (
        <PDFView url={url} onClose={() => setViewOpen(false)}>
          {encounterKey && showEditButton && (
            <NoteFooter
              pdfRequisitionURL={pdfRequisitionURL}
              encounterKey={encounterKey}
              onClose={handleClose}
              visitType={visitType}
            />
          )}
        </PDFView>
      )}
      <Button
        onClick={() => setViewOpen(true)}
        id={id}
        className={cn(style, className)}
        color={style ? "" : "primary"}
        style={{ height: height, width: width }}
        disabled={!!disabled}
      >
        {children}
      </Button>
    </>
  );
};

export default PDFButton;
