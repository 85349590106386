import React, { FC, useState } from "react";
import update from "immutability-helper";

import GeneralSection from "../PhysicalExam/General";
import ChestEntry from "../PhysicalExam/ChestEntry";
import SkinEntry from "../PhysicalExam/SkinEntry";
import Attachments from "./Attachments";
import HeadEntry from "../PhysicalExam/HeadEntry";
import EyesEntry from "../PhysicalExam/EyesEntry";
import EarsEntry from "../PhysicalExam/EarsEntry";
import NoseEntry from "../PhysicalExam/NoseEntry";
import LipsEntry from "../PhysicalExam/LipsEntry";
import MucusMembraneEntry from "../PhysicalExam/MucusMembraneEntry";
import DentitionEntry from "../PhysicalExam/DentitionEntry";
import TongueEntry from "../PhysicalExam/TongueEntry";
import TonsillarArch from "../PhysicalExam/TonsillarArch";
import PosteriorPharynx from "../PhysicalExam/PosteriorPharynx";
import NeckEntry from "../PhysicalExam/NeckEntry";
import HeartEntry from "../PhysicalExam/HeartEntry";
import ExtremitiesEntry from "../PhysicalExam/ExtremitiesEntry";
import NeuroEntry from "../PhysicalExam/NeuroEntry";
import PsychEntry from "../PhysicalExam/PsychEntry";
import InfoEditWrapper from "./InfoEditWrapper";
import Vitals from "./Vitals";
import TytocareResults from "./TytocareResults";
import RxHowWhen from "../PhysicalExam/RxHowWhen";
import RxDuration from "../PhysicalExam/RxDuration";
import RxSideEffects from "../PhysicalExam/RxSideEffects";
import RxDiscontinue from "../PhysicalExam/RxDiscontinue";
import RxNotAsPrescribed from "../PhysicalExam/RxNotAsPrescribed";
import RxStoreDispose from "../PhysicalExam/RxStoreDispose";
import RxOtherMeds from "../PhysicalExam/RxOtherMeds";
import RxInteractions from "../PhysicalExam/RxInteractions";
import RxNotAsInstructed from "../PhysicalExam/RxNotAsInstructed";
import GastroEntry from "../PhysicalExam/GastroEntry";
import Genitourinary from "../PhysicalExam/GenitourinaryEntry";

import {
  IPhysicalExam,
  IProviderNote,
  ITestDetails,
  IUserMedicalIntake,
} from "../../constants/Types";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { PHYSICAL_EXAM_TAB } from "./PatientHeader";

const PhysicalExamEntries = ({
  physicalExam,
  onFeatureUpdate,
  onNoteUpdate,
  visitType,
  unselectedFields,
  usedTytoCare,
  onCheckboxUpdate,
  isNursingHomeSite,
  onLocationNoteUpdate,
  onRemoveSection,
  ...props
}) => {
  return (
    <div className="start-blue">
      <HeadEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Head"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <EyesEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Eyes"]}
        visitType={visitType}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <EarsEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        usedTytoCare={usedTytoCare}
        note={physicalExam.groupNotes["Ears"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <NoseEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        usedTytoCare={usedTytoCare}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Nose"]}
        visitType={visitType}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <LipsEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Lips"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <MucusMembraneEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Mucus Membranes"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <DentitionEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Dentition"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />

      <TongueEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Tongue"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <TonsillarArch
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Tonsillar Arch"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <PosteriorPharynx
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Posterior Pharynx"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <NeckEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Neck"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <ChestEntry
        features={physicalExam.features}
        notes={physicalExam.groupNotes || {}}
        onChange={onFeatureUpdate}
        onNoteChange={onNoteUpdate}
        usedTytoCare={usedTytoCare}
        visitType={visitType}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <HeartEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        unselectedFields={unselectedFields}
        usedTytoCare={usedTytoCare}
        note={physicalExam.groupNotes["Heart"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      {!!isNursingHomeSite && (
        <GastroEntry
          features={physicalExam.features}
          onChange={onFeatureUpdate}
          onNoteUpdate={onNoteUpdate}
          unselectedFields={unselectedFields}
          note={physicalExam.groupNotes["Gastrointestinal"]}
          onCheckboxUpdate={onCheckboxUpdate}
          isNursingHomeSite={isNursingHomeSite}
          onRemoveSection={onRemoveSection}
        />
      )}
      {!!isNursingHomeSite && (
        <Genitourinary
          features={physicalExam.features}
          onChange={onFeatureUpdate}
          onNoteUpdate={onNoteUpdate}
          note={physicalExam.groupNotes["Genitourinary"]}
          onCheckboxUpdate={onCheckboxUpdate}
          isNursingHomeSite={isNursingHomeSite}
          onRemoveSection={onRemoveSection}
        />
      )}
      <SkinEntry
        onFeatureUpdate={onFeatureUpdate}
        features={physicalExam.features}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Skin"]}
        title="Skin"
        visitType={visitType}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />

      {!isNursingHomeSite && (
        <ExtremitiesEntry
          features={physicalExam.features}
          onChange={onFeatureUpdate}
          onNoteUpdate={onNoteUpdate}
          note={physicalExam.groupNotes["Extremities"]}
          onCheckboxUpdate={onCheckboxUpdate}
          isNursingHomeSite={isNursingHomeSite}
          onRemoveSection={onRemoveSection}
        />
      )}
      <NeuroEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Neurological"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
      <PsychEntry
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["Psychological"]}
        onCheckboxUpdate={onCheckboxUpdate}
        isNursingHomeSite={isNursingHomeSite}
        onRemoveSection={onRemoveSection}
      />
    </div>
  );
};

const RxEntries = ({
  physicalExam,
  onFeatureUpdate,
  onNoteUpdate,
  visitType,
  unselectedFields,
  usedTytoCare,
  onCheckboxUpdate,
  ...props
}) => {
  return (
    <div>
      <RxHowWhen
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["RxHowandWhen"]}
      />
      <RxDuration
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["RxDuration"]}
      />
      <RxSideEffects
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["RxSideEffects"]}
      />
      <RxDiscontinue
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["RxDiscontinue"]}
      />
      <RxNotAsPrescribed
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["RxNotAsPrescribed"]}
      />
      <RxInteractions
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["RxInteractions"]}
      />
      <RxStoreDispose
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["RxStoreAndDispose"]}
      />
      <RxOtherMeds
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["RxOtherMeds"]}
      />
      <RxNotAsInstructed
        features={physicalExam.features}
        onChange={onFeatureUpdate}
        onNoteUpdate={onNoteUpdate}
        note={physicalExam.groupNotes["RxOtherMeds"]}
      />
    </div>
  );
};

type PhysicalExamProps = {
  note: Partial<IProviderNote>;
  visitType: string;
  onUpdate: (field: string, value: any) => void;
  attachments?: {
    imageURL?: string | undefined;
    thumbnailURL?: string | undefined;
  }[];
  usedTytoCare: boolean | null;
  intake: IUserMedicalIntake;
  onIntakeChanged: (section?: string) => (newValues: any) => void;
  onIntakeSave: (parts?: string[]) => void;
  onRemoveUnsavedSection: (section: string) => void;
  unsavedSections: object;
  heartSoundRequiredFeatures: string[];
  unselectedFields: boolean;
  isClinicalServices?: {
    name: string;
    phone: string;
  };
  testResultProps: {
    testResult?: ITestDetails;
    onSave: (data: any) => void;
    onChange: (newValues: any) => void;
    timeout: number;
    telemedExtender?: {
      name: string;
      phone: string;
    };
  };
  isNursingHomeSite: boolean;
  peTabisNotEmpty: (physicalExam: Partial<IPhysicalExam>) => void;
};

const PhysicalExam: FC<PhysicalExamProps> = (props) => {
  const physicalExam = props.note.physicalExam || ({} as Partial<IPhysicalExam>);
  const unselectedFields = props.unselectedFields;
  physicalExam.groupNotes = physicalExam.groupNotes || {};
  const testResultProps = {
    testResult: props.testResultProps.testResult,
    onSave: props.testResultProps.onSave,
    onChange: props.testResultProps.onChange,
    telemedExtender: props.testResultProps.telemedExtender,
  };

  const onUpdate = (field) => (value) => {
    const newValue = update(physicalExam, { [field]: { $set: value } });
    props.onUpdate("physicalExam", newValue);
  };

  const onCheckboxUpdate = (features, group, checked, field) => {
    let newFeatures;
    if (features) {
      newFeatures = [...features];
    } else {
      newFeatures = [];
    }

    if (field === "unableToAssess" && checked) {
      //unselect the choices
      newFeatures = newFeatures.filter((nf) => {
        return !(nf.group === group && nf.attributes && nf.attributes.length > 0);
      });
    }

    let found = false;
    newFeatures.map((e) => {
      if (e.group === group) {
        e[field] = checked;

        if (field === "unableToAssess" && checked) {
          e.reportedByNurse = false;
        } else if (field === "reportedByNurse" && checked) {
          e.unableToAssess = false;
        }

        found = true;
      }
    });

    if (!found && checked) {
      const newF = {
        group: group,
        attributes: [],
        key: "",
        feature: "",
        negativeAttributes: [],
        reportedByNurse: false,

        unableToAssess: false,
      };
      newF[field] = checked;
      newFeatures.push(newF);
    } else if (!checked) {
      // Trim unchecked features with no attributes

      newFeatures = newFeatures.filter(
        (e) =>
          !(
            e.group === group &&
            (e.attributes === [] || e.attributes === null) &&
            (e.negativeAttributes === [] || e.negativeAttributes === null)
          ),
      );
    }
    const nt = update(physicalExam, { features: { $set: newFeatures } });
    props.onUpdate("physicalExam", nt);
  };

  const onFeatureUpdate = (feature) => {
    let newValue;
    const foundIdx = (physicalExam.features || []).findIndex((e) => e.key === feature.key);
    if (foundIdx >= 0) {
      newValue = update(physicalExam, {
        features: { [foundIdx]: { $set: feature } },
      });
      const { key, attributes } = feature;
      if (key === "heartSounds" && attributes.includes("Normal")) {
        let features = newValue.features.filter(
          (e) => !props.heartSoundRequiredFeatures.includes(e.key),
        );
        newValue = update(newValue, { features: { $set: features } });
      }
    } else {
      if (physicalExam.features) {
        newValue = update(physicalExam, { features: { $push: [feature] } });
      } else {
        newValue = update(physicalExam, { features: { $set: [feature] } });
      }
    }

    //uncheck the box
    newValue.features = newValue.features.map((item) => {
      if (item.group === feature.group) {
        item.unableToAssess = false;
      }
      return item;
    });
    props.peTabisNotEmpty(newValue)

    props.onUpdate("physicalExam", newValue);
  };

  const onRemoveSection = (section: string) => {
    const features = (physicalExam.features || []).filter((item) => item.group !== section);
    let pe = update(physicalExam, { features: { $set: features } });
    if (section === "General") {
      pe = update(pe, { notes: { $set: "" } });
    } else {
      pe = update(pe, { groupNotes: { [section]: { $set: "" } } });
    }
    props.peTabisNotEmpty(pe)
    props.onUpdate("physicalExam", pe);
  };

  const onNoteUpdate = async (groupName, newValue) => {
    const nv = update(physicalExam, {
      groupNotes: { [groupName]: { $set: newValue } },
    });
    await props.onUpdate("physicalExam", nv);
    props.peTabisNotEmpty(nv)
  };

  const visitType = props.visitType;
  const usedTytoCare = props.usedTytoCare;

  const examProperties = {
    physicalExam,
    onNoteUpdate,
    onFeatureUpdate,
    visitType,
    usedTytoCare,
    unselectedFields,
    isNursingHomeSite: props.isNursingHomeSite,
    onCheckboxUpdate,
    onRemoveSection,
  };

  const onCancelIntake = (destination) => (v) => {
    props.onRemoveUnsavedSection("vitals");
    if (props.onIntakeChanged) {
      props.onIntakeChanged()({ [destination]: v });
    }
  };

  const clearChildFields = () => {
    const newVitals = { vitals: { ...props.intake.vitals } };

    if (!props.intake.vitals.systolic && !props.intake.vitals.diastolic) {
      newVitals.vitals.bloodPressureArm = "";
      newVitals.vitals.bloodPressurePosition = "";
    }
    if (!props.intake.vitals.saO2) {
      newVitals.vitals.saO2Source = "";
    }
    return props.onIntakeChanged("vitals")(newVitals);
  };

  const onSaveIntake =
    (...fields) =>
    async () => {
      await clearChildFields();
      props.onRemoveUnsavedSection("vitals");
      if (props.onIntakeSave) {
        props.onIntakeSave(fields);
      }
    };

  if (visitType === "rxrenewal") {
    return <RxEntries {...{ ...examProperties }} />;
  } else {
    return (
      <div className="physical-exam">
        {props.attachments && <Attachments attachments={props.attachments} visitType={visitType} />}
        {visitType !== "nursing_home" &&
          visitType !== "in_home_health" &&
          visitType !== "rx_request" && (
            <InfoEditWrapper
              data={props.intake.vitals}
              onCancel={onCancelIntake("vitals")}
              onSave={onSaveIntake("vitals")}
              title={"vitals"}
              unsavedSections={props.unsavedSections}
            >
              <Vitals
                vitals={props.intake.vitals}
                onChanged={props.onIntakeChanged("vitals")}
                isClinicalServices={props.isClinicalServices}
              />
            </InfoEditWrapper>
          )}
        {props.usedTytoCare && <TytocareResults testResultProps={testResultProps} />}
        {visitType !== "rx_request" && (
          <GeneralSection
            physicalExam={physicalExam}
            onChange={onUpdate("notes")}
            visitType={props.visitType}
            onFeatureUpdate={onFeatureUpdate}
            onCheckboxUpdate={onCheckboxUpdate}
            isNursingHomeSite={props.isNursingHomeSite}
            onRemoveSection={onRemoveSection}
          />
        )}

        <PhysicalExamEntries {...{ ...examProperties }} />
      </div>
    );
  }
};

export default PhysicalExam;
