import React, { useState, useEffect } from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import ButtonGroupEntry from "./ButtonGroupEntry";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import ContentBLock from "../Encounter/ContentBlock";
import PropTypes from "prop-types";

const EyeAllergyQuestions = ({ features, setAttribute }) => {
  const sclera = findFeature(features, "sclera", "Sclera", "Eyes");
  const pupil = findFeature(features, "pupil", "Pupil", "Eyes");
  const chemosis = findFeature(features, "eyesChemosis", "Chemosis", "Eyes");
  const discharge = findFeature(features, "eyesDischarge", "Discharge", "Eyes");
  const periorbitalTissue = findFeature(
    features,
    "periorbitalTissue",
    "Periorbital Tissue",
    "Eyes",
  );
  const foreignBody = findFeature(features, "eyesForeignBody", "Foreign Body in Eye", "Eyes");

  return (
    <React.Fragment>
      <ButtonGroupEntry
        {...{
          feature: sclera,
          attributes: ["White", "Icteric", "Injected"],
          onChange: setAttribute,
        }}
      />
      <FeatureEntry
        {...{
          feature: sclera,
          values: ["No pterygium", "Pterygium"],
          onChange: setAttribute,
          omitTitle: true,
          id: "qa-eyes-entry-sclera",
        }}
      />
      <FeatureEntry
        {...{
          feature: sclera,
          values: ["No pinguecula", "Pinguecula"],
          onChange: setAttribute,
          omitTitle: true,
          id: "qa-eyes-entry-sclera-pinguecula",
        }}
      />
      <ButtonGroupEntry
        {...{
          feature: pupil,
          attributes: ["Normal ", "Dilated", "Pinpoint"],
          onChange: setAttribute,
        }}
      />
      <FeatureEntry
        {...{
          feature: chemosis,
          values: ["No chemosis ", "Chemosis"],
          onChange: setAttribute,
          id: "qa-eyes-entry-chemosis",
        }}
      />
      <ButtonGroupEntry
        {...{
          feature: discharge,
          attributes: ["None ", "Clear", "Muco- purulent"],
          onChange: setAttribute,
        }}
      />
      <ButtonGroupEntry
        {...{
          feature: discharge,
          attributes: ["Left Eye ", "Right eye", "Both eyes"],
          onChange: setAttribute,
          omitTitle: true,
        }}
      />
      <ButtonGroupEntry
        {...{
          feature: periorbitalTissue,
          attributes: [
            "No Erythema",
            "Left Eye Erythema",
            "Right Eye Erythema",
            "Both Eyes Erythema",
          ],
          onChange: setAttribute,
        }}
      />
      <ButtonGroupEntry
        {...{
          feature: periorbitalTissue,
          attributes: ["No Edema", "Left Eye Edema", "Right Eye Edema", "Both Eyes Edema"],
          onChange: setAttribute,
          omitTitle: true,
        }}
      />
      <FeatureEntry
        {...{
          feature: foreignBody,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-eyes-entry-foreignBody",
        }}
      />
    </React.Fragment>
  );
};

const EyesEntry = ({
  features,
  onChange,
  note,
  onNoteUpdate,
  visitType,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [hideContent, setHideContent] = useState(true);
  const perrla = findFeature(features, "eyesPERRLA", "PERRLA", "Eyes");
  const eomi = findFeature(features, "eyesEOMI", "EOMI", "Eyes");
  const conjunctivae = findFeature(features, "conjunctivae", "Conjunctivae", "Eyes");
  const eyelids = findFeature(features, "eyelids", "Eyelids", "Eyes");
  const sclerae = findFeature(features, "sclerae", "Sclerae", "Eyes");

  const setAttribute = featureSetAttributes({ onChange });
  const isAllergies = visitType === "rash_and_allergy";

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Eyes", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Eyes");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Eyes");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Eyes");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Eyes"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Eyes"  className="large">
      <div>
        <FeatureEntry
          {...{
            feature: perrla,
            values: ["Yes", "No"],
            onChange: setAttribute,
            className: "yes-no-toggle",
            id: "qa-eyes-entry-perrla",
          }}
        />
        <FeatureEntry
          {...{
            feature: eomi,
            values: ["Yes", "No"],
            onChange: setAttribute,
            className: "yes-no-toggle",
            id: "qa-eyes-entry-eomi",
          }}
        />
        <FeatureEntry
          {...{
            feature: conjunctivae,
            values: ["Normal", "Erythematous"],
            onChange: setAttribute,
            id: "qa-eyes-entry-conjunctivae-erythematous",
          }}
        />
        <FeatureEntry
          {...{
            feature: conjunctivae,
            values: ["No exudate ", "Exudate"],
            onChange: setAttribute,
            omitTitle: true,
            id: "qa-eyes-entry-conjunctivae-exudate",
          }}
        />
        <FeatureEntry
          {...{
            feature: eyelids,
            values: ["Normal ", "Inflamed"],
            onChange: setAttribute,
            id: "qa-eyes-entry-eyelids",
          }}
        />
        {isNursingHomeSite && (
          <FeatureEntry
            {...{
              feature: sclerae,
              values: ["Clear ", "Icteric"],
              onChange: setAttribute,
              id: "qa-eyes-entry-sclerae",
            }}
          />
        )}
        {isAllergies && <EyeAllergyQuestions features={features} setAttribute={setAttribute} />}
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{ groupName: "Eyes", onChange: onNoteUpdate, note, id: "qa-eyes-group-entry" }}
        />
      </div>
    </EntryBlock>
  );
};

EyesEntry.propTypes = {
  features: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  visitType: PropTypes.string,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default EyesEntry;
