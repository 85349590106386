import React, { FC } from "react";
import { Button } from "reactstrap";

import CheckMark from "../images/CheckMark.svg";
import SmallCheckMark from "../images/SmallCheckMark.svg";

type Props = {
  size?: string;
  checked: boolean;
  children?: JSX.Element | string;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
};

const CheckToggle: FC<Props> = ({ size, checked, disabled, onChange, children, className }) => {
  const onClick = () => {
    if (typeof onChange === "function") {
      onChange(!checked);
    }
  };

  return (
    <div className="check-toggle d-flex">
      <div className={`check-container ${className}`}>
        {size === "small" ? (
          <Button className="small-check-box" disabled={disabled} onClick={onClick}>
            {checked && <SmallCheckMark />}
          </Button>
        ) : (
          <Button className="check-toggle-button" disabled={disabled} onClick={onClick}>
            {checked && <CheckMark />}
          </Button>
        )}
      </div>
      {children && (
        <div className="check-label" onClick={() => !disabled && onClick()}>
          {children}
        </div>
      )}
    </div>
  );
};

export default CheckToggle;
