import React, { Component } from "react";
import update from "immutability-helper";
import { Form, Input, FormGroup, Label } from "reactstrap";

import {
  RESULT_ID_LEUKOCYTES,
  RESULT_ID_NITRITES,
  nitritesLookup,
  leukocytesLookup,
  NITRITES_NEGATIVE,
  NITRITES_POSITIVE,
  LUEKOCYTES_NEGATIVE,
  LEUKOCYTES_TRACE,
  LEUKOCYTES_SMALL,
  LEUKOCYTES_MODERATE,
  LEUKOCYTES_LARGE,
  findResult,
  utiLabel,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
} from "../../constants/TestConstants";

const CheckOption = (props) => {
  const onChange = () => {
    if (props.onChange) {
      props.onChange(props.value);
    }
  };

  let checked = false;
  if (props.current && props.current.value == props.value.value) {
    checked = true;
  }

  return (
    <FormGroup check>
      <Label check>
        <Input type="radio" name={props.result} checked={checked} onChange={onChange} />
        &nbsp;
        {props.label}
      </Label>
    </FormGroup>
  );
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const LeukocyteFormGroup = (props) => {
  return (
    <FormGroup>
      <legend>Leukocytes</legend>
      <div className="test-confirmation-options">
        <CheckOption
          label="Negative"
          current={props.value}
          result={RESULT_ID_LEUKOCYTES}
          value={{ value: LUEKOCYTES_NEGATIVE }}
          onChange={props.onChange}
        />
        <CheckOption
          label="Trace"
          current={props.value}
          result={RESULT_ID_LEUKOCYTES}
          value={{ value: LEUKOCYTES_TRACE }}
          onChange={props.onChange}
        />
        <CheckOption
          label="Small"
          current={props.value}
          result={RESULT_ID_LEUKOCYTES}
          value={{ value: LEUKOCYTES_SMALL }}
          onChange={props.onChange}
        />
        <CheckOption
          label="Moderate"
          current={props.value}
          result={RESULT_ID_LEUKOCYTES}
          value={{ value: LEUKOCYTES_MODERATE }}
          onChange={props.onChange}
        />
        <CheckOption
          label="Large"
          current={props.value}
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
          result={RESULT_ID_LEUKOCYTES}
          value={{ value: LEUKOCYTES_LARGE }}
          onChange={props.onChange}
        />
        <CheckOption
          label="Inconclusive"
          current={props.value}
          result={RESULT_ID_LEUKOCYTES}
          value={{ inconclusive: true }}
          onChange={props.onChange}
        />
      </div>
    </FormGroup>
  );
};

const NitriteFormGroup = (props) => {
  return (
    <FormGroup>
      <legend>Nitrites</legend>
      <div className="test-confirmation-options">
        <CheckOption
          label="Positive"
          current={props.value}
          result={RESULT_ID_NITRITES}
          value={{ value: NITRITES_POSITIVE }}
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
          onChange={props.onChange}
        />
        <CheckOption
          label="Negative"
          current={props.value}
          result={RESULT_ID_NITRITES}
          value={{ value: NITRITES_NEGATIVE }}
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
          onChange={props.onChange}
        />
        {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newValue' implicitly has an 'any' type. */}
        <CheckOption
          label="Inconclusive"
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'results' does not exist on type 'Readonl... Remove this comment to see the full error message
          current={props.value}
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'r' implicitly has an 'any' type.
          result={RESULT_ID_NITRITES}
          value={{ inconclusive: true }}
          onChange={props.onChange}
        />
      </div>
    </FormGroup>
  );
};

class UTIConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      invalid: false,
      set: false,
    };
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'set' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
    this.onResultChange = this.onResultChange.bind(this);
  }

  onResultChange(result) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'invalid' does not exist on type 'Readonl... Remove this comment to see the full error message
    return (newValue) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      const idx = this.state.results.findIndex((r) => r.name === result);
      let newState;
      let nv = { name: result, ...newValue };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type 'Readonly... Remove this comment to see the full error message
      if (idx > -1) {
        newState = update(this.state, { results: { [idx]: { $set: nv } } });
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
        newState = update(this.state, { results: { $push: [nv] } });
      }
      newState.set = newState.results.length >= 2;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'invalid' does not exist on type 'Readonl... Remove this comment to see the full error message
      newState.invalid = false;
      this.setState(newState);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'results' does not exist on type 'Readonl... Remove this comment to see the full error message

      this.props.onChange(newState);
    };
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'invalid' does not exist on type 'Readonl... Remove this comment to see the full error message
  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'results' does not exist on type 'Readonl... Remove this comment to see the full error message
    const algValue = utiLabel(this.props.result);
    const onInvalid = () => {
      const iv = { set: true, invalid: true, results: [] };
      this.setState(iv);
      this.props.onChange({ set: true, invalid: true, results: [] });
    };

    let l = this.state.invalid ? null : findResult(this.state.results, RESULT_ID_LEUKOCYTES);
    let n = this.state.invalid ? null : findResult(this.state.results, RESULT_ID_NITRITES);

    return (
      <div className="test-confirmation-form">
        {this.props.showAlgorithmResult && (
          <div className="algorithm-result">
            <div className="alg-title">Algorithm returned:</div>
            <div className="alg-value">{algValue}</div>
          </div>
        )}
        <div>
          <Form className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex w-100">
              <div className="w-50">
                <LeukocyteFormGroup
                  value={l}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'invalid' does not exist on type 'Readonl... Remove this comment to see the full error message
                  onChange={this.onResultChange(RESULT_ID_LEUKOCYTES)}
                />
              </div>
              <div className="w-50">
                <NitriteFormGroup value={n} onChange={this.onResultChange(RESULT_ID_NITRITES)} />
              </div>
            </div>

            <FormGroup check className="mt-4">
              <Label check>
                <Input
                  type="radio"
                  name="invalid"
                  checked={this.state.invalid}
                  onChange={onInvalid}
                />
                &nbsp; Image Invalid
              </Label>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default UTIConfirmation;
