import React from "react";
import CheckSelect from "../CheckSelect";
import FeatureEntry from "./FeatureEntry";
import PillList from "../Encounter/PillList";
import PropTypes from "prop-types";

const rashOptions = ["Red", "Lacy", "Macular", "Papular", "Vesicular", "Urticarial"];
const bodyPartOptions = [
  "Head/face/neck",
  "Chest/abdomen/back",
  "Pelvis/groin/buttocks",
  "Vagina/penis",
  "Left arm",
  "Right arm",
  "Left leg",
  "Right leg",
  "Left hand",
  "Right hand",
  "Left foot",
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'list' implicitly has an 'any' type.
  "Right foot",
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'options' implicitly has an 'any' type.
];

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'r' implicitly has an 'any' type.
const FilterRashOptions = (list, options) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'list' implicitly has an 'any' type.
  return (list || []).filter((r) => options.includes(r));
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'options' implicitly has an 'any' type.
};

const anyOptionsSelected = (list, options) => {
  if (!list) return false;
  for (const option in options) {
    if (list.includes(option)) return true;
  }
  return false;
};

const RashEntry = ({ onChange, rash }) => {
  // rash is a feature
  const filteredRashOptions = FilterRashOptions(rash.attributes, rashOptions);
  const filteredBodyPartOptions = FilterRashOptions(rash.attributes, bodyPartOptions);

  const rashSelected = rash.attributes.includes("Rash");
  const onSelect = (newValues) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newValues' implicitly has an 'any' type... Remove this comment to see the full error message
    let as = rash.attributes.filter((v) => !newValues.includes(v));
    as.push(...newValues);
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
    onChange(rash, as);
  };

  // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
  const onYesNoChange = (feature, newValues) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'feature' implicitly has an 'any' type.
    if (newValues.includes("No rash")) {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newValues' implicitly has an 'any' type... Remove this comment to see the full error message
      onChange(rash, ["No rash"]);
    } else {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChange(rash, newValues);
    }
  };

  // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
  const rashOptionsSelected = anyOptionsSelected(rash.attributes, rashOptions)
    ? "yes-no-toggle item-selected d-flex"
    : "yes-no-toggle d-flex";
  const bodyPartSelected = anyOptionsSelected(rash.attributes, bodyPartOptions)
    ? "yes-no-toggle item-selected d-flex"
    : "yes-no-toggle d-flex";

  const onRemove = (rashItem) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rashItem' implicitly has an 'any' type.
    onChange(rash, rash.attributes.filter((v) => v !== rashItem));
  // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
  };

  return (
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
    <div className="attribute-container">
      <FeatureEntry
        {...{
          feature: rash,
          values: ["No rash", "Rash"],
          onChange: onYesNoChange,
          id:"qa-rash-entry-rash"
        }}
      />
      {rashSelected && (
        <React.Fragment>
          <div className={rashOptionsSelected}>
            <CheckSelect
              options={rashOptions}
              selected={filteredRashOptions}
              onChange={onSelect}
              label={"Features"}
            />
          </div>
          <div className="rash-items">
            <PillList items={FilterRashOptions(rash.attributes, rashOptions)} onRemove={onRemove} />
          </div>
        </React.Fragment>
      )}
      {rashSelected && (
        <React.Fragment>
          <div className={bodyPartSelected}>
            <CheckSelect
              options={bodyPartOptions}
              selected={filteredBodyPartOptions}
              onChange={onSelect}
              label={"Areas"}
            />
          </div>
          <div className="rash-items">
            <PillList
              items={FilterRashOptions(rash.attributes, bodyPartOptions)}
              onRemove={onRemove}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

RashEntry.propTypes = {
  onChange: PropTypes.func,
  rash: PropTypes.object,
};

export default RashEntry;
