import React, { FC } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import ArrowIcon from "../images/whiteDownArrow.svg";
import "./NewNoteSelect.scss";
import RightArrow from "../images/ArrowRightGrey.svg";
import NoteIcon from "../images/PatientNoteIcon.svg";

type Props = {
  disabled: boolean;
  startEncounter: (type: string) => void;
  setAdding: Function;
  isScribe?: boolean;
};

const NewNoteSelect: FC<Props> = ({ disabled, startEncounter, setAdding, isScribe }) => {
  const iconColor = disabled ? "#7F91A8" : "white";
  return (
    <>
      <UncontrolledDropdown className="dropDownWrapper" direction="down">
        <DropdownToggle
          disabled={disabled}
          className="dropDownToggle"
          id="qa-newnote-drp"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <div>
            <NoteIcon stroke={iconColor} className="ml-2 mr-3" />
            New Note
          </div>
          <div>
            <span className="ml-2">|</span>
            <ArrowIcon stroke={iconColor} height={30} width={30} />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropDownMenu">
          <DropdownItem
            id="qa-newnote-patientvisit-drp"
            className="dropDownItem"
            onClick={() => startEncounter("nursing_home")}
          >
            Patient Visit
            <RightArrow />
          </DropdownItem>
          <DropdownItem id="qa-newnote-blankvisit-drp" divider className="dropdownDivider" />
          <DropdownItem className="dropDownItem" onClick={() => startEncounter("blank_ltc")}>
            Blank Visit Template
            <RightArrow />
          </DropdownItem>
          {!isScribe && (
            <>
              <DropdownItem id="qa-newnote-other-drp" divider className="dropdownDivider" />
              <DropdownItem className="dropDownItem" onClick={() => setAdding(true)}>
                Other
                <RightArrow stroke={iconColor} />
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default NewNoteSelect;
