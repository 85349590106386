import React from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import PropTypes from "prop-types";

const RxStoreAndDispose = ({ features, onChange, note, onNoteUpdate }) => {
  const rxStoreAndDispose = findFeature(
    features,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'features' does not exist on type 'Props'... Remove this comment to see the full error message
    "rxStoreAndDispose",
    "RxStoreAndDispose",
    "RxStoreAndDispose",
  );

  const setAttribute = featureSetAttributes({ onChange });

  return (
    <EntryBlock title="Do you know how to store and dispose of the medication?">
      <div>
        <FeatureEntry
          {...{
            feature: rxStoreAndDispose,
            values: ["Yes", "No"],
            onChange: setAttribute,
            omitTitle: true,
          }}
        />
      </div>
      <div>
        <GroupNoteEntry {...{ groupName: "RxStoreAndDispose", onChange: onNoteUpdate, note, id:"qa-rxStoreAndDispose-group-entry" }} />
      </div>
    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ groupName: string; onChange: ((...args: an... Remove this comment to see the full error message */}
    </EntryBlock>
  );
};

RxStoreAndDispose.propTypes = {
  feature: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
};
export default RxStoreAndDispose;
