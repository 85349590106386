import React from "react";
import { connect } from "react-redux";

import api from "../api";
import { TOKEN_LOAD } from "../constants/actionTypes";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    ...state.common,
    inProgress: state.progress[TOKEN_LOAD],
    targetPath: `${state.router.location.pathname}${state.router.location.search}`,
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  onLoad: (payload, token, targetPath, redirectTo) =>
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'redirectTo' implicitly has an 'any' typ... Remove this comment to see the full error message
    dispatch({
      type: TOKEN_LOAD,
      payload,
      token,
      targetPath: targetPath,
      redirectTo: redirectTo,
    }),
});

class TokenLoader extends React.Component {
  componentDidMount() {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      api.setToken(token);
    }
    let actualTarget = this.props.targetPath;
    if (actualTarget === "/login") {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'targetPath' does not exist on type 'Read... Remove this comment to see the full error message
      actualTarget = "/";
    }
    let redirectTo = "/login";
    if (
      actualTarget.startsWith("/accept-invitation/") ||
      actualTarget.startsWith("/reset-password")
    ) {
      redirectTo = actualTarget;
      actualTarget = "/";
    }
    this.props.onLoad(token ? api.Authorization.current() : null, token, actualTarget, redirectTo);
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onLoad' does not exist on type 'Readonly... Remove this comment to see the full error message
  render() {
    // TODO: this causes issues, so I'm commenting it out for now...
    // if (this.props.inProgress) {
    //   return (<PageLoadSpinner inProgress={this.props.inProgress} />)
    // }
    return <div>{this.props.children}</div>;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TokenLoader);
