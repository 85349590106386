import React from "react";

import styles from "./chart.scss";

const InsuranceInfoPanel = ({ userID, insuranceProfile, card }) => {
  const profile = insuranceProfile || {};
  const payorRelationship =
    (profile.responsibleParty || "").charAt(0).toUpperCase() +
    (profile.responsibleParty || "").slice(1);
  const responsibleParty = profile.responsiblePartyInfo || {};
  const cardFrontImageURL = (card || {}).frontImageURL;
  const cardBackImageURL = (card || {}).backImageURL;
  const hasInsurance = (profile.companyName || "").trim().length > 0 ? true : false;

  return (
    <div className={styles.insuranceInfoPage}>
      {!hasInsurance ? (
        <div>No insurance information entered</div>
      ) : (
        <div className="d-flex flex-row">
          <div className={styles.leftside}>
            <div>
              <h1>Insurance Info</h1>
            </div>
            <div className={styles.profileBlock}>
              <span>Insurance Company:</span>&nbsp;&nbsp;<span>{profile.companyName}</span>
            </div>
            <div className={styles.profileBlock}>
              <span>ID Number:</span>&nbsp;&nbsp;<span>{profile.insuranceID}</span>
            </div>
            <div className={styles.profileBlock}>
              <span>Group Number:</span>&nbsp;&nbsp;<span>{profile.groupNumber}</span>
            </div>
            <div className={styles.profileBlock}>
              <span>CoPay Amount:</span>&nbsp;&nbsp;<span>${profile.pcpCopay}</span>
            </div>
            <div className={styles.profileBlock}>
              <span>Patient Relationship to Responsible Party:</span>&nbsp;&nbsp;
              <span>{payorRelationship}</span>
            </div>
            <div className={styles.profileBlock}>
              <a target="_blank" href={`/insurnace-history/${userID}`}>
                Insurance History
              </a>
            </div>
          </div>
          <div className={styles.rightside}>
            <div>
              <h1>Insurance Card</h1>
            </div>
            <div className={styles.cardContainer}>
              {!!cardFrontImageURL ? (
                <img src={cardFrontImageURL} />
              ) : (
                <div className={styles.emptyCard}>No card front image provided</div>
              )}
            </div>
            <div className={styles.cardContainer}>
              {!!cardBackImageURL ? (
                <img src={cardBackImageURL} />
              ) : (
                <div className={styles.emptyCard}>No card back image provided</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InsuranceInfoPanel;
