import React, { FC } from "react";

const arrayDiff = function (t, a) {
  return t.filter(function (i) {
    return a.indexOf(i) < 0;
  });
};

type Props = {
  customCSS?: string;
  items?: string[] | null;
  onRemove: (target) => void;
  oldItems?: string[];
};
type PropsPill = {
  value: string;
  customCSS?: string;
  className?: string;
  RemoveItem: (target: string) => () => void;
};

const Pill: FC<PropsPill> = (props) => {
  const cn = `pill d-flex ml-1 mb-1 ${props.className} ${props.customCSS}`;
  return (
    <div className={cn}>
      <div className="pill-content blue align-self-center">{props.value}</div>
      <div className="close-button ml-4 align-self-center" onClick={props.RemoveItem(props.value)}>
        {" "}
        &#x2715;{" "}
      </div>
    </div>
  );
};

const PillList: FC<Props> = (props) => {
  let items = props.items || [];
  let newItems = [];
  if (items.length == 0) {
    return null;
  }
  const ef = () => {};
  const onRemove = props.onRemove || ef;
  const removeItem = (item: string) => () => {
    onRemove(item);
  };
  if (props.oldItems) {
    newItems = arrayDiff(items, props.oldItems);
    items = arrayDiff(props.items, newItems);
  }

  return (
    <div className="h-pill-list d-flex flex-wrap">
      {items.map(function (i, idx) {
        return <Pill value={i} key={idx} RemoveItem={removeItem} customCSS={props.customCSS} />;
      })}
      {newItems.map(function (i, idx) {
        return (
          <Pill
            className="new"
            value={i}
            key={idx}
            RemoveItem={removeItem}
            customCSS={props.customCSS}
          />
        );
      })}
    </div>
  );
};

export default PillList;
