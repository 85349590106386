import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";

import styles from "./SignatureCollector.scss";
import SigningImage from "../images/Signing.svg";
import { SIGNATURE_COLLECTED } from "../constants/actionTypes";
import api from "../api";
import Success from "./Provider/Success";

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signatureCollected: (payload) => dispatch({ type: SIGNATURE_COLLECTED, payload }),
});

const STEP_INTRO = "STEP_INTRO";
const STEP_COLLECTION = "STEP_COLLECTION";
const STEP_CONTACT_SUPPORT = "STEP_CONTACT_SUPPORT";
const STEP_SUCCESS = "STEP_SUCCESS";
const STEP_COMPLETE = "STEP_COMPLETE";

const Introduction = ({ onNext }) => {
  return (
    <div className={styles.signatureSetupIntro}>
      <h1>Hold Up! We need your signature...</h1>
      <SigningImage />
      <div>It looks like you haven’t stored a signature yet.</div>
      <div> You must set it up before taking on any patients. </div>
      <Button color="primary" onClick={onNext}>
        {" "}
        Set up signature &gt;&gt;
      </Button>
    </div>
  );
};

class CollectSignature extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = { submitDisabled: true };
  }

  uploadSignature = (canvasRef) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'canvasRef' implicitly has an 'any' type... Remove this comment to see the full error message
    let canvas = canvasRef.getTrimmedCanvas();
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'blob' implicitly has an 'any' type.
    canvas.toBlob((blob) => this.props.uploadSignature(blob));
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'uploadSignature' does not exist on type ... Remove this comment to see the full error message
  };

  strokeEnd = (canvasRef) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'canvasRef' implicitly has an 'any' type... Remove this comment to see the full error message
    let canvas = canvasRef.getTrimmedCanvas();
    if (canvas.width > 50 && canvas.height > 50) {
      this.setState({ submitDisabled: false });
    }
  };

  clear = (canvasRef) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'canvasRef' implicitly has an 'any' type... Remove this comment to see the full error message
    canvasRef.clear();
    this.setState({ submitDisabled: true });
  };

  render() {
    return (
      <div className={styles.signatureCollector}>
        <h1>Signature Setup</h1>
        <div className="w-100 d-flex justify-content-between">
          <SignatureCanvas
            penColor="black"
            ref={(ref) => {
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'ref' implicitly has an 'any' type.
              this.sigCanvas = ref;
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'sigCanvas' does not exist on type 'Colle... Remove this comment to see the full error message
            }}
            onEnd={() => this.strokeEnd(this.sigCanvas)}
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'sigCanvas' does not exist on type 'Colle... Remove this comment to see the full error message
            canvasProps={{ width: 600, height: 300, className: "sigCanvas" }}
          />
          <div className="d-flex flex-column ml-5">
            <p>Sign on the pad to the left by clicking and holding while drawing your signature.</p>
            <p>
              You can clear and try again if needed.
              <br />
              Press Submit when finished.{" "}
            </p>
            <Button
              disabled={this.state.submitDisabled}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitDisabled' does not exist on type '... Remove this comment to see the full error message
              className={styles.submitSignature}
              onClick={() => this.uploadSignature(this.sigCanvas)}
            >
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'sigCanvas' does not exist on type 'Colle... Remove this comment to see the full error message */}
              Submit Signature
            </Button>
            <Button color="link" onClick={this.props.onSupport}>
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'onSupport' does not exist on type 'Reado... Remove this comment to see the full error message */}
              Not working - help!
            </Button>
          </div>
        </div>
        <Button
          color="link"
          className={styles.clearButton}
          onClick={() => this.clear(this.sigCanvas)}
        >
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'sigCanvas' does not exist on type 'Colle... Remove this comment to see the full error message */}
          Clear
        </Button>
      </div>
    );
  }
}

const ContactSupport = ({}) => {
  return (
    <div className={styles.supportDialog}>
      <h1>Sorry to hear your signature setup is not cooperating!</h1>

      <div>
        <h2>Please contact support at:</h2>
        <a href="mailto:support@reliantid.com">support@reliantid.com</a>
      </div>
    </div>
  );
};

class SignatureCollector extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = {
      step: STEP_INTRO,
    };
  }

  collectSignature = () => {
    this.setState({ step: STEP_COLLECTION });
  };

  displaySupport = () => {
    this.setState({ step: STEP_CONTACT_SUPPORT });
  };

  uploadSignature = async (imageData) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'imageData' implicitly has an 'any' type... Remove this comment to see the full error message
    let uploadUrl = await api.Signature.getUploadUrl();
    uploadUrl.uploadHeaders["Content-Type"] = "image/png";
    let result = await api.Signature.putSignature(
      uploadUrl.destinationUrl,
      imageData,
      uploadUrl.uploadHeaders,
    );
    if (result.statusCode == 200) {
      result = await api.Signature.setSignatureUploaded(true);
      this.setState({ step: STEP_SUCCESS });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'signatureCollected' does not exist on ty... Remove this comment to see the full error message
      this.props.signatureCollected(result);
      let complete = () => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'signatureCollected' does not exist on ty... Remove this comment to see the full error message
        this.props.signatureCollected(result);
        this.setState({ step: STEP_COMPLETE });
      };
      setTimeout(complete, 3000);
    }
  };

  render() {
    if (this.state.step == STEP_COMPLETE) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      return null;
    }

    return (
      <div className="modal-overlay">
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
        {this.state.step === STEP_INTRO && <Introduction onNext={this.collectSignature} />}
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
        {this.state.step === STEP_COLLECTION && (
          <CollectSignature
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ uploadSignature: (imageData: any) => Promi... Remove this comment to see the full error message
            uploadSignature={this.uploadSignature}
            onSupport={this.displaySupport}
          />
        )}
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
        {this.state.step === STEP_CONTACT_SUPPORT && <ContactSupport />}
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
        {this.state.step == STEP_SUCCESS && <Success message="Signature Saved" />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignatureCollector);
