import React, { FC } from "react";
import { Button } from "reactstrap";

type Props = {
  visible: boolean;
  onCancel: Function;
  onContinue: Function;
};

const PendingModal: FC<Props> = ({ visible, onCancel, onContinue }) => {
  if (!visible) {
    // popup not visible
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="ml-auto mr-auto center-modal w-50 h-50">
        <h1 className="title">Pending Chart Item Found</h1>
        <div className="center ml-5 mr-5 mt-5">
          <p>
            This patient has a pending test result on their chart. If you are attempting to upload
            results for this test, please press cancel and use the plus button that appears next to
            the pending test line item. If you are uploading a different file, please press
            continue.
          </p>
        </div>
        <div className="buttons">
          <Button onClick={() => !!onCancel && onCancel()}>Cancel</Button>
          <Button onClick={() => !!onContinue && onContinue()}>Continue</Button>
        </div>
      </div>
    </div>
  );
};
export default PendingModal;
