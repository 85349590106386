import React, { FC } from "react";

import {
  ICatalogItem,
  IReviewOfSystems,
  IUserMedicalIntake,
  IUserReviewOfSystemsKeys,
  UserSymptomDetailsKeys,
} from "../../constants/Types";
import BlockSection from "./BlockSection";
import { HandleChangeFuncCurrentSymptomsType } from "./CurrentSymptoms";
import SymptomEditorWithDetails from "./SymptomEditorWithDetails";

const EmptyComponent = () => {
  return null;
};

type Props = {
  visitType?: string;
  ros: IUserReviewOfSystemsKeys;
  itemOrder: {
    systemKey: number;
    systemName: string;
    items: ICatalogItem[];
  }[];
  details: UserSymptomDetailsKeys;
  catalog: Partial<IReviewOfSystems>;
  title?: string;
  onChange: HandleChangeFuncCurrentSymptomsType;
  allergyType: string | null;
  symptomsDetailField: keyof IUserMedicalIntake;
  symptomByStystemField: keyof IUserMedicalIntake;
};

const CurrentSymptomsEditor: FC<Props> = (props) => {
  const catalog = props.catalog;
  const systems = (props.ros || {}).systems;
  const d = props.details || { symptoms: [] };
  const ros = props.ros.systems || {};
  const symptomsDetailField = props.symptomsDetailField;
  const symptomByStystemField = props.symptomByStystemField;

  let allOptions = () => {
    let options: ICatalogItem[] = [];
    let visitType = props.allergyType ? props.allergyType : props.visitType;
    props.itemOrder.forEach((item) => {
      item.items.forEach((systemItem) => {
        if (catalog.relevantSymptomsKeys![visitType || ""].indexOf(systemItem.key) > -1) {
          systemItem.group = item.systemKey;
          options.push(systemItem);
        }
      });
    });
    return options;
  };

  let flatSystem = () => {
    let flat: number[] = [];
    for (let system in systems) {
      flat = flat.concat(systems[system]);
    }
    return flat;
  };
  return (
    <BlockSection title={props.title || ""}>
      <div>
        <div className="system-edit-container w-100">
          <div className="w-100">
            <SymptomEditorWithDetails
              order={allOptions()}
              system={flatSystem()}
              details={d.symptoms}
              onChange={props.onChange}
              components={{
                IndicatorSeparator: EmptyComponent,
                MultiValueContainer: EmptyComponent,
              }}
              options={allOptions()}
              ros={ros}
              symptomsDetailField={symptomsDetailField}
              symptomByStystemField={symptomByStystemField}
              systems={systems}
            />
          </div>
        </div>
      </div>
    </BlockSection>
  );
};

export default CurrentSymptomsEditor;
