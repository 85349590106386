import React, { FC } from "react";
import { Row, Col } from "reactstrap";

import Icon from "../../images/ProfileSearchIcon.svg";
import Chart from "./Chart";

const ActivePatientChart: FC<{}> = ({}) => {
  return (
    <div>
      <div className="dashboard-container">
        <h4>
          {" "}
          <div className="header-icon float-left">
            <Icon />
          </div>{" "}
          Patients{" "}
        </h4>
        <Row>
          <Col md={{ size: 12 }}>
            <Chart fromActiveEncounter={true} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ActivePatientChart;
