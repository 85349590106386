import {
  COMPONENT_LOAD,
  COMPONENT_LOAD_MORE,
  COMPONENT_UNLOAD,
  COMPONENT_CHANGED,
  ASYNC_START,
  ASYNC_END,
  ASSIGNED_FAX_LIST,
  UNASSIGNED_FAX_LIST,
  FAX_ASSIGN,
  FAX_COMPLETE,
  DISMISS_FAX,
  ENCOUNTER_CHAT_DATA,
  ENCOUNTER_POLLER_DATA,
  ENCOUNTER_VISIT_HISTORY,
  ENCOUNTER_MONITOR_DETAILS,
  ENCOUNTER_MONITOR_LIST,
  PRESCRIPTION_NOTIFICATIONS,
  PRESCRIPTION_NOTIFICATION_LINK,
  PROVIDERS_LIST,
  PROVIDER_VISIT_HISTORY,
  PROVIDER_DETAILS_LOAD,
  PROVIDER_EDIT_SAVE,
  PROVIDER_RESEND_INVITE,
  VIDEO_ROOM_TOKEN,
  ENCOUNTER_IMAGES,
  ENCOUNTERS_SEARCH_RESULTS,
} from "../constants/actionTypes";

import update from "immutability-helper";
import { Reducer } from "redux";
import {
  IAssignFaxResponse,
  IChatHistory,
  IDosespotNotifications,
  IEncounterImages,
  IEncounterList, IEncounterListItem,
  IEngagementStatusResponse,
  IMonitorEncounter,
  IProviderList,
  IProviderParameters,
  IVisitHistory,
  IVisitHistoryResponse,
} from "../constants/Types";

type componentDataState = {
  [ENCOUNTER_CHAT_DATA]?: dataWithErorrs<IChatHistory>;
  [ENCOUNTER_POLLER_DATA]?: dataWithErorrs<IEngagementStatusResponse>;
  [ENCOUNTER_VISIT_HISTORY]?: dataWithErorrs<IVisitHistoryResponse>;
  [ENCOUNTER_MONITOR_DETAILS]?: dataWithErorrs<IMonitorEncounter>;
  [ENCOUNTER_MONITOR_LIST]?: dataWithErorrs<IEncounterList>;
  [PRESCRIPTION_NOTIFICATIONS]?: dataWithErorrs<IDosespotNotifications>;
  [ENCOUNTERS_SEARCH_RESULTS]?: dataWithErorrs< IEncounterListItem[]>;
  [PRESCRIPTION_NOTIFICATION_LINK]?: dataWithErorrs<{
    link: string;
  }>;
  [PROVIDERS_LIST]?: dataWithErorrs<IProviderList>;
  [PROVIDER_VISIT_HISTORY]?: dataWithErorrs<IVisitHistory>;
  [PROVIDER_DETAILS_LOAD]?: dataWithErorrs<IProviderParameters>;
  [PROVIDER_EDIT_SAVE]?: dataWithErorrs<IProviderParameters>;
  [PROVIDER_RESEND_INVITE]?: dataWithErorrs<{
    email: string;
  }>;
  [VIDEO_ROOM_TOKEN]?: dataWithErorrs<any>; // unused(endpoint doesn't exist)
  [UNASSIGNED_FAX_LIST]?: dataWithErorrs<IAssignFaxResponse>;
  [ASSIGNED_FAX_LIST]?: dataWithErorrs<IAssignFaxResponse>;
  [ENCOUNTER_IMAGES]?: dataWithErorrs<IEncounterImages>;
};
type componentDataAction = {
  subtype: keyof componentDataState;
  type: any;
  payload: any;
  index?: number;
  faxID?: string;
  error?: string[];
};

type dataWithErorrs<data> = {
  data: data;
  errors: string[];
};

const defaultState = {};

const extractErrors = (action: componentDataAction) => {
  if (!action.error) {
    return null;
  }
  const payload = action.payload || {};
  if (payload.errors) {
    return payload.errors;
  }
  if (payload.message) {
    return [payload.message];
  }
  if (payload.status) {
    return [payload.status];
  }
  return ["Unknown error"];
};

const updateFaxAttribute = (state, faxID, payload, faxList, attribute) => {
  let idx = state[faxList].data.items.findIndex((i) => i.faxID.ID === payload.faxID.ID);

  if (idx >= 0) {
    return update(state, {
      [faxList]: {
        data: {
          items: { [idx]: { [attribute]: { $set: payload[attribute] } } },
        },
      },
    });
  }
  return state;
};

const dismissFax = (state, faxID) => {
  let faxList = ASSIGNED_FAX_LIST;

  let idx = -1;
  if (state[faxList] && state[faxList].data && state[faxList].data.items) {
    idx = state[faxList].data.items.findIndex((i) => i.faxID.ID === faxID.ID);
  }

  if (idx < 0) {
    faxList = UNASSIGNED_FAX_LIST;
    if (state[faxList] && state[faxList].data && state[faxList].data.items) {
      idx = state[faxList].data.items.findIndex((i) => i.faxID.ID === faxID.ID);
    }
  }

  if (idx >= 0) {
    return update(state, {
      [faxList]: { data: { items: { $splice: [[idx, 1]] } } },
    });
  }
  return state;
};

const componentDataReducer: Reducer<componentDataState, componentDataAction> = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case COMPONENT_LOAD:
      return {
        ...state,
        [action.subtype]: {
          errors: extractErrors(action),
          data: action.payload,
        },
      };
    case COMPONENT_UNLOAD:
      const { [action.subtype]: value, ...newState } = state;
      return newState;

    case COMPONENT_LOAD_MORE:
      if (!action.payload) {
        return state;
      }
      return update(state, {
        [action.subtype]: { data: { items: { $push: action.payload.items } } },
      });
    case COMPONENT_CHANGED:
      return update(state, { [action.subtype]: { data: action.payload } });

    case ASYNC_START:
      if (action.subtype) {
        return {
          ...state,
          [action.subtype]: { ...state[action.subtype], inProgress: true },
        };
      }
      return state;
    case ASYNC_END:
      if (action.subtype) {
        return {
          ...state,
          [action.subtype]: { ...state[action.subtype], inProgress: false },
        };
      }
      return state;
    case FAX_ASSIGN:
      return updateFaxAttribute(
        state,
        action.faxID,
        action.payload,
        UNASSIGNED_FAX_LIST,
        "assigned",
      );
    case FAX_COMPLETE:
      return updateFaxAttribute(state, action.faxID, action.payload, ASSIGNED_FAX_LIST, "complete");
    case DISMISS_FAX:
      return dismissFax(state, action.faxID);
  }
  return state;
};

export default componentDataReducer;
