import React, { useState, useEffect, useCallback, FC } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { FormGroup, Input, Button, Table, Badge } from "reactstrap";
import moment from "moment";

import NavBar from "../Provider/NavBar";
import { store } from "../../store";
import Spinner from "../../images/Spinner.svg";
import api from "../../api";
import {
  COMPONENT_LOAD,
  COMPONENT_UNLOAD,
  ENCOUNTER_MONITOR_LIST,
  NAVIGATE_TO,
  SELECT_PRACTICE,
  SWITCH_PRACTICE,
} from "../../constants/actionTypes";
import { waitingRoomVisitTypeLookup } from "../../constants/Encounter";

import "../../stylesheets/encounterMonitor.scss";

import MonitorEncounterSearch from "./MonitorEncounterSearch";

import PracticeFilter from "../Patients/EncounterSearch/PraticeFilter/PracticeFilter";
import { IEncounterListItem, IlistPractice } from "../../constants/Types";

import Chat from "../../images/MonitorChat.svg";
import Video from "../../images/MonitorVideo.svg";
import PaginationAE from "./PaginationBlock";
import bugsnag from "../../services/bugsnag";
const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    permissions: state.common.permissions,
    selectedPractice: state.monitoring.selectedPractice || {},
    ...state.componentData[ENCOUNTER_MONITOR_LIST],
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) =>
    dispatch({
      type: COMPONENT_LOAD,
      subtype: ENCOUNTER_MONITOR_LIST,
      payload,
    }),
  onUnload: () => dispatch({ type: COMPONENT_UNLOAD, subtype: ENCOUNTER_MONITOR_LIST }),
  viewEncounter: (id) => dispatch({ type: NAVIGATE_TO, targetPath: `/encounter-monitor/${id}` }),
  setSelectedPractice: (practice: { name: string; id: string; nhFlag: boolean }) =>
    dispatch({ type: SELECT_PRACTICE, practice }),
  onSwitchPractice: (practiceID: string, id: string) =>
    dispatch({
      type: SWITCH_PRACTICE,
      payload: api.Authorization.switchPractice(practiceID),
      targetPath: `/encounter-monitor/${id}`,
    }),
});

export const formatTime = (lastContact: string) => {
  const time = moment.utc(lastContact);
  if (!time.isValid()) {
    return "";
  }
  let d = moment.duration(moment().diff(lastContact)).asHours();

  if (d < 1) {
    let mins = Math.round(moment.duration(moment().diff(lastContact)).asMinutes());
    return `(${mins} min ago)`;
  } else if (d < 48) {
    let hours = Math.round(moment.duration(moment().diff(lastContact)).asHours());
    return `(${hours} hours ago)`;
  } else {
    let days = Math.round(moment.duration(moment().diff(lastContact)).asDays());
    return `(${days} days ago)`;
  }
};

const SortEncounters = (encounters) => {
  encounters?.sort((a, b) => {
    const aTime = moment(a.timestamp);
    const bTime = moment(b.timestamp);
    if (aTime.isBefore(bTime)) {
      return 1;
    }
    if (bTime.isBefore(aTime)) {
      return -1;
    }
    return 0;
  });
  return encounters;
};
type Props = {
  encounter: IEncounterListItem;
  onClick: (string, practiceID?: string) => (event: MouseEvent) => void;
  currentView: string;
};

export const EncounterRow: FC<Props> = ({ encounter, onClick, currentView }) => {
  const t = moment(encounter.timestamp);
  const lastContact = moment.utc(encounter.lastContact || "").isValid()
    ? moment
        .utc(encounter.lastContact || "")
        .local()
        .format("MM/DD/YY hh:mma")
    : "N/A";

  const duration = encounter?.lastContact && formatTime(encounter.lastContact);
  const isChat = encounter.communicationMethod === "Chat";

  return currentView === VIEW_POSTPONED_ENCOUNTERS ? (
    <tr onClick={onClick(encounter.referenceID)}>
      <td>{t.format("MM/DD/YY h:mma")}</td>
      <td>
        {encounter.status}
        {encounter.postponed && (
          <div>
            <span>(postponed)</span>
          </div>
        )}
      </td>
      <td>{waitingRoomVisitTypeLookup[encounter.visitType] || encounter.visitType}</td>
      <td>
        <div>User: {encounter.userState}</div>
        <div>Provider: {encounter.providerState}</div>
      </td>
      <td>
        <div>{lastContact}</div>
        <div className="time-elapsed">{duration}</div>
      </td>
      <td>{encounter.newMessages}</td>
      <td>{encounter.demographic}</td>
      <td>{encounter.location}</td>
      <td>{(encounter.languages || []).join(", ")}</td>
      <td>
        {encounter.redFlagged && (
          <Badge color="danger" pill className="qa-monitorRedFlagYes">
            Yes
          </Badge>
        )}
        {!encounter.redFlagged && (
          <Badge color="info" pill className="qa-monitorRedFlagNo">
            No
          </Badge>
        )}
      </td>
    </tr>
  ) : (
    <tr onClick={onClick(encounter.referenceID, encounter.practiceID)} className="encounterRow">
      <td>{t.format("MM/DD/YY h:mma")}</td>
      <td>{encounter.medicalPracticeName}</td>
      <td>{encounter.chiefComplaint || "--"}</td>
      <td>{encounter.location || "--"}</td>
      <td>
        {isChat ? (
          <Chat />
        ) : encounter.communicationMethod === "Video" ? (
          <Video />
        ) : (
          <span>--</span>
        )}
      </td>
      <td className="monitorNewMessages qa-monitorNewMessages">
        {!!isChat && encounter.newMessages === "Yes" ? (
          <Badge pill color="info" className="monitorNewMessagesBadge">
            {encounter.newMessages}
          </Badge>
        ) : !!isChat && encounter.newMessages === "No" ? (
          <span>{encounter.newMessages}</span>
        ) : (
          <span>--</span>
        )}
      </td>
      <td>
        <div className="last-contact">{lastContact}</div>
        <div className="time-elapsed">{duration}</div>
      </td>
      <td>{encounter.providerName || "--"}</td>
      <td>{encounter.latestVisitStatus}</td>
      <td>{encounter.patientName || "--"}</td>
    </tr>
  );
};

const VIEW_RECENT_ENCOUNTER = "recentEncounter";
const VIEW_AT_HOME_PCR_TEST_KITS = "atHomePCRTestKits";
const VIEW_POSTPONED_ENCOUNTERS = "postponedEncounter";

const filterBy = (filter: string, value: string) => {
  return filter === "all" || !!!filter ? true : value === filter;
};

const defaultOptions = [{ value: "all", label: "All" }];

const TestKitRow = ({ encounter, currentUser }) => {
  const vd = moment(encounter.date);
  let badgeColor;
  switch (encounter.status.toLowerCase()) {
    case "complete":
      badgeColor = "success";
      break;
    case "pending":
      badgeColor = "warning";
      break;
    default:
      badgeColor = "warning";
  }

  const viewUser = (userID) => {
    store.dispatch(push(`/patients/${userID}`));
  };

  if (!!currentUser && currentUser?.currentPracticeID === encounter?.practiceID) {
    return (
      <tr>
        <td>{vd.format("MM/DD/YY")}</td>
        <td>
          <Button
            color="link"
            className="patient-link qa-monitorPatientLink"
            onClick={() => viewUser(encounter.userID)}
          >
            {encounter.name}
          </Button>
        </td>
        <td>{encounter.barcode}</td>
        <td className="encounter-status-cell">
          <Badge color={badgeColor} className="qa-monitorEncounterStatus">
            {encounter.status.toLowerCase() !== "complete" ? "Pending" : "Complete"}
          </Badge>
        </td>
        <td>&nbsp;</td>
      </tr>
    );
  } else {
    return <></>;
  }
};

const PaginationBlock = ({ pageStart, totalCount, onChange, pageSize }) => {
  const totalPages = totalCount / pageSize;
  const currentPage = pageStart / pageSize;

  let previouslist = [];
  let newList = [];
  let count = pageStart;
  if (currentPage > 0) {
    while (previouslist.length < 2 && count > 0) {
      count -= pageSize;
      previouslist.unshift(count);
    }
  }
  count = pageStart;

  if (currentPage < totalPages) {
    while (newList.length + previouslist.length < 4 && count + pageSize < totalCount) {
      count += pageSize;
      newList.push(count);
    }
  }

  return (
    <div className="paginationBlock">
      {previouslist.map((item) => (
        <div key={item}>
          <Button color="link" onClick={() => onChange(item)}>
            {item / pageSize + 1}
          </Button>
        </div>
      ))}
      <div className="currentPage">{currentPage + 1}</div>
      {newList.map((item) => (
        <div key={item}>
          <Button color="link" onClick={() => onChange(item)}>
            {item / pageSize + 1}
          </Button>
        </div>
      ))}
    </div>
  );
};

function Monitor({
  onLoad,
  onUnload,
  currentUser,
  viewEncounter,
  data,
  inProgress,
  selectedPractice,
  onSwitchPractice,
  ...props
}) {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentView, setCurrentView] = useState(VIEW_RECENT_ENCOUNTER);
  const [searchText, setSearchText] = useState(undefined);
  const [pcrTestKits, setPcrTestKits] = useState([]);
  const [filteredKits, setFilteredKits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ status: "", visitType: "", message: "" });
  const [postponedEncounters, setPostponedEncounters] = useState([]);
  const [pageStart, setPageStart] = useState(0);
  const [totalCount, setTotalCount] = useState();

  const [nextPageToken, setNextPageToken] = useState("");
  const [pageSize, setPageSize] = useState(200);

  const [pagOpen, setPagOpen] = useState(false);

  const [practices, setPracites] = useState<IlistPractice["items"]>({});

  const fetchPractices = async () => {
    //send selected practice on change trigger in useEffect
    try {
      let result = await api.Providers.listPractices();

      setPracites(result.items);
    } catch (error) {
      console.log("ERROR Fetching Practices::", error);
    }
  };

  useEffect(() => {
    if (currentView === VIEW_RECENT_ENCOUNTER) {
      fetchPractices();

      const queryParams = {
        pageStart: pageStart,
        pageSize: pageSize,
        practiceID: selectedPractice?.id,
      };

      onLoad(api.Monitoring.filteredEncounters(queryParams));
    } else if (currentView === VIEW_POSTPONED_ENCOUNTERS) {
      setLoading(true);
      api.Monitoring.postponedEncounters(nextPageToken)
        .then((res) => {
          setPostponedEncounters(res.items || []);
          setTotalCount(res.totalCount);
          setPageSize(res.items.length || 0);
          setNextPageToken(res.nextPageToken || "");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          bugsnag.notify(err);
        });
    } else {
      setLoading(true);
      api.UserRecords.getPcrTests()
        .then((res) => {
          setPcrTestKits(res.items);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return () => {
      onUnload();
    };
  }, [selectedPractice, currentUser, currentView, pageStart]);

  useEffect(() => {
    filterData("");
  }, [pcrTestKits]);

  useEffect(() => {
    const items = SortEncounters(data ? data.items : []);

    setItems(items);
    setFilteredItems(items);
    if (data) {
      setTotalCount(data?.totalCount);
      setPageSize(data?.pageSize);
    }
  }, [data]);

  const rowClick = (id: string, practiceID?: string) => (e: MouseEvent) => {
    e.stopPropagation();

    !!practiceID ? onSwitchPractice(practiceID, id) : viewEncounter(id);
  };

  const changeView = (view) => {
    if (view === VIEW_RECENT_ENCOUNTER) {
      setPageSize(200);
      setPageStart(0);
    }
    setCurrentView(view);
  };

  const filterData = (filterStr) => {
    const filteredKits = pcrTestKits.filter(
      (kit) =>
        kit.name.toLowerCase().indexOf(filterStr.toLowerCase()) > -1 ||
        kit.barcode.toLowerCase().indexOf(filterStr.toLowerCase()) > -1,
    );
    setFilteredKits(filteredKits);
  };

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'barcode' does not exist on type 'never'.
  const handleSearchTextChange = useCallback((text) => {
    setSearchText(text);
    filterData(text);
  });

  const handlePageChange = (start: number) => {
    setPageStart(start);
    setLoading(true);
  };

  const handlePracticeSelect = (practice) => {
    setFilteredItems([]);
    setPageStart(0);
    setPageSize(200);
    props.setSelectedPractice(practice);
  };

  const handleLoadNewItems = (pageStart, pageSize) => {
    setFilteredItems([]);
    setPageStart(pageStart);
    setPageSize(pageSize);
  };

  return (
    <div>
      <NavBar />
      <div className="dashboard-container">
        <MonitorEncounterSearch onRowClick={rowClick} />

        <div className="dashboard-component align-content-stretch encounter-monitor-container">
          <div className="d-flex  w-100">
            <h1
              className={
                currentView === VIEW_RECENT_ENCOUNTER
                  ? `selected qa-monitorEncounterMonitorHeaderToggle`
                  : "qa-monitorEncounterMonitorHeaderToggle"
              }
              onClick={() => changeView(VIEW_RECENT_ENCOUNTER)}
            >
              All Encounters
            </h1>
            <h1
              className={
                currentView === VIEW_POSTPONED_ENCOUNTERS
                  ? `selected qa-monitorPostponedEncountersToggle`
                  : "qa-monitorPostponedEncountersToggle"
              }
              onClick={() => changeView(VIEW_POSTPONED_ENCOUNTERS)}
            >
              Postponed Encounters
            </h1>
            <h1
              className={
                currentView === VIEW_AT_HOME_PCR_TEST_KITS
                  ? `selected qa-monitorPCRTestKitHeaderToggle`
                  : "qa-monitorPCRTestKitHeaderToggle"
              }
              onClick={() => changeView(VIEW_AT_HOME_PCR_TEST_KITS)}
            >
              At-Home PCR Test Kits
            </h1>

            {currentView === VIEW_RECENT_ENCOUNTER && (
              <div className="recent-encounterSearch">
                <div className="pagination-allEnounters">
                  <PaginationAE
                    totalCount={totalCount}
                    pageSize={pageSize}
                    pageStart={pageStart}
                    onChange={handleLoadNewItems}
                  />
                </div>
                <div className="practiceFilter d-flex">
                  <PracticeFilter
                    selectedPractice={selectedPractice}
                    providerPractices={Object.keys(practices)?.map((item) => ({
                      state: item,
                      practices: practices[item],
                    }))}
                    onPracticeSelect={handlePracticeSelect}
                  ></PracticeFilter>
                </div>
              </div>
            )}
          </div>

          {currentView === VIEW_RECENT_ENCOUNTER && (
            <>
              <Table className="encounter-monitor-table" striped hover>
                <thead>
                  <tr>
                    <th>Visit Created</th>
                    <th>Practice</th>
                    <th>Chief Complaint</th>
                    <th>Location</th>
                    <th>Method</th>
                    <th>New Messages</th>
                    <th>Last Message</th>
                    <th>Provider</th>
                    <th>Visit Timeline</th>
                    <th>Patient Name</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems?.map((item, idx) => (
                    <EncounterRow
                      encounter={item}
                      key={`recentEncounters-${idx}`}
                      onClick={rowClick}
                      className="qa-monitorEncounterRow monitorEncounterRow"
                      currentView={currentView}
                    />
                  ))}
                </tbody>
              </Table>
            </>
          )}
          {currentView === VIEW_AT_HOME_PCR_TEST_KITS && (
            <>
              <div className="filterBar">
                <FormGroup className="d-flex">
                  <Input
                    placeholder="Search Patient Name or Barcode Number"
                    value={searchText}
                    onChange={(event) => handleSearchTextChange(event.target.value)}
                    className="qa-monitorPCRTestSearch"
                  />
                </FormGroup>
              </div>
              <Table className="encounter-monitor-pcr-testkits-table" striped hover>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Patient Name</th>
                    <th>Test Barcode Number</th>
                    <th className="encounter-status-cell qa-monitorPCRTestKitStatus">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    filteredKits?.map((kit, idx) => (
                      <TestKitRow
                        encounter={kit}
                        key={`qa-monitorTestKitRow-${idx}`}
                        className="qa-monitorTestKitRow"
                        currentUser={currentUser}
                      />
                    ))}
                </tbody>
              </Table>
            </>
          )}
          {currentView === VIEW_POSTPONED_ENCOUNTERS && (
            <>
              <div className="paginationBar">
                <PaginationBlock
                  pageStart={pageStart}
                  totalCount={totalCount}
                  onChange={handlePageChange}
                  pageSize={pageSize}
                />
              </div>
              <Table className="encounter-monitor-table" striped hover>
                <thead>
                  <th>Visit Created</th>
                  <th>Status</th>
                  <th>Chief Complaint</th>
                  <th>User State</th>
                  <th>New Messages</th>
                  <th>Last Messages</th>
                  <th>Demographic</th>
                  <th>Location</th>
                  <th>Language</th>
                  <th>Red Flagged</th>
                </thead>
                <tbody>
                  {postponedEncounters &&
                    postponedEncounters?.map((item) => (
                      <EncounterRow
                        encounter={item}
                        key={item.id}
                        onClick={rowClick}
                        className="qa-monitorPostponedEncounterRow"
                        currentView={currentView}
                      />
                    ))}
                </tbody>
              </Table>
            </>
          )}
          {(inProgress || loading) && (
            <div className="d-flex flex-column w-100 align-items-center">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Monitor);
