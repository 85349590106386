import React, { useState, FC } from "react";
import { Button } from "reactstrap";

import styles from "./PatientSideBar.scss";
import RightVector from "../../images/RightWhiteVector.svg";
import AddEntry from "./AddEntry";
import UploadFileModal from "./UploadFileModal";
import PatientSideBarProfileInfo from "./PatientSideBarProfileInfo";
import PatientSideBarProfileEdit from "./PatientSideBarProfileEdit";

import { ICurrentUser } from "../../constants/Types";
import { IPatientProfile } from "../../constants/Types";

type IPatientSideBar = {
  adding: boolean;
  setAdding: Function;
  consent: boolean | null;
  setConsent: Function;
  userID: string;
  currentUser: ICurrentUser;
  profile: IPatientProfile;
  image: string;
  defaultImage: string;
  initiateEncounter: (visitType: string) => void;
  handleBack: () => void;
  loadEntries: (base: any, token?: string) => void;
  fromActiveEncounter: boolean;
  updateProfile: Function;
  setConsentModal: (consent: boolean) => void;
  isNursingHomeSite: boolean;
  consentDate: string | null;
  permissions:string[]
  setEditConsentModal: (consentModal:boolean) => void;
  isPCCPatient: boolean;
  viewHealthGorillaChart: () => void;
  isBtnPending: boolean;

};
const PatientSideBar: FC<IPatientSideBar> = ({
  adding,
  setAdding,
  consent,
  setConsent,
  profile,
  defaultImage,
  currentUser,
  userID,
  handleBack,
  updateProfile,
  initiateEncounter,
  loadEntries,
  fromActiveEncounter,
  setConsentModal,
  isNursingHomeSite,
  consentDate,
  image,
  setEditConsentModal,
  isPCCPatient,
  permissions,
  viewHealthGorillaChart,
  isBtnPending,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [prescriptionError, setPrescriptionError] = useState("");
  const [testID, setTestID] = useState("");
  const [testType, setTestType] = useState([]);

  const patientSex = profile.patient.sex === "F" ? "Female" : "Male";

  const name = profile.patient ? profile.patient.name : "";

  return (
    <div className="d-flex justify-content-center">
      <>
        {adding && (
          <AddEntry
            userID={userID}
            userName={name}
            onClose={() => setAdding(false)}
            currentUser={currentUser}
            loadEntries={loadEntries}
          />
        )}
        <UploadFileModal
          visible={showModal}
          userID={userID}
          userName={name}
          testID={testID}
          testType={testType}
          onClose={() => setShowModal(false)}
          loadEntries={loadEntries}
        />
      </>
      <div className={styles.profileContainer}>
        {!fromActiveEncounter && (
          <div className={styles.backButtonWrapper}>
            <Button onClick={handleBack} className={styles.backButton}>
              <RightVector /> Back
            </Button>
          </div>
        )}
        {isEditing ? (
          <PatientSideBarProfileEdit
            profile={profile}
            userID={userID}
            onSave={() => setIsEditing(false)}
            updateProfile={updateProfile}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <PatientSideBarProfileInfo
            viewHealthGorillaChart={viewHealthGorillaChart}
            isBtnPending={isBtnPending}
            isPCCPatient={isPCCPatient}
            permissions={permissions}
            consent={consent}
            setConsent={setConsent}
            profile={profile}
            image={image}
            defaultImage={defaultImage}
            userID={userID}
            onUpload={() => setShowModal(true)}
            onAddNote={() => setAdding(true)}
            setPrescriptionError={setPrescriptionError}
            onEdit={() => setIsEditing(true)}
            currentUser={currentUser}
            initiateEncounter={initiateEncounter}
            isNursingHomeSite={isNursingHomeSite}
            consentDate={consentDate}
            setEditConsentModal={setEditConsentModal}
          />
        )}
      </div>
    </div>
  );
};

export default PatientSideBar;
