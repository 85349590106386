import React from "react";
import { UncontrolledTooltip } from "reactstrap";

import Synced from "../images/Synced.svg";

const PCCBadge = () => {
  return (
    <div className="pcc-badge">
      <div id="pcc-pill" className={`pill`}>
        <span>Synced from</span>
        <Synced />
      </div>
      <UncontrolledTooltip
        placement="auto"
        target="pcc-pill"
        className="pcc-tooltip"
        innerClassName="pcc-tooltip-popup"
      >
        <h3>PointClickCare Integration</h3>
        This data has been synced from this patient’s chart in PointClickCare. If you need to edit
        this data please contact a DON or Nurse from this patient’s facility.
      </UncontrolledTooltip>
    </div>
  );
};

export default PCCBadge;
