import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Table, Button } from "reactstrap";

import { NOT_ENGAGED } from "../../constants/Encounter";
import ExtenderTag from "../../images/Extender.svg";
import ChatIcon from "../../images/ChatDots.svg";
import VideoCamera from "../../images/VideoCamera.svg";
import api from "../../api";
import {
  hasAnyPermission,
  PERMISSION_MANAGE,
  PERMISSION_MEDICAL_DIRECTOR,
} from "../../constants/Permissions";
import PostponeModal from "./PostponeModal";
import { toast } from "react-toastify";

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.common.permissions,
    currentUser: state.common.currentUser,
    engagementStatus: state.encounter.engagementStatus,
    currentTime: state.time.currentDate,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const TakingTest = ({ patient }) => {
  let timeRemaining = "Any second now";

  const inFuture = patient.completionTime && patient.completionTime > moment().format();

  if (patient.completionTime === "0001-01-01T00:00:00Z") {
    timeRemaining = "User is in intake";
  } else if (
    patient.completionTime &&
    patient.completionTime !== "0001-01-01T00:00:00Z" &&
    inFuture
  ) {
    timeRemaining = moment(patient.completionTime).fromNow(true);
  }
  return (
    <div>
      <div className="time-remaining">{timeRemaining}</div>
      {inFuture && <div className="test-status">until available</div>}
    </div>
  );
};

const TimeWaiting = ({ patient }) => {
  return (
    <div>
      <div className="time-waiting">{moment(patient.readyAt).fromNow(true)}</div>
      <div className="waiting-status">in waiting room</div>
    </div>
  );
};

const Language = ({ languages }) => {
  let ls = languages || ["English"];
  if (ls.length > 1) {
    ls = languages.map((i, idx) => {
      if (idx == 0) {
        return `${i} preferred`;
      }
      return i;
    });
  }

  return <span>{ls.join(", ")}</span>;
};

const PatientItem = ({ patient, canAccept, onAccept, practiceList, onShowModal }) => {
  const available = patient.status == "available" || patient.status == "aborted";
  // only allowed to accept top patient (patient who has waited longest), except in dev
  //  const active =
  //    available && (props.first || api.getEndpoint().includes("augustadev"));
  // This decision was changed, you can now accept any patient in the queue.
  const active = available;
  const onAcceptPatient = () => {
    onAccept(patient);
  };
  const practices =
    patient.visitType === "nursing_home" ? practiceList.nursingHomes : practiceList.other;

  const practicesByState =
    !!practices && practices[patient.location] ? practices[patient.location] : [];

  const isMoreThanHour = () => {
    const minutesOut = patient.readyAt
      ? moment.duration(moment(Date.now()).diff(patient.readyAt)).asMinutes()
      : 0;
    return minutesOut >= 60;
  };

  const memoizedPracticeValue = useMemo(() => {
    const p =
      practicesByState.length > 0 && practicesByState.find((item) => item.ID === patient.practice);
    if (!p && practices) {
      return Object.values(practices)
        .flat(2)
        .find((item) => item.ID === patient.practice);
    } else {
      return p;
    }
  }, [practicesByState.length]);

  return (
    <tr className={active ? patient.status + " active" : patient.status}>
      <td>
        <div className="d-flex flex-column test-description">
          <div className="test-name">{patient.chiefComplaint ? patient.chiefComplaint : ""}</div>
          {patient.followUp && <div className="follow-up">Follow Up</div>}
        </div>
      </td>
      <td>
        <div className="practice-name">{memoizedPracticeValue && memoizedPracticeValue.name}</div>
      </td>
      <td>{patient.age} yo</td>
      <td>
        <Language languages={patient.languages} />
      </td>
      <td>
        <div className="patient-location">{patient.location}</div>
      </td>

      <td>{patient.isClinicalServices && <ExtenderTag />}</td>
      {!!patient.communicationWithChat ? (
        <td>
          <ChatIcon /> Chat
        </td>
      ) : (
        <td>
          <VideoCamera /> Video
        </td>
      )}

      <td className="text-right">
        {!available && <TakingTest patient={patient} />}
        {available && <TimeWaiting patient={patient} />}
      </td>
      <td className="text-center">
        {active && (
          <Button
            className="accept-button"
            disabled={!canAccept}
            onClick={onAcceptPatient}
            color="primary"
          >
            Accept
          </Button>
        )}
        {active && isMoreThanHour() && (
          <Button
            color="secondary"
            onClick={() => onShowModal(patient.referenceID)}
            className="postpone-button"
          >
            Postpone
          </Button>
        )}
        {!available && (
          <span className="ml-2">
            <i> Currently completing intake... </i>
          </span>
        )}
      </td>
    </tr>
  );
};

const SortPatients = (patients) => {
  patients.sort((a, b) => {
    let aTime, bTime;
    if (a.status !== b.status) {
      if (a.status === "active") {
        return -1;
      }
      if (b.status === "active") {
        return -1;
      }
    }

    if (
      a.completionTime !== "0001-01-01T00:00:00Z" &&
      b.completionTime !== "0001-01-01T00:00:00Z"
    ) {
      if (a.status === "pending" || a.status === "initialized") {
        aTime = moment(a.completionTime);
        bTime = moment(b.completionTime);
      }
      if (a.status === "available") {
        aTime = moment(a.readyAt);
        bTime = moment(b.readyAt);
      }
      if (aTime && aTime.isBefore(bTime)) {
        return -1;
      }
      if (bTime && bTime.isBefore(aTime)) {
        return 1;
      }
    }
    return 0;
  });
  return patients;
};

function PatientList({
  permissions,
  items,
  engagementStatus,
  onAcceptPatient,
  currentTime,
  currentUser,
}) {
  const [patients, setPatients] = useState([]);
  const [practices, setPractices] = useState({});
  const [modalShown, setPostponeModal] = useState(false);
  const [encounterKey, setEncounterKey] = useState({});

  const onShowModal = (referenceID) => {
    const splitedReferenceID = referenceID.split("|");
    const EncounterKey = {
      ID: splitedReferenceID[0],
      Kind: splitedReferenceID[1],
    };
    setEncounterKey(EncounterKey);
    setPostponeModal(true);
  };

  const onCancelModal = () => {
    setEncounterKey({});
    setPostponeModal(false);
  };

  const onDeleteEncounter = async () => {
    try {
      await api.Encounters.deleteEncounter(encounterKey);
      onCancelModal();
    } catch (e) {
      setEncounterKey({});
      if (currentUser?.isBetaTester) {
        toast.error(e.response.body?.message || `Error ${e.response.statusCode}`);
      }
      onCancelModal();
    }
  };

  useEffect(() => {
    const pats = SortPatients(items);
    setPatients(pats);

    const getPractices = () => {
      api.MedicalPractices.list().then((res) => {
        setPractices(res);
      });
    };
    const canGetPractices = hasAnyPermission(
      permissions,
      PERMISSION_MEDICAL_DIRECTOR,
      PERMISSION_MANAGE,
    );
    if (canGetPractices) getPractices();
  }, [items]);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <>
      <PostponeModal visible={modalShown} onCancel={onCancelModal} onDelete={onDeleteEncounter} />
      <Table className="patient-list">
        <thead>
          <tr>
            <td width="12%"></td>
            <td width="17%"></td>
            <td width="5%" className=" align-text-center"></td>
            <td width="5%" className=" align-text-center"></td>
            <td width="5%" className=" align-text-center"></td>
            <td width="10%"></td>
            <td width="5%"></td>
            <td width="8%"></td>
            <td width="15%"></td>
          </tr>
        </thead>
        <tbody>
          {patients.map((patient, idx) => (
            <PatientItem
              onShowModal={onShowModal}
              canAccept={engagementStatus === NOT_ENGAGED}
              onAccept={onAcceptPatient}
              key={idx}
              time={currentTime}
              first={idx == 0}
              patient={patient}
              practiceList={practices}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
