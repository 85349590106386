import "regenerator-runtime/runtime";
import { applyMiddleware, createStore, compose } from "redux";
import { createLogger } from "redux-logger";
import createHistory from "history/createBrowserHistory";
import { connectRouter, routerMiddleware } from "connected-react-router";

import { promiseMiddleware, localStorageMiddleware, refreshUserMiddleware } from "./middleware";
import reducer from "./reducer";

export const history = createHistory();

// Build the middleware for intercepting and dispatching navigation actions
// const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(
      routerMiddleware(history),
      promiseMiddleware,
      localStorageMiddleware,
      refreshUserMiddleware,
    );
  } else {
    // Enable additional logging in non-production environments.
    const composeEnhancers =
      (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) || compose;
    return composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        promiseMiddleware,
        localStorageMiddleware,
        refreshUserMiddleware,
        createLogger(),
      ),
    );
  }
};

export const store = createStore(
  connectRouter(history)(reducer),
  {
    // new root reducer with router state
  },
  getMiddleware(),
);
