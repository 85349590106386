import React, { useState, useEffect, FC } from "react";
import { Creatable } from "react-select";
import XGray from "../../images/XGray.svg";
import { Button } from "reactstrap";
import PlusBlue from "../../images/PlusBlue.svg";
import api from "../../api";
import { maternalOrPaternal } from "../../constants/MedicalProfile";
import { IFamilyMedicalHistory, IMedicalProfile } from "../../constants/types";
import { CommaSeparatedList } from "./MedicalHistory";

const EmptyComponent = () => {
  return null;
};

const cleanText = (value: string) => {
  let str = value;
  if (!str) {
    return value;
  }
  str = str.toLowerCase();
  str = str.replace(/\s/g, "");
  return str;
};

const getOptions = (items: string[] = [], selectedConditions?: IFamilyMedicalHistory[]) => {
  if (selectedConditions && selectedConditions.length > 0) {
    return (
      !!items &&
      items
        .filter(
          (item) =>
            !selectedConditions.find((c) => cleanText(c.familyHealthCondition) === cleanText(item)),
        )
        .map((item) => getOption(item))
    );
  }

  return !!items && items.map((item) => ({ value: item, label: item }));
};

const getOption = (item: string) => ({ value: item, label: item });

type ConditionEditorProps = {
  onConditionChange: Function;
  onDetailChange: Function;
  onInputChange: Function;
  conditions: string[];
  mp: string[];
  selectedCondition?: IFamilyMedicalHistory;
  relationShips: string[];
  selectedConditions: IFamilyMedicalHistory[];
  onRemove: Function;
  isPatientChart?: boolean;
};
const ConditionEditor: FC<ConditionEditorProps> = ({
  onConditionChange,
  onDetailChange,
  onInputChange,
  conditions,
  selectedCondition = {},
  relationShips,
  selectedConditions,
  mp,
  onRemove,
  isPatientChart = false,
}) => {
  return (
    <>
      <div className="d-flex">
        <div className={`add-condition-select ${isPatientChart && "in-patient-chart"}`}>
          <Creatable
            isClearable={false}
            hideSelectedOptions={true}
            className="info-select"
            classNamePrefix="info-select"
            placeholder={"Enter Condition"}
            onChange={onConditionChange(selectedCondition || "", "familyHealthCondition")}
            components={EmptyComponent}
            options={getOptions(conditions, selectedConditions)}
            value={getOption(selectedCondition?.familyHealthCondition || "Enter Condition")}
          />
        </div>
        <XGray
          className="close-select-button align-self-center"
          onClick={onRemove(selectedCondition)}
        />
      </div>

      <div className={`add-condition-select details ${isPatientChart && "in-patient-chart"}`}>
        <Creatable
          isClearable={false}
          hideSelectedOptions={true}
          className="info-select"
          classNamePrefix="info-select"
          placeholder={"Relationship to Patient"}
          onChange={onDetailChange(selectedCondition, "relationshipToPatient")}
          components={EmptyComponent}
          options={getOptions(relationShips)}
          value={getOption(selectedCondition?.relationshipToPatient || "Relationship to Patient")}
        />
      </div>
      <div className={`add-condition-select details ${isPatientChart && "in-patient-chart"}`}>
        <Creatable
          isClearable={false}
          hideSelectedOptions={true}
          className="info-select"
          classNamePrefix="info-select"
          placeholder={"Maternal/Paternal?"}
          onChange={onDetailChange(selectedCondition, "maternalOrPaternal")}
          components={EmptyComponent}
          options={getOptions(mp)}
          value={getOption(selectedCondition?.maternalOrPaternal || "Maternal/Paternal?")}
        />
      </div>

      <div className={`add-condition-select details ${isPatientChart && "in-patient-chart"}`}>
        <div className="text-details-input">
          <input
            onChange={onInputChange(selectedCondition, "ageAtDX")}
            value={selectedCondition?.ageAtDX || ""}
          ></input>
          {` Age at Dx`}
        </div>
      </div>
    </>
  );
};

type Props = {
  onProfileChanged: Function;
  profile: Partial<IMedicalProfile>;
  isPatientChart?: boolean;
  readOnly?: boolean;
};
export default function FamilyHealthHistory(props: Props) {
  const [addNewSelect, setAddNewSelect] = useState<boolean>(false);
  const [conditions, setConditions] = useState<string[]>([]);
  const [relationShips, setRelationShips] = useState<string[]>([]);

  const selectedConditions: IFamilyMedicalHistory[] = props.profile.FamilyMedicalHistory || [];

  useEffect(() => {
    async function loadcatalog() {
      try {
        const rShip = await api.Catalog.relationshipToPatient();
        setRelationShips(rShip.items || []);
      } catch (e) {}

      try {
        const fConditions = await api.Catalog.familyHealthConditions();
        setConditions(fConditions.items || []);
      } catch (e) {}
    }
    loadcatalog();
  }, []);

  const onConditionChange =
    (oldItem: IFamilyMedicalHistory, section: string) =>
    (item: { label: string; value: string }) => {
      let changes = JSON.parse(JSON.stringify(selectedConditions)) as IFamilyMedicalHistory[];

      let idx = changes.findIndex(
        (c: IFamilyMedicalHistory) =>
          !!oldItem.familyHealthCondition &&
          cleanText(c.familyHealthCondition) === cleanText(oldItem.familyHealthCondition),
      );
      if (idx < 0) {
        changes.push({ ...oldItem, [section]: item.value });
      } else {
        changes[idx][section] = item.value;
      }

      props.onProfileChanged(changes);

      if (addNewSelect) setAddNewSelect(!addNewSelect);
    };

  const onDetailChange =
    (oldItem: IFamilyMedicalHistory, section: string) =>
    (item: { label: string; value: string }) => {
      let changes = JSON.parse(JSON.stringify(selectedConditions)) as IFamilyMedicalHistory[];

      let idx = changes.findIndex(
        (c: IFamilyMedicalHistory) =>
          !!oldItem.familyHealthCondition &&
          cleanText(c.familyHealthCondition) === cleanText(oldItem.familyHealthCondition),
      );
      if (idx > -1) {
        changes[idx][section] = item.value;
      }

      props.onProfileChanged(changes);
    };

  const onInputChange = (oldItem: IFamilyMedicalHistory, section: string) => (e: any) => {
    let changes = JSON.parse(JSON.stringify(selectedConditions)) as IFamilyMedicalHistory[];

    let idx = changes.findIndex(
      (c: IFamilyMedicalHistory) =>
        !!oldItem.familyHealthCondition &&
        cleanText(c.familyHealthCondition) === cleanText(oldItem.familyHealthCondition),
    );
    if (idx > -1) {
      changes[idx][section] = parseInt(e.target.value, 10);
    }

    props.onProfileChanged(changes);
  };

  const onRemove = (oldItem: IFamilyMedicalHistory) => (e: any) => {
    let changes = JSON.parse(JSON.stringify(selectedConditions)) as IFamilyMedicalHistory[];

    let idx = changes.findIndex(
      (c: IFamilyMedicalHistory) =>
        !!oldItem.familyHealthCondition &&
        cleanText(c.familyHealthCondition) === cleanText(oldItem.familyHealthCondition),
    );
    if (idx > -1) {
      changes.splice(idx, 1);
    }

    props.onProfileChanged(changes);
    if (addNewSelect) setAddNewSelect(!addNewSelect);
  };

  const toggleAddNew = () => {
    setAddNewSelect(!addNewSelect);
  };

  if (!props.readOnly) {
    return (
      <div>
        {selectedConditions.map((condition, idx) => (
          <ConditionEditor
            isPatientChart={props.isPatientChart}
            onConditionChange={onConditionChange}
            onDetailChange={onDetailChange}
            onInputChange={onInputChange}
            onRemove={onRemove}
            conditions={conditions}
            relationShips={relationShips}
            mp={maternalOrPaternal}
            selectedCondition={condition}
            selectedConditions={selectedConditions}
          />
        ))}
        {addNewSelect && (
          <ConditionEditor
            isPatientChart={props.isPatientChart}
            onConditionChange={onConditionChange}
            onDetailChange={onDetailChange}
            onInputChange={onInputChange}
            onRemove={onRemove}
            conditions={conditions}
            relationShips={relationShips}
            selectedConditions={selectedConditions}
            mp={maternalOrPaternal}
          />
        )}
        <div
          className="add-new-condition-container d-flex"
          onClick={addNewSelect ? undefined : toggleAddNew}
          style={{ cursor: addNewSelect ? "auto" : "pointer" }}
        >
          <div>
            <Button className="plus-condition-button fh-add" disabled={addNewSelect}>
              <PlusBlue />
            </Button>
          </div>
          <div className="add-condition-label align-self-center">
            Add new family health condition
          </div>
        </div>
      </div>
    );
  }

  return (
    <CommaSeparatedList
      items={selectedConditions.map((item) => [
        item?.familyHealthCondition || "-",
        item?.relationshipToPatient || "-",
        item?.maternalOrPaternal || "-",
      ])}
    />
  );
}
