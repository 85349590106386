import React, { Component } from "react";

import ContentBlock from "./ContentBlock";
import styles from "./Attachments.scss";
import FullScreenImage from "../FullScreenImage";
import RotateIcon from "../../images/Rotate.svg";

type AttachmentProps = {
  attachment: any;
  onViewImage: any;
  rotateImg: any;
  visitType: string;
};

const Attachment = React.forwardRef<HTMLImageElement, AttachmentProps>(
  ({ attachment, onViewImage, rotateImg, visitType }, ref) => {
    const onClick = () => {
      onViewImage(attachment.imageURL);
    };

    return (
      <div className={styles.imgContainer}>
        <img src={attachment.thumbnailURL} onClick={onClick} ref={ref} />
        {visitType != "rxrenewal" ? (
          <RotateIcon className={styles.iconRotate} onClick={rotateImg} />
        ) : (
          ""
        )}
      </div>
    );
  },
);

type Props = {
  visitType: string;
  attachments: {
    imageURL?: string | undefined;
    thumbnailURL?: string | undefined;
  }[];
};

class Attachments extends Component<Props, { angle: number; viewedUrl?: string | null }> {
  private attachmentImg: React.RefObject<HTMLImageElement>;
  constructor(props: Props) {
    super(props);
    this.state = { angle: 0 };
    this.attachmentImg = React.createRef();
  }

  componentDidMount() {}
  onViewImage = (url) => {
    this.setState({ viewedUrl: url });
  };

  rotateImg = () => {
    // The callback is necessary to ensure only the most recent angle value is used.
    this.setState({ angle: this.state.angle + 90 }, () => {
      this.attachmentImg.current!.style.transform = `rotate(${this.state.angle}deg)`;
    });
  };

  onCloseImage = () => {
    this.setState({ viewedUrl: null });
  };

  render() {
    return (
      <ContentBlock title={this.props.visitType != "rxrenewal" ? "Picture from patient" : ""}>
        {this.state.viewedUrl && (
          <FullScreenImage
            angle={this.state.angle}
            url={this.state.viewedUrl}
            onClose={this.onCloseImage}
          />
        )}
        <div className={styles.attachmentList}>
          {this.props.attachments.map((a, idx) => (
            <Attachment
              key={idx}
              attachment={a}
              rotateImg={this.rotateImg}
              ref={this.attachmentImg}
              onViewImage={this.onViewImage}
              visitType={this.props.visitType}
            />
          ))}
        </div>
      </ContentBlock>
    );
  }
}

export default Attachments;
