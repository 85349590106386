import React, { useEffect, useState } from "react";
import pick from "lodash.pick";
import PropTypes from "prop-types";

import InfoEditWrapper from "./InfoEditWrapper";
import SkinTestPanel from "./SkinTestPanel";
import Spinner from "../../images/Spinner.svg";
import api from "../../api";
import bugsnagClient from "../../services/bugsnag";

const SkinAllergyTest = ({
  medicalIntake,
  onIntakeChanged,
  onIntakeSave,
  details,
  onRemoveUnsavedSection,
  unsavedSections,
  ...props
}) => {
  const [catalog, setCatalog] = useState(undefined);

  useEffect(() => {
    loadCatalog();
  }, []);

  const loadCatalog = async () => {
    try {
      let result = await api.Catalog.allergySkinTests();
      const catalog = result.find((test) => test.key === medicalIntake.allergySkinTest.key);
      setCatalog(catalog);
    } catch (err) {
      setCatalog(null);
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      bugsnagClient.notify(err);
    }
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'test' implicitly has an 'any' type.
  const onChange = (section) => (newPanel) => {
    const temp = medicalIntake.allergySkinTest.panels.map((panel) => {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      if (panel.key === newPanel.key) {
        return newPanel;
      } else {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'section' implicitly has an 'any' type.
        return panel;
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newPanel' implicitly has an 'any' type.
      }
    });
    onIntakeChanged(section)({
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'panel' implicitly has an 'any' type.
      allergySkinTest: { key: medicalIntake.allergySkinTest.key, panels: temp },
    });
  };
  const onSaveIntakeHistory = (fields, section) => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onRemoveUnsavedSection(section);
    onIntakeSave(fields);
  };
  const onCancelIntakeHistory = (section) => (oldData) => {
    onRemoveUnsavedSection(section);
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
    onIntakeChanged()(oldData.intakeHistory);
  };

  // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
  const intakeHistory = pick(medicalIntake, ["allergySkinTest"]);

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'section' implicitly has an 'any' type.
  return (
    <div>
      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'oldData' implicitly has an 'any' type. */}
      {catalog ? (
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        <>
          {/* @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
          {medicalIntake.allergySkinTest.panels.map((panel, index) => {
            const subPanel = catalog && catalog.subPanels.find((sp) => sp.key === panel.key);
            return (
              <InfoEditWrapper
                // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'panel' implicitly has an 'any' type.
                key={index}
                // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
                data={{ intakeHistory }}
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                onCancel={onCancelIntakeHistory(subPanel.title)}
                onSave={() => onSaveIntakeHistory(["allergySkinTest"], subPanel.title)}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; key: any; data: { intak... Remove this comment to see the full error message
                title={subPanel.title}
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                unsavedSections={unsavedSections}
              >
                <SkinTestPanel
                  panel={panel}
                  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  allergens={subPanel.allergens}
                  images={details.skinTestImages}
                  onChange={onChange(subPanel.title)}
                />
              </InfoEditWrapper>
            );
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          })}
        </>
      ) : (
        <div className="d-flex justify-content-center">
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          {catalog === null ? <h4>User opted to skip test</h4> : <Spinner />}
        </div>
      )}
    </div>
  );
};

SkinAllergyTest.propTypes = {
  medicalIntake: PropTypes.object,
  onIntakeChanged: PropTypes.func,
  onIntakeSave: PropTypes.func,
  details: PropTypes.object,
  onRemoveUnsavedSection: PropTypes.func,
  unsavedSections: PropTypes.object,
};

export default SkinAllergyTest;
