import React, { useEffect, useState } from "react";
import moment from "moment";
import { Input, Button } from "reactstrap";
import PropTypes from "prop-types";

import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

import COVID19Followup from "./COVID19Followup";
import ContentBlock from "./ContentBlock";
import { visitNameLookup } from "../../constants/Encounter";
import DatePicker from "./DateRangePicker";
import CheckToggle from "../CheckToggle";
import Caret from "../../images/Caret01.svg";
import { formatPhoneNumber } from "../../utils";
import {
  generateSickNote,
  selectItemToString,
  PrescriptionDetails,
  DiagnosisCode,
  InputError,
  ContentBlockBilling,
} from "./Diagnosis";
import { Moment } from "moment";

const DigitalReview = (props) => {
  const { note, missingDiagnosisFields, onDateRangePick, prescriptionError } = props;

  const { diagnosis, isProviderConfirmed } = note;

  const providerName = props.currentUser
    ? `${props.currentUser.firstName} ${props.currentUser.lastName}`
    : "";
  const patientName = props.patient.name;
  const assessment = note.assessment || "";

  let treatmentPlan = note.treatmentPlan || "";

  const errorMessage = "*This field is required";
  const noneSelectedError = "*No followup items selected";

  const pharmacyID = props?.profile?.preferredPharmacy?.pharmacyID;
  const pharmacyName = props?.profile?.preferredPharmacy?.storeName;
  const pharmacyAddress = props?.profile?.preferredPharmacy?.address1;
  const pharmacyIndex = props?.profile?.preferredPharmacy?.zip;
  const pharmacyState = props?.profile?.preferredPharmacy?.state;
  const pharmacyCity = props?.profile?.preferredPharmacy?.city;
  const pharmacyPhone = formatPhoneNumber(props?.profile?.preferredPharmacy?.primaryPhone);

  useEffect(() => {
    const sickNote = generateSickNote(patientName, providerName);
    props.onUpdate("sickNote", sickNote);
  }, []);

  const handleDiagnosisChange = (selectedOptions) => {
    props.onUpdate("diagnosis", selectedOptions.map(selectItemToString));
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    props.onUpdate(event.target.name, event.target.value);
  };
  const dayBlocker = (m: Moment) => {
    if (startDate) {
      return m.isAfter(moment(startDate || "").add(7, "days"));
    }
  };
  const isOutsideRange = (day) => {
    return false;
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);

    if (endDate !== null && startDate !== null) {
      let startD = moment(startDate).format("YYYY-MM-DD");
      let endD = moment(endDate).format("YYYY-MM-DD");
      onDateRangePick(startD, endD);
    }
  };

  const onProviderConfirmed = () => {
    props.onUpdate("isProviderConfirmed", !isProviderConfirmed);
  };

  return (
    <div className="patient-diagnosis">
      <ContentBlock title="Resident's daily VS, clinical data, COVID-19 screeners, Labs and medical records reviewed:">
        <DatePicker
          isOutsideRange={isOutsideRange}
          dayBlocker={dayBlocker}
          startDate={startDate}
          endDate={endDate}
          handleDatesChange={handleDatesChange}
        />
      </ContentBlock>
      <ContentBlock title="Clinical Status">
        <Input
          placeholder="Type any additional info…"
          onChange={handleChange}
          value={assessment}
          type="textarea"
          className={assessment === "" ? "required" : ""}
          name="assessment"
          id="assessment"
        />
      </ContentBlock>
      <ContentBlock title="Diagnosis">
        <DiagnosisCode
          placeholder="Start typing diagnosis or ICD 10 code..."
          diagnosis={diagnosis}
          handleSelectChange={handleDiagnosisChange}
          visitType={props.visitType}
          allergySystems={props.intake.allergySystems}
        />
      </ContentBlock>
      {visitNameLookup[props.visitType] === "Coronavirus" && (
        <ContentBlock
          title="Coronavirus (COVID-19) Consult Visit Treatment Plan"
          render={() => (
            <InputError
              message={note.coronavirusFollowup !== undefined ? noneSelectedError : errorMessage}
              flag={missingDiagnosisFields["coronavirusFollowup"]}
            />
          )}
        >
          <COVID19Followup
            note={note}
            onUpdate={props.onUpdate}
            error={missingDiagnosisFields["coronavirusFollowup"]}
          />
        </ContentBlock>
      )}
      <ContentBlock title="Prescription">
        <p style={{ fontSize: 12 }}>
          <span>Patient-Selected Pharmacy - </span>
          {!!pharmacyID
            ? `${pharmacyName} - ${pharmacyID} ${pharmacyAddress}, ${pharmacyCity}, ${pharmacyState}, ${pharmacyIndex} - ${pharmacyPhone}`
            : "None"}
        </p>
        <Button
          className="prescribe-button"
          id="qa-encounter-writerx-btn"
          onClick={props.onPrescriptionLink}
          color="primary"
          outline
        >
          Write Prescription
        </Button>
        {prescriptionError && (
          <p className="prescriptionError">DoseSpot Error: {prescriptionError}</p>
        )}
        <PrescriptionDetails details={note.prescriptionDetails} pharmacies={note.pharmacyDetails} />
      </ContentBlock>
      <ContentBlock title="Plan">
        <Input
          placeholder="Type any additional info…"
          onChange={handleChange}
          value={treatmentPlan}
          type="textarea"
          name="treatmentPlan"
        />
        <div>
          <div
            className={`sub-section ${
              !!missingDiagnosisFields["isProviderConfirmed"]
                ? "required-error"
                : treatmentPlan.length > 0
                ? "required"
                : ""
            } ${treatmentPlan.length === 0 ? "disabled" : ""}`}
          >
            <CheckToggle
              checked={isProviderConfirmed}
              disabled={treatmentPlan.length === 0}
              onChange={onProviderConfirmed}
            >
              <span className="sub-section-text">
                I acknowledge no new orders have been given for this visit.
              </span>
            </CheckToggle>
            <InputError
              message={errorMessage}
              flag={!!missingDiagnosisFields["isProviderConfirmed"]}
            />
          </div>
        </div>
      </ContentBlock>
      <ContentBlockBilling
        visitType={props.visitType}
        missingDiagnosisFields={missingDiagnosisFields}
        note={note}
        effectiveDate={props.effectiveDate}
        onUpdate={props.onUpdate}
        onEffectiveDateChange={props.onEffectiveDateChange}
      />
      <div ref={props.myref} />
    </div>
  );
};

export default DigitalReview;
