import React from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import PropTypes from "prop-types";

const RxDiscontinue = ({ features, onChange, note, onNoteUpdate }) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'features' does not exist on type 'Props'... Remove this comment to see the full error message
  const rxDiscontinue = findFeature(features, "rxDiscontinue", "RxDiscontinue", "RxDiscontinue");

  const setAttribute = featureSetAttributes({ onChange });

  return (
    <EntryBlock title="Did taking this medication mean you stopped taking an older medication?">
      <div>
        <FeatureEntry
          {...{
            feature: rxDiscontinue,
            values: ["Yes", "No"],
            onChange: setAttribute,
            omitTitle: true,
            id:"qa-rxDiscontinue-entry-rxDiscontinue"
          }}
        />
      </div>
      <div>
        <GroupNoteEntry {...{ groupName: "RxDiscontinue", onChange: onNoteUpdate, note, id:"qa-rxDiscontinue-group-entry" }} />
      </div>
    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ groupName: string; onChange: ((...args: an... Remove this comment to see the full error message */}
    </EntryBlock>
  );
};

RxDiscontinue.propTypes = {
  feature: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
};
export default RxDiscontinue;
