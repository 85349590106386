import React from "react";
import PropTypes from "prop-types";
import CircularProgressbar from "react-circular-progressbar";
import classNames from "classnames";
import Octicon, { Check } from "@githubprimer/octicons-react";

const AuditInfo = ({ percentage, threshold, size = "32px", children, className }) => {
 
  const chartPercentage = Math.floor(Math.min(percentage / threshold, 1) * 100);
  return (
    <div className={classNames(className, "audit-info")}>
      <div
        className="audit-progress-bar-container"
        style={{
          height: size,
          width: size,
          minWidth: size,
        }}
      >
        <CircularProgressbar
          percentage={chartPercentage}
          styles={{
            path: { stroke: chartPercentage === 100 ? "#ADC85E" : "#3678CC" },
            trail: { stroke: "#c7c7c7" },
          }}
        />
        {chartPercentage === 100 && <Octicon icon={Check} className="audit-checkmark" />}
      </div>
      <div className="audit-progress-text">{children}</div>
    </div>
  );
};

AuditInfo.propTypes = {
  percentage: PropTypes.number,
  threshold: PropTypes.number,
  size: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
};

export default AuditInfo;
