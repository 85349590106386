import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@git... Remove this comment to see the full error message
import Octicon, { ChevronRight } from "@githubprimer/octicons-react";
import classNames from "classnames";
import moment from "moment";
import Select from "react-select";
import { Table, Button, FormGroup, Label } from "reactstrap";

import NavBar from "../Provider/NavBar";
import PatientIcon from "../../images/ProfileIconWithSquare.svg";
import api from "../../api";
import bugsnagClient from "../../services/bugsnag";
import Spinner from "../../images/Spinner.svg";
import InsuranceProfile from "../Patients/InsuranceProfile";
import styles from "../Patients/chart.scss";
import ErrorList from "../ErrorList";

const validPayors = [
  "Humana",
  "Cigna",
  "United Health Care",
  "Blue Cross Blue Shield",
  "Aetna",
  "Oscar",
].map((p) => {
  return { label: p, value: p };
});

const VerifyModal = ({ userID, details, onClose, onVerify }) => {
  const [payor, setPayor] = useState();
  const [saveError, setSaveError] = useState();

  const verify = (verified) => {
    const recordVerify = async () => {
      try {
        let result = await api.UserRecords.verifyInsurance(userID, verified, payor.label);
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        setSaveError();
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        onVerify();
      } catch (err) {
        bugsnagClient.notify(err);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Error saving insurance verifica... Remove this comment to see the full error message
        setSaveError("Error saving insurance verification");
      }
    };
    recordVerify();
  };

  return (
    <div className="modal-overlay">
      <div className="ml-auto mr-auto insurance-verify-container d-flex flex-column ">
        <div className={classNames(styles.profileContainer)}>
          <div className="d-flex flex-row-reverse">
            <Button onClick={onClose} color="link">
              Close
            </Button>
          </div>

          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <ErrorList errors={[saveError]} />
          <div className={styles.profileBlock}>
            <h2>Patient Name</h2>
            <p>{details.patient.name}</p>
          </div>
          <div className={styles.profileBlock}>
            <h2>Patient DOB</h2>
            <p>{details.patient.dob}</p>
          </div>
          <div className={styles.profileBlock}>
            <h2>Patient Sex</h2>
            <p>{details.patient.sex}</p>
          </div>
          <InsuranceProfile
            insuranceProfile={details.medicalProfile.insuranceProfile}
            card={details.insuranceCardURLs}
          />
          <FormGroup className="mt-3">
            <Label> Payor </Label>
            <Select
              value={payor}
              options={validPayors}
              onChange={(s) => setPayor(s)}
              backspaceRemovesValue={false}
            />
          </FormGroup>
          <Button color="primary" onClick={() => verify(true)} disabled={!payor}>
            Verify
          </Button>
          <Button color="link" onClick={() => verify(false)}>
            {" "}
            Decline{" "}
          </Button>
        </div>
      </div>
    </div>
  );
};

const InsuranceEntry = ({ item, onVerified }) => {
  const [details, setDetails] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const verified = () => {
    setShowDetails(false);
    onVerified(item.userID);
  };

  const loadDetails = () => {
    const fetchData = async () => {
      try {
        let result = await api.UserRecords.profile(item.userID);
        setDetails(result);
        setShowDetails(true);
      } catch (err) {
        bugsnagClient.notify(err);
      }
    };
    fetchData();
  };
  return (
    <tr>
      <td>{moment(item.createdAt).format("MM/DD/YY h:mma")}</td>
      <td>
        {item.name}
        {showDetails && (
          <VerifyModal
            userID={item.userID}
            details={details}
            onClose={() => setShowDetails(false)}
            onVerify={verified}
          />
        )}
      </td>
      <td>
        <Button color="primary" onClick={loadDetails}>
          Verify&nbsp;
          <Octicon size="small" icon={ChevronRight} className="chevron" />
        </Button>
      </td>
    </tr>
  );
};

const InsuranceList = ({ currentUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pendingList, setPendingList] = useState([]);

  useEffect(() => {
    if (currentUser) {
      setIsLoading(true);
      api.UserRecords.insuranceVerifications()
        .then((result) => {
          setPendingList(result.items);
        })
        .finally(() => setIsLoading(false));
    }
  }, [currentUser]);

  const verified = (userID) => {
    const newList = pendingList.filter((i) => !i.userID == userID);
    setPendingList(newList);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'userID' does not exist on type 'never'.
  };
  return (
    <div>
      <NavBar />
      <div className="dashboard-container">
        <h4>
          <div className="header-icon float-left">
            <PatientIcon />
          </div>
          Pending Insurance Verifications
        </h4>

        <div className="dashboard-component align-conent-stretch">
          {isLoading ? (
            <div className="component-load-spinner">
              <Spinner />
            </div>
          ) : (
            <div>
              {pendingList.length == 0 && <p>There are no pending insurance verifications</p>}

              <Table className="insurance-verify-list">
                <thead>
                  <tr>
                    <th width="10%" />
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ width: string; }' is not assignable to typ... Remove this comment to see the full error message */}
                    <th width="80%"></th>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ width: string; }' is not assignable to typ... Remove this comment to see the full error message */}
                    <th width="10%"></th>
                  </tr>
                  {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ width: string; }' is not assignable to typ... Remove this comment to see the full error message */}
                </thead>
                <tbody>
                  {pendingList.map((item) => {
                    return <InsuranceEntry item={item} key={item.userID} onVerified={verified} />;
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'userID' does not exist on type 'never'.
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => ({
  currentUser: state.common.currentUser,
}))(InsuranceList);
