import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import api from "../../api";
import InsuranceProfile from "./InsuranceProfile";
import styles from "./chart.scss";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'userID' implicitly has an 'any' t... Remove this comment to see the full error message
const InsuranceHistory = ({ userID }) => {
  const [insuranceHistory, setInsuranceHistory] = useState({});

  useEffect(() => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
    api.UserRecords.insuranceHistory(userID).then((data) => {
      setInsuranceHistory(data);
    });
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-component centered w-50">
        <div className={styles.profileContainer}>
          {insuranceHistory.profiles &&
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'profiles' does not exist on type '{}'.
            insuranceHistory.profiles.map((profile, index) => {
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
              return (
                <>
                  {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'cards' does not exist on type '{}'. */}
                  {insuranceHistory.cards[index] && (
                    <div className={classNames(styles.insurnaceEntry, "mb-4 pb-4")}>
                      <InsuranceProfile
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'cards' does not exist on type '{}'.
                        insuranceProfile={profile}
                        card={insuranceHistory.cards[index]}
                        key={index}
                      />
                      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type. */}
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userID: state.router.location.pathname.split("/").pop(),
});

export default connect(mapStateToProps)(InsuranceHistory);
