import React, { FC } from "react";
import PropTypes from "prop-types";
import BodyBackgroundColor from "react-body-backgroundcolor";

import Logo from "../images/Reliant-Logo_white.svg";

const CenteredForm: FC = (props) => {
  return (
    <BodyBackgroundColor backgroundColor="#4889DE">
      <div>
        <div className="text-center centered-form-logo">
          <Logo />
        </div>
        <div className="text-center centered-form-container">{props.children}</div>
      </div>
    </BodyBackgroundColor>
  );
};

CenteredForm.propTypes = {
  children: PropTypes.element,
};

export default CenteredForm;
