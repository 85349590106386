import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { store } from "../store";
import { REDIRECT } from "../constants/actionTypes";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    ...state.common,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  onRedirect: () => dispatch({ type: REDIRECT }),
});

class Redirect extends React.Component {
  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'redirectTo' does not exist on type 'Read... Remove this comment to see the full error message
    if (this.props.redirectTo) {
      store.dispatch(push(this.props.redirectTo));
    }
  }
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'redirectTo' does not exist on type 'Read... Remove this comment to see the full error message
  componentWillReceiveProps(nextProps) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'nextProps' implicitly has an 'any' type... Remove this comment to see the full error message
    if (nextProps.redirectTo) {
      this.props.onRedirect();
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onRedirect' does not exist on type 'Read... Remove this comment to see the full error message
      store.dispatch(push(nextProps.redirectTo));
    }
  }
  render() {
    return null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Redirect);
