import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import api from "../../api";
import TytocareReviewModal from "./TytocareReviewModal";
import { ENCOUNTER_LOAD_TYTOCARE_LINK } from "../../constants/actionTypes";
import "../../stylesheets/tytocareResults.scss";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  encounterID: state.encounter.encounterID,
  tytocareURL: state.encounter.tytocareURL,
});

const matchDispatchToProps = (dispatch) => ({
  loadTytocareURL: (payload) => dispatch({ type: ENCOUNTER_LOAD_TYTOCARE_LINK, payload }),
});

const TytocareResults = (props) => {
  const [showPopup, reviewPopup] = useState(false);
  const tytocareURL = props.tytocareURL || "";
  const onlyShowPopUp = props.onlyShowPopUp || false;
  const testResultProps = props.testResultProps || {};
  const hideHeader = props.patientchartView ? true : false;
  const vitals = props.vitals || {};

  const showReviewPopup = (flag) => {
    reviewPopup(flag);
    if (props.patientchartView && !flag) {
      props.onClose("");
    }
  };

  useEffect(() => {
    if (props.onlyShowPopUp) {
      showReviewPopup(true);
    }
    props.encounterKey
      ? props.loadTytocareURL(api.UserRecords.getTytoCareLink(props.encounterKey))
      : props.loadTytocareURL(api.Encounters.tytocareLink(props.encounterID));
  }, []);

  return (
    <div className="content-block tytocare-results">
      {showPopup && (
        <TytocareReviewModal
          showReviewPopup={showReviewPopup}
          tytocareURL={tytocareURL}
          testResultProps={testResultProps}
          onlyShowPopUp={onlyShowPopUp}
          hideHeader={hideHeader}
          vitals={vitals}
        />
      )}
      {!props.onlyShowPopUp && (
        <>
          <h1>Tytocare Remote Exam Results</h1>
          <Button id="qa-tytoresults-btn" className="view-btn" color="primary" onClick={() => showReviewPopup(true)}>
            View Results
          </Button>
        </>
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(TytocareResults);
