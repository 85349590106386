import React, { PureComponent } from "react";
import { Modal } from "reactstrap";
import styled from "styled-components";
import momentz from "moment-timezone";

import styles from "./modal.scss";
import Thumbnails from "./Thumbnails";
import Zoom from "./Zoom";
import ConfirmedDelete from "./ConfirmedDelete";

const Title = styled.h2`
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin: 10px 12px;
`;

const CloseButton = styled.span`
  position: absolute;
  right: 12px;
  top: 11px;

  font-family: "Open Sans";
  font-size: 16px;
  line-height: 22px;
  color: #4a8fe7;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

const UploadDate = styled.p`
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 15px;
  margin-left: 12px;
  margin: 20px 0 12px 20px;

  span {
    font-weight: bold;
  }
`;

export interface IImageGalleryObject {
  imageSrc: string;
  date: string;
  thumbnailSrc: string;
  rawPath: string;
}

interface Props {
  isOpen: boolean;
  onClose?: () => any;
  onDelete?: (currentImageIdx: number) => any; // if present delete button will be shown
  images: IImageGalleryObject[];
  title?: string;
  imageIdx: number;
}

interface State {
  currentImageIdx: number;
}

export default class ImageGalleryModal extends PureComponent<Props, State> {
  state = {
    currentImageIdx: 0,
  };

  componentDidMount() {
    let idx = this.props.imageIdx;
    this.setState({ currentImageIdx: idx });
  }

  onClose = () => {
    if (!!this.props.onClose) this.props.onClose();
  };

  onThumbnailClick = (index) => {
    this.setState({ currentImageIdx: index });
  };

  render() {
    const { isOpen, images, title, onDelete } = this.props;
    const { currentImageIdx } = this.state;
    const thumbnails = images.map((img) => img.thumbnailSrc);
    const image = images[currentImageIdx];
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const providerTimeZone = momentz.tz(timezone).zoneAbbr();

    return isOpen ? (
      <Modal
        isOpen={this.props.isOpen}
        backdrop="static"
        size={"xl"}
        className={styles.modalWrap}
        contentClassName={styles.modalContent}
      >
        <CloseButton onClick={this.onClose}>Close</CloseButton>
        {title && <Title>{title}</Title>}

        <Thumbnails
          images={thumbnails}
          currentImage={currentImageIdx}
          onClick={this.onThumbnailClick}
        />

        <Zoom image={image?.imageSrc} />

        <UploadDate>
          Upload Date:{" "}
          <span>
            {momentz(image?.date).format("dddd, MMM Do, YYYY hh:mm")} {providerTimeZone}
          </span>
        </UploadDate>
        {!!onDelete && (
          <ConfirmedDelete
            onDelete={() => {
              onDelete(currentImageIdx);
            }}
          />
        )}
      </Modal>
    ) : null;
  }
}
