import React, { FC } from "react";
import { ICatalogItem, IReviewOfSystems } from "../../constants/Types";

type PropsNegativeDetails = {
  answer: any;
  item: Partial<ICatalogItem>;
};

const NegativeDetails: FC<PropsNegativeDetails> = ({ answer, item }) => {
  let catalogDetails = item?.details?.map((detail) => {
    if (detail.options) {
      return detail.options.find((d) => d.key === answer);
    }
  });
  let catalogDetail = catalogDetails!.filter((i) => i)[0];
  if (!catalogDetail) {
    return null;
  }
  return <li key={catalogDetail?.key}>{catalogDetail?.label}</li>;
};

type PropsNegative = {
  detail: any;
  order: {
    systemKey: number;
    systemName: string;
    items: ICatalogItem[];
  };
};

const Negative: FC<PropsNegative> = ({ detail, order }) => {
  let item =
    order.items.find((i) => i.key === detail) || ({ details: [] } as Partial<ICatalogItem>);
  return (
    <div>
      <div className="d-flex">
        <div className="flex-grow-1"> {item.label}</div>
      </div>
    </div>
  );
};

type NegativeWithOptionsProps = {
  negatives: any;
  order: {
    systemKey: number;
    systemName: string;
    items: ICatalogItem[];
  };
};

const NegativeWithOptions: FC<NegativeWithOptionsProps> = ({ negatives, order }) => {
  const symptoms = Object.keys(negatives);
  return (
    <>
      {symptoms.map((symptom, i) => {
        const item =
          order.items.find((i) => String(i.key) == symptom) ||
          ({ details: [] } as Partial<ICatalogItem>);
        return (
          <div key={i}>
            {negatives[symptom] && (
              <div>
                <div className="flex-grow-1"> {`${item.label} with:`}</div>
                {negatives[symptom].map((answer, idx) => {
                  return (
                    <ul key={idx}>
                      <NegativeDetails item={item} answer={answer} />
                    </ul>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

type PertinentNegativesProps = {
  order: {
    systemKey: number;
    systemName: string;
    items: ICatalogItem[];
  };
  system?: any; //number[]
  catalog: Partial<IReviewOfSystems>;
  perSystemTitles: boolean;
};

const PertinentNegatives: FC<PertinentNegativesProps> = ({
  order,
  system,
  catalog,
  perSystemTitles,
}) => {
  if (!perSystemTitles) {
    return (
      <div>
        {system &&
          system.map(function (k, index) {
            return <Negative detail={k} order={order} key={index} />;
          })}
      </div>
    );
  } else {
    return (
      <div>
        {system &&
          system[order.systemKey].symptomNegatives &&
          system[order.systemKey].symptomNegatives.map(function (k, index) {
            return <Negative detail={k} order={order} key={index} />;
          })}
        {system && system[order.systemKey].optionNegatives && (
          <NegativeWithOptions negatives={system[order.systemKey].optionNegatives} order={order} />
        )}
      </div>
    );
  }
};

export default PertinentNegatives;
