import React from "react";

export default ({}) => {
  const userAgent = navigator.userAgent;
  const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
  const webkit = !!userAgent.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !userAgent.match(/CriOS/i);

  if (userAgent.indexOf("Chrome") !== -1 || iOSSafari) {
    return null;
  }
  if (iOS && !iOSSafari) {
    return (
      <div className="browser-banner">
        Safari is required for this site to work properly on an iOS device. Please open this page in
        Safari .
      </div>
    );
  }
  return (
    <div className="browser-banner">
      Google Chrome is required for this site to work properly. Please open this page in Chrome or
      download Chrome{" "}
      <a href="https://www.google.com/chrome/" target="_blank">
        here
      </a>
      .
    </div>
  );
};
