import React, { FC } from "react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Input } from "reactstrap";

import api from "../../api";

import BlockSection from "./BlockSection";
import ListEditor from "./ListEditor";
import SocialHistory from "./SocialHistory";
import CorrectiveLenses from "./CorrectiveLenses";
import FluImmunization from "./FluImmunization";
import WomensHealth from "./WomensHealth";
import WomensHealthHistory from "./WomensHealthHistory";
import CatalogBackedListOrEditor from "./CatalogBackedListOrEditor";
import FamilyHealthHistory from "./FamilyHealthHistory";
import { visitNameLookup } from "../../constants/Encounter";
import { IMedicalProfile, IUserDetails, IUserMedicalIntake } from "../../constants/Types";

const mapStringsToKey = (items, catalog) => {
  let entries = items.map((k) => catalog.find((v) => v.labels.en === k)).filter(Boolean);
  return entries.map((l) => l.key);
};

export const CommaSeparatedList = (props) => {
  const items = props.items || [];
  let content = "-";
  if (items.length > 0) {
    content = items.join(", ");
  }
  return <div>{content}</div>;
};

export const ListOrEditor = (props) => {
  const itemListSource = props.itemListSource;
  const debouncedSource = AwesomeDebouncePromise(itemListSource, 500);
  if (props.isEditing && !props.readOnly) {
    return (
      <ListEditor
        items={props.items}
        oldItems={props.oldItems || []}
        itemListSource={debouncedSource}
        onChange={props.onChange}
        userID={props.userID}
        patientTab={props.patientTab}
      />
    );
  }

  return <CommaSeparatedList items={props.items} />;
};

type Props = {
  profile: Partial<IMedicalProfile>;
  intake?: Partial<IUserMedicalIntake>;

  onProfileChanged: (value: { [key: string]: any }) => void;
  onIntakeChanged: (value: IUserMedicalIntake) => void;
  title?: string;
  providerNotes?: string;
  onProviderNoteUpdate: (value: string) => void;
  isPCCPatient?: boolean;
  showFemaleSpecific?: boolean;
  visitType?: string;
  patient?: IUserDetails;

  requiredFields?: string[];
  restoreData?: { medicalHistory: Partial<IMedicalProfile>; medicalIntake: IUserMedicalIntake };
  isEditing?: boolean;
};

const MedicalHistory: FC<Props> = ({
  profile,
  intake,
  /*  isEditing, */
  onProfileChanged,
  onIntakeChanged,
  title,
  providerNotes,
  onProviderNoteUpdate,
  isPCCPatient,
  readOnly = false,
  ...props
}) => {
  const data: Partial<Props["restoreData"]> = props.restoreData || {};
  const isEditing = true;
  const restoreData = data.medicalHistory || {};
  const intakeRestore: Partial<IUserMedicalIntake> = data.medicalIntake || {};

  const handleChanged = (field) => (newValue) => {
    onProfileChanged({ [field]: newValue });
  };
  const handleChangedKeyedValues = (field) => (catalog) => (keyValues, actionType) => {
    let other = profile[field].other ? profile[field].other.slice() : [];
    if (actionType === "create-option") {
      let otherValue = keyValues.pop();
      other.push(otherValue);
    }
    let keys = mapStringsToKey(keyValues || [], catalog || []);
    onProfileChanged({ [field]: { keys, other } });
  };

  const handleRemoveKeyedValues = (field) => (catalog) => (keyValues, otherValue) => {
    let other = profile[field].other ? profile[field].other.slice() : [];
    const indexOfOtherValue = other.indexOf(otherValue);
    if (indexOfOtherValue > -1) {
      other.splice(indexOfOtherValue, 1);
    }
    let keys = mapStringsToKey(keyValues || [], catalog || []);
    onProfileChanged({ [field]: { keys, other } });
  };

  const handleIntakeChanged = (field) => (newValue) => {
    onIntakeChanged({ [field]: newValue });
  };

  return (
    <div>
      <div className="mt-2">
        {isEditing && !readOnly ? (
          <BlockSection title="HPI">
            <Input
              type="textarea"
              name="providerNotes"
              value={providerNotes}
              onChange={(e) => onProviderNoteUpdate(e.target.value)}
              id="qa-nonpcc-hpi-input"
            />
          </BlockSection>
        ) : (
          <BlockSection title="HPI">
            <div>
              <p>{profile.providerNotes || "None"}</p>
            </div>
          </BlockSection>
        )}
      </div>

      <BlockSection title="Allergies" bodyClassName="mt-3">
        <ListOrEditor
          isEditing={isEditing}
          items={profile.allergies}
          oldItems={restoreData.allergies}
          itemListSource={api.Catalog.allergies}
          onChange={handleChanged("allergies")}
          readOnly={readOnly}
        />
      </BlockSection>
      <BlockSection title="Past Medical History" bodyClassName="mt-3">
        <CatalogBackedListOrEditor
          isEditing={isEditing}
          catalogEndpoint={api.Catalog.medicalHistory}
          values={profile.medicalHistoryKeys}
          oldItems={restoreData.medicalHistoryKeys || {}}
          onChange={handleChangedKeyedValues("medicalHistoryKeys")}
          onRemove={handleRemoveKeyedValues("medicalHistoryKeys")}
          readOnly={readOnly}
        />
      </BlockSection>
      <BlockSection title="Surgical History" bodyClassName="mt-3">
        <CatalogBackedListOrEditor
          isEditing={isEditing}
          catalogEndpoint={api.Catalog.surgicalHistory}
          values={profile.surgicalHistoryKeys}
          oldItems={restoreData.surgicalHistoryKeys || {}}
          onChange={handleChangedKeyedValues("surgicalHistoryKeys")}
          onRemove={handleRemoveKeyedValues("surgicalHistoryKeys")}
          readOnly={readOnly}
        />
      </BlockSection>
      <BlockSection title="Medications" bodyClassName="mt-3">
        <ListOrEditor
          isEditing={isEditing}
          items={profile.medications}
          oldItems={restoreData.medications}
          itemListSource={api.MedicalProfile.medicationsSearch}
          onChange={handleChanged("medications")}
          userID={props.patient?.userID}
          readOnly={readOnly}
        />
      </BlockSection>
      <BlockSection title="Social History" childClassName="social-history" bodyClassName="mt-3">
        <SocialHistory
          isEditing={isEditing}
          oldSocialHistory={restoreData.socialHistoryV2}
          socialHistory={profile.socialHistoryV2}
          onChange={handleChanged("socialHistoryV2")}
          readOnly={readOnly}
        />
      </BlockSection>
      <BlockSection title="Corrective Lenses" bodyClassName="mt-3">
        <CorrectiveLenses
          isEditing={isEditing}
          oldCorrectiveLenses={restoreData.correctiveLenses || {}}
          correctiveLensesSet={profile.correctiveLensesSet}
          correctiveLenses={profile.correctiveLenses || {}}
          onChange={handleChanged("correctiveLenses")}
          readOnly={readOnly}
        />
      </BlockSection>
      {props.showFemaleSpecific && (
        <>
          <WomensHealth
            isEditing={isEditing}
            visitType={props.visitType}
            womensHealth={intake?.womensHealth}
            oldWomensHealth={intakeRestore.womensHealth}
            onChange={handleIntakeChanged("womensHealth")}
            readOnly={readOnly}
          />
          <WomensHealthHistory
            isEditing={isEditing}
            womensHealthProfile={profile.womensHealth}
            oldWomensHealthProfile={restoreData.womensHealth}
            onProfileChange={handleChanged("womensHealth")}
            readOnly={readOnly}
          />
        </>
      )}
      {(visitNameLookup[props.visitType!] === "Flu" ||
        visitNameLookup[props.visitType!] === "Coronavirus") && (
        <BlockSection title="Immunizations" bodyClassName="mt-3">
          <FluImmunization
            isEditing={isEditing}
            oldFluImmunization={intakeRestore.fluImmunization}
            fluImmunization={intake?.fluImmunization}
            onChange={handleIntakeChanged("fluImmunization")}
            readOnly={readOnly}
          />
        </BlockSection>
      )}

      <BlockSection title="Family Health History" bodyClassName="mt-4">
        <FamilyHealthHistory
          onProfileChanged={handleChanged("FamilyMedicalHistory")}
          profile={profile}
          readOnly={readOnly}
        />
      </BlockSection>
    </div>
  );
};

export default MedicalHistory;
