import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";

import api from "../../api";
import { COMPONENT_LOAD, PROVIDERS_LIST, COMPONENT_UNLOAD } from "../../constants/actionTypes";

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.progress[PROVIDERS_LIST],
    ...state.componentData[PROVIDERS_LIST],
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: COMPONENT_LOAD, subtype: PROVIDERS_LIST, payload }),
  onUnload: () => dispatch({ type: COMPONENT_UNLOAD, subtype: PROVIDERS_LIST }),
});

const getLabel = (option) => {
  return option.fullName;
};

const getValue = (option) => {
  return option.id;
};

class ProviderSelect extends Component {
  componentDidMount() {
    if (
      this.props.encounterID != undefined &&
      this.props.encounterID != null &&
      this.props.encounterID != ""
    ) {
      this.props.onLoad(api.Providers.getAssignable(this.props.encounterID));
    } else {
      this.props.onLoad(api.Providers.list());
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    let options = [];
    if (this.props.data && this.props.data.items) {
      options = this.props.data.items.filter((i) => i.active === "active");
    }

    if (this.props.state) {
      options = options.filter(
        (i) => i.capabilities?.geographic && i.capabilities?.geographic?.includes(this.props.state),
      );
    }

    const onSelect = (newValue) => {
      this.props.onChange({ target: { value: newValue.id } });
    };

    const selected = options.find((x) => x.id === this.props.value);
    return (
      <div className={this.props.className}>
        <Select
          value={selected}
          options={options}
          defaultOptions={true}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          onChange={onSelect}
          isSearchable={true}
          menuPlacement={this.props.menuPlacement}
          backspaceRemovesValue={false}
        />
      </div>
    );
  }
}

ProviderSelect.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  menuPlacement: PropTypes.string,
  onChnage: PropTypes.func,
  onLoad: PropTypes.func,
  onUnload: PropTypes.func,
  selected: PropTypes.string,
  state: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSelect);
