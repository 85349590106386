import React, { FC, useState } from "react";
import { Button, Input, Row, Col } from "reactstrap";
import Select from "react-select";

import styles from "./addEntry.scss";
import Plus from "../../images/Plus.svg";
import CheckToggle from "../CheckToggle";
import api from "../../api";

import { ICurrentUser } from "../../constants/Types";

const descriptionMap = {
  patientPhone: "Phone call with patient",
  pharmacyPhone: "Phone call with pharmacy",
  other: "Other note",
  nursePhone: "Phone call with nurse",
};

const notesOptions = [
  { value: "other", label: "Other" },
  { value: "Covid19Note", label: "COVID-19 review note" },
];

const OtherNote = ({ selectedDescription, content, isPCCPatient, ...props }) => {
  return (
    <>
      <div className="d-flex justify-content-around mt-2 mb-2 pr-5 pl-5">
        {!isPCCPatient ? (
          <CheckToggle
            className="qa-chartNotePatientPhoneToggle"
            checked={selectedDescription === "patientPhone"}
            onChange={(t) => props.descChanged(t, "patientPhone")}
          >
            Phone call with patient
          </CheckToggle>
        ) : (
          <CheckToggle
            className="qa-chartNoteNursePhoneToggle"
            checked={selectedDescription === "nursePhone"}
            onChange={(t) => props.descChanged(t, "nursePhone")}
          >
            Phone call with nurse
          </CheckToggle>
        )}
        <CheckToggle
          className="qa-chartNotePharmacyPhoneToggle"
          checked={selectedDescription === "pharmacyPhone"}
          onChange={(t) => props.descChanged(t, "pharmacyPhone")}
        >
          Phone call with pharmacy
        </CheckToggle>
        <CheckToggle
          className="qa-chartNoteOtherToggle"
          checked={selectedDescription === "other"}
          onChange={(t) => props.descChanged(t, "other")}
        >
          Other
        </CheckToggle>
      </div>
      <Input
        type="textarea"
        rows={12}
        value={content}
        onChange={(evt) => props.setContent(evt.target.value)}
        className="qa-chartNoteEntryTextBox"
      />
    </>
  );
};

const Covid19Note = (props) => {
  return <div></div>;
};
interface IAddEntry {
  userID: string;
  userName: string;
  onClose: Function;
  currentUser: ICurrentUser;
  loadEntries: (base: any, token?: string) => void;
  isPCCPatient?: boolean;
}
const AddEntry: FC<IAddEntry> = ({
  userID,
  userName,
  onClose,
  currentUser,
  isPCCPatient,
  loadEntries,
}) => {
  const [selectedDescription, setSelectedDescription] = useState("");
  const [content, setContent] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [noteOption, setNoteOption] = useState("other");
  const [orderAcknowledgment, setOrderAcknowledgment] = useState(false);
  const [providerName, setProviderName] = useState("");
  const isNursingHomeSite = !!(currentUser && currentUser.isNursingHomeSite);
  const otherSelected = noteOption === "other";
  const value = notesOptions.find((option) => option.value === noteOption);

  const descChanged = (checked, v) => {
    if (checked) {
      setSelectedDescription(v);
    }
  };
  const changeOrder = () => {
    setOrderAcknowledgment(!orderAcknowledgment);
  };
  const onProviderName = (e) => {
    setProviderName(e.target.value);
  };

  const saveEntry = async () => {
    setInProgress(true);
    let newContent =
      content + `\n\n-This plan and any patient orders were given verbally to: ${providerName}`;
    let res = await api.UserRecords.addChartItem(userID, {
      description: descriptionMap[selectedDescription] || "Other note",
      content: selectedDescription === "nursePhone" ? newContent : content,
    });
    if (!res.errors) {
      onClose(res);
      loadEntries([]);
    }
  };
  const isDisabled = () => {
    if (selectedDescription === "nursePhone") {
      return content === "" || inProgress || providerName === "" || !orderAcknowledgment;
    }
    return content === "" || selectedDescription === "" || inProgress;
  };

  return (
    <div className="modal-overlay">
      <div className={styles.modalContainer}>
        <h1>Add note to {userName}'s chart:</h1>
        {isNursingHomeSite && false && (
          <Row>
            <Col className="col-4 mt-4 mb-2">
              <Select
                value={value}
                options={notesOptions}
                onChange={(option) => setNoteOption(option.value)}
                backspaceRemovesValue={false}
              />
            </Col>
          </Row>
        )}

        {otherSelected ? (
          <OtherNote
            selectedDescription={selectedDescription}
            descChanged={descChanged}
            setContent={setContent}
            content={content}
            isPCCPatient={isPCCPatient}
          />
        ) : (
          <>{isNursingHomeSite && <Covid19Note />}</>
        )}
        {selectedDescription === "nursePhone" && (
          <>
            <Row>
              <Col className="justify-content-start col-12 mt-4 mb-1">
                <CheckToggle checked={orderAcknowledgment} onChange={changeOrder}>
                  <p>
                    I acknowledge that I have verbally communicated these orders to a qualified
                    staff member at this patient’s facility.
                  </p>
                </CheckToggle>
              </Col>
            </Row>
            <Row>
              <Col className="col-5 mt-2 mb-2">
                <span>Who did you communicate the plan/order to?</span>
                <Input
                  value={providerName}
                  onChange={onProviderName}
                  placeholder="Type provider’s full name..."
                />
              </Col>
            </Row>
          </>
        )}
        <div className={styles.buttonContainer}>
          <Button className="mr-2" id="qa-othernote-cancel-btn" onClick={() => onClose()} color="link">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={saveEntry}
            disabled={isDisabled()}
            active={!isDisabled()}
            className="qa-chartNoteAddNoteButton"
          >
            Add note &nbsp;
            <Plus />
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AddEntry;
