import { Reducer } from "redux";

import { ENCOUNTER_LOAD_UNSIGNED, LOGOUT, SWITCH_PRACTICE } from "../constants/actionTypes";
import { IAllProviderSavedEncounters, IProviderSavedEncounter } from "../constants/Types";

const emptyResponse = {
  oneDay: [],
  twoDays: [],
  overdue: [],
};

const defaultState = {
  unsignedEncountersList: emptyResponse,
  numberOfEncountersWithNewMessages: 0,
};

const extractRedNotificationBubbleNumber = ({
  oneDay = [],
  twoDays = [],
  overdue = [],
}: IAllProviderSavedEncounters) => {
  const items: IProviderSavedEncounter[] = [];
  if (oneDay) {
    items.push(...oneDay);
  }
  if (twoDays) {
    items.push(...twoDays);
  }
  if (overdue) {
    items.push(...overdue);
  }

  return items.reduce((nbOfMessages, item) => {
    return item.newMessages || item.scribeComplete || item.reassigned ? nbOfMessages + 1 : nbOfMessages;
  }, 0);
};

const unsignedEncounters: Reducer<any, any> = (state = defaultState, action: any) => {
  switch (action.type) {
    case ENCOUNTER_LOAD_UNSIGNED:
      return {
        ...state,
        redNotificationBubbleNumber: extractRedNotificationBubbleNumber(
          action.payload || emptyResponse,
        ),
        unsignedEncountersList: action.payload || emptyResponse,
      };
    case SWITCH_PRACTICE:
      return defaultState;
    case LOGOUT:
      return defaultState;
    default:
      return state;
  }
};

export default unsignedEncounters;
