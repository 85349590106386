import React, { useState } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import PropTypes from "prop-types";

import ArrowIcon from "../../images/whiteDownArrow.svg";
import "./covidZoneDropdown.scss";

const CovidZoneDropdown = ({ status, userID, setPatientZone }) => {
  const [value, setValue] = useState(status);

  const changeValue = (e) => {
    setValue(e.currentTarget.name);
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
    !!setPatientZone && setPatientZone(userID, e.currentTarget.name);
  };

  return (
    <>
      <UncontrolledDropdown className="dropdownStatus" direction="down">
        <DropdownToggle
          className="CCMStatuses"
          id="qa-covidzone-drp"
          style={{
            color: "white",
            backgroundColor:
              value === "Green"
                ? "#8AA639"
                : "" || value === "Red"
                ? "#B84D4D"
                : "" || value === "Yellow"
                ? "#E9B75F"
                : "" || value === "Yellow"
                ? "#069"
                : "" || value === "" || "N/A"
                ? "grey"
                : "",
          }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type. */}
          <span className={`d-flex ${!!setPatientZone ? "mr-3" : ""}`}>
            {value === "N/A" || !value ? "N/A" : `${value} Zone`}
          </span>
          {!!setPatientZone && <ArrowIcon height={22} width={22} className="arrow-icon" />}
        </DropdownToggle>
        {!!setPatientZone && (
          <DropdownMenu className="dropdown-menu-status">
            <DropdownItem className="dropdown-item-status" name="Green" onClick={changeValue}>
              Green Zone
            </DropdownItem>
            
            <DropdownItem className="dropdown-item-status" name="Blue" onClick={changeValue}>
              Blue Zone
            </DropdownItem>

            <DropdownItem className="dropdown-item-status" name="Yellow" onClick={changeValue}>
              Yellow Zone
            </DropdownItem>

            <DropdownItem className="dropdown-item-status" name="Red" onClick={changeValue}>
              Red Zone
            </DropdownItem>           
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    </>
  );
};

CovidZoneDropdown.propTypes = {
  status: PropTypes.string,
  userID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setPatientZone: PropTypes.func,
};

export default CovidZoneDropdown;
