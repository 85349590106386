import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import api from "../../api";
import momentz from "moment-timezone";
import { IUpdateLogEvents } from "../../constants/Types";
import "./UpdateLogTable.scss";

type ProviderUpdateLogProps = {
  providerID: string;
};

const FIELD_LABELS = [
  { value: "Capabilities.Demographic", label: "Demographic Capabilities" },
  { value: "Capabilities.Geographic", label: "Licensed States" },
  { value: "Capabilities.Linguistic", label: "Language Capabilities" },
  { value: "Street", label: "Street" },
  { value: "City", label: "City" },
  { value: "State", label: "State" },
  { value: "PostalCode", label: "Zip" },
  { value: "Phone", label: "Phone" },
  { value: "Email", label: "Email" },
  { value: "Prefix", label: "Prefix" },
  { value: "FirstName", label: "First Name" },
  { value: "LastName", label: "Last Name" },
  { value: "Suffix", label: "Suffix" },
  { value: "DateOfBirth", label: "Date of Birth" },
  { value: "DEANumber", label: "DEA Number" },
  { value: "NPINumber", label: "NPI Number" },
  { value: "IsBetaTester", label: "Beta Tester" },
  { value: "DisableAlerts", label: " Disabled Text Alerts" },
  { value: "MedicalDirectors", label: "Medical Directors" },
  { value: "SupervisorUpdatedAt", label: "Supervisor Updated" },
  { value: "Permissions", label: "Permissions" },
  { value: "DoseSpotID", label: "DoseSpot ID" },
  { value: "MedicalPractices", label: "Medical Practices" },
  { value: "CreatedAt", label: "Created" },
  { value: "InvitePending", label: "Invite Pending" },
  { value: "Active", label: "Active" },
  { value: "CurrentPracticeID", label: "Current Practice ID" },
  { value: "VideoSetup", label: "Video Setup" },
  { value: "SignatureUploaded", label: "Signature Uploaded" },
  { value: "HomeBaseProviderID", label: "Home Base ID" },
  { value: "GroupSort", label: "Group Sort" },
];

const getDate = (dt) => momentz(dt).format("MM/DD/YYYY HH:mm");
const getZone = momentz.tz.guess();
const zone = momentz.tz(getZone).zoneAbbr();

const TableRows = ({ item, idx }) => {
  const Grouped =
    item?.changedProperties &&
    Object.entries(item?.changedProperties)?.map(([key, value]) => {
      return {
        field: key,
        old: item.changedProperties[key].__oldValue,
        new: item.changedProperties[key].__newValue,
        name: item.triggeredByName,
        createdAt: item.createdAt,
      };
    });

  // format items in medical direcors object
  const MedicalDirector = (Directors) => {
    //  return providerName(state)
    return Object.entries(Directors)?.map(([key, value]) => {
      return `${value} (${key})`;
    });
  };

  // format Superviser Updated At times
  const SuperviserUpdatedAt = (Time) => {
    if (Time) {
      return Object.entries(Time)?.map(([key, value]) => {
        return `${getDate(value)}${zone} (${key})`;
      });
    }
  };

  return (
    <>
      {Grouped?.map((event) => {
        const { field, old: oldValue, new: newValue, name, createdAt } = event;

        const fieldName = FIELD_LABELS?.find((f) => f.value === field);
        // format old values
        const oldValueString =
          field === "MedicalDirectors"
            ? MedicalDirector(oldValue)?.join(", ")
            : field === "SupervisorUpdatedAt"
            ? SuperviserUpdatedAt(oldValue)?.join(", ")
            : typeof oldValue === "boolean"
            ? oldValue
              ? "Yes"
              : "No"
            : Array.isArray(oldValue)
            ? oldValue?.join(",  ")
            : oldValue;

        // format new values
        const newValueString =
          field === "MedicalDirectors"
            ? MedicalDirector(newValue)?.join(", ")
            : field === "SupervisorUpdatedAt"
            ? SuperviserUpdatedAt(newValue)?.join(", ")
            : typeof newValue === "boolean"
            ? newValue
              ? "Yes"
              : "No"
            : Array.isArray(newValue)
            ? newValue?.join(",  ")
            : newValue;

        return (
          <tr key={idx} className="updateLogTable-body">
            <td className="table-item ">{`${getDate(event?.createdAt)} ${zone}`}</td>
            <td className="table-item ">{fieldName?.label}</td>
            <td className="table-item">{oldValueString}</td>
            <td className="table-item">{newValueString}</td>
            <td className="table-item ">{event?.name}</td>
          </tr>
        );
      })}
    </>
  );
};

const ProviderUpdateLog: FC<ProviderUpdateLogProps> = ({ providerID }) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.Providers.getUpdateLog(providerID);
      setData(response?.events);
    } catch (error) {
      console.log("ERROR:: ", error);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <div className="dashboard-component align-content-stretch">
      <h4>Provider Profile Edit History</h4>
      <Table className="update-log-table">
        <thead>
          <tr className="tableHead">
            <th>Date</th>
            {/* changedAt */}
            <th>Field Name</th>
            {/* changedProperties */}
            <th>Changed From</th>
            {/* oldValue */}
            <th>Changed To</th>
            {/* newValue */}
            <th>Changed By</th>
            {/* triggeredByName */}
          </tr>
        </thead>
        <tbody>
          {!!data &&
            data?.map((event, idx) => {
              return <TableRows item={event} idx={idx} />;
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default ProviderUpdateLog;
