import React, { ChangeEventHandler, FC, useEffect, useRef, useState } from "react";

import styles from "./PracticeFilter.scss";
import { IPracticeItem } from "../../../../constants/Types";
import Arrow from "../../../../images/arrow.svg";
import DropDownArrow from "../../../../images/menuarrow.svg";
import Magnifying from "../../../../images/SearchMagnifying.svg";
import { STATES } from "../../../../constants/Providers";

function getFiltredItems(items: Props["providerPractices"], searchString: string) {
  return items
    .reduce<IPracticeItem[]>((ac, a) => [...ac, ...a.practices], [])
    .filter((el) => el.name.toLowerCase().includes(searchString.toLocaleLowerCase()));
}

type DropDownItemProps = {
  dropDownLabel: string;
  openDropDown: (event: React.MouseEvent<HTMLElement>) => void;
};

const DropDownItem: FC<DropDownItemProps> = ({ dropDownLabel, openDropDown }) => {
  return (
    <li style={{ display: "flex", justifyContent: "space-between" }} onClick={openDropDown}>
      <span>{dropDownLabel}</span>
      <span style={{ display: "flex", alignItems: "center" }}>
        <DropDownArrow />
      </span>
    </li>
  );
};

type Props = {
  providerPractices: { state: string; practices: IPracticeItem[] }[];
  onPracticeSelect: (practice: { name: string; id: string; nhFlag: boolean }) => void;
  selectedPractice: { name: string; id: string; nhFlag: boolean };
};

const PraticeFilter: FC<Props> = ({ providerPractices, selectedPractice, onPracticeSelect }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const [searchString, setSearch] = useState("");

  const [dropDownPractices, setDropDownPractices] = useState<IPracticeItem[]>([]);

  const dropDownMenu = useRef<HTMLUListElement>(null);
  const dropDownState = useRef("");
  const mainDropDown = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!toggleMenu) {
      return;
    }
    const handleClickOutside = (event: Event) => {
      if (mainDropDown.current && !mainDropDown.current.contains(event.target as Node)) {
        setToggleMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleMenu]);

  const setSelectedPractice = (practice: { name: string; id: string; nhFlag: boolean }) => {
    onPracticeSelect(practice);
    setToggleMenu(false);
  };

  const openDropDown = (
    event: React.MouseEvent<HTMLElement>,
    state: string,
    practices: IPracticeItem[],
  ) => {
    const parentcord = event.currentTarget.parentElement!.getBoundingClientRect();
    const childCord = event.currentTarget.getBoundingClientRect();
    if (dropDownState.current === state) {
      dropDownMenu.current!.style.display = "none";
      dropDownState.current = "";
    } else {
      dropDownMenu.current!.style.display = "block";
      dropDownState.current = state;
    }

    dropDownMenu.current!.style.top = childCord.top - parentcord.top + "px";
    dropDownMenu.current!.style.transform = "translate(-95%, 50%)";

    setDropDownPractices(practices);
  };

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (dropDownState.current !== "") {
      dropDownMenu.current!.style.display = "none";
      dropDownState.current = "";
    }

    setSearch(e.target.value);
  };
  const selectAll = () => {
    if (dropDownState.current !== "") {
      dropDownMenu.current!.style.display = "none";
      dropDownState.current = "";
    }

    setSelectedPractice({ name: "", id: "", nhFlag: true });
  };

  return (
    <div className={styles.wrapper} ref={mainDropDown}>
      <div className={styles.display} onClick={() => setToggleMenu(!toggleMenu)} tabIndex={0}>
        <div className={styles.displayString}>
          <span>{selectedPractice.name ? selectedPractice.name : "All Practices"}</span>
        </div>
        <div className={styles.displayArroy}>
          <Arrow className={styles.arrow} />
        </div>
      </div>

      {toggleMenu && (
        <>
          <ul className={styles.toggleMenu}>
            <label className={styles.searchBlock}>
              <Magnifying className={styles.magnifyingGlass} />
              <input
                id="site-search"
                placeholder="Search practices"
                value={searchString}
                onChange={handleSearch}
              />
            </label>
            {searchString ? (
              getFiltredItems(providerPractices, searchString).map((el) => (
                <li
                  key={el.id}
                  onClick={() =>
                    setSelectedPractice({ id: el.id, name: el.name, nhFlag: el.isNursingHomeSite })
                  }
                >
                  {el.name}
                </li>
              ))
            ) : (
              <>
                {providerPractices.map((el) => (
                  <DropDownItem
                    key={el.state}
                    dropDownLabel={STATES[el.state]}
                    openDropDown={(event) => openDropDown(event, el.state, el.practices)}
                  ></DropDownItem>
                ))}
                <li onClick={selectAll}>Select All Practices</li>
              </>
            )}
          </ul>

          <ul className={`${styles.toggleMenu} ${styles.dropDownPractices}`} ref={dropDownMenu}>
            {dropDownPractices.map((el) => (
              <li
                key={el.id}
                onClick={() =>
                  setSelectedPractice({ id: el.id, name: el.name, nhFlag: el.isNursingHomeSite })
                }
              >
                {el.name}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default PraticeFilter;
