import React, { Component, Dispatch } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  createLocalTracks,
  Room,
  connect as twConnect,
  RemoteParticipant,
} from "twilio-video";

import api from "../../api";
import bugsnagClient from "../../services/bugsnag";
import {
  ENCOUNTER_START,
  ENCOUNTER_VIDEO_STOP,
  ENCOUNTER_USER_VIDEO_DISCONNECT,
  ENCOUNTER_USER_VIDEO_CONNECT,
} from "../../constants/actionTypes";
import { USER_VIDEO_FINISHED } from "../../constants/Encounter";
import ErrorList from "../ErrorList";
import Spinner from "../../images/Spinner.svg";
import EndCallIcon from "../../images/EndCallIcon.svg";
import MaximizeIcon from "../../images/Maximize.svg";
import PauseIcon from "../../images/PauseIcon.svg";
import PlayIcon from "../../images/PlayIcon.svg";
import Phone from "../../images/Phone.png";
import StartTimer from "./StartTimer";
import { IAppState } from "../../reducer";
import { IEncounterDetailsResponse } from "../../constants/Types";

const mapStateToProps = (state: IAppState, ownProps: VideoSessionOwnProps) => {
  return {
    encounterID: state.encounter.encounterID || "",
    videoToken: state.encounter.videoToken,
    roomName: state.encounter.roomName,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  startVideo: (payload) => dispatch({ type: ENCOUNTER_START, payload }),
  stopVideo: () => dispatch({ type: ENCOUNTER_VIDEO_STOP }),
  userVideoDisconnect: () => dispatch({ type: ENCOUNTER_USER_VIDEO_DISCONNECT }),
  userVideoConnect: () => dispatch({ type: ENCOUNTER_USER_VIDEO_CONNECT }),
});

const Placeholder = (props) => {
  return (
    <div className="w-100 video-placeholder d-flex flex-column justify-content-end h-100">
      <div className="d-flex flex-column align-items-center justify-content-end h-100">
        {props.children}
      </div>
    </div>
  );
};

const MedTechBanner = ({ telemedExtender }) => {
  return (
    <div className="med-tech-banner d-flex">
      <span>{`With Tech: ${telemedExtender.name}`}</span>
      <div className="d-flex">
        <img className="phone-icon align-self-center" src={Phone} />
        <span className="align-self-center">{`: ${telemedExtender.phone}`}</span>
      </div>
    </div>
  );
};

const VideoEndConfirmation = ({ onCancel, onConfirm }) => {
  return (
    <div className="modal-overlay d-flex flex-column">
      <div className="ml-auto mr-auto provider-message-container video-end-confirm d-flex flex-column justify-content-center">
        <h4>Are you sure you’re ready to end the encounter and write your note?</h4>
        <div className="mt-5 d-flex justify-content-between">
          <Button color="link" onClick={onCancel}>
            {" "}
            Go back
          </Button>
          <Button color="primary" onClick={onConfirm}>
            {" "}
            End call &amp; write note
          </Button>
        </div>
      </div>
    </div>
  );
};

type track = LocalAudioTrack | LocalVideoTrack;

export interface VideoSessionOwnProps {
  onUserVideoFinished: Function;
  timeout: number;
  videoStatus: string;
  supressConfirmation?: boolean;
  telemedExtender: IEncounterDetailsResponse["telemedExtender"];
  attemptReconnect?: boolean;
  onReconnect: Function;
  setContinueOrAbandon: Function;
}

type VideoSessionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface VideoSessionState {
  connected: boolean;
  paused: boolean;
  fullScreen: boolean;
  remoteTracks: track[];
  localTracks: track[];
  reconnected: boolean;
  error: string | null;
  connecting: boolean;
  clientConnected: boolean;
  room: Room | null;
  showConfirmation: boolean;
}
let connectingToRoom = false;

class VideoSession extends Component<VideoSessionProps, VideoSessionState> {
  node: any;

  constructor(props: VideoSessionProps) {
    super(props);
    this.state = {
      connected: false,
      paused: false,
      fullScreen: false,
      remoteTracks: [],
      localTracks: [],
      reconnected: false,
      error: null,
      connecting: false,
      clientConnected: false,
      room: null,
      showConfirmation: false,
    };
    this.onVideoStart = this.onVideoStart.bind(this);
    this.onVideoEnd = this.onVideoEnd.bind(this);
    this.onPauseVideo = this.onPauseVideo.bind(this);
    this.onConfirmCancel = this.onConfirmCancel.bind(this);
    this.onConfirmEnd = this.onConfirmEnd.bind(this);
    this.onToggleFullScreen = this.onToggleFullScreen.bind(this);
  }

  componentDidMount() {
    this.createLocalTracks();
  }

  setError = (error: string) => {
    bugsnagClient.notify(error);
    this.setState({ error: error });
  };

  createLocalTracks() {
    const localMediaContainer = document.getElementById("local-tracks");
    if (!localMediaContainer) {
      return;
    }

    createLocalTracks({
      audio: true,
      video: { height: 350, width: 205 },
    }).then(
      (localTracks) => {
        localMediaContainer.innerText = "";
        localTracks.forEach(function (track) {
          localMediaContainer.appendChild((track as track).attach());
        });
        this.setState({ localTracks: localTracks as track[] });
        if (this.state.connecting) {
          this.connectOrFetchToken();
        }
      },
      (error: Error) => this.setError(error.message),
    );
  }

  removeLocalTracks() {
    if (this.state.localTracks) {
      this.state.localTracks.forEach(function (track) {
        track.stop();
        track.detach().forEach(function (detachedElement) {
          detachedElement.remove();
        });
      });
      this.setState({ localTracks: [] });
    }
  }

  componentWillUnmount() {
    this.removeLocalTracks();
    this.disconnectVideo();

    if (this.props.videoStatus === USER_VIDEO_FINISHED) {
      this.onVideoEnd();
    }
    localStorage.removeItem("videoToken");
    localStorage.removeItem("roomName");
  }

  trackSubscribed(remoteTrack: track) {
    const findSID = (track: track) => {
      return this.state.remoteTracks.find((remoteTrack) => {
        return remoteTrack.mediaStreamTrack.id == track.mediaStreamTrack.id;
      });
    };

    if (
      (this.state.remoteTracks.length < 2 && !findSID(remoteTrack)) ||
      (this.node && this.node.innerHTML.indexOf("</video>") < 0)
    ) {
      document.getElementById("remote-tracks")?.appendChild(remoteTrack.attach());
      this.setState({
        remoteTracks: this.state.remoteTracks.concat([remoteTrack]),
      });
    }
  }

  participantConnected = (participant: RemoteParticipant) => {
    this.setState({ clientConnected: true });
    this.props.userVideoConnect();

    participant.on("trackSubscribed", (track: track) => {
      return this.trackSubscribed(track);
    });
    participant.on("trackUnsubscribed", (track: track) => {
      track.detach().forEach((element) => element.remove());
      const remaining = this.state.remoteTracks.filter((t) => t.name !== track.name);
      this.setState({ remoteTracks: remaining });
    });

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed && publication.track) {
        this.trackSubscribed(publication.track as any); // ???
      }
    });
  };

  participantDisconnected = (participant: RemoteParticipant) => {
    this.state.remoteTracks.forEach((track) =>
      track.detach().forEach((element) => element.remove()),
    );
    if (this.state.remoteTracks.length > 0) {
      if ((!this.state.showConfirmation && participant.videoTracks.size < 1) || this.state.error) {
        // Only trigger this if the provider wasn't the one ending the video
        this.props.userVideoDisconnect();
      }
      this.setState({ remoteTracks: [], clientConnected: false });
    }
  };

  disconnected = (room: Room, err: Error) => {
    this.setState({ connected: false });
    room.participants.forEach(this.participantDisconnected);
  };

  bindToRoom = (room: Room) => {
    this.setState({ connected: true, room: room });

    // adds video and audio tracks if patient already connected
    room.participants.forEach(this.participantConnected);

    // adds video and audio tracks when patient connects
    room.on("participantConnected", this.participantConnected);

    room.on("participantDisconnected", this.participantDisconnected);

    room.on("reconnecting", (error: Error) => this.setError(error.message));
    room.on("reconnected", () => this.setState({ error: null }));

    room.once("disconnected", this.disconnected);
  };

  connectToRoom(roomName: string, token: string) {
    if (!this.state.error && connectingToRoom) {
      return;
    }
    connectingToRoom = true;

    twConnect(token, {
      name: roomName,
      audio: true,
      video: true,
      tracks: this.state.localTracks,
    })
      .then(this.bindToRoom, (error: Error) => {
        this.setError(error.message);
        
        this.props.setContinueOrAbandon();
      })
      .then(() => {
        connectingToRoom = false;
      });
  }

  connectOrFetchToken() {
    if (!this.props.videoToken) {
      this.props.startVideo(api.Encounters.start(this.props.encounterID));
    } else {
      this.connectToRoom(this.props.roomName, this.props.videoToken);
    }
  }

  onVideoStart() {
    this.setState({ connecting: true });
    if (this.state.localTracks.length === 0) {
      this.createLocalTracks();
    } else {
      this.connectOrFetchToken();
    }
  }

  onPauseVideo() {
    if (this.state.paused) {
      this.state.localTracks.forEach((t) => t.enable());
    } else {
      this.state.localTracks.forEach((t) => t.disable());
    }
    this.setState({ paused: !this.state.paused });
  }

  onToggleFullScreen() {
    this.setState({ fullScreen: !this.state.fullScreen });
  }

  disconnectVideo() {
    if (this.state.room) {
      this.state.room.disconnect();
    }

    this.state.remoteTracks.forEach((track) =>
      track.detach().forEach((element) => element.remove()),
    );
    localStorage.removeItem("videoToken");
    localStorage.removeItem("roomName");
  }

  onConfirmEnd() {
    this.setState({ showConfirmation: true });
  }

  onConfirmCancel() {
    this.setState({ showConfirmation: false });
  }

  onVideoEnd(clickEvent?: Event) {
    if (clickEvent || this.state.connected) {
      api.Encounters.videoComplete(this.props.encounterID).then(() => {
        this.props.stopVideo();
        this.disconnectVideo();
      });
    }
  }

  componentDidUpdate(prevProps: VideoSessionProps, prevState: VideoSessionState) {
    if (
      prevProps.videoStatus !== USER_VIDEO_FINISHED &&
      this.props.videoStatus === USER_VIDEO_FINISHED
    ) {
      this.props.onUserVideoFinished();
      return;
    }

    const savedVideoToken = localStorage.getItem("videoToken") || "";
    const savedVideoRoom = localStorage.getItem("roomName") || "";
    const savedInLocalStorage = savedVideoToken && savedVideoRoom;

    if (!prevProps.videoToken && this.props.videoToken && !savedInLocalStorage) {
      localStorage.setItem("videoToken", this.props.videoToken);
      localStorage.setItem("roomName", this.props.roomName);
      this.connectToRoom(this.props.roomName, this.props.videoToken);
    } else if (
      (!prevState.reconnected && !this.props.videoToken && savedVideoToken) ||
      this.props.attemptReconnect
    ) {
      this.setState({ reconnected: true, error: null });
      if (this.props.attemptReconnect) {
        this.props.onReconnect();
      }

      this.connectToRoom(savedVideoRoom, savedVideoToken);
      this.onVideoStart();
    }
  }

  render() {
    const clientPresent = this.state.clientConnected;
    const { connecting, connected, remoteTracks } = this.state;
    const timeout = this.props.timeout;
    const { supressConfirmation, telemedExtender } = this.props;
    let rootClass = "video-session d-flex flex-column align-items-center";
    if (this.state.fullScreen) {
      rootClass = `${rootClass} full-screen`;
    }
    const firstConnect = connected && !clientPresent;
    const reconnect = (connected || this.state.reconnected) && remoteTracks.length < 2;
    return (
      <div className={rootClass}>
        {telemedExtender && !this.state.fullScreen && (
          <MedTechBanner telemedExtender={telemedExtender} />
        )}
        {this.state.showConfirmation && !supressConfirmation && (
          <VideoEndConfirmation onCancel={this.onConfirmCancel} onConfirm={this.onVideoEnd} />
        )}
        <div className="local-preview">
          <div id="local-tracks" />
          {telemedExtender && this.state.fullScreen && (
            <MedTechBanner telemedExtender={telemedExtender} />
          )}
        </div>

        <div className="video-container w-100">
          {clientPresent && (
            <div className="full-screen-toggle d-flex justify-content-end">
              <Button onClick={this.onToggleFullScreen}>
                <MaximizeIcon />
              </Button>
            </div>
          )}
          {!connected && !this.state.reconnected && (
            <Placeholder>
              {!connecting && !this.state.reconnected && (
                <div className="d-flex flex-column align-items-stretch">
                  {!localStorage.roomName ? (
                    <div>
                      <StartTimer timeout={timeout} />
                      <Button onClick={this.onVideoStart} disabled={connecting} color="primary">
                        Start Video
                      </Button>
                    </div>
                  ) : (
                    <div className="mb-5">
                      <Spinner />
                    </div>
                  )}
                </div>
              )}
              {connecting && (
                <div className="mb-5">
                  <Spinner />
                </div>
              )}
            </Placeholder>
          )}
          <div className="flex-grow-1 w-100">
            <div
              id="remote-tracks"
              className="d-flex align-items-stretch justify-content-center"
              ref={(node) => (this.node = node)}
            />

            {(firstConnect || reconnect) && (
              <Placeholder>
                <h4> Waiting for user to connect... </h4>
              </Placeholder>
            )}
            {(connected || this.state.reconnected) &&
              this.node &&
              this.node.innerHTML.indexOf("</video>") < 0 &&
              !!remoteTracks.length && (
                <Placeholder>
                  <h4> Video has been disabled by user... </h4>
                </Placeholder>
              )}

            {(connected || this.state.reconnected) && (
              <div className="d-flex justify-content-center align-items-center video-ctl-container">
                <Button onClick={this.onPauseVideo} className="mr-3 video-ctl">
                  {this.state.paused && (
                    <div className="play-btn">
                      <PlayIcon />
                    </div>
                  )}
                  {!this.state.paused && <PauseIcon />}
                </Button>
                <Button onClick={this.onConfirmEnd} className="ml-3 video-ctl end-call">
                  <EndCallIcon />
                </Button>
              </div>
            )}
          </div>
        </div>
        <ErrorList className="mt-5" errors={this.state.error ? [this.state.error] : []} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSession);
