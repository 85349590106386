import React, { Component } from "react";
import { connect } from "react-redux";

import { COMPONENT_LOAD, COMPONENT_UNLOAD, COMPONENT_LOAD_MORE } from "../constants/actionTypes";
import Spinner from "../images/Spinner.svg";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => ({
  data: state.componentData[ownProps.listName],
  currentUser: state.common.currentUser,
  ...ownProps,
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  onLoad: (type, payload) => dispatch({ type: COMPONENT_LOAD, subtype: type, payload }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  onLoadMore: (type, payload) =>
    dispatch({
      type: COMPONENT_LOAD_MORE,
      subtype: type,
      payload,
      withoutSpinner: true,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  onUnload: (type) => dispatch({ type: COMPONENT_UNLOAD, subtype: type }),
});

class RemoteList extends React.Component {
  componentDidMount() {
    if (this.props.getItems) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getItems' does not exist on type 'Readon... Remove this comment to see the full error message
      this.getItems();
    }
  }

  componentDidUpdate(prevProps) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
    if (this.props.getItems && this.props.getItems !== prevProps.getItems) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getItems' does not exist on type 'Readon... Remove this comment to see the full error message
      this.getItems();
      return;
    }
    if (this.props.currentUser != prevProps.currentUser) {
      this.getItems();
    }
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
  componentWillUnmount() {
    this.props.onUnload(this.props.listName);
  }

  getItems() {
    if (!this.props.getItems) {
      return;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onUnload' does not exist on type 'Readon... Remove this comment to see the full error message
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'listName' does not exist on type 'Readon... Remove this comment to see the full error message
    const response = this.props.getItems();
    let items = [];

    if (response.next) {
      this.iterator = response;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getItems' does not exist on type 'Readon... Remove this comment to see the full error message
      items = response.next().then(({ value }) => value);
    } else {
      this.iterator = null;
      items = response;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'iterator' does not exist on type 'Remote... Remove this comment to see the full error message
    this.props.onLoad(this.props.listName, items);
  }

  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
  handleLoadMore = () => {
    if (this.iterator) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'iterator' does not exist on type 'Remote... Remove this comment to see the full error message
      return this.props.onLoadMore(
        this.props.listName,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onLoad' does not exist on type 'Readonly... Remove this comment to see the full error message
        this.iterator.next().then(({ value }) => value),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'listName' does not exist on type 'Readon... Remove this comment to see the full error message
      );
    }

    return Promise.resolve();
  };

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'iterator' does not exist on type 'Remote... Remove this comment to see the full error message
  renderChildren() {
    let items = [];
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'listName' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.data && !this.props.data.inProgress && this.props.data.data) {
      // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
      items = this.props.data.data.items;
    }
    return React.Children.map(this.props.children, (child) => {
      // @ts-expect-error ts-migrate(7034) FIXME: Variable 'items' implicitly has type 'any[]' in so... Remove this comment to see the full error message
      return React.cloneElement(child, {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        items: items,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        currentUser: this.props.currentUser,
        inProgress: this.props.inProgress,
        onLoadMore: this.handleLoadMore,
      });
    });
  }

  render() {
    let inProgress = false;
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'child' implicitly has an 'any' type.
    if (this.props.data) {
      inProgress = this.props.data.inProgress;
    }
    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'items' implicitly has an 'any[]' type.
    if (inProgress) {
      return (
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
        <div className="d-flex flex-column w-100 align-items-center">
          <Spinner />
        </div>
      );
    }

    return this.renderChildren();
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoteList);
// @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
