import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";

import api from "../../api";
import bugsnagClient from "../../services/bugsnag";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
const getMDLabel = (option) => option.name;

const getMDValue = (option) => option.providerID;

export const exemptedStates = [
  "AK",
  "AZ",
  "CO",
  "CT",
  "DC",
  "HI",
  "ID",
  "IA",
  "ME",
  "MI",
  "MD",
  "MN",
  "MT",
  "NE",
  "NV",
  "NH",
  "NM",
  "ND",
  "NY", // NOTE: NY is temporary
  "OR",
  "RI",
  "SD",
  "UT",
  "VT",
  "WA",
  "WY",
];

function SupervisingPhysicianSelect({ providerState, currentUser, onChange, value, providerID }) {
  const [directors, setDirectors] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (!providerState || providerState.length == 0) {
        setDirectors([]);
        return;
      }
      try {
        //let result = await api.Providers.medicalDirectors(providerState);
        let result = await api.Providers.supervisingPhysician(providerState);
        const validResult = result.items
          ? result.items.filter((item) => item.providerID !== providerID)
          : [];
        setDirectors(validResult);
      } catch (err) {
        bugsnagClient.notify(err);
      }
    };
    fetchData();
  }, [currentUser, providerState]);

  const selected = directors.find((x) => x.providerID === value);

  const onMDSelect = (newValue: any) => {
    if (newValue) {
      onChange({ state: providerState, value: newValue.providerID });
    } else {
      onChange({ state: providerState, value: "" });
    }
  };

  return (
    <div>
      <Select
        id="qa-medicalDirectorMenu"
        value={selected}
        options={directors}
        defaultOptions={true}
        getOptionLabel={getMDLabel}
        getOptionValue={getMDValue}
        onChange={onMDSelect}
        backspaceRemovesValue={false}
        isSearchable={false}
        isClearable
        id="qa-director-dropdown"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
});

SupervisingPhysicianSelect.propTypes = {
  providerState: PropTypes.string,
  currentUser: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  providerID: PropTypes.string,
};

export default connect(mapStateToProps)(SupervisingPhysicianSelect);
