import React, { FC, useEffect } from "react";
import { Label } from "reactstrap";

import BlockSection from "./BlockSection";
import YesNoToggle from "./YesNoToggle";
import InfoSelect from "./InfoSelect";
import ListEditor from "./ListEditor";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { womensHealthHistory } from "../../constants/Encounter";
import { IMedicalProfile } from "../../constants/Types";

const Editor = ({
  profile,
  oldProfile,
  onProfileFieldChange,
  onContraceptionChange,
  onPregnancyChange,
  patientTab,
  ...props
}) => {
  const { timesPregnantOptions, timesDelivered, timeOnOralBC, contraceptionOptions } =
    womensHealthHistory;

  useEffect(() => {
    if (profile.numberOfPregnancies < profile.numberOfDeliveries) {
      onProfileFieldChange("numberOfDeliveries")(0);
    }
  }, [profile]);

  const searchableItemListSource = (text) => {
    let filter = contraceptionOptions.filter(
      (item) => item.toLowerCase().indexOf(text.toLowerCase()) > -1,
    );
    return { items: filter };
  };

  const numberOfDeliveriesOptions = timesDelivered.slice(
    0,
    timesDelivered.indexOf(profile.numberOfPregnancies) + 1,
  );

  return (
    <div>
      <div className={patientTab ? "d-flex mt-2 patient-select" : "d-flex mt-2"}>
        <div className="mr-5">
          <Label> Has been pregnant: </Label>
          <YesNoToggle
            onChange={onPregnancyChange}
            value={profile.hasBeenPregnant}
            oldValue={oldProfile.hasBeenPregnant}
          />
        </div>
        {profile.hasBeenPregnant && (
          <>
            <div className="mr-5">
              <Label>Number of pregnancies:</Label>
              <InfoSelect
                value={profile.numberOfPregnancies}
                options={timesPregnantOptions}
                oldValue={oldProfile.numberOfPregnancies}
                onChange={onProfileFieldChange("numberOfPregnancies")}
              />
            </div>
            <div className="mr-5">
              <Label>Number of deliveries:</Label>
              <InfoSelect
                value={profile.numberOfDeliveries}
                options={numberOfDeliveriesOptions}
                oldValue={oldProfile.numberOfDeliveries}
                onChange={onProfileFieldChange("numberOfDeliveries")}
              />
            </div>
          </>
        )}
      </div>
      <div className="d-flex">
        <div className="mr-5 mt-2">
          <Label>Birth control method:</Label>
          <ListEditor
            items={profile.birthControlMethod}
            oldItems={oldProfile.birthControlMethod}
            itemListSource={searchableItemListSource}
            onChange={onContraceptionChange}
          />
        </div>
      </div>
      {profile.birthControlMethod &&
        profile.birthControlMethod.indexOf("Birth control pills") > -1 && (
          <div className="d-flex">
            <div className="mr-5">
              <Label>How long on birth control pills?</Label>
              <InfoSelect
                value={profile.birthControlDuration}
                options={timeOnOralBC}
                oldValue={oldProfile.birthControlDuration}
                onChange={onProfileFieldChange("birthControlDuration")}
              />
            </div>
          </div>
        )}
    </div>
  );
};

const View = ({ profile }) => {
  return (
    <div>
      <ul>
        <li>Has been pregnant: {profile.hasBeenPregnant ? "Yes" : "No"}</li>
        {profile.hasBeenPregnant && (
          <ul>
            <li>Number of pregnancies: {`${profile.numberOfPregnancies}`}</li>
            <li>Number of deliveries: {`${profile.numberOfDeliveries}`}</li>
          </ul>
        )}
        {profile.birthControlMethod && (
          <li>Birth control method: {profile.birthControlMethod.join(", ")}</li>
        )}
        {profile.birthControlMethod &&
          profile.birthControlMethod.indexOf("Birth control pills") > -1 && (
            <ul>How long on birth control pills? {`${profile.birthControlDuration}`}</ul>
          )}
      </ul>
    </div>
  );
};

type Props = {
  womensHealthProfile: Partial<IMedicalProfile>["womensHealth"];
  oldWomensHealthProfile: Partial<IMedicalProfile>["womensHealth"];
  onProfileChange: (newValue: any) => void;
  isEditing?: boolean;
  patientTab?: string;
};

const WomensHealthHistory: FC<Props> = ({
  womensHealthProfile,
  oldWomensHealthProfile,
  isEditing,
  onProfileChange,
  patientTab,
  readOnly,
}) => {
  const handleProfileFieldChange = (field) => (newValue) => {
    onProfileChange({ ...womensHealthProfile, [field]: newValue });
  };

  const handleContraceptionChange = (newValue) => {
    const indexOfNone = newValue.indexOf("None");
    if (indexOfNone > -1) {
      newValue.splice(indexOfNone, 1);
    }
    if (newValue && newValue.indexOf("Birth control pills") > -1) {
      onProfileChange({ ...womensHealthProfile, birthControlMethod: newValue });
    } else {
      let value = newValue.length ? newValue : ["None"];
      onProfileChange({
        ...womensHealthProfile,
        birthControlMethod: value,
        birthControlDuration: null,
      });
    }
  };

  const handlePregnancyChange = (newValue) => {
    onProfileChange({
      ...womensHealthProfile,
      hasBeenPregnant: newValue,
      numberOfPregnancies: 0,
      numberOfDeliveries: 0,
    });
  };

  let content;
  if (isEditing && !readOnly) {
    content = (
      <Editor
        profile={womensHealthProfile}
        oldProfile={oldWomensHealthProfile}
        onProfileFieldChange={handleProfileFieldChange}
        onContraceptionChange={handleContraceptionChange}
        onPregnancyChange={handlePregnancyChange}
        patientTab={patientTab}
      />
    );
  } else {
    content = <View profile={womensHealthProfile} />;
  }

  return (
    <BlockSection title={patientTab ? "" : "Women's Health History"} bodyClassName="mt-3">
      {content}
    </BlockSection>
  );
};

export default WomensHealthHistory;
