import {
  WAITING_ROOM_AVAILABLE,
  WAITING_ROOM_PENDING,
  WAITING_ROOM_ASSIGNED,
  WAITING_ROOM_CANCEL,
  WAITING_ROOM_LOAD_PENDING,
  WAITING_ROOM_LOAD_AVAILABLE,
  WAITING_ROOM_MESSAGE_CLEAR,
  ENCOUNTER_EJECTED,
  WAITING_ROOM_MESSAGE,
  LOGOUT,
} from "../constants/actionTypes";
import { Reducer } from "redux";

import update from "immutability-helper";

const defaultState = {
  available: [],
  pending: [],
  message: null,
};

const waitingRoomReducer: Reducer<any, any> = (state = defaultState, action) => {
  const e = action.encounter;
  let pendingIndex = -1;
  let aIndex = -1;
  let u = {};

  switch (action.type) {
    case WAITING_ROOM_AVAILABLE:
      aIndex = state.available.findIndex((i) => i.referenceID == e.referenceID);
      if (aIndex >= 0) {
        u = { available: { $splice: [[aIndex, 1, action.encounter]] } };
      } else {
        u = { available: { $push: [e] } };
      }

      pendingIndex = state.pending.findIndex((i) => i.referenceID == e.referenceID);
      if (pendingIndex >= 0) {
        u["pending"] = { $splice: [[pendingIndex, 1]] };
      }
      return update(state, u);
    case WAITING_ROOM_PENDING:
      pendingIndex = state.pending.findIndex((i) => i.referenceID == e.referenceID);
      if (pendingIndex >= 0) {
        return update(state, {
          pending: { $splice: [[pendingIndex, 1, action.encounter]] },
        });
      }
      return update(state, { pending: { $push: [action.encounter] } });
    case WAITING_ROOM_CANCEL:
      pendingIndex = state.pending.findIndex((i) => i.referenceID == e.referenceID);
      aIndex = state.available.findIndex((i) => i.referenceID == e.referenceID);
      u = {};
      if (pendingIndex >= 0) {
        u["pending"] = { $splice: [[pendingIndex, 1]] };
      }
      if (aIndex >= 0) {
        u["available"] = { $splice: [[aIndex, 1]] };
      }
      return update(state, u);
    case WAITING_ROOM_ASSIGNED:
      aIndex = state.available.findIndex((i) => i.referenceID == e.referenceID);
      if (aIndex < 0) return state;
      return update(state, {
        available: { $splice: [[aIndex, 1]] },
      });

    case WAITING_ROOM_LOAD_PENDING:
      return {
        ...state,
        pending: (action.payload || {}).items || [],
        pendingLoaded: true,
      };
    case WAITING_ROOM_LOAD_AVAILABLE:
      return {
        ...state,
        available: (action.payload || {}).items || [],
        availableLoaded: true,
      };
    case WAITING_ROOM_MESSAGE_CLEAR:
      return {
        ...state,
        message: null,
      };
    case WAITING_ROOM_MESSAGE:
      return {
        ...state,
        message: action.message,
        messageAt: new Date().getTime(),
      };
    case ENCOUNTER_EJECTED:
      // also do this when we don't get message
      return {
        ...state,
        message: (action.payload || {}).message || "",
        messageAt: new Date().getTime(),
      };
    case LOGOUT:
      return defaultState;
    default:
      return state;
  }
};

export default waitingRoomReducer;
