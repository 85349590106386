import React, { FC } from "react";
import { Label } from "reactstrap";

import BlockSection from "./BlockSection";
import InfoSelect from "./InfoSelect";
import { allergyHistory } from "../../constants/Encounter";
import { IAllergySocialHistory } from "../../constants/Types";

type Props = {
  socialHistory: IAllergySocialHistory;
  oldSocialHistory: IAllergySocialHistory;
  onAllergyHistoryChange: (value: string) => (value: string | number) => void;
};

const AllergyChildrenHistoryEditor: FC<Props> = ({
  socialHistory,
  oldSocialHistory,
  onAllergyHistoryChange,
}) => {
  return (
    <BlockSection title={"Children"}>
      <div className="form-row">
        <div>
          <Label>Children in daycare?</Label>
          <div className="pl-0 pr-3">
            <InfoSelect
              value={socialHistory.allergyChildrenHistory.numberSchoolChildren!}
              options={allergyHistory.childrenInDaycare}
              oldValue={oldSocialHistory.allergyChildrenHistory.numberSchoolChildren!}
              onChange={onAllergyHistoryChange("numberSchoolChildren")}
            />
          </div>
        </div>
      </div>
    </BlockSection>
  );
};

export default AllergyChildrenHistoryEditor;
