import { Reducer } from "redux";

import { ACTIVITY_SUMMARY, SELECT_PRACTICE } from "../constants/actionTypes";

const defaultState = {
  locationSummaries: {},
  encounters: [],
  selectedPractice: { name: "", id: "", nhFlag: false },
};

const monitoringReducer: Reducer<any, any> = (state = defaultState, action) => {
  const payload = action.payload || {};
  switch (action.type) {
    case SELECT_PRACTICE:
      return {
        ...state,
        selectedPractice: action.practice,
      };
    case ACTIVITY_SUMMARY:
      if (payload.locationSummaries) {
        return { ...state, ...action.payload };
      }
      // this should be true, but checking anyways
      else if (payload.encounters && payload.encounters.length > 0) {
        return { ...state, encounters: [...state.encounters, payload.encounters[0]] };
      }
      return state;
    default:
      return state;
  }
};

export default monitoringReducer;
