import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "reactstrap";
import moment from "moment";
import PatientNoteIcon from "../images/PatientNoteIcon.svg";
import CheckMark from "../images/CheckMarkWhite.svg";
import AssignFax from "./Provider/AssignFax";
import { FAX_VIEW_MODE_ASSIGN, FAX_VIEW_MODE_COMPLETE } from "../constants/Fax";
import CompleteFax from "./Provider/CompleteFax";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
export const FaxRow = ({ item, onView }) => {
  const fd = moment(item.receivedAt);
  const completed = item.complete;
  const assigned = item.assigned;
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
  const onClick = (url, faxID) => () => {
    onView(url, faxID);
  };
  const buttonClass = completed || assigned ? "completed-button" : "dashboard-button";
  const completedText = completed ? "Completed" : "Forwarded";
  const isButton = !assigned && !completed;

  return (
    <tr>
      <td>{fd.format("MM/DD/YY h:mma")}</td>
      <td className="button-cell">
        {!isButton && (
          <div className="d-flex justify-content-end">
            <div className="completed-button">
              {completedText}
              <CheckMark />
            </div>
          </div>
        )}
        {isButton && (
          <Button
            className={`${buttonClass} qa-faxesToForwardButton`}
            onClick={onClick(item.contentURL, item.faxID)}
          >
            <PatientNoteIcon />
          </Button>
        )}
      </td>
    </tr>
  );
};

FaxRow.propTypes = {
  item: PropTypes.shape({
    receivedAt: PropTypes.string,
    complete: PropTypes.bool,
    assigned: PropTypes.bool,
    contentURL: PropTypes.string,
    faxID: PropTypes.object,
  }),
  onView: PropTypes.func,
};

class FaxList extends Component {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props);
    this.state = { viewOpen: false };
    this.onView = this.onView.bind(this);
    this.onCloseView = this.onCloseView.bind(this);
  }

  onView(url, faxID) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
    this.setState({ viewOpen: true, url, faxID });
  }

  onCloseView() {
    this.setState({ viewOpen: false });
  }

  render() {
    const { items } = this.props;
    if (!items || items.length === 0) {
      return <div className="fax-list-empty">You're all caught up!</div>;
    }
    return (
      <div className="fax-list-container">
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'viewMode' does not exist on type 'Readon... Remove this comment to see the full error message */}
        {this.props.viewMode === FAX_VIEW_MODE_ASSIGN && this.state.viewOpen && (
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'viewOpen' does not exist on type 'Readon... Remove this comment to see the full error message
          <AssignFax url={this.state.url} faxID={this.state.faxID} onClose={this.onCloseView} />
        )}
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'viewMode' does not exist on type 'Readon... Remove this comment to see the full error message */}
        {this.props.viewMode === FAX_VIEW_MODE_COMPLETE && this.state.viewOpen && (
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'viewOpen' does not exist on type 'Readon... Remove this comment to see the full error message
          <CompleteFax url={this.state.url} faxID={this.state.faxID} onClose={this.onCloseView} />
        )}
        <Table>
          <tbody>
            {items.map((v, idx) => (
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
              <FaxRow item={v} key={idx} onView={this.onView} />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

FaxList.propTypes = {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
  items: PropTypes.array,
  viewMode: PropTypes.string,
};

export default FaxList;
