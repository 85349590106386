import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import ButtonSpinner from "./ButtonSpinner";

const SubmitButton = (props) => {
  return (
    <Button disabled={props.inProgress} color="primary" id="submitBtn">
      <ButtonSpinner inProgress={props.inProgress} />
      {props.children}
    </Button>
  );
};

SubmitButton.propTypes = {
  inProgress: PropTypes.bool,
  children: PropTypes.string,
};

export default SubmitButton;
