import React, { FC } from "react";
import { Label } from "reactstrap";

import BlockSection from "./BlockSection";

type IOtherIntake = {
  question: string;
  answer: string;
};

const OtherIntakeGeneral: FC<{ otherIntake?: IOtherIntake[] }> = ({ otherIntake = [] }) => {
  return otherIntake.length > 0 ? (
    <div className="info-edit-wrapper content-block">
      <BlockSection title="Additional Information">
        {otherIntake.map(({ question, answer }, index) => {
          return (
            <div key={index}>
              <Label className={"mr-2 mt-2"}>{question}</Label>
              <span>{answer}</span>
            </div>
          );
        })}
      </BlockSection>
    </div>
  ) : null;
};

export default OtherIntakeGeneral;
