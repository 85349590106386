import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { version } from "../../package.json";

export default Bugsnag.start({
  apiKey: __BUGSNAG_API_KEY__ || "",
  appVersion: `${version}.${__COMMIT_HASH__}` || "",
  releaseStage: APP_ENV,
  enabledReleaseStages: ["prod", "stage", "develop", "dev"], // ignore errors from "unspecified" (i.e. local development) builds
  plugins: [new BugsnagPluginReact()],
});
