import React, { FC } from "react";
import { Button } from "reactstrap";

type Props = {
  visible: boolean;
  onDecline: () => void;
  onAccept: () => void;
};
const ConsentPopUp: FC<Props> = ({ visible, onDecline, onAccept, children }) => {
  if (!visible) {
    // popup not visible
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="consent-pop-up ml-auto mr-auto">
        <h4 className="mb-2 mt-4">Consent to Treat Needed</h4>
        <div className="w-75 center mb-3">
          <p>
            Please indicate if this patient has consented to services from Reliant before
            continuing. This consent can always be updated from the patient chart
          </p>
        </div>
        <div className="d-flex justify-content-around mb-4">
          <Button color="primary" onClick={onAccept}>
            Consent to Treat
          </Button>
          <Button outline color="danger" onClick={onDecline}>
            No Consent
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ConsentPopUp;
