import React, { FC } from "react";

type Props = {
  visible: boolean;
  styles: any;
  coordinate: { x: string | number; y: string | number };
};
const OnHoverTitle: FC<Props> = ({ visible, styles, coordinate, children }) => {
  if (!visible) {
    // popup not visible
    return null;
  }
  return (
    <div
      className={`${visible && styles}`}
      style={{
        top: coordinate.y,
        left: coordinate.x,
      }}
    >
      {children}
    </div>
  );
};
export default OnHoverTitle;
