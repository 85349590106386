import React, { Component, Dispatch } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, FormGroup, Input } from "reactstrap";

import api from "../../api";
import CenteredForm from "../CenteredForm";
import SubmitButton from "../SubmitButton";
import ErrorList from "../ErrorList";
import FlashMessage from "../FlashMessage";

import { AUTHENTICATE, TOKEN_LOAD } from "../../constants/actionTypes";
import { IAppState } from "../../reducer";

const mapStateToProps = (state: IAppState) => {
  return {
    inProgress: state.progress[AUTHENTICATE] || state.progress[TOKEN_LOAD],
    errors: state.common.errors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onSubmit: (email: string, password: string) =>
    dispatch({
      type: AUTHENTICATE,
      payload: api.Authorization.login(email, password),
    }),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type State = {
  email: string;
  password: string;
};

class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
    });
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value,
    });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.onSubmit(this.state.email, this.state.password);
  };

  render() {
    return (
      <CenteredForm>
        <div>
          <h4> Sign in to your account!</h4>
          <Form className="form-signin" onSubmit={this.handleSubmit}>
            <FlashMessage />
            <FormGroup>
              <Input
                type="email"
                name="email"
                value={this.state.email}
                placeholder="provider@gmail.com"
                disabled={this.props.inProgress}
                onChange={this.handleEmailChange}
                id="loginEmail"
              />
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="********"
                type="password"
                name="password"
                value={this.state.password}
                disabled={this.props.inProgress}
                onChange={this.handlePasswordChange}
                id="loginPassword"
              />
            </FormGroup>
            <ErrorList errors={this.props.errors} />
            <SubmitButton inProgress={this.props.inProgress}>Sign in</SubmitButton>
          </Form>
          <div className="reset-container">
            <Link className="reset-link" to="/request-password-reset">
              Forgot your password?
            </Link>
          </div>
        </div>
      </CenteredForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
