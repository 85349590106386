import React, { FC } from "react";

import PlusIcon from "../../images/PlusIcon.svg";

type Props = {
  className?: string;
  title: string;
  render?: Function;
  children?: React.ReactNode;
  titleOnly?: boolean;
  onClick?: () => void;
};

const ContentBlock: FC<Props> = (props: Props) => {
  const cn = props.className || "";

  if (props.titleOnly) {
    return (
      <div className={`content-block ${cn}`}>
        <span className="icon">{props.titleOnly && <PlusIcon onClick={props.onClick} />}</span>
        <div className={`d-flex title`} onClick={props.onClick}>
          <h1>{props.title}</h1>
        </div>
        {<div className="info-details">{props.children}</div>}
      </div>
    );
  }
  return (
    <div className={`content-block ${cn}`}>
      <div className={`d-flex`}>
        <h1 className="block-title">{props.title}</h1>
        {props.render && props.render()}
      </div>
      {<div className="info-details">{props.children}</div>}
    </div>
  );
};

export default ContentBlock;
