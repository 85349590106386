import React, { FC } from "react";
import {
  ICatalogItem,
  IReviewOfSystems,
  ISymptomsDetail,
  ISymptomsDetailDetail,
  IUserReviewOfSystemsKeys,
  UserSymptomDetailsKeys,
} from "../../constants/Types";

import BlockSection from "./BlockSection";
import PertinentNegatives from "./PertinentNegatives";

type SymptomAnswersProps = {
  details: ISymptomsDetailDetail;
  item: Partial<ICatalogItem>;
};

const SymptomAnswers: FC<SymptomAnswersProps> = ({ details, item }) => {
  if (!details.answer || !item.details) {
    return null;
  }

  let catalogDetails = item.details.find((d) => d.key === details.key);

  if (!catalogDetails) {
    return null;
  }

  let answerString = details.answer.map(function (a, idx) {
    let name = a;
    let option = (catalogDetails?.options || []).find((o) => String(o.key) == a) || {
      label: name,
    };
    name = option.label;

    return name;
  });
  return (
    <li key={catalogDetails.key}>
      {catalogDetails.question} {answerString.join(", ")}
    </li>
  );
};

type SymptomProps = {
  detail?: ISymptomsDetail | null;
  order: {
    systemKey: number;
    systemName: string;
    items: ICatalogItem[];
  };
};

const Symptom: FC<SymptomProps> = ({ detail, order }) => {
  const details = detail?.details || [];
  let item =
    order.items.find((i) => i.key === detail?.key) || ({ details: [] } as Partial<ICatalogItem>);
  return (
    <div>
      <div className="d-flex">
        <div className="flex-grow-1"> {item.label}</div>
      </div>
      <div>
        <ul>
          {details.map(function (d, idx) {
            return <SymptomAnswers key={idx} details={d} item={item} />;
          })}
        </ul>
      </div>
    </div>
  );
};

type SystemViewProps = {
  order: {
    systemKey: number;
    systemName: string;
    items: ICatalogItem[];
  };
  system?: number[];
  pertinentNegatives?: number[];
  details: ISymptomsDetail[] | null;
  catalog: Partial<IReviewOfSystems>;
  title: string;
  perSystemTitles: boolean;
  visitType?: string;
};

const SystemView: FC<SystemViewProps> = ({
  order,
  system,
  pertinentNegatives,
  details,
  catalog,
  title,
  perSystemTitles,
  visitType,
}) => {
  let relevantDetails: (ISymptomsDetail | null | undefined)[] = [];
  if (system && details) {
    relevantDetails = order.items.map((item) => {
      if (system.find((i) => i == item.key)) {
        return details.find((d) => d.key == item.key);
      }
      return null;
    });
  }
  relevantDetails = relevantDetails.filter((i) => i);

  if (!perSystemTitles) {
    return (
      <>
        {relevantDetails.map((k, index) => {
          return <Symptom detail={k} order={order} key={k?.key} />;
        })}
      </>
    );
  } else {
    return (
      <BlockSection title={title + " - " + order.systemName}>
        <h1 className="ros-subheader">Patient Reports</h1>
        {relevantDetails.length > 0 ? (
          relevantDetails.map(function (k, index) {
            return (
              <div key={index}>
                <Symptom detail={k} order={order} key={k?.key} />
              </div>
            );
          })
        ) : (
          <div>None</div>
        )}
        {visitType !== "nursing_home" && (
          <div>
            <h1 className="ros-subheader">Patient Denies</h1>
            <PertinentNegatives
              order={order}
              system={pertinentNegatives}
              catalog={catalog}
              perSystemTitles={perSystemTitles}
            />
          </div>
        )}
      </BlockSection>
    );
  }
};

type Props = {
  ros: IUserReviewOfSystemsKeys;
  itemOrder: {
    systemKey: number;
    systemName: string;
    items: ICatalogItem[];
  }[];
  details: UserSymptomDetailsKeys;
  catalog: Partial<IReviewOfSystems>;
  title: string;
  perSystemTitles: boolean;
  pertinentNegatives?: number[];
  visitType?: string;
};

const CurrentSymptomsView: FC<Props> = ({
  ros,
  itemOrder,
  details,
  catalog,
  title,
  perSystemTitles,
  pertinentNegatives,
  visitType,
}) => {
  const systems = (ros || {}).systems;
  const d = details || { symptoms: [] };
  if (perSystemTitles) {
    return (
      <div>
        {itemOrder.map(function (k, index) {
          return (
            <div key={index}>
              <SystemView
                order={k}
                system={systems && systems[k.systemKey]}
                details={d.symptoms}
                catalog={catalog}
                title={title}
                perSystemTitles={perSystemTitles}
                pertinentNegatives={pertinentNegatives}
                visitType={visitType}
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    if (!systems || !d.symptoms || d.symptoms.length == 0) {
      return <BlockSection title={title}>No symptoms given</BlockSection>;
    }
    return (
      <BlockSection title={title}>
        <BlockSection title={"Patient Reports"}>
          {itemOrder.map(function (k, index) {
            return (
              <div key={index}>
                <SystemView
                  order={k}
                  system={systems[k.systemKey]}
                  details={d.symptoms}
                  catalog={catalog}
                  title={title}
                  perSystemTitles={perSystemTitles}
                />
              </div>
            );
          })}
        </BlockSection>
        <BlockSection title={"Patient Denies"}>
          {itemOrder.map(function (k, index) {
            return (
              <div key={index}>
                <PertinentNegatives
                  order={k}
                  system={pertinentNegatives}
                  catalog={catalog}
                  perSystemTitles={perSystemTitles}
                />
              </div>
            );
          })}
        </BlockSection>
      </BlockSection>
    );
  }
};

export default CurrentSymptomsView;
