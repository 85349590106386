import React, { FC } from "react";
import Select from "react-select";

type Props = {
  oldValue?: string | number | null;
  onChange: (value: string | number) => void;
  value: string | number | null;
  options: string[] | number[];
};
type select = {
  value: number | string;
  label: number | string;
};

const stringToSelectItem = (s: string | number | null) => {
  return { value: s, label: s };
};
const selectItemToString = (so: select) => {
  return so.label;
};
const EmptyComponent = () => {
  return null;
};

const InfoSelect: FC<Props> = (props) => {
  const options = props.options.map(stringToSelectItem);
  const selectChanged = (newItem) => {
    const v = selectItemToString(newItem);
    if (props.onChange) {
      props.onChange(v);
    }
  };
  let cl = "info-select";
  if (props.value !== props.oldValue) {
    cl = cl + " edited";
  }

  return (
    <Select
      value={stringToSelectItem(props.value)}
      components={{ IndicatorSeparator: EmptyComponent }}
      onChange={selectChanged}
      isClearable={false}
      hideSelectedOptions={true}
      className={cl}
      classNamePrefix="info-select"
      options={options}
    />
  );
};

export default InfoSelect;
