import update from "immutability-helper";

// Two cases, one is yes and no toggle. One sets value. One adds or removes values
export function featureAddAttribute(feature, attribute) {
  const u = {};
  if (!feature.attributes.includes(attribute)) {
    u["attributes"] = { $push: [attribute] };
  }
  const idx = feature.negativeAttributes.findIndex((a) => a === attribute);
  if (idx >= 0) {
    u["negativeAttributes"] = { $splice: [[idx, 1]] };
  }
  return update(feature, u);
}

export function featureRemoveAttribute(feature, attribute) {
  const u = {};
  if (!feature.negativeAttributes.includes(attribute)) {
    u["negativeAttributes"] = { $push: [attribute] };
  }
  const idx = feature.attributes.findIndex((a) => a === attribute);
  if (idx >= 0) {
    u["attributes"] = { $splice: [[idx, 1]] };
  }
  return update(feature, u);
}

export function featureSetAttributes({ onChange }) {
  return (feature, attributes) => {
    const nt = update(feature, { attributes: { $set: attributes } });
    onChange(nt);
  };
}

export function featureSetNegativeAttributes({ onChange }) {
  return (feature, attributes) => {
    const nt = update(feature, { negativeAttributes: { $set: attributes } });
    onChange(nt);
  };
}

export function featureSetNotes({ onChange, feature }) {
  return (newValue) => {
    const nt = update(feature, { notes: { $set: newValue } });
    onChange(nt);
  };
}

export function findFeature(features, key, feature, group) {
  const foundIdx = (features || []).findIndex((e) => e.key === key);

  if (foundIdx < 0) {
    return {
      group: group,
      attributes: [],
      key: key,
      feature: feature,
      negativeAttributes: [],
      reportedByNurse: false,
      unableToAssess: false,
      notes: "",
    };
  }

  return {
    ...features[foundIdx],
    attributes: features[foundIdx].attributes || [],
    negativeAttributes: features[foundIdx].negativeAttributes || [],
    reportedByNurse: features[foundIdx].reportedByNurse || false,
    unableToAssess: features[foundIdx].unableToAssess || false,
  };
}
