import React, { FC } from "react";
import CatalogSelect from "./CatalogSelect";
import PillList from "./PillList";

type Props = {
  items?: string[] | null;
  oldItems: string[];
  itemListSource: (text: any) => { items: string[] };
  onChange: (keyValues: any, actionType?: any) => void;
  onRemove?: (keyValues: any, actionType: any) => void;
  patientTab?: boolean;
  userID?: string;
};

const ListEditor: FC<Props> = (props) => {
  const onRemove = (item: string) => {
    props.onRemove
      ? props.onRemove(
          props.items!.filter((i) => i !== item),
          item,
        )
      : props.onChange(props.items!.filter((i) => i !== item));
  };
  return (
    <div className={props.patientTab ? "d-flex patient-select" : "d-flex"}>
      <div className="list-editor-select-container">
        <CatalogSelect
          items={props.items}
          handleSelectChange={props.onChange}
          userID={props.userID}
          itemListSource={props.itemListSource}
          placeholder="Type to search / add..."
        />
      </div>
      <div className="list-editor-pill-container">
        <PillList items={props.items} oldItems={props.oldItems} onRemove={onRemove} />
      </div>
    </div>
  );
};

export default ListEditor;
