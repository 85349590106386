import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import PropTypes from "prop-types";

import styles from "./AddendumEditor.scss";
import api from "../../api";

const AddendumEditor = ({ onCancel, onSave, encounterKey }) => {
  const [content, setContent] = useState("");

  const onSaveClick = async () => {
    try {
      await api.NoteAddendum.create(encounterKey, content);
    } finally {
      onSave();
    }
  };

  return (
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    <div className="w-100 d-flex flex-column align-items-center">
      <Input
        value={content}
        onChange={(e) => setContent(e.target.value)}
        className={styles.editorTextArea}
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
        type="textarea"
        name="text"
      />
      <div className="d-flex">
        <Button color="link" className="mr-4" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={onSaveClick}>
          Submit addendum
        </Button>
      </div>
    </div>
  );
};

AddendumEditor.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  encounterKey: PropTypes.string,
};

export default AddendumEditor;
