import React from "react";

// @ts-expect-error ts-migrate(2552) FIXME: Cannot find name 'APP_ENV'. Did you mean 'appEnv'?
const appEnv = process.env.APP_ENV || APP_ENV|| "unspecified";

export default ({}) => {
  if (appEnv === "prod") {
    return null;
  }
  return (
    <div className="env-banner">
      This environment ({appEnv}) is for testing and development purposes only.{" "}
      <strong>No PHI allowed!</strong>
    </div>
  );
};
