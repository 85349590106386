import React, { FC } from "react";
import { Button } from "reactstrap";
import Warning from "../../images/WarningTriangle.svg";

type Props = {
  visible: boolean,
  onCancel: () => void,
  onDelete: () => void,
}
const PostponeModal: FC<Props> = ({ visible, onCancel, onDelete, children }) => {
  if (!visible) {
    // popup not visible
    return null;
  }
  return (
    <div className="modal-overlay">
      <div className="ml-auto mr-auto center-modal p-3 d-flex flex-column h-auto mt-5">
        <Button
          className="align-self-end"
          size="sm"
          color="link"
          onClick={onCancel}
          close={true}
        />
        <div className="justify-content-center align-items-center flex-column d-flex">
          <Warning />
          <h2 className="text-center w-75 mr-5 ml-5">Remove patient from waiting room and postpone visit?</h2>
        </div>
        <div className="pl-4 pt-4 pr-4 d-flex">
          <p className="text-center bold-text mr-5 ml-5">This can not be undone. The patient will need to restart their visit to re-enter the waiting room.</p>
        </div>
        <div className="justify-content-around d-flex mr-5 ml-5 pb-3 mt-5">
          <div className="w-75 d-flex justify-content-around">
            <Button color="link" onClick={onCancel}>Cancel</Button>
            <Button color="danger" className="pl-4 pr-4" onClick={onDelete}>Remove</Button>
          </div>
        </div>
      </div>
    </div>
  );

};
export default PostponeModal;
