import React from "react";
import { connect } from "react-redux";
import { UncontrolledAlert } from "reactstrap";

const mapStateToProps = (state, ownProps) => {
  return { message: state.flash.message };
};

const mapDispatchToProps = (dispatch) => ({});

class FlashMessage extends React.Component {
  render() {
    const message = this.props.message;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (message) {
      return <UncontrolledAlert color="info">{message}</UncontrolledAlert>;
    } else {
      return null;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FlashMessage);
