import React, { FC } from "react";
import { connect } from "react-redux";
import Octicon, { ChevronRight } from "@githubprimer/octicons-react";
import {
  Table,
  Button,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";

import { store } from "../../store";
import { NAVIGATE_TO } from "../../constants/actionTypes";
import { STATES } from "../../constants/Providers";
import AuditInfo from "./AuditInfo";
import {
  hasPermission,
  PERMISSION_MANAGE,
  PERMISSION_MEDICAL_DIRECTOR,
  PERMISSION_SUPERVISING_PHYSICIAN,
} from "../../constants/Permissions";
import { IAuditProviderListItem, ICurrentUser } from "../../constants/Types";

const EmptyComponent = () => null;

const activeChoices = [
  { value: "active", label: "Active" },
  { value: "disabled", label: "Disabled" },
];

const statusFilterOptions = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
];

const stateChoices = Object.keys(STATES).map((state) => `${state} - ${STATES[state]}`);

export const calculatePercentage = (audited, finished) => {
  if (!audited && !finished) {
    return 0;
  } else {
    return Math.floor((audited / finished) * 100);
  }
};

type ProviderItemProps = {
  provider: IAuditProviderListItem;
  currentUser: ICurrentUser;
  onChange: Function;
  index: number;
  isDirector: boolean;
  seesAuditProgress: boolean;
  hasEdit: boolean;
};

const ProviderItem: FC<ProviderItemProps> = ({
  provider,
  currentUser,
  onChange,
  index,
  isDirector,
  seesAuditProgress,
  hasEdit,
}) => {
  const canManage = currentUser && hasPermission(currentUser.permissions, PERMISSION_MANAGE);
  const hasSupervisor = !!provider?.supervisingPhysicians;

  const activeChange = (id: string) => (newValue: any) => {
    onChange(id, index, newValue.value === "active");
  };
  const edit = (id: string) => () => {
    store.dispatch({ type: NAVIGATE_TO, targetPath: `/providers/edit/${id}` });
  };
  const audit = (id: string) => () => {
    store.dispatch({ type: NAVIGATE_TO, targetPath: `/providers/audit/${id}` });
  };

  const active = activeChoices.find((i) => i.value === provider.active);
  const location = provider.capabilities.geographic
    ? provider.capabilities.geographic.join(", ")
    : provider.state;

  // A supervising physician should only see the “audit” button for providers assigned to them
  // medical director can audit any provider
  const currentUserId = currentUser?.providerID || "";

  let canAuditProvider = false;
  if (provider.supervisingPhysicians) {
    canAuditProvider = Object.values(provider.supervisingPhysicians).reduce((accumulator, id) => {
      return accumulator || id === currentUserId;
    }, false as boolean);
  }

  const showAuditButton = (isDirector || canAuditProvider) && provider?.auditStatistics;
  return (
    <Row>
      <Col md={seesAuditProgress ? "2" : "3"}>
        {" "}
        {provider.prefix} {provider.firstName} {provider.lastName} {provider.suffix}{" "}
      </Col>
      <Col md={seesAuditProgress ? "2" : "3"}> {location} </Col>
      <Col md="2">{provider.phone} </Col>
      <Col md="2">
        <div>
          {canManage ? (
            <Select
              isSearchable={false}
              value={active}
              components={{ IndicatorSeparator: EmptyComponent }}
              onChange={activeChange(provider.id)}
              options={activeChoices}
              classNamePrefix="td-select"
              className="td-select w-50 qa-provider-status-drp"
              id={`qa-providerItem-select-${index}`}
            />
          ) : (
            active && <div className="mt-1"> {active.value} </div>
          )}
        </div>
      </Col>
      {seesAuditProgress && hasSupervisor ? (
        <Col className="col-md-2 audit-progress">
          {provider.auditStatistics && provider.capabilities.geographic ? (
            <div className="d-flex flex-wrap">
              {provider.capabilities.geographic.map((state, index) => {
                const stateAudited = provider.auditStatistics.stateAudited || {};
                const stateFinished = provider.auditStatistics.stateFinished || {};
                const visitsAudited = stateAudited[state] ? stateAudited[state] : 0;
                const visitsFinished = stateFinished[state] ? stateFinished[state] : 0;

                // only show audit percentage for states where a provider has a supervising physician assigned to that state
                const show =
                  !!provider.supervisingPhysicians &&
                  provider.supervisingPhysicians[state] !== undefined &&
                  (provider.supervisingPhysicians[state] === currentUserId ||
                    (currentUser.permissions.includes(PERMISSION_MEDICAL_DIRECTOR) &&
                      currentUser.capabilities.geographic.includes(state)));

                return show ? (
                  <AuditInfo
                    className="mt-1 audit-percent"
                    threshold={20}
                    percentage={calculatePercentage(visitsAudited, visitsFinished)}
                    key={`audit-${index}`}
                  >
                    <div>
                      <div className="audit-state">{STATES[state]}</div>
                      <div>{calculatePercentage(visitsAudited, visitsFinished)}%</div>
                    </div>
                  </AuditInfo>
                ) : null;
              })}
            </div>
          ) : (
            "N/A"
          )}
        </Col>
      ) : (
        <Col className="col-md-2 audit-progress">N/A</Col>
      )}
      <Col md="2">
        {hasEdit && (
          <Button color="link" size="sm" onClick={edit(provider.id)} className="qa-providerEdit">
            Edit
          </Button>
        )}
        {showAuditButton && (
          <Button
            color="primary"
            disabled={provider.id === currentUser.providerID}
            className="audit-button qa-providerAuditButton"
            onClick={audit(provider.id)}
          >
            Audit
            <Octicon size="small" icon={ChevronRight} className="chevron" />
          </Button>
        )}
      </Col>
    </Row>
  );
};

type Props = {
  items: IAuditProviderListItem[];
  permissions: string[];
  currentUser: ICurrentUser;
  onProviderStateChange: Function;
  dropSearch: Function;
  allProviders: IAuditProviderListItem[];
  filters: { name: string; location: string; status: string };
};

const List: FC<Props> = ({
  items,
  permissions,
  currentUser,
  onProviderStateChange,
  dropSearch,
  allProviders,
  filters,
}) => {
  const providers = items || [];

  const theProviders = allProviders || [];
  const hasEdit = hasPermission(permissions, PERMISSION_MANAGE);
  const seesAuditProgress =
    hasPermission(permissions, PERMISSION_MEDICAL_DIRECTOR) ||
    hasPermission(permissions, PERMISSION_SUPERVISING_PHYSICIAN) ||
    hasPermission(permissions, PERMISSION_MANAGE);
  const isDirector = hasPermission(permissions, PERMISSION_MEDICAL_DIRECTOR);

  return (
    <Table className="provider-list">
      <thead>
        <Row className="provider-header">
          <Col md={seesAuditProgress ? "2" : "3"}>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" caret className="qa-providerHeaderSortProvider">
                Provider
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: "400px", overflow: "scroll" }}>
                {theProviders.map((name, idx) => (
                  <DropdownItem
                    tag="a"
                    key={`provider-${idx}`}
                    onClick={(e) => dropSearch("name", e.target.text)}
                    style={{ color: "rgb(68, 68, 68)", fontSize: "12px" }}
                  >
                    {name.fullName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col md={seesAuditProgress ? "2" : "3"}>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" caret className="qa-providerHeaderSortLocation">
                Location
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: "400px", overflow: "scroll" }}>
                {stateChoices.map((states, idx) => (
                  <DropdownItem
                    tag="a"
                    key={`state-${idx}`}
                    onClick={(e) => dropSearch("location", e.target.text)}
                    style={{ color: "rgb(68, 68, 68)", fontSize: "12px" }}
                  >
                    {states}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col md="2">
            <span className="qa-providerHeaderPhone">Phone</span>
          </Col>
          <Col md="2">
            <UncontrolledDropdown>
              <DropdownToggle tag="a" caret className="qa-providerHeaderStatus">
                Status
              </DropdownToggle>
              <DropdownMenu>
                {statusFilterOptions.map((status, idx) => (
                  <DropdownItem
                    tag="a"
                    key={`status-${idx}`}
                    active={filters.status === status.label}
                    onClick={(e) => dropSearch("status", e.target.text)}
                    style={{ color: "rgb(68, 68, 68)", fontSize: "12px" }}
                    id="qa-status-drp-item"
                  >
                    {status.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          {seesAuditProgress && (
            <Col md="2">
              <span className="qa-providerHeaderAuditProgress">Audit progress this month</span>
            </Col>
          )}
          <Col md="2"></Col>
        </Row>
      </thead>
      <tbody>
        {providers.map((provider, idx) => (
          <ProviderItem
            currentUser={currentUser}
            hasEdit={hasEdit}
            isDirector={isDirector}
            seesAuditProgress={seesAuditProgress}
            key={`item-${idx}`}
            index={idx}
            provider={provider}
            onChange={onProviderStateChange}
          />
        ))}
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.common.permissions,
  currentUser: state.common.currentUser,
});

export default connect(mapStateToProps, {})(List);
