import React, { FC, useEffect, useState } from "react";
import moment from "moment-timezone";
import { Input, Row, Col, Button, ButtonGroup } from "reactstrap";
import debounce from "lodash.debounce";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import "./DatePicker.scss";

type DateAndTimeProps = {
  onChange: (date: {
    date: string;
    hours: string;
    mins: string;
    zone: string;
    amPM: string;
  }) => void;
  time: { hours: string; mins: string; zone: string; amPM: string };
  setTime: (time: { hours: string; mins: string; zone: string; amPM: string }) => void;
  onInputChange: (field: string) => (e: any) => void;
  handleDateChange: any;
  effectiveDate: string;
  startDate: string;
  handleDateInputChange: (d: string) => void;
};

const DateAndTime: FC<DateAndTimeProps> = ({
  onChange,
  time,
  setTime,
  onInputChange,
  handleDateChange,
  effectiveDate,
  startDate,
  handleDateInputChange,
}) => {
  const [date, setDate] = useState({ month: "", day: "", year: "" });
  const [calendarDate, setCalendarDate] = useState({});
  const [error, setError] = useState(false);
  const [ampm, setAmPm] = useState("");

  const debounceCallback = debounce((func, d) => {
    let newMo = d.month.length === 1 ? 0 + d.month : d.month;
    let newDy = d.day.length === 1 ? 0 + d.day : d.day;
    let newYr = d.year.length === 1 ? 0 + d.year : d.year;
    const newDate = `${newMo}/${newDy}/20${newYr}`;
    setDate({ ...date, day: newDy, month: newMo, year: newYr });
    if (moment(newDate).isValid()) {
      !!func && func(newDate);
      setError(false);
    } else {
      setError(true);
    }
  }, 600);

  useEffect(() => {
    const effectiveMoment = !!effectiveDate ? moment.parseZone(effectiveDate) : {};
    setCalendarDate(effectiveMoment);

    if (!!startDate) {
      const [month, day, year] = startDate.split("/");
      let shortYear = year.slice(-2);
      setDate({ ...date, day: day, month: month, year: shortYear });
    }

    if (!!time) {
      const amPM = moment.parseZone(effectiveDate).format("A");
      setAmPm(amPM);
    }
  }, [startDate, effectiveDate]);

  const dateChange = (field: string) => (e: any) => {
    let fieldIndex = e.target.name || 0;
    fieldIndex = Number(fieldIndex);
    // format to fit
    let value = e.target.value;

    // value = ("00" + value).slice(-2);

    if (value.length === e.target.maxLength) {
      if (fieldIndex < 3) {
        const nextfield = document.querySelector(
          `input[name='${fieldIndex + 1}']`,
        ) as HTMLInputElement;

        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }

    const d = { ...date, [field]: value };
    setDate(d);
    debounceCallback(handleDateInputChange, d);
  };

  const amPmBtnSelect = (e: any) => {
    const u = { ...time, date: startDate, amPM: e.target.value };
    setAmPm(e.target.value);
    setTime(u);
    onChange(u);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <>
      <div className="nh_date-time">
        {/*         Date Inputs         */}
        <div className="nh-date">
          <Input
            onChange={dateChange("month")}
            value={date.month}
            className={`nh-mon ${!!error && "error"}`}
            name="1"
            id="mon"
            maxLength={2}
            // type="number"
            onFocus={handleFocus}
            selectTextOnFocus={true}
          />
          <span className="sep">/</span>
          <Input
            onChange={dateChange("day")}
            value={date.day}
            className={`nh-day ${!!error && "error"}`}
            name="2"
            id="day"
            maxLength={2}
            // type="number"
            onFocus={handleFocus}
            selectTextOnFocus={true}
          />
          <span className="sep">/</span>
          <Input
            onChange={dateChange("year")}
            value={date.year}
            className={`nh-year ${!!error && "error"}`}
            name="3"
            id="year"
            placeholder="00"
            maxLength={2}
            // type="number"
            onFocus={handleFocus}
            selectTextOnFocus={true}
          />
        </div>

        <div className="nh-time">
          <Input
            onChange={onInputChange("hours")}
            value={time.hours}
            className="nh-hours"
            id="nh-hours"
            placeholder="H"
          />
          <span className="nh-sep">:</span>
          <Input
            onChange={onInputChange("mins")}
            value={time.mins}
            className="nh-mins"
            id="nh-mins"
            placeholder="MM"
          />
          <ButtonGroup className="amPm-btnGroup">
            <Button
              onClick={(e) => amPmBtnSelect(e)}
              id="amPM"
              className={`amPm ${ampm === "AM" ? "selected" : ""}`}
              value="AM"
            >
              AM
            </Button>
            <Button
              onClick={(e) => amPmBtnSelect(e)}
              id="amPM"
              className={`amPm ${ampm === "PM" ? "selected" : ""}`}
              value="PM"
            >
              PM
            </Button>
          </ButtonGroup>
          <span className="timeSpan">Time and date in provider local time</span>
        </div>
      </div>
      <div className="nh-CalendarSelect">
        {/* calendar */}
        <DayPickerSingleDateController
          date={moment(calendarDate)}
          focused={true}
          onFocusChange={() => {}}
          // initialVisibleMonth={() => (!effectiveDate ? setCalendarDate(calendarDate) : moment())}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel={true}
          daySize={39}
          horizontalMonthPadding={0}
          verticalHeight={200}
          transitionDuration={0}
          onDateChange={handleDateChange}
          isDayHighlighted={(day) =>
            moment(day).format("MM/DD/YYYY") === moment(calendarDate).format("MM/DD/YYYY")
              ? true
              : false
          }
          enableOutsideDays={true}
        />
      </div>
    </>
  );
};

export default DateAndTime;
