import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import "./unsigned-note-modal.scss";

const UnsignedNoteModal = ({ onSubmit, getList }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    makeList();
  }, {});

  const makeList = async () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
    const { oneDay, twoDays, overdue } = await getList;
    const item = [];

    if (oneDay) {
      item.push(...oneDay);
    }
    if (twoDays) {
      item.push(...twoDays);
    }
    if (overdue) {
      item.push(...overdue);
    }
    return setList(item);
  };

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
  const listOfNames = list.map((name) => name.userName);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'userName' does not exist on type 'never'... Remove this comment to see the full error message
  const nonRepNames = Array.from(new Set(listOfNames)).join(", ");
  const keys = list.map((group) => group.GroupSort);

  return (
    <>
      {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'GroupSort' does not exist on type 'never... Remove this comment to see the full error message */}
      <div className="modal-overlay">
        <div className="ml-auto mr-auto unsigned-notes-modal">
          <h4>You Have Overdue Patient Notes</h4>
          <Row>
            <Col>
              <p>
                {"You have patient notes for the following patients that are overdue: "}
                {<span key={keys}>{nonRepNames}</span>}
                {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'string | n... Remove this comment to see the full error message */}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>You must sign these notes before continuing</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="okButton" onClick={onSubmit}>
                OK
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

UnsignedNoteModal.propTypes = {
  onSubmit: PropTypes.func,
  getList: PropTypes.object,
};

export default UnsignedNoteModal;
