import React, { FC } from "react";
import { Label } from "reactstrap";

import BlockSection from "./BlockSection";
import InfoSelect from "./InfoSelect";
import { allergyHistory } from "../../constants/Encounter";
import { IAllergySocialHistory } from "../../constants/Types";

type Props = {
  socialHistory: IAllergySocialHistory;
  oldSocialHistory: IAllergySocialHistory;
  onAllergyHistoryChange: (field: string) => (newValue: any) => void;
};

const AllergyMissedDaysHistoryEditor: FC<Props> = ({
  socialHistory,
  oldSocialHistory,
  onAllergyHistoryChange,
}) => {
  return (
    <BlockSection title={"Days Missed"}>
      <div className="form-row">
        <div className="col-6 col-sm-4 mr-4">
          <Label>Days missed for allergies</Label>
          <div className="col-sm-9 pl-0 pr-2">
            <InfoSelect
              value={socialHistory.allergyMissedDaysHistory.allergyDays}
              options={allergyHistory.missedDays}
              oldValue={oldSocialHistory.allergyMissedDaysHistory.allergyDays}
              onChange={onAllergyHistoryChange("allergyDays")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <Label>Days missed for illness</Label>
          <div className="col-6 col-sm-9 pl-0 pr-2">
            <InfoSelect
              value={socialHistory.allergyMissedDaysHistory.sickDays}
              options={allergyHistory.missedDays}
              oldValue={oldSocialHistory.allergyMissedDaysHistory.sickDays}
              onChange={onAllergyHistoryChange("sickDays")}
            />
          </div>
        </div>
      </div>
    </BlockSection>
  );
};

export default AllergyMissedDaysHistoryEditor;
