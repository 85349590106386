import React, { useEffect, useState } from "react";
import styled from "styled-components";
import XIcon from "../../../images/x-black.svg";
import ListEditor from "./ListEditor";

export const Card = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;
  padding: 20px;
`;

export const EditorWrapper = styled(Card)`
  h2 {
    color: #4a8fe7;
    font-weight: bold;
    font-size: 18px;
  }
`;

export const EditorHeader = styled.div`
  h2 {
    color: #4a8fe7;
    font-weight: bold;
    font-size: 18px;
  }
`;

export const StyledXIcon = styled(XIcon)`
  cursor: pointer;
`;

const ListView = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 53, 119, 0.2);
  padding: 0.7rem 10px 0.7rem 10px;
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
  &:hover {
    background: rgba(141, 178, 224, 0.15);
  }

  ${(props) =>
    props.new === true &&
    `
      span {
        color: #4a8fe7;
      }
    `}
`;

const ListEditorWrapper = styled.div`
  margin-top: 20px;
`;
const EditorBody = styled.div``;

type Props = {
  catalogEndpoint?: (...args: any[]) => any;
  data?: any[];
  itemListSource?: (...args: any[]) => any;
  items?: any[];
  oldItems?: any;
  onChange?: (...args: any[]) => any;
  onRemove?: (...args: any[]) => any;
  title?: string;
  values?: any;
  className: string;
};

function Editor(props: Props) {
  const [itemList, setItemList] = useState([]);
  const [catalog, setCatalog] = useState(null);

  useEffect(() => {
    loadCatalog();
  }, []);

  const loadCatalog = async () => {
    try {
      let result = await props.catalogEndpoint();

      setCatalog(result);
      mapCatalogToList(result);
    } catch (err) {
      // bugsnagClient.notify(err);
    }
  };

  const mapKeysToString = (items, catalog = []) => {
    let entries = items.map((k) => catalog.find((v) => v.key === k)).filter(Boolean);
    return entries.map((l) => (l && l.labels ? l.labels["en"] : "Undefined"));
  };

  const mapCatalogToList = (catalog) => {
    let itemListMap = catalog.map((item) => item.labels.en);
    setItemList({ items: itemListMap });
  };

  const searchableItemListSource = (text) => {
    let filter = itemList.items
      ? itemList.items.filter((item) => item.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : [];
    return { items: filter };
  };

  const isNewLabel = (label, oldItemLabels) => {
    return !oldItemLabels.includes(label);
  };

  const handleOnRemove = (item, labels, catalog) => {
    props.onRemove(catalog)(labels.filter((i) => i !== item), item);
  };

  let labels = mapKeysToString(props.values.keys || [], catalog || []);
  labels = labels.concat(props.values.other || []);
  labels = labels.length == 0 ? null : labels;
  let oldItemLabels = mapKeysToString(props.oldItems.keys || [], catalog || []);
  oldItemLabels = oldItemLabels.concat(props.oldItems.other || []);

  return (
    <EditorWrapper>
      <EditorHeader>
        <h2>{props.title}</h2>
      </EditorHeader>
      <EditorBody>
        {props.data.map((item, idx) => (
          <ListView key={idx} new={isNewLabel(item, oldItemLabels)}>
            <span>{item}</span>
            <StyledXIcon onClick={() => item!=="None" && handleOnRemove(item, labels, catalog)} />
          </ListView>
        ))}
        <ListEditorWrapper>
          <ListEditor
            items={labels}
            oldItems={oldItemLabels || []}
            itemListSource={searchableItemListSource}
            onChange={props.onChange(catalog)}
            md={12}
            sm={12}
            className="qa-catalogEditor"
          />
        </ListEditorWrapper>
      </EditorBody>
    </EditorWrapper>
  );
}
export default Editor;
