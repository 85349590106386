import React, { useState } from "react";
import { Button, Row, Col, FormGroup, ButtonGroup, Input, InputGroup, Label } from "reactstrap";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "../Patients/add-patient-modal.scss";
import api from "../../api";
import ErrorList from "../ErrorList";
import StateSelector from "../StateSelector";

const languageChoices = [
  { value: "English", label: "English" },
  { value: "Spanish", label: "Spanish" },
];
const covidZoneOptions = [
  { value: "Red", label: "Red Zone" },
  { value: "Yellow", label: "Yellow Zone" },
  { value: "Green", label: "Green Zone" },
  { value: "Blue", label: "Blue Zone" },
  { value: "N/A", label: "N/A" },
];

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
  };
};
const NewRoundingPatient = ({ setModal, handleListChange, currentUser }) => {
  const [selectedSex, setSelectedSex] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [mon, setMon] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [patientMRN, setPatientMRN] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [areaCode, setAreaCode] = useState<string>("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [phoneLine, setPhoneLine] = useState("");
  const [language, setLanguage] = useState("");
  const [zone, setZone] = useState("");

  const [medicareA, setMedicareA] = useState<boolean | null | string>(null);

  const [zoneError, setZoneError] = useState<string | boolean>("");
  const [medicareAError, setMedicareAError] = useState<string | boolean>("");
  const [dobError, setDobError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [mrnError, setmrnError] = useState<boolean>(false);
  const [languageError, setLanguageError] = useState<boolean>(false);
  const [patientSexErr, setPatientSexErr] = useState<boolean>(false);
  const [streetError, setStreetError] = useState<boolean>(false);
  const [cityError, setCityError] = useState<boolean>(false);
  const [stateError, setStateError] = useState<boolean>(false);
  const [zipError, setZipError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [saveError, setSaveError] = useState();

  const validData = () => {
    let locMon = mon;
    let locDay = day;

    if (locMon.length === 1) {
      locMon = `0${locMon}`;
      setMon(locMon);
    }
    if (locDay.length === 1) {
      locDay = `0${locDay}`;
      setDay(locDay);
    }

    if (!selectedSex.trim()) {
      setPatientSexErr(true);
    } else {
      setPatientSexErr(false);
    }

    if (patientMRN.length < 7) {
      if (!currentUser.isNursingHomeSite) {
        setmrnError(false);
      } else setmrnError(true);
    } else {
      setmrnError(false);
    }

    if (
      locDay.length < 2 ||
      locMon.length < 2 ||
      year.length < 4 ||
      isNaN(locDay) ||
      isNaN(locMon) ||
      isNaN(year) ||
      !moment(`${year}-${locMon}-${locDay}`).isValid() ||
      moment(`${year}-${locMon}-${locDay}`).utc() > moment(new Date()).utc()
    ) {
      setDobError(true);
    } else {
      setDobError(false);
    }

    if (first.length < 1 || last.length < 1) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (isNaN(zip) || zip.length < 1) {
      setZipError(true);
    } else {
      setZipError(false);
    }

    if (city.length < 1) {
      setCityError(true);
    } else {
      setCityError(false);
    }

    if (street.length < 1) {
      setStreetError(true);
    } else {
      setStreetError(false);
    }
    if (state.length != 2) {
      setStateError(true);
    } else {
      setStateError(false);
    }

    if (!!!language) {
      setLanguageError(true);
    } else {
      setLanguageError(false);
    }

    if (
      isNaN(areaCode) ||
      isNaN(phonePrefix) ||
      isNaN(phoneLine) ||
      areaCode.length != 3 ||
      phonePrefix.length != 3 ||
      phoneLine.length != 4
    ) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    if (zone.length < 1) {
      if (!currentUser.isNursingHomeSite) {
        setZoneError(false);
      } else setZoneError(true);
    } else {
      setZoneError(false);
    }
    if (medicareA === null) {
      if (!currentUser.isNursingHomeSite) {
        setMedicareAError(false);
      } else setMedicareAError(true);
    } else {
      setMedicareAError(false);
    }

    return (
      first.length > 0 &&
      last.length > 0 &&
      (currentUser.isNursingHomeSite ? patientMRN.trim().length > 1 : true) &&
      (currentUser.isNursingHomeSite ? !isNaN(patientMRN) : true) &&
      (selectedSex === "M" || selectedSex === "F") &&
      year.length === 4 &&
      locDay.length === 2 &&
      !!language &&
      locMon.length === 2 &&
      !isNaN(locDay) &&
      !isNaN(locMon) &&
      !isNaN(year) &&
      moment(`${year}-${locMon}-${locDay}`).isValid() &&
      moment(`${year}-${locMon}-${locDay}`).utc() <= moment(new Date()).utc() &&
      !isNaN(zip) &&
      city.length > 0 &&
      street.length > 0 &&
      state.length == 2 &&
      zip.length > 0 &&
      !isNaN(areaCode) &&
      !isNaN(phonePrefix) &&
      !isNaN(phoneLine) &&
      areaCode.length == 3 &&
      phonePrefix.length == 3 &&
      phoneLine.length == 4 &&
      (currentUser.isNursingHomeSite ? typeof medicareA === "boolean" : true) &&
      (currentUser.isNursingHomeSite ? zone.length > 0 : true)
    );
  };

  const onSubmit = () => {
    if (!validData()) {
      return;
    }

    const newPatient = {
      firstName: first,
      LastName: last,
      dob: `${year}-${mon}-${day}`,
      sex: selectedSex,
      pccmrn: patientMRN,
      street,
      city,
      state,
      postalCode: zip,
      language,
      medicareA,
      covidZone: zone,
      activeRounding: !(zone === "Green"),
      phone: `${areaCode}${phonePrefix}${phoneLine}`,
    };

    api.UserRecords.createUser(newPatient)
      .then(setModal)
      .then(handleListChange ? handleListChange : null)
      .catch((error) => {
        console.log(error.response);
        setSaveError(error.response.body.message);
      });
  };
  return (
    <div className="modal-overlay">
      <div className="ml-auto mr-auto add-patient-modal mb-3">
        <h4>New Patient Chart</h4>
        {saveError && <ErrorList errors={[saveError]} />}
        <FormGroup>
          <Row className="mb-4">
            <Col className="col-12">
              <label>PCC Patient ID</label>
              <InputGroup>
                <Input
                  type="number"
                  className="input-field"
                  placeholder="Enter PCC Patient ID"
                  onChange={(e) => setPatientMRN(e.target.value)}
                />
              </InputGroup>
              {mrnError && (
                <span className="error-message">* Please enter a valid PCC Patient ID</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Patient First Name</label>
              <Input
                className="input-field"
                placeholder="Enter First Name"
                onChange={(e) => setFirst(e.target.value)}
              />
            </Col>
            <Col>
              <label>Patient Last Name</label>
              <Input
                className="input-field"
                placeholder="Enter Last Name"
                onChange={(e) => setLast(e.target.value)}
              />
            </Col>
          </Row>
          {nameError && (
            <div className="d-flex justify-content-center error-message">
              * Patient Name required
            </div>
          )}
          <Row className="mt-4">
            <Col>
              <label>Date of Birth</label>
              <div className="d-flex">
                <Input
                  type="text"
                  maxLength="2"
                  placeholder="MM"
                  onChange={(e) => setMon(e.target.value)}
                  className="input-field"
                  style={{ width: "3.5rem" }}
                />
                <span className="dob-separator"> /</span>
                <Input
                  type="text"
                  maxLength="2"
                  placeholder="DD"
                  onChange={(e) => setDay(e.target.value)}
                  className="input-field"
                  style={{ width: "3.5rem" }}
                />
                <span className="dob-separator"> /</span>
                <Input
                  type="text"
                  maxLength="4"
                  placeholder="YYYY"
                  onChange={(e) => setYear(e.target.value)}
                  className="input-field"
                  style={{ width: "4.5rem" }}
                />
              </div>
              {dobError && <span className="error-message">* Valid DOB is required</span>}
            </Col>
            <Col>
              <label>Patient Sex</label>
              <ButtonGroup>
                <Input
                  type="button"
                  value="Male"
                  onClick={() => setSelectedSex("M")}
                  className={selectedSex === "M" ? "selectButtonActive" : "selectButton"}
                />
                <Input
                  type="button"
                  value="Female"
                  onClick={() => setSelectedSex("F")}
                  className={selectedSex === "F" ? "selectButtonActive" : "selectButton"}
                />
              </ButtonGroup>
              <br />
              {patientSexErr && <span className="error-message">* Patient Sex required</span>}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-6">
              <label>Language</label>
              <Select
                options={languageChoices}
                onChange={(option) => setLanguage(option.value)}
                backspaceRemovesValue={false}
              />
              {languageError && (
                <span className="error-message">* Patient Language is required</span>
              )}
            </Col>
            <Col className="col-6">
              <label>Covid-19 Zone</label>
              <Select
                options={covidZoneOptions}
                onChange={(option) => setZone(option.value)}
                backspaceRemovesValue={false}
              />

              {zoneError && (
                <span className="error-message">* Patient Covid-19 Zone is required</span>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-12">
              <label>Street</label>
              <Input
                className="input-field"
                placeholder="Enter Street"
                onChange={(e) => setStreet(e.target.value)}
              />
              {streetError && <span className="error-message">* Please enter a valid street</span>}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-4">
              <label>City</label>
              <Input
                className="input-field"
                placeholder="Enter City"
                onChange={(e) => setCity(e.target.value)}
              />
              {cityError && <span className="error-message">* Please enter a valid city</span>}
            </Col>

            <Col className="col-4">
              <label>State</label>
              <StateSelector onChange={(e) => setState(e.target.value)} value={state} />
              {stateError && <span className="error-message">* Please enter a valid state</span>}
            </Col>
            <Col className="col-4">
              <label>Postal Code</label>
              <Input
                className="input-field"
                placeholder="Enter Postal Code"
                onChange={(e) => setZip(e.target.value)}
              />
              {zipError && (
                <span className="error-message">* Please enter a valid postal code</span>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-6">
              <label>Phone Number</label>
              <div className="d-flex align-items-start" style={{ marginLeft: -25 }}>
                <span className="dob-separator">(</span>
                <Input
                  className="input-field"
                  type="text"
                  maxLength="3"
                  style={{ width: "3.5rem" }}
                  onChange={(e) => setAreaCode(e.target.value)}
                />
                <span className="dob-separator">) </span>
                <Input
                  className="input-field"
                  type="text"
                  maxLength="3"
                  style={{ width: "3.5rem" }}
                  onChange={(e) => setPhonePrefix(e.target.value)}
                />
                <span className="dob-separator">-</span>
                <Input
                  className="input-field"
                  type="text"
                  maxLength="4"
                  style={{ width: "4.5rem" }}
                  onChange={(e) => setPhoneLine(e.target.value)}
                />
              </div>
              {phoneError && (
                <span className="error-message">* Please enter a valid phone number</span>
              )}
            </Col>
            <Col className="col-6 d-flex flex-column align-items-center">
              <div className="d-flex flex-column  align-items-start ml-3">
                <label>Primary payer is Medicare A?</label>
                <div className="d-flex justify-content-center mt-2">
                  <FormGroup check className="mr-3">
                    <Label check>
                      <Input type="radio" name="medicareA" onChange={() => setMedicareA(true)} />
                      &nbsp;
                      <label>Yes</label>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="medicareA" onChange={() => setMedicareA(false)} />
                      &nbsp;
                      <label>No</label>
                    </Label>
                  </FormGroup>
                </div>
              </div>
              {medicareAError && (
                <span className="error-message">* Please choose your payer status</span>
              )}
            </Col>
          </Row>
        </FormGroup>
        <div className="d-flex mt-4 align-items-center">
          <div className="cancelButton" onClick={setModal}>
            Cancel
          </div>
          <Button className="saveButton" onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

NewRoundingPatient.propTypes = {
  setModal: PropTypes.func,
  handleListChange: PropTypes.func,
  currentUser: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewRoundingPatient);
