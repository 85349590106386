import React, { FC, useEffect, useRef, useState } from "react";
import Select from "react-select";

import "../../stylesheets/encounterMonitor.scss";
import { STATES } from "../StateSelector";
import { Table } from "reactstrap";
import { EncounterRow } from "./Monitor";
import api from "../../api";
import styles from "../Patients/search.scss";
import { IEncounterList, IEncounterListItem } from "../../constants/Types";
import { statuses } from "../../constants/Encounter";
import { Action, Dispatch } from "redux";
import {
  COMPONENT_LOAD,
  COMPONENT_UNLOAD,
  ENCOUNTER_VISIT_HISTORY,
  ENCOUNTERS_SEARCH_RESULTS,
  SELECT_PRACTICE_FOR_ENCOUNTERS_SEARCH,
  SET_IDLE_LOGOUT_TIME,
} from "../../constants/actionTypes";
import { connect } from "react-redux";

type DefaultParamsType = {
  pageSize?: number | null;
  patientName: string;
  practiceName: string;
  chiefComplaint: string;
  location: string;
  method: string;
  visitStatus: string;
  providerName: string;
  newMessages: string;
};
const defaultParams = {
  pageSize: null,
  patientName: "",
  practiceName: "",
  chiefComplaint: "",
  location: "",
  method: "",
  visitStatus: "",
  providerName: "",
  newMessages: "",
};

// pageStart - Pagination control, defaults to 0
// pageSize - Number of results, maximum of 25
const methods = [
  { label: "Chat", value: "Chat" },
  { label: "Video", value: "Video" },
  { label: "- -", value: "N/A" },
];
const newMessages = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const saveSelectValue = (value: string, variants: { label: string; value: string | boolean }[]) => {
  return variants.find((el) => el.value === value) || null;
};

const states = STATES.map((el) => ({ label: el.value, value: el.value }));

type Props = {
  onRowClick: (id) => (event: MouseEvent) => void;
  encounterSearchResults: { data: IEncounterListItem[]; errors: string[] };
  setEncounterSearchResults: Function;
  clearEncounterSearchResults: Function;
};

const MonitorEncounterSearch: FC<Props> = ({
  onRowClick,
  encounterSearchResults,
  setEncounterSearchResults,
  clearEncounterSearchResults,
}) => {
  const body = useRef(document.querySelector("body"));
  const [searchParams, setSearchParams] = useState<DefaultParamsType>(defaultParams);
  const [disableSearch, setDisableSearchSearch] = useState<boolean>(true);
  const [cancelButtonIsVisible, setCancelButtonVisibility] = useState<boolean>(false);

  const cancelHandler = () => {
    setSearchParams(defaultParams);
    clearEncounterSearchResults();
    setDisableSearchSearch(true);
    setCancelButtonVisibility(false);
  };

  useEffect(() => {
    setCancelButtonVisibility(!!encounterSearchResults?.data.length);
  }, []);

  const searchHandler = async () => {
    const paramsWithPageSize = { ...searchParams, pageSize: 25 };
    try {
      const result = await api.Monitoring.filteredEncounters(paramsWithPageSize);
      setEncounterSearchResults(result.items);
    } catch (err) {
      console.log(err);
    }
  };
  const setParam = (value: string, key: keyof typeof defaultParams) => {
    const updatedParams = { ...searchParams };

    updatedParams[key] = value;
    if (value === "" && Object.values(updatedParams).every((val) => val === "")) {
      setCancelButtonVisibility(false);
      setDisableSearchSearch(true);
    } else {
      setCancelButtonVisibility(true);
      setDisableSearchSearch(false);
    }
    setSearchParams(updatedParams);
  };

  const inputParamHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParam(event.target.value, event.target.name as keyof typeof defaultParams);
  };

  const selectParamHandler = (
    item: { label: string; value: string },
    meta: { name: keyof typeof defaultParams },
  ) => {
    setParam(item.value, meta.name);
  };
  const handleEnterPress = (e) => {
    const checkParams = Object.values(searchParams).filter((item) => item !== "");
    if (e.key === "Enter" && checkParams.length > 0) {
      e.preventDefault();
      searchHandler();
    }
  };

  return (
    <div className="dashboard-component align-content-stretch encounter-search-menu encounter-monitor-table">
      <div className="d-flex">
        <h1>Encounter Search</h1>

        <form className="d-flex" onKeyPress={handleEnterPress}>
          <div className="encounter-search-menu-block">
            <div className="encounter-search-menu-item">
              <label>Patient Name</label>
              <input
                type="text"
                className="input-text-item"
                placeholder="Patient Name or Initals"
                name="patientName"
                onChange={inputParamHandler}
                value={searchParams.patientName}
              />
            </div>
            <div className="encounter-search-menu-item">
              <label>Practice</label>
              <input
                type="text"
                className="input-text-item"
                placeholder="Name of Practice"
                name="practiceName"
                onChange={inputParamHandler}
                value={searchParams.practiceName}
              />
            </div>
            <div className="encounter-search-menu-item">
              <label>Chief Complaint</label>
              <input
                type="text"
                className="input-text-item"
                placeholder="Complaint"
                name="chiefComplaint"
                onChange={inputParamHandler}
                value={searchParams.chiefComplaint}
              />
            </div>
          </div>
          <div className="encounter-search-menu-block">
            <div className="encounter-search-menu-item">
              <label>Location</label>
              <Select
                name="location"
                onChange={selectParamHandler as any}
                className="last-input-item"
                menuPortalTarget={body.current}
                placeholder="State Abbreviation"
                value={saveSelectValue(searchParams.location, states)}
                options={states}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: null,
                }}
              />
            </div>
            <div className="encounter-search-menu-item">
              <label>Method</label>
              <Select
                name="method"
                isSearchable={false}
                onChange={selectParamHandler as any}
                value={saveSelectValue(searchParams.method, methods)}
                className="last-input-item"
                menuPortalTarget={body.current}
                placeholder="Select"
                options={methods}
              />
            </div>
            <div className="encounter-search-menu-item">
              <label>Status</label>
              <Select
                name="visitStatus"
                onChange={selectParamHandler as any}
                value={saveSelectValue(searchParams.visitStatus, statuses)}
                isSearchable={false}
                className="last-input-item"
                menuPortalTarget={body.current}
                placeholder="Select"
                options={statuses}
              />
            </div>
          </div>
          <div className="encounter-search-menu-block last-block">
            <div className="encounter-search-menu-item">
              <label>Provider</label>
              <input
                type="text"
                className="last-input-item input-text-item"
                placeholder="Name"
                name="providerName"
                onChange={inputParamHandler}
                value={searchParams.providerName}
              />
            </div>
            <div className="encounter-search-menu-item">
              <label>New Messages</label>
              <Select
                name="newMessages"
                onChange={selectParamHandler as any}
                value={saveSelectValue(searchParams.newMessages, newMessages)}
                isSearchable={false}
                className="last-input-item"
                menuPortalTarget={body.current}
                placeholder="Select"
                options={newMessages}
              />
            </div>
            <div className="encounter-search-menu-buttons">
              {cancelButtonIsVisible && (
                <button className="encounter-cancel-button" type="button" onClick={cancelHandler}>
                  Cancel
                </button>
              )}
              <button
                className={`encounter-search-button ${disableSearch ? "disabled" : ""} `}
                type="button"
                disabled={disableSearch}
                onClick={searchHandler}
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <div>
        {encounterSearchResults?.data.length && (
          <>
            <Table className="encounter-monitor-table" striped hover>
              <thead>
                <tr>
                  <th>Visit Created</th>
                  <th>Practice</th>
                  <th>Chief Complaint</th>
                  <th>Location</th>
                  <th>Method</th>
                  <th>New Messages</th>
                  <th>Last Message</th>
                  <th>Provider</th>
                  <th>Visit Timeline</th>
                  <th>Patient Name</th>
                </tr>
              </thead>
              <tbody>
                {encounterSearchResults.data.map((item, idx) => (
                  <EncounterRow
                    encounter={item}
                    key={`qa-monitorEncounterRow-${idx}`}
                    onClick={onRowClick}
                  />
                ))}
              </tbody>
            </Table>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  encounterSearchResults: state.componentData[ENCOUNTERS_SEARCH_RESULTS],
});

const matchDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setEncounterSearchResults: (payload) =>
    dispatch({ type: COMPONENT_LOAD, subtype: ENCOUNTERS_SEARCH_RESULTS, payload }),
  clearEncounterSearchResults: () =>
    dispatch({ type: COMPONENT_UNLOAD, subtype: ENCOUNTERS_SEARCH_RESULTS }),
});

export default connect(mapStateToProps, matchDispatchToProps)(MonitorEncounterSearch);
