import React, { useState, useEffect } from "react";
// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
import PropTypes from "prop-types";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'onSelected' implicitly has an 'an... Remove this comment to see the full error message
const SelectorOption = ({ type, selected, onSelected, ...props }) => {
  const onClick = () => {
    !!onSelected && onSelected(type);
  };
  return (
    <div className={`option ${!!selected && "active"}`} onClick={onClick}>
      {props.children}
    </div>
  );
};

const SelectorBar = ({ options, selected, leftBarRenderer, onOptionSelected }) => {
  const [selectedType, setSelectedType] = useState(selected || "");

  const onSelected = (type) => {
    if (!!onOptionSelected) {
      if (onOptionSelected(type) !== false) {
        setSelectedType(type);
      }
    } else {
      setSelectedType(type);
    }
  };

  return (
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
    <div className="selector-bar-component">
      <div className="d-flex flex-row flex-wrap justify-content-between selector-bar">
        {(options || []).map((opt, idx) => (
          <SelectorOption
            key={`opt-${idx}`}
            type={opt.type}
            selected={selectedType === opt.type}
            onSelected={onSelected}
          >
            {opt.text}
          </SelectorOption>
        ))}
        <div className="d-flex align-items-center pl-5">
          {!!leftBarRenderer && leftBarRenderer({ options, selectedType })}
        </div>
      </div>
    </div>
  );
};

SelectorBar.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  selected: PropTypes.string,
  leftBarRenderer: PropTypes.func,
  onOptionSelected: PropTypes.func,
};

export default SelectorBar;
