import React from "react";
import PropTypes from "prop-types";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
const arrayDiff = function(t, a) {
  return t.filter(function(i) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    return a.indexOf(i) < 0;
  });
};

const Pill = (props) => {
  const cn = `pill d-flex ml-1 mb-1 ${props.className} ${props.customCSS}`;

  return (
    <div className={cn} style={{ backgroundColor: "#E6ECF3", height: "27px" }}>
      <div className="pill-content align-self-center">{props.value}</div>
      <div className="close-button ml-4 align-self-center" onClick={props.RemoveItem(props.value)}>
        {" "}
        &#x2715;{" "}
      </div>
    </div>
  );
};

export default function PillList(props) {
  let items = props.items || [];
  let newItems = [];
  if (items.length == 0) {
    return null;
  }
  const ef = () => {};
  const onRemove = props.onRemove || ef;
  const removeItem = (item) => () => {
    onRemove(item);
  };
  if (props.oldItems) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    newItems = arrayDiff(items, props.oldItems);
    items = arrayDiff(props.items, newItems);
  }

  return (
    <div className="h-pill-list d-flex flex-wrap">
      {items.map(function(i, idx) {
        return <Pill value={i} key={idx} RemoveItem={removeItem} customCSS={props.customCSS} />;
      })}
      {newItems.map(function(i, idx) {
        return (
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'customCSS' does not exist on type 'PillL... Remove this comment to see the full error message
          <Pill
            className="new"
            value={i}
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
            key={idx}
            RemoveItem={removeItem}
            customCSS={props.customCSS}
          />
        );
      })}
    </div>
  );
}

PillList.propTypes = {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'customCSS' does not exist on type 'PillL... Remove this comment to see the full error message
  items: PropTypes.arrayOf(PropTypes.string),
  oldItems: PropTypes.arrayOf(PropTypes.string),
  onRemove: PropTypes.func,
};
