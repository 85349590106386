import React, { FC, useState } from "react";
import "react-dates/initialize";
import ReactDates, { DateRangePicker, DayPickerRangeControllerShape } from "react-dates";

import "react-dates/lib/css/_datepicker.css";
import "./DatePicker.scss";
import Calendar from "../../images/CalendarIcon.svg";

type Props = {
  startDate: DayPickerRangeControllerShape["startDate"];
  endDate: DayPickerRangeControllerShape["endDate"];
  handleDatesChange: (arg: {
    startDate: DayPickerRangeControllerShape["startDate"];
    endDate: DayPickerRangeControllerShape["endDate"];
  }) => void;
  dayBlocker: any;
  isOutsideRange: any;
};

const DatePicker: FC<Props> = ({
  startDate,
  endDate,
  handleDatesChange,
  dayBlocker,
  isOutsideRange,
}) => {
  const [focusedInput, setFocusedInput] = useState<ReactDates.FocusedInputShape | null>(null);
  return (
    <div className="DateRangePickerWrap">
      <DateRangePicker
        isOutsideRange={isOutsideRange}
        isDayBlocked={dayBlocker}
        startDatePlaceholderText="Review start date"
        endDatePlaceholderText="Review end date"
        noBorder
        customInputIcon={<Calendar />}
        numberOfMonths={1}
        startDate={startDate}
        startDateId="data-start-date"
        endDate={endDate}
        endDateId="data-end-date"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        customArrowIcon={"|"}
        hideKeyboardShortcutsPanel
        keepOpenOnDateSelect
      />
    </div>
  );
};

export default DatePicker;
