import React from "react";
import styled from "styled-components";

export const StyledCard = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  margin: 8px 0;
`;
const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  background: #76adf1;
  height: 33px;
  border-radius: 10px 10px 0 0;
  padding-left: 15px;
  color: FFFFFF;
  font-weight: 700;
  font-size: 14px;

  span {
    margin-left: 10px;
  }
`;

export const StyledCardBody = styled.div`
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #efefef;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border-radius: 15px;
  }
  max-height: 245px;
  overflow-y: auto;
  padding: 20px;
  font-size: 12px;
  line-height: 19px;
`;

export const Card = ({ children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>;
};

export const CardHeader = ({ children }) => {
  return <StyledCardHeader>{children}</StyledCardHeader>;
};

export const CardBody = ({ children }) => {
  return <StyledCardBody>{children}</StyledCardBody>;
};

//Diagnosis view in the patient card

const StyledDiagnosisCard = styled(StyledCard)``;

const StyledDiagnosisCardBody = styled(StyledCardBody)`
  max-height: 550px;

  .list-item {
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid rgba(0, 53, 119, 0.2);
    height: auto;
    overflow-y: scroll;

    font-size: 11px;
    cursor: pointer;

    .title {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0.7rem;
      .description {
        width: 70%;
        font-weight: 600;
        color: #000000;
      }
      .rank {
        margin: auto;
        text-align: end;
        width: 30%;
        color: #4a4a4a;
        font-weight: 400;
      }
    }

    .content {
      margin-top: 10px;
      height: 70px;
      display: none;
      transition: height 0.5s ease;
      th,
      td {
        border: none;
        font-size: 12px;
        padding: 0.2rem;
        font-style: italic;
        color: #000000;
      }
    }

    &.active {
      .title {
        padding-top: 0;
      }
      .content {
        display: block;
      }
    }

    :hover {
      background: rgba(117, 178, 255, 0.05);
      .rank,
      .description {
        color: #4a8fe7;
      }
    }
  }
`;
export const DiagnosisCardBody = ({ children }) => {
  return <StyledDiagnosisCardBody>{children}</StyledDiagnosisCardBody>;
};

export const DiagnosisCard = ({ children }) => {
  return <StyledDiagnosisCard>{children}</StyledDiagnosisCard>;
};

//Medication view in the patient chart
export const MedicationsCardBody = ({ children }) => {
  return <StyledDiagnosisCardBody>{children}</StyledDiagnosisCardBody>;
};

export const MedicationsCard = ({ children }) => {
  return <StyledDiagnosisCard>{children}</StyledDiagnosisCard>;
};
