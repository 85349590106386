import React, { Component } from "react";
import ContentBlock from "./ContentBlock";

type Props = {
  score: number;
};
class CentorScore extends Component<Props> {
  render() {
    return (
      <ContentBlock title={"Centor Score"}>
        <ul>
          <li>{"Score: " + this.props.score}</li>
        </ul>
      </ContentBlock>
    );
  }
}

export default CentorScore;
