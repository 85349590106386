import React, { FC } from "react";
import { IConditionProgression } from "../../constants/Types";

import ContentBlock from "./ContentBlock";

type Props = {
  progression: IConditionProgression;
};

const ConditionProgression: FC<Props> = ({ progression }) => (
  <ContentBlock title={"Condition Progression"}>
    <ul>
      <li>
        {"Duration: " + progression.durationWeeks + " weeks, " + progression.durationDays + " days"}
      </li>
      <li>{"Progression: " + progression.progression}</li>
    </ul>
  </ContentBlock>
);

export default ConditionProgression;
