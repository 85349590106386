import React, { useEffect, useState } from "react";
import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import ContentBLock from "../Encounter/ContentBlock";

const Genitourinary = ({
  features,
  onChange,
  note,
  onNoteUpdate,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection
}) => {
  const [hideContent, setHideContent] = useState(true);
  const catheter = findFeature(features, "catheter", "Catheter in place", "Genitourinary");
  const incontinent = findFeature(features, "incontinent", "Incontinent", "Genitourinary");

  const setAttribute = featureSetAttributes({ onChange });

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Genitourinary", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Genitourinary");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Genitourinary");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Genitourinary");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Genitourinary"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Genitourinary"  className="large">
      <div>
        <FeatureEntry
          {...{
            feature: catheter,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id:"qa-genitourinary-entry-catheter"
          }}
        />
        <FeatureEntry
          {...{
            feature: incontinent,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id:"qa-genitourinary-entry-incontinent"
          }}
        />
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{
            groupName: "Genitourinary",
            onChange: onNoteUpdate,
            note,
            inputClass: "genitourinary-notes",
            id:"qa-genitourinary-group-entry"
          }}
        />
      </div>
    </EntryBlock>
  );
};
export default Genitourinary;
