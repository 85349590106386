import React from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";

import CatalogSelect from "./CatalogSelect";
import PillList from "./PillList";

const CategorySelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export default function ListEditor(props) {
  const onRemove = (item) => {
    props.onRemove
      ? props.onRemove(props.items.filter((i) => i !== item), item)
      : props.onChange(props.items.filter((i) => i !== item));
  };

  return (
    <Row>
      <Col md={{ size: props.md }} sm={{ size: props.sm }}>
        {" "}
        <CategorySelectWrapper>
          <CatalogSelect
            items={props.items}
            handleSelectChange={props.onChange}
            userID={props.userID}
            itemListSource={props.itemListSource}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ items: any; handleSelectChange: any; userI... Remove this comment to see the full error message
            placeholder="Select"
          />
        </CategorySelectWrapper>
      </Col>

      {props.pillList && (
        <Col md={{ size: 8 }} className="mt-1">
          <PillList items={props.items} oldItems={props.oldItems} onRemove={onRemove} />
        </Col>
      )}
    </Row>
  );
}
