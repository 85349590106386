import React from "react";
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../images/DoubleRingSmallOr.sv... Remove this comment to see the full error message
import PropTypes from "prop-types";
import Spinner from "../images/DoubleRingSmallOr.svg";

const ButtonSpinner = ({ inProgress }) => {
  if (inProgress) {
    return (
      <div className="buttonSpinner">
        <Spinner />
      </div>
    );
  }
  return null;
};

ButtonSpinner.propTypes = {
  inProgress: PropTypes.bool
};

export default ButtonSpinner;
