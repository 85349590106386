import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "reactstrap";
import Octicon, { X } from "@githubprimer/octicons-react";
import ProviderSelect from "./ProviderSelect";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onAssign: () => void;
  encounterID: { ID: string; Kind: string };
  handleSelect: () => void;
  selected: { target: { value: string } };
  state: string;
};

const ForwardVisitModal = ({
  visible,
  onCancel,
  onAssign,
  encounterID,
  handleSelect,
  selected,
  state,
}) => {
  const [referenceID, setReferenceID] = useState("");

  useEffect(() => {
    if (encounterID?.ID && encounterID?.Kind) {
      let formmedReference = `${encounterID.ID}|${encounterID.Kind}`;
      setReferenceID(formmedReference);
    }
  }, [encounterID]);

  const handleCancel = () => {
    setReferenceID("");
    onCancel();
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="ml-auto mr-auto assign-visit">
        <Row>
          <Col>
            <h4 className="assign-header">Assign to Another Provider</h4>
          </Col>
          <Button onClick={handleCancel} className="assign-exit-btn">
            <Octicon icon={X} />
          </Button>
        </Row>
        <Row size={8}>
          <Col>
            <ProviderSelect
              className="assign-select"
              selected={selected}
              onChange={handleSelect}
              encounterID={referenceID}
              state={state}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="assign-subText">
              Once assigned, you will not be able to retrieve this note unless it is re-assigned it
              back to you.
            </p>
          </Col>
        </Row>
        <Row>
          <div className="assign-btn-grp">
            <Button onClick={handleCancel} className="assign-cancel-btn">
              Cancel
            </Button>
            <Button onClick={() => onAssign(referenceID)} className="assign-btn">
              Assign
            </Button>
          </div>
        </Row>
      </div>
    </div>
  );
};
export default ForwardVisitModal;
