import React, { useState, useEffect } from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";
import ContentBLock from "../Encounter/ContentBlock";

const TongueEntry = ({
  features,
  onChange,
  note,
  onNoteUpdate,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [hideContent, setHideContent] = useState(true);
  const edematous = findFeature(features, "tongueEdematous", "Edematous", "Tongue");
  const petechiae = findFeature(features, "tonguePetechiae", "Petechiae", "Tongue");
  const setAttribute = featureSetAttributes({ onChange });

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Tongue", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Tongue");

    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Tongue");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Tongue");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Tongue"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Tongue"  className="large">
      <div>
        <FeatureEntry
          {...{
            feature: edematous,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id: "qa-tongue-entry-edematous",
          }}
        />
        <FeatureEntry
          {...{
            feature: petechiae,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id: "qa-tongue-entry-petechiae",
          }}
        />
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{ groupName: "Tongue", onChange: onNoteUpdate, note, id: "qa-tongue-group-entry" }}
        />
      </div>
    </EntryBlock>
  );
};

TongueEntry.propTypes = {
  features: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default TongueEntry;
