import React, { useEffect, useState } from "react";
import { Input, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import ContentBlock from "./ContentBlock";
import RevertIcon from "../../images/revert-icon.svg";
import CloseIcon from "../../images/close-icon.svg";
import api from "../../api";

function isEmpty(obj) {
  for (let key in obj) {
    return false;
  }
  return true;
}

function getDosageRange(dsRange) {
  if (dsRange.indexOf("-") > -1) {
    return dsRange.split("-");
  }
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'patientAge' implicitly has an 'any' typ... Remove this comment to see the full error message
  return dsRange.split("+");
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'medications' implicitly has an 'any' ty... Remove this comment to see the full error message
const getMedications = (patientAge, medications) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'medication' implicitly has an 'any' typ... Remove this comment to see the full error message
  const patientMedications = (medications || []).filter((medication) => {
    const dosageRange = getDosageRange(medication.dosageRange).filter(Boolean);

    if (dosageRange.length > 1) {
      //must be of length 2 !!!
      return (
        patientAge >= parseInt(dosageRange[0], 10) && patientAge <= parseInt(dosageRange[1], 10)
      );
    }
    return patientAge >= parseInt(dosageRange[0], 10);
  });

  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'Icon' implicitly has an 'any' typ... Remove this comment to see the full error message
  return patientMedications;
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'onClick' implicitly has an 'any' ... Remove this comment to see the full error message
const IconButton = ({ Icon, children, onClick }) => {
  return (
    <button className="note-input-btn" onClick={onClick}>
      <Icon className="icon" />
      {children}
      {/* @ts-expect-error ts-migrate(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message */}
    </button>
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'note' implicitly has an 'any' typ... Remove this comment to see the full error message
  );
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'includeNote' implicitly has an 'a... Remove this comment to see the full error message
const NoteInput = ({
  name,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'placeholder' implicitly has an 'a... Remove this comment to see the full error message
  note,
  includeNote,
  initialNote,
  placeholder,

  ...props
}) => {
  useEffect(() => {
    if (!note) {
      props.onChange(name, initialNote);
    }
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  }, [initialNote]);

  const revertText = (e) => {
    if (!includeNote) {
      props.removeFromPlan();
    }
    props.onChange(name, initialNote);
  };

  const removeFromPlan = () => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
    props.removeFromPlan();
  };

  const noteChange = (e) => {
    const { value } = e.target;
    props.onChange(name, value);
  };

  return (
    <div className="note-input">
      {includeNote && (
        <Input
          value={note}
          type="textarea"
          placeholder={placeholder || ""}
          name={name}
          onChange={noteChange}
        />
      )}
      <div className="note-input-btns">
        <IconButton Icon={RevertIcon} onClick={revertText}>
          Revert Text
        </IconButton>
        {includeNote && (
          <IconButton Icon={CloseIcon} onClick={removeFromPlan}>
            Remove from Plan
          </IconButton>
        )}
      </div>
      {/* @ts-expect-error ts-migrate(7031) FIXME: Binding element 'note' implicitly has an 'any' typ... Remove this comment to see the full error message */}
    </div>
  );
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'intialNote' implicitly has an 'an... Remove this comment to see the full error message
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'includeNote' implicitly has an 'a... Remove this comment to see the full error message
const SupportiveCare = ({ note, intialNote, includeNote, ...props }) => {
  const onChange = (name, value) => {
    props.onSupportiveCareChange(value);
  };

  return (
    <div className="supportive-care">
      {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ initialNote: any; removeFromPlan: any; not... Remove this comment to see the full error message */}
      <h1 className="header">Supportive Care</h1>
      <NoteInput
        initialNote={intialNote}
        removeFromPlan={props.removeSupportiveCareFromPlan}
        note={note}
        includeNote={includeNote}
        onChange={onChange}
      />
      {/* @ts-expect-error ts-migrate(7031) FIXME: Binding element 'medication' implicitly has an 'an... Remove this comment to see the full error message */}
    </div>
  );
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
const NewMedication = ({ medication, ...props }) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onInputChange = (field) => (e) => {
    const { value } = e.target;
    const updatedmedication = {
      ...medication,
      [field]: value,
    };

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
    props.onInputChange(updatedmedication, medication);
  };
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter '_' implicitly has an 'any' type.

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const onNoteChange = (field) => (_, value) => {
    const updatedmedication = { ...medication, [field]: value };

    props.onInputChange(updatedmedication, medication);
  };

  const removeFromPlan = () => {
    props.removeFromPlan(medication);
  };
  return (
    <div className="new-medication">
      <Row>
        <Col size={6}>
          <div className="new-medication-inputs">
            <input
              type="text"
              value={medication.name}
              className="name"
              placeholder="Medication name"
              onChange={onInputChange("name")}
            />
            <input
              type="text"
              value={medication.dosage}
              className="dosage"
              placeholder="Dosage"
              onChange={onInputChange("dosage")}
            />
          </div>
        </Col>
        <Col size={6}>
          <NoteInput
            name="Other"
            removeFromPlan={removeFromPlan}
            placeholder="Enter patient instructions"
            includeNote={true}
            note={medication.instructions || ""}
            initialNote={""}
            onChange={onNoteChange("instructions")}
          />
        </Col>
      </Row>
      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'sp' implicitly has an 'any' type. */}
    </div>
  );
};

const getSymptomKeys = (sp) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'catalog' implicitly has an 'any' type.
  return sp.key;
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'symptomKey' implicitly has an 'an... Remove this comment to see the full error message
const getSymptoms = (catalog, symptomsKeys) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  return catalog.filter(({ symptomKey }) => symptomsKeys.includes(symptomKey));
};

const SymptomBlock = (props) => {
  const sp = props.symptom;
  // const patient = props.patient;
  const symptomSupportiveCare = props.symptomSupportiveCare || {};
  const symptomKey = sp.symptomKey;
  const includeNote = symptomSupportiveCare[symptomKey] !== undefined ? true : false;
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const supportiveCareText = sp.supportiveCareText || "";

  const onChange = (value) => {
    props.onSupportiveCareChange(sp.symptomKey, value);
  };

  const removeSupportiveCareFromPlan = () => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: any; }' is not a... Remove this comment to see the full error message
    props.removeSupportiveCareFromPlan(sp.symptomKey);
  };

  return (
    <ContentBlock title={sp.symptom}>
      <SupportiveCare
        intialNote={supportiveCareText}
        removeSupportiveCareFromPlan={removeSupportiveCareFromPlan}
        includeNote={includeNote}
        note={symptomSupportiveCare[symptomKey] || ""}
        onSupportiveCareChange={onChange}
      />
    </ContentBlock>
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'pd' implicitly has an 'any' type.
  );
};

const PrescriptionDetails = ({ pd }) => {
  return (
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
    <ul className="prescribed-items">
      {pd.map((v, idx) => (
        <li key={idx}>
          {v.name}
          {v.strength ? `, ${v.strength}` : ""}: {v.directions}
        </li>
      ))}
    </ul>
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'intake' implicitly has an 'any' type.
  );
};
// @ts-expect-error ts-migrate(7034) FIXME: Variable 'sp' implicitly has type 'any[]' in some ... Remove this comment to see the full error message
const getAllergySymptoms = (intake) => {
  let sp = [];

  // @ts-expect-error ts-migrate(7005) FIXME: Variable 'sp' implicitly has an 'any[]' type.
  if (intake.allergyEyesSymptoms.symptoms) {
    sp = sp.concat(intake.allergyEyesSymptoms.symptoms);
  }

  if (intake.allergyNoseSymptoms.symptoms) {
    sp = sp.concat(intake.allergyNoseSymptoms.symptoms);
  }

  if (intake.allergyLungsSymptoms.symptoms) {
    sp = sp.concat(intake.allergyLungsSymptoms.symptoms);
  }

  if (intake.allergySkinSymptoms.symptoms) {
    sp = sp.concat(intake.allergySkinSymptoms.symptoms);
  }

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'intake' implicitly has an 'any' type.
  return sp;
};
const getCurrentSymptoms = (intake) => {
  let symptoms = [];
  if (intake) {
    const visitType = intake.visitType;
    if (visitType === "rash_and_allergy") {
      symptoms = getAllergySymptoms(intake);
    } else {
      symptoms = intake.currentSymptoms.symptoms;
    }
  }

  return symptoms || [];
};
const TreatmentPlan = (props) => {
  const note = props.note;
  const [ICD10CatalogTX, setICD10CatalogTX] = useState({});
  const [symptomSuppotiveCare, setSymptomSuppotiveCare] = useState([]);
  const patient = props.patient;
  const prescriptionDetails = note.prescriptionDetails || [];
  const selectedICD10 = props.note.diagnosis || [];
  const symptomsKeys = (getCurrentSymptoms(props.intake) || []).map(getSymptomKeys);
  const currentSymptoms = getSymptoms(symptomSuppotiveCare, symptomsKeys);
  const detailedTreatmentPlan = props.note.detailedTreatmentPlan || {};
  useEffect(() => {
    loadCatalogs();
  }, []);

  const loadCatalogs = async () => {
    let ICD10Catalog = {};
    const ICD10Codes = selectedICD10.map((item) => item.split(" ")[0]);

    ICD10Codes.forEach(async (code) => {
      const result = await api.Catalog.icd10TX({ icd10: code });
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      ICD10Catalog[code] = result;
      setICD10CatalogTX(ICD10Catalog);
    });

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'code' implicitly has an 'any' type.
    await loadSupportiveCareCatalog();
  };

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const loadAllergyCatalogs = async () => {
    let result = [];
    let response = {};
    const intake = props.intake;

    if (intake.allergyEyesSymptoms.symptoms) {
      // @ts-expect-error ts-migrate(7034) FIXME: Variable 'result' implicitly has type 'any[]' in s... Remove this comment to see the full error message
      response = await api.Catalog.symptomSupportiveCare({
        visitType: "allergy-eye",
      });
      result = result.concat(response.supportiveCare);
    }

    if (intake.allergyNoseSymptoms.symptoms) {
      response = await api.Catalog.symptomSupportiveCare({
        // @ts-expect-error ts-migrate(7005) FIXME: Variable 'result' implicitly has an 'any[]' type.
        visitType: "allergy-nose",
      });
      result = result.concat(response.supportiveCare);
    }

    if (intake.allergyLungsSymptoms.symptoms) {
      response = await api.Catalog.symptomSupportiveCare({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'supportiveCare' does not exist on type '... Remove this comment to see the full error message
        visitType: "allergy-lungs",
      });
      result = result.concat(response.supportiveCare);
    }

    if (intake.allergySkinSymptoms.symptoms) {
      response = await api.Catalog.symptomSupportiveCare({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'supportiveCare' does not exist on type '... Remove this comment to see the full error message
        visitType: "allergy-skin",
      });
      result = result.concat(response.supportiveCare);
    }

    return result;
  };

  const loadSupportiveCareCatalog = async () => {
    const intake = props.intake;

    let result = [];
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'supportiveCare' does not exist on type '... Remove this comment to see the full error message
    if (intake.visitType !== "rash_and_allergy") {
      try {
        const response = await api.Catalog.symptomSupportiveCare({
          // @ts-expect-error ts-migrate(7034) FIXME: Variable 'result' implicitly has type 'any[]' in s... Remove this comment to see the full error message
          visitType: intake.visitType,
        });
        result = result.concat(response.supportiveCare);
      } catch (e) {
        // error getting supportive care info (may throw a 404 error - not all visit types have supportive care)
      }
    } else {
      result = await loadAllergyCatalogs();
    }
    setSymptomSuppotiveCare(result);
  };
  // @ts-expect-error ts-migrate(7005) FIXME: Variable 'result' implicitly has an 'any[]' type.
  const onSupportiveCareChange = (symptomKey, value) => {
    if (!detailedTreatmentPlan["symptomSupportiveCare"]) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      detailedTreatmentPlan["symptomSupportiveCare"] = {};
    }

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'symptomKey' implicitly has an 'any' typ... Remove this comment to see the full error message
    detailedTreatmentPlan["symptomSupportiveCare"][symptomKey] = value;
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
  };

  const getinitialNote = (field) => {
    let note = "";
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    if (!isEmpty(ICD10CatalogTX) && selectedICD10.length > 0) {
      selectedICD10.forEach((item) => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
        const code = item.split(" ")[0];
        const text = ICD10CatalogTX[code] ? ICD10CatalogTX[code][field] : "";
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
        note += !!text ? text + "\n " : text;
      });
    }
    return note;
  };

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const removeFromPlan = (field) => () => {
    const value = detailedTreatmentPlan[field];
    detailedTreatmentPlan[field] = !value;
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
  };

  const handleOnChange = (field, value) => {
    detailedTreatmentPlan[field] = value;
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const onAdditionalNoteChange = (field) => (e) => {
    const { value } = e.target;
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    detailedTreatmentPlan[field] = value;
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const removeSupportiveCareFromPlan = (symptomKey) => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    delete detailedTreatmentPlan["symptomSupportiveCare"][symptomKey];
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'symptomKey' implicitly has an 'any' typ... Remove this comment to see the full error message
  };

  return (
    <div className="patient-treatment-plan">
      {/* @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
      <ContentBlock title="Diagnosis">
        <>
          {selectedICD10.map((item) => {
            return <h1 className="header">{item}</h1>;
          })}
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; title: string; }' is ... Remove this comment to see the full error message */}
        </>
        <NoteInput
          name="information"
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
          note={detailedTreatmentPlan["information"]}
          includeNote={!detailedTreatmentPlan["includeInformation"]}
          onChange={handleOnChange}
          selectedICD10={selectedICD10}
          initialNote={getinitialNote("information")}
          removeFromPlan={removeFromPlan("includeInformation")}
          placeholder="Enter diagnosis information"
        />
      </ContentBlock>
      <ContentBlock title="Prescription">
        <Row>
          <Col size={6}>
            <PrescriptionDetails pd={prescriptionDetails} />
          </Col>

          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message */}
          <Col size={6}>
            <Input
              type="textarea"
              note={detailedTreatmentPlan["additionalPrescriptionNote"]}
              placeholder="Any additional prescription notes"
              onChange={onAdditionalNoteChange("additionalPrescriptionNote")}
              className="prescription-note"
              name="prescriptionNote"
              id="prescriptionNote"
            />
          </Col>
        </Row>
      </ContentBlock>
      <ContentBlock title="Recommended OTC Medication">
        <Input
          type="textarea"
          name="recommendedMedications"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message
          note={detailedTreatmentPlan["recommendedMedications"]}
          placeholder="Enter recommended OTC medication"
          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
          value={note.detailedTreatmentPlan.recommendedMedications}
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
          initialNote={getinitialNote("recommendedMedications")}
          className="recommendedMedications"
        />
      </ContentBlock>
      <ContentBlock title="Warning">
        <NoteInput
          name="warning"
          note={detailedTreatmentPlan["warning"]}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message
          includeNote={!detailedTreatmentPlan["includeWarning"]}
          onChange={handleOnChange}
          initialNote={getinitialNote("warningSymptoms")}
          removeFromPlan={removeFromPlan("includeWarning")}
          placeholder="Enter warning details"
        />
      </ContentBlock>
      {currentSymptoms.map((symptom, idx) => {
        return (
          <SymptomBlock
            symptom={symptom}
            onSupportiveCareChange={onSupportiveCareChange}
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'symptom' implicitly has an 'any' type.
            removeSupportiveCareFromPlan={removeSupportiveCareFromPlan}
            symptomSupportiveCare={detailedTreatmentPlan["symptomSupportiveCare"]}
            key={idx}
            patient={patient}
          />
        );
      })}

      <ContentBlock title="Prevent Spread">
        <NoteInput
          name="preventSpread"
          note={detailedTreatmentPlan["preventSpread"]}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message
          includeNote={!detailedTreatmentPlan["includePreventSpread"]}
          onChange={handleOnChange}
          initialNote={getinitialNote("preventSpread")}
          removeFromPlan={removeFromPlan("includePreventSpread")}
          placeholder="Enter prevent spread details"
        />
      </ContentBlock>
      <ContentBlock title="Expect Improvement">
        <NoteInput
          name="expectedImprovement"
          note={detailedTreatmentPlan["expectedImprovement"]}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message
          includeNote={!detailedTreatmentPlan["includeExpectedImprovement"]}
          onChange={handleOnChange}
          initialNote={getinitialNote("expectedImprovement")}
          removeFromPlan={removeFromPlan("includeExpectedImprovement")}
          placeholder="Enter notes of expected improvement"
        />
      </ContentBlock>
      <ContentBlock title="Return to School or Work">
        <NoteInput
          name="returnToActivities"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message
          note={detailedTreatmentPlan["returnToActivities"]}
          includeNote={!detailedTreatmentPlan["includeReturnToActivities"]}
          onChange={handleOnChange}
          initialNote={getinitialNote("returnToActivities")}
          removeFromPlan={removeFromPlan("includeReturnToActivities")}
          placeholder="Return to School or Work notes"
        />
      </ContentBlock>
      <ContentBlock title="Additional Patient Notes">
        <Input
          name="additionalNote"
          id="additionalNote"
          type="textarea"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message
          placeholder="Aditional notes for patients"
          includeNote={detailedTreatmentPlan["includeAdditionalNote"]}
          className="additional-note"
          onChange={onAdditionalNoteChange("additionalNote")}
        />
      </ContentBlock>
    </div>
  );
};

TreatmentPlan.propTypes = {
  currentSymptoms: PropTypes.object,
  intake: PropTypes.object,
  note: PropTypes.object,
  onUpdate: PropTypes.func,
  patient: PropTypes.object,
  visitType: PropTypes.string,
};
export default TreatmentPlan;
