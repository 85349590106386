import React, { useState } from "react";
import Select from "react-select";
import { Label } from "reactstrap";
import api from "../../api";

const RequisitionSelect = ({ reqOptions, status, missingDiagnosisFields, requisitionStatus }) => {
  const [selected, setSelected] = useState();
  const onSelectChange = (choice) => {
    if (choice) {
      setSelected(choice);
      let status = [choice.value];
      requisitionStatus({ status: status });
    }
  };

  return (
    <Select
      options={reqOptions}
      onChange={onSelectChange}
      isSearchable={false}
      id="requisitionSelect"
      className={
        missingDiagnosisFields["additionalStatus"]
          ? "info-select error w-50 mt-3"
          : "info-select w-50 mt-3"
      }
      value={selected ? selected : status}
    />
  );
};

export default RequisitionSelect;
