import React, { FC } from "react";

import BlockSection from "./BlockSection";
import { allergyHistory } from "../../constants/Encounter";
import AllergyPetsHistoryEditor from "./AllergyPetsHistoryEditor";
import AllergyOccupationalHistoryEditor from "./AllergyOccupationalHistoryEditor";
import AllergyHobbiesHistoryEditor from "./AllergyHobbiesHistoryEditor";
import AllergyChildrenHistoryEditor from "./AllergyChildrenHistoryEditor";
import AllergyMissedDaysHistoryEditor from "./AllergyMissedDaysHistoryEditor";
import AllergyLivingHistoryEditor from "./AllergyLivingHistoryEditor";
import { IAllergySocialHistory } from "../../constants/Types";

type Props = {
  socialHistory: IAllergySocialHistory;
  oldSocialHistory: IAllergySocialHistory;
  onChange: (value: object) => void;
};

const AllergySocialHistoryEditor: FC<Props> = ({ socialHistory, oldSocialHistory, onChange }) => {
  const onAllergyHistoryChange = (allergyHistoryKey) => (field) => (newValue) => {
    onChange({
      ...socialHistory,
      [allergyHistoryKey]: {
        ...socialHistory[allergyHistoryKey],
        [field]: newValue,
      },
    });
  };

  const handleCheckboxClicked = (newValue) => {
    let keys = socialHistory.allergyHobbiesHistory.hobbiesMaterials
      ? socialHistory.allergyHobbiesHistory.hobbiesMaterials.slice()
      : [];
    if (keys.indexOf(newValue) > -1) {
      keys.splice(keys.indexOf(newValue), 1);
    } else if (newValue === "None") {
      keys = ["None"];
    } else {
      keys.push(newValue);
      keys = keys.filter((key) => key !== "None");
    }
    onChange({
      ...socialHistory,
      allergyHobbiesHistory: {
        ...socialHistory.allergyHobbiesHistory,
        hobbiesMaterials: keys,
      },
    });
  };

  const searchableItemListSource = (text) => {
    let filter = allergyHistory.pets.filter(
      (item) => item.toLowerCase().indexOf(text.toLowerCase()) > -1,
    );
    return { items: filter };
  };
  return (
    <BlockSection title={"Allergy Social History"}>
      <AllergyPetsHistoryEditor
        socialHistory={socialHistory}
        oldSocialHistory={oldSocialHistory}
        searchableItemListSource={searchableItemListSource}
        onAllergyHistoryChange={onAllergyHistoryChange("allergyPetsHistory")}
      />
      <AllergyOccupationalHistoryEditor
        socialHistory={socialHistory}
        oldSocialHistory={oldSocialHistory}
        onAllergyHistoryChange={onAllergyHistoryChange("allergyOccupationalHistory")}
      />
      <AllergyHobbiesHistoryEditor
        socialHistory={socialHistory}
        oldSocialHistory={oldSocialHistory}
        handleCheckboxClicked={handleCheckboxClicked}
        onAllergyHistoryChange={onAllergyHistoryChange("allergyHobbiesHistory")}
      />
      <AllergyChildrenHistoryEditor
        socialHistory={socialHistory}
        oldSocialHistory={oldSocialHistory}
        onAllergyHistoryChange={onAllergyHistoryChange("allergyChildrenHistory")}
      />
      <AllergyMissedDaysHistoryEditor
        socialHistory={socialHistory}
        oldSocialHistory={oldSocialHistory}
        onAllergyHistoryChange={onAllergyHistoryChange("allergyMissedDaysHistory")}
      />
      <AllergyLivingHistoryEditor
        socialHistory={socialHistory}
        oldSocialHistory={oldSocialHistory}
        onAllergyHistoryChange={onAllergyHistoryChange("allergyLivingHistory")}
      />
    </BlockSection>
  );
};

export default AllergySocialHistoryEditor;
