import React, { Component } from "react";
import Octicon, { Plus } from "@githubprimer/octicons-react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import isEqual from "react-fast-compare";

import NavBar from "../Provider/NavBar";
import DashboardIcon from "../../images/DashboardIcon.svg";
import ProviderList from "./List";
import api from "../../api";
import List from "../ProviderManagement/List";
import Search from "../ProviderManagement/Search";
import { NAVIGATE_TO } from "../../constants/actionTypes";
import {
  hasPermission,
  PERMISSION_MANAGE,
  PERMISSION_SUPERVISING_PHYSICIAN,
} from "../../constants/Permissions";
import { ICurrentUser } from "../../constants/Types";

type Props = {
  currentUser: ICurrentUser;
  onNewProvider: () => any;
};

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onNewProvider: () => dispatch({ type: NAVIGATE_TO, targetPath: "/providers/new" }),
});

class Providers extends Component<Props> {
  state = {
    scope: "all",
    listItems: [],
    searchValue: "",
    filters: {
      name: "",
      location: "",
      status: "Active",
    },
    isLoading: false,
  };

  refreshList = () => {
    this.setState({ isLoading: true });
    api.Providers.list({ scope: this.state.scope }).then((response) => {
      this.setState({ listItems: response.items, isLoading: false });
    });
  };

  componentDidMount() {
    this.refreshList();
  }

  componentDidUpdate(prevProps: Props) {
    if (!!prevProps.currentUser && !isEqual(prevProps.currentUser, this.props.currentUser)) {
      // current user changed since the last update, refresh the list
      this.refreshList();
    }
  }

  dropSearch = (field, search) => {
    //this.setState({ searchValue: "" });
    this.setState({ filters: { ...this.state.filters, [field]: search } });
  };

  providerStateChanged = async (providerID, index, flag) => {
    await api.Providers.setActive(providerID, flag);
    const updatedList = this.state.listItems.map((item: any) => {
      return item.id === providerID ? { ...item, active: flag ? "active" : "disabled" } : item;
    });

    this.setState({ listItems: updatedList });
  };

  handleScopeChange = (scope) => {
    this.setState({ scope }, () => {
      this.refreshList();
    });
  };

  render() {
    const permissions = this.props.currentUser?.permissions || [];
    const isManager = hasPermission(permissions, PERMISSION_MANAGE);
    const isSupervisingPhysician = hasPermission(permissions, PERMISSION_SUPERVISING_PHYSICIAN);

    return (
      <div>
        <NavBar />
        <div className="dashboard-container">
          <div>
            {isManager && (
              <div className="float-right">
                <div className="add-new-provider">Add new provider</div>
                <Button
                  color="success"
                  className="round-button qa-addNewProviderButton"
                  onClick={this.props.onNewProvider}
                >
                  <Octicon icon={Plus} />
                </Button>
              </div>
            )}
            <h4>
              {" "}
              <div className="header-icon float-left">
                <DashboardIcon />
              </div>
              Providers{" "}
            </h4>
          </div>
          <Search onChange={this.providerStateChanged} />
          <div className="dashboard-component align-content-stretch">
            <h5>List of Providers</h5>
            {isSupervisingPhysician && (
              <div className="provider-toggle">
                <span
                  className={classNames(
                    "option",
                    {
                      selected: !(this.state.scope === "supervised"),
                    },
                    "qa-myProvidersButton",
                  )}
                  onClick={() => this.handleScopeChange("supervised")}
                >
                  My Providers
                </span>
                <span className="provider-toggle-spacer">|</span>
                <span
                  className={classNames(
                    "option",
                    {
                      selected: !(this.state.scope === "all"),
                    },
                    "qa-allProvidersButton",
                  )}
                  onClick={() => this.handleScopeChange("all")}
                >
                  All
                </span>
              </div>
            )}
            <ProviderList
              listItems={this.state.listItems}
              listName="providers"
              inProgress={this.state.isLoading}
              filters={this.state.filters}
            >
              <List
                onProviderStateChange={this.providerStateChanged}
                dropSearch={this.dropSearch}
                allProviders={this.state.listItems}
                filters={this.state.filters}
              />
            </ProviderList>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Providers);
