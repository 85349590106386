import React from "react";
import Select from "react-select";

const stringToSelectItem = (s) => {
  return { value: s, label: s };
};
const selectItemToString = (so) => {
  return so.label;
};
const EmptyComponent = () => {
  return null;
};

export default function InfoSelect(props) {
  const options = props.options.map(stringToSelectItem);
  const selectChanged = (newItem) => {
    const v = selectItemToString(newItem);
    if (props.onChange) {
      props.onChange(v);
    }
  };
  let cl = "info-select";
  if (props.value !== props.oldValue) {
    cl = cl + " edited";
  }

  return (
    <Select
      value={stringToSelectItem(props.value)}
      components={{ IndicatorSeparator: EmptyComponent }}
      onChange={selectChanged}
      isClearable={false}
      hideSelectedOptions={true}
      className={cl}
      classNamePrefix="info-select"
      options={options}
    />
  );
}
