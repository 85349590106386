import React, { useEffect, useState, useCallback } from "react";
import { Input, Table, Button } from "reactstrap";
import debounce from "lodash.debounce";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./rounding-tab.scss";
import Spinner from "../../images/Spinner.svg";
import api from "../../api";
import styles from "../Patients/search.scss";
import { SET_IDLE_LOGOUT_TIME } from "../../constants/actionTypes";
import NewPatient from "./NewRoundingPatient";
import CovidZoneDropdown from "./CovidZoneDropdown";
import { isPCCUser } from "../../constants/Providers";
import { I_IndexedUser } from "../../constants/Types";

const ResultTable = ({ items, addToList }) => {
  items = items || [];
  return (
    <>
      <Table responsive className={styles.patientTable}>
        {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type. */}
        <tbody>
          {items.map((i) => {
            const dob = moment(i.dob).format("MM/DD/YYYY");
            return (
              <tr key={i.userID}>
                <td className="w-25">
                  {i.firstName} {i.lastName}
                </td>
                <td>
                  {" "}
                  <div className="roundingStatusZone">
                    <CovidZoneDropdown status={i.covidZone} userID={i.userID} />
                  </div>
                </td>
                <td>{i.PCCMRN}</td>
                <td>{i.sex}</td>
                <td>{dob}</td>
                <td>
                  <Button className="btn-add right" onClick={() => addToList(i.userID)}>
                    + Add to Rounding List
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <p className={styles.warningLabel}>
        Only showing {items.length} results. If you don’t see what you’re looking for, type more of
        your search…
      </p>
    </>
  );
};
function RoundingListSearch({ currentUser, setIdleTime, handleListChange }) {
  const [searchVal, setSearchVal] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<I_IndexedUser[] | null>([]);
  const [inProgress, setInProgress] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchValue.length < 3) {
        setSearchResults(null);
        return;
      }
      setInProgress(true);

      try {
        let result = await api.UserRecords.search({ query: debouncedSearchValue });
        setSearchResults(result.items);
      } finally {
        setInProgress(false);
      }
    };

    fetchData();
  }, [currentUser, debouncedSearchValue]);

  const debouncedSetSearchValue = useCallback(debounce(setDebouncedSearchValue, 375), []);

  function handleSearchChange(event) {
    const { value } = event.target;
    setSearchVal(value);
    debouncedSetSearchValue(value);
    setIdleTime();
  }
  const addToList = async (userID) => {
    await api.Providers.changeRoundingFlag(userID, { activeRounding: true });
    handleListChange();
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  const isPccIntegrated = isPCCUser(currentUser);

  return (
    <div className="dashboard-component">
      {/* @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
      {modal && <NewPatient handleListChange={handleListChange} setModal={() => setModal(false)} />}
      <div className="mb-2 search">
        <h5>Add Patient to Rounding List</h5>
        <Input
          className={styles.searchInput}
          placeholder="Search patient name"
          onChange={handleSearchChange}
          value={searchVal}
        />
      </div>
      {inProgress && (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
      {!inProgress && searchVal.length > 3 && !searchResults && (
        <div className="mt-3 empty-results">
          <h5>No Results Found</h5>
          {!isPccIntegrated && (
            <Button className="btn-add" onClick={() => setModal(true)}>
              + Create New Patient{" "}
            </Button>
          )}
        </div>
      )}
      {!inProgress && searchResults && <ResultTable items={searchResults} addToList={addToList} />}
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
});

const matchDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
  setIdleTime: () => dispatch({ type: SET_IDLE_LOGOUT_TIME }),
});

RoundingListSearch.propTypes = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  currentUser: PropTypes.object,
  setIdleTime: PropTypes.func,
  handleListChange: PropTypes.func,
};

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(RoundingListSearch);
