import React from "react";
import { Label } from "reactstrap";
import PropTypes from "prop-types";

import InfoSelect from "./InfoSelect";
import BlockSection from "./BlockSection";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export const Editor = ({ testType, onChange }) => {
  const onChanged = (value) => {
    onChange(value);
  };

  const options = ["current", "past"];
  return (
    <div className="d-flex">
      <div className="w-25">
        <InfoSelect
          value={testType ? `${testType} infection` : "Select.."}
          options={options}
          onChange={onChanged}
        />
      </div>
    </div>
  );
};

export default function RequestedTestType(props) {
  const illContacts = props.illContacts || {};
  const testActiveOrPast = "Why does the patient want to get tested?";
  const onChange = (newValue) => {
    props.onChanged({
      illContacts: { ...illContacts, testActiveOrPast: newValue },
    });
  };
  let content;
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newValue' implicitly has an 'any' type.
  if (props.isEditing && !props.readOnly) {
    content = (
      <div>
        <Label>{testActiveOrPast}</Label>
        <Editor
          testType={illContacts.testActiveOrPast}
          oldDTestType={props.restoreData.illContacts}
          onChange={onChange}
        />
      </div>
    );
  } else {
    content = (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ testType: any; oldDTestType: any; onChange... Remove this comment to see the full error message
      <div>
        <Label className="mr-2">{testActiveOrPast}</Label>
        {illContacts.testActiveOrPast === undefined
          ? "N/A"
          : `${illContacts.testActiveOrPast} infection`}
      </div>
    );
  }
  return (
    <div>
      <BlockSection title="Requested Test Type">{content}</BlockSection>
    </div>
  );
}

RequestedTestType.propTypes = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message
  illContacts: PropTypes.object,
  isEditing: PropTypes.bool,
  restoreData: PropTypes.object,
  onChanged: PropTypes.func,
};
