import React from "react";
import { Label } from "reactstrap";
import PropTypes from "prop-types";

import InfoSelect from "./InfoSelect";
import BlockSection from "./BlockSection";
import YesNoToggle from "./YesNoToggle";

const testingReasonsMap = {
  "Required by employer": "employer",
  "Required for return to school": "return to school",
  "Required by residential facility (LTCF)": "residential facility",
  "Required for pre-operative clearance": "pre-operative clearance",
  Other: "other",
};

const testingReasons = {
  employer: "Required by employer",
  "return to school": "Required for return to school",
  "residential facility": "Required by residential facility (LTCF)",
  "pre-operative clearance": "Required for pre-operative clearance",
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'testingReason' implicitly has an ... Remove this comment to see the full error message
  other: "Other",
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'onChange' implicitly has an 'any'... Remove this comment to see the full error message
export const Editor = ({ testingReason, onChange, oldData }) => {
  const handleChange = (field) => (value) => {
    onChange(field, value);
  };

  const options = Object.keys(testingReasonsMap);

  return (
    <>
      <div className="d-flex">
        <div className="w-50">
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <InfoSelect
            value={testingReason ? testingReasons[testingReason.reason] : ""}
            options={options}
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            onChange={(val) => handleChange("reason")(testingReasonsMap[val])}
          />
        </div>
      </div>
      <div className="mt-3">
        <Label>
          {`Is this your first covid test? ${
            testingReason && testingReason.firstTime ? "Yes" : "No"
          }`}
        </Label>
        <YesNoToggle
          value={testingReason ? testingReason.firstTime : ""}
          oldValue={
            oldData && oldData.intakeHistory.testingReason
              ? oldData.intakeHistory.testingReason.firstTime
              : ""
          }
          onChange={handleChange("firstTime")}
        />
      </div>
    </>
  );
};

export default function TestingReason({
  title,
  isEditing,
  readOnly,
  onChanged,
  testingReason,
  restoreData,
}) {
  const handleChange = (field, newValue) => {
    onChanged({
      testingReason: {
        ...testingReason,
        [field]: newValue,
      },
    });
  };

  return (
    <div>
      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type. */}
      <BlockSection title={title}>
        {isEditing && !readOnly ? (
          <div>
            <Editor testingReason={testingReason} onChange={handleChange} oldData={restoreData} />
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
          </div>
        ) : (
          <>
            <div>
              <Label>{testingReason ? testingReasons[testingReason.reason] : "N/A"}</Label>
            </div>
            <div>
              <Label>
                {`Is this your first covid test? ${
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  testingReason && testingReason.firstTime ? "Yes" : "No"
                }`}
              </Label>
            </div>
          </>
        )}
      </BlockSection>
    </div>
  );
}

TestingReason.propTypes = {
  title: PropTypes.string,
  isEditing: PropTypes.func,
  onChanged: PropTypes.func,
  testingReason: PropTypes.object,
  restoreData: PropTypes.object,
};
