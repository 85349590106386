import React, { useState, useEffect, Dispatch, FC } from "react";
import { Button } from "reactstrap";
import LeftArrow from "../../images/leftArrow.svg";
import RightArrow from "../../images/rightArrow.svg";
import "./ReqPDF.scss";
import api from "../../api";
import { ENCOUNTER_LOAD_NOTE_PDF, ENCOUNTER_UNLOAD_NOTE_PDF } from "../../constants/actionTypes";
import { IAppState } from "../../reducer";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  loadNotePDF: (
    payload: Promise<{
      url: string;
      requisitionURL?:string
    }>,
  ) => dispatch({ type: ENCOUNTER_LOAD_NOTE_PDF, payload }),
});
const mapStateToProps = (
  state: IAppState,
  ownProps: {
    referenceID: string;
    onBack: () => void;
    onReviewNote: () => void;
    visitType?:string
  },
) => {
  return {
    ...ownProps,
    currentUser: state.common.currentUser,
    encounterID: state.encounter.encounterID,
    noteURL: state.encounter.noteURL,
    loading: state.progress[ENCOUNTER_LOAD_NOTE_PDF],
    requisitionURL: state.encounter.requisitionURL
  };
};
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ReqPDF : FC<Props> = (props) => {
  // const [pdfURL, setPDFURL] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    props.encounterID && props.loadNotePDF(api.Encounters.notePDF(props.encounterID));
  }, []);

  return (
    <div className="dialog-container download-form-dialog">
      <div className="req-form-review">
        <div className="header">
          <div className="header-side">
            <Button className="edit-profile" onClick={props.onBack}>
              <LeftArrow />
              <span>Back</span>
            </Button>
          </div>
          <div>
            <div className="reqpdf-message">Download Requisition Form</div>
            <span className="ml-3 center">
              Upload to patient chart after downloading and editing
            </span>
          </div>
          <div className="header-side">
            <div className="encounter-error-message"></div>
            <Button className="next-sign-note" onClick={() => props.onReviewNote()}>
              Next <RightArrow />
            </Button>
          </div>
        </div>
        <div className="review-content flex-grow-1">
          <object data={props.requisitionURL} type="application/pdf">
            alt : <a href={props.requisitionURL}>{`${props.visitType} PDF form.`}</a>
          </object>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReqPDF);
