import React, { FC } from "react";
import { Row, Col } from "reactstrap";

import NavBar from "../Provider/NavBar";
import Icon from "../../images/Billing.svg";
import BillingSearch from "./BillingSearch";

const Tab: FC<{}> = () => {
  return (
    <div>
      <NavBar />
      <div className="dashboard-container">
        <h4>
          {" "}
          <div className="header-icon float-left">
            <Icon />
          </div>{" "}
          Billing{" "}
        </h4>
        <Row>
          <Col md={{ size: 12 }}>
            <BillingSearch />
          </Col>
        </Row>
        <Row></Row>
      </div>
    </div>
  );
};

export default Tab;
