import React, { FC, useState } from "react";
import { Button } from "reactstrap";

type Props = {
  status: string[];
  onChangeStatus: (status: string[]) => void;
};
const buttonsText = {
  scheduleNext: "Schedule Next Visit ",
  ccmEnroll: "Enroll In CCM",
  rpmEnroll: "Enroll In RPM",
  labRequisition: "Generate Lab Requisition",
  revokeConsent: "No Further Services",
  reschedule: "Reschedule this visit - unable to complete encounter",
};

const InHomeHealthStatusButtons: FC<Props> = ({status,onChangeStatus}) => {
  const [statusButton, setStatus] = useState(status);

  const onButtonClick = (e) => {
    if (statusButton.some((status) => status === e.target.name)) {
      const newArr: string[] = statusButton.filter(el => el !== e.target.name)
      setStatus(newArr);
      onChangeStatus(newArr)
    } else {
      const newArr: string[] = [e.target.name]
      setStatus(newArr);
      onChangeStatus(newArr)
    }
  };

  return (
    <div className="d-flex flex-column justify-content-start">
      {Object.keys(buttonsText).map((item) => (
        <Button
          key={`statusBtn-${item}`}
          color=""
          className="statusBtn mr-3"
          name={item}
          onClick={onButtonClick}
          active={statusButton ? statusButton.some((status) => status === item) : false}
        >
          {buttonsText[item]}
        </Button>
      ))}
    </div>
  );
};
export default InHomeHealthStatusButtons;
