import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";

import VitalsEditor from "./VitalsEditor";
import BlockSection from "./BlockSection";
import { BPArmReadOptions } from "../../constants/Encounter";
import PCCVitals from "./PCCVitals";

const View = ({ vitals, isClinicalServices }) => {
  let [feet, inches] = SplitHeight(vitals.height);
  let parts = [
    ["Height", `${feet}\'${inches}\"`],
    ["Weight", vitals.weight, "lbs"],
    ["Heart Rate", vitals.heartRate, "bpm"],
  ];
  parts = parts.filter((p) => p[1]);

  const item = (i) => {
    return (
      <span key={i[0]}>
        {i[0]}: {i.slice(1).join("  ")}&nbsp;
      </span>
    );
  };
  const checkIfEmpty = (value) => {
    if (value) {
      return value;
    }
    return "not recorded";
  };
  return (
    <div>
      {parts.map((data, index) => (
        <div key={index}>{item(data)}</div>
      ))}
      {isClinicalServices && (
        <>
          <div>{`Respiratory rate: ${checkIfEmpty(
            vitals.respiratoryRate,
          )} breaths per minute`}</div>
          <div>{`Blood Pressure: ${
            !vitals.systolic || !vitals.diastolic
              ? checkIfEmpty(false)
              : `${vitals.systolic} / ${vitals.diastolic} mmHg`
          }`}</div>
          {vitals.systolic && vitals.diastolic && (
            <ul>
              {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              <li>{checkIfEmpty(BPArmReadOptions[vitals.bloodPressureArm])}</li>
              <li>{checkIfEmpty(vitals.bloodPressurePosition)}</li>
            </ul>
          )}
          <div>{`SaO2 (Pulse Oxygen Saturation): ${
            vitals.saO2 ? `${vitals.saO2}%` : checkIfEmpty(false)
          }`}</div>
          {vitals.saO2Source && vitals.saO2Source === "L/min of oxygen" && (
            <ul>
              <li>{`${checkIfEmpty(vitals.saO2LitersMin)} L/min of oxygen`}</li>
            </ul>
          )}
        </>
      )}
    </div>
  );
};

const SplitHeight = (height) => {
  if (!height) {
    return ["", ""];
  }
  let [feet, inches] = height.split("'", 2);
  feet = feet || "";
  inches = (inches || "").replace('"', "");

  return [feet, inches];
};

export default function Vitals(props) {
  const vitals = props.vitals || {};
  const oldVitals = props.restoreData || {};
  const requiredVitals = ["height", "weight", "heartRate"];

  const visitType = props.visitType;

  const [showErrorsFor, setShowErrorsFor] = useState([]);
  const PCC = props.isPCCPatient;
  const PCCObservations = props.PCCObservations ? props.PCCObservations || [] : [];
  useEffect(() => {
    setShowErrorsFor(props.requiredFields);
  }, [props.showRequiredFieldErrors]);
  const onFieldChange = (field) => (newValue) => {
    if (requiredVitals.indexOf(field) > -1 && !newValue) {
      props.setRequiredFields(props.requiredFields.concat([field]));
    } else if (props.requiredFields.indexOf(field) > -1 && newValue) {
      const temp = props.requiredFields.slice();
      temp.splice(props.requiredFields.indexOf(field), 1);
      props.setRequiredFields(temp);
    }
    if (field === "saO2Source" && newValue === "room air") {
      props.onChanged({
        vitals: { ...vitals, [field]: newValue, saO2LitersMin: null },
      });
    } else {
      props.onChanged({ vitals: { ...vitals, [field]: newValue } });
    }
  };

  let content;
  if (props.isEditing && !props.readOnly) {
    content = (
      <VitalsEditor
        vitals={vitals}
        onFieldChange={onFieldChange}
        oldVitals={oldVitals}
        isClinicalServices={props.isClinicalServices}
        showErrorsFor={showErrorsFor}
      />
    );
  } else {
    if (!PCC && visitType === "nursing_home" && !props.readOnly) {
      content = (
        <Input
          type="textarea"
          disabled={false}
          name="vitals"
          onChange={(v) => props.onChange(v)}
          value={props.value}
          style={{ height: "60px" }}
          placeholder="Enter additional comments or corrections regarding vitals"
        />
      );
    } else if (!!PCC) {
      // input section labeled Provider Notes but this is actually for free form vitals input
      content = (
        <>
          <PCCVitals PCCObservations={PCCObservations} />
          <Label>
            <h1>Additional notes for Vitals</h1>
          </Label>
          <Input
            type="textarea"
            disabled={false}
            name="vitals"
            onChange={(v) => props.onChange(v)}
            value={props.value}
            id="qa-encounter-vitalsnotes-freetext"
            placeholder="Enter additional comments or corrections regarding vitals"
            style={{ height: "60px" }}
          />
        </>
      );
    } else {
      content = <View vitals={vitals} isClinicalServices={props.isClinicalServices} />;
    }
  }
  return <BlockSection title="Vitals">{content}</BlockSection>;
}
