import React, { useState, useEffect } from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import ButtonGroupEntry from "./ButtonGroupEntry";
import PillList from "../Encounter/PillList";
import CheckSelect from "../CheckSelect";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";
import ContentBLock from "../Encounter/ContentBlock";

const NoseAllergyQuestions = ({ features, setAttribute }) => {
  const shiners = findFeature(features, "allergicShiners", "Allergic Shiners", "Nose");
  const sinusErythema = findFeature(features, "sinusErythema", "External Sinuses", "Nose");
  const sinusEdema = findFeature(features, "sinusEdema", "External Sinuses", "Nose");
  const foreignBody = findFeature(features, "noseForeignBody", "Foreign Body in Nose", "Nose");
  const nasalFlaring = findFeature(features, "nasalFlaring", "Nasal Flaring", "Nose");

  return (
    <React.Fragment>
      <FeatureEntry
        {...{
          feature: shiners,
          values: ["Absent", "Present"],
          onChange: setAttribute,
          id: "qa-nose-entry-shiners",
        }}
      />
      <FeatureEntry
        {...{
          feature: sinusErythema,
          values: ["No Erythema", "Erythematous"],
          onChange: setAttribute,
          id: "qa-nose-entry-sinusErythema",
        }}
      />
      <FeatureEntry
        {...{
          feature: sinusEdema,
          values: ["No Edma", "Edematous"],
          onChange: setAttribute,
          omitTitle: true,
          id: "qa-nose-entry-sinusEdema",
        }}
      />
      <FeatureEntry
        {...{
          feature: foreignBody,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-nose-entry-foreignBody",
        }}
      />
      <FeatureEntry
        {...{
          feature: nasalFlaring,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-nose-entry-nasalFlaring",
        }}
      />
    </React.Fragment>
  );
};

const NasalMucosaEntries = ({ onChange, features }) => {
  const nasalMucosaOptions = ["Erythematous", "Pale", "Boggy", "Abnormal color: White", "Polypoid"];

  const nasalMucosa = findFeature(features, "nasalMucosa", "Nasal mucosa", "Nose");

  const filternasalMucosaOptions = (list, options) => {
    return (list || []).filter((r) => options.includes(r));
  };

  const anyOptionsSelected = (list, options) => {
    if (!list) return false;
    for (const option in options) {
      if (list.includes(option)) return true;
    }
    return false;
  };

  const filterednasalMucosaOptions = filternasalMucosaOptions(
    nasalMucosa.attributes,
    nasalMucosaOptions,
  );

  const onRemove = (nasalMucosaItem) => {
    onChange(
      nasalMucosa,
      nasalMucosa.attributes.filter((v) => v !== nasalMucosaItem),
    );
  };

  const onSelect = (newValues) => {
    let as = nasalMucosa.attributes.filter((v) => !newValues.includes(v));
    as.push(...newValues);
    onChange(nasalMucosa, as);
  };

  const nasalMucosaOptionsSelected = anyOptionsSelected(nasalMucosa.attributes, nasalMucosaOptions)
    ? "yes-no-toggle item-selected d-flex"
    : "yes-no-toggle  d-flex";

  return (
    <>
      <FeatureEntry feature={nasalMucosa} values={[]} id="qa-nose-entry-bl" />

      <div className="attribute-container">
        <div className={nasalMucosaOptionsSelected}>
          <CheckSelect
            options={nasalMucosaOptions}
            selected={filterednasalMucosaOptions}
            onChange={onSelect}
            label={"Select"}
          />
        </div>
        <div className="nasalMucosa-items">
          <PillList
            items={filternasalMucosaOptions(nasalMucosa.attributes, nasalMucosaOptions)}
            onRemove={onRemove}
          />
        </div>
      </div>
    </>
  );
};

const NoseEntry = ({
  features,
  onChange,
  note,
  onNoteUpdate,
  visitType,
  usedTytoCare = false,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [hideContent, setHideContent] = useState(true);

  const appearance = findFeature(features, "noseAppearance", "Appearance", "Nose");
  const septum = findFeature(features, "nasalSeptum", "Nasal Septum", "Nose");
  const rhinorrhea = findFeature(features, "rhinorrhea", "Rhinorrhea", "Nose");
  const nares = findFeature(features, "nares", "Nares", "Nose");
  const setAttribute = featureSetAttributes({ onChange });

  const isAllergies = visitType === "rash_and_allergy";

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Nose", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Nose");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Nose");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Nose");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Nose"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Nose"  className="large">
      <div className="nose-entries">
        <FeatureEntry
          {...{
            feature: appearance,
            values: ["Atraumatic", "Traumatic"],
            onChange: setAttribute,
            id: "qa-nose-entry-appearance",
          }}
        />
        <FeatureEntry
          {...{
            feature: septum,
            values: ["Straight", "Deviated"],
            onChange: setAttribute,
            id: "qa-nose-entry-septum",
          }}
        />
        <ButtonGroupEntry
          {...{
            feature: rhinorrhea,
            attributes: ["None", "Clear", "Purulent"],
            onChange: setAttribute,
            id: "qa-nose-entry-rhinorrhea",
          }}
        />
        {isNursingHomeSite && (
          <FeatureEntry
            {...{
              feature: nares,
              values: ["Clear", "Exudates"],
              onChange: setAttribute,
              id: "qa-nose-entry-nares",
            }}
          />
        )}

        {usedTytoCare && <NasalMucosaEntries onChange={setAttribute} features={features} />}
        {isAllergies && <NoseAllergyQuestions features={features} setAttribute={setAttribute} />}
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />

        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{ groupName: "Nose", onChange: onNoteUpdate, note, id: "qa-nose-group-entry" }}
        />
      </div>
    </EntryBlock>
  );
};

NoseEntry.propTypes = {
  features: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  visitType: PropTypes.string,
  usedTytoCare: PropTypes.bool,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default NoseEntry;
