import React, { FC, useState } from "react";
import { Button, Input } from "reactstrap";

type Props = {
  onReturn: () => void;
  onAbandon: (value: string) => void;
};

const AbandonConfirmModal: FC<Props> = ({ onReturn, onAbandon, ...props }) => {
  const [confirmAbandonPage, setConfirmAbandonPage] = useState(true);

  return (
    <div className="modal-overlay d-flex flex-column">
      <div className="ml-auto mr-auto provider-message-container d-flex flex-column justify-content-between align-items-stretch">
        {!confirmAbandonPage && (
          <div className="back-btn">
            <Button className="btn-abandon-nav" onClick={onReturn} color="link">
              ← Go Back
            </Button>
          </div>
        )}
        {confirmAbandonPage ? (
          <div>
            <h4>
              Are you sure you want to mark this visit as incomplete? You won't be able to resume
              this patient encounter.
            </h4>
            <div className="first-button d-flex justify-content-between">
              <Button className="go-back-button" color="link" onClick={onReturn}>
                {" "}
                Go back{" "}
              </Button>
              <Button
                className="abandon-button"
                color="primary"
                onClick={() => setConfirmAbandonPage(false)}
              >
                {" "}
                Confirm{" "}
              </Button>
            </div>
          </div>
        ) : (
          <AbandonReasonsModal onAbandon={onAbandon} />
        )}
      </div>
    </div>
  );
};

const AbandonReasonsModal: FC<Omit<Props, "onReturn">> = ({ onAbandon }) => {
  const [showTextField, setShowTextField] = useState(false);
  const [reason, setReason] = useState("");
  return (
    <div>
      <div className="align-self-center">
        <h4>Why are you marking this visit incomplete?</h4>
        <div className="text-center">
          {!showTextField ? (
            <div>
              <Button
                className="prescribe-button btn btn-outline-primary mt-3"
                onClick={() => onAbandon("Patient never showed")}
              >
                Patient never showed
              </Button>
              <Button
                className="prescribe-button btn btn-outline-primary mt-3"
                onClick={() => onAbandon("Patient disconnected")}
              >
                Patient disconnected
              </Button>
              <Button
                className="prescribe-button btn btn-outline-primary mt-3"
                onClick={() => onAbandon("Technical Difficulties")}
              >
                Technical Difficulties
              </Button>
              <Button
                className="prescribe-button btn btn-outline-primary mt-3"
                onClick={() => setShowTextField(true)}
              >
                Other
              </Button>
            </div>
          ) : (
            <div>
              <div className="abandon-reason-textfield">
                <h1>Other:</h1>
                <Input
                  className="other-reason"
                  type="textarea"
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                  placeholder="Type any additional info..."
                />
              </div>
              <Button
                className="abandon-button"
                color="primary"
                onClick={() => onAbandon(reason)}
                disabled={reason.length < 1}
              >
                {" "}
                Submit{" "}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AbandonConfirmModal;
