import React, { FC, useState } from "react";
import { Input, Row, Col } from "reactstrap";

import BlockSection from "./BlockSection";
import CheckToggle from "../CheckToggle";
import PillList from "./PillList";

type Props = {
  title?: string;
  labels: string[];
  medicationsMap: any;
  medicationsTried: any[];
  otherMedicationsTried: any[];
  onKeyValuesChange: (newValue: any) => void;
  onOtherMedicationsChange: (newValue: any) => void;
  onRemove: (newValue: any) => void;
  oldValues: any;
};

const MedicationsTriedEditor: FC<Props> = (props) => {
  const [inputValue, setInputValue] = useState("");
  let medicationsNames = Object.keys(props.medicationsMap);
  let rows = createRows(medicationsNames);

  const handlekeyPress = (event) => {
    setInputValue(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    props.onOtherMedicationsChange(inputValue);
    setInputValue("");
  };
  return (
    <BlockSection title={props.title}>
      {rows.map((row, i) => {
        return (
          <Row key={i}>
            {row.map((medication, j) => {
              return (
                <Col md="3" className="d-flex supportive-care-checkbox" key={j}>
                  <CheckToggle
                    checked={props.medicationsTried.indexOf(props.medicationsMap[medication]) > -1}
                    onChange={() => props.onKeyValuesChange(medication)}
                  >
                    {medication}
                  </CheckToggle>
                </Col>
              );
            })}
          </Row>
        );
      })}
      <Row>
        <div className="ml-3">
          <span className="">Other: </span>
        </div>
      </Row>
      <Row>
        <div className="d-flex ml-3">
          <div className="d-flex align-self-end">
            <form className="align-self-center mb-1" onSubmit={(e) => handleSubmit(e)}>
              <Input value={inputValue} className="info-select" onChange={handlekeyPress} />
            </form>
          </div>
          <div className="list-editor-pill-container">
            <PillList
              items={props.otherMedicationsTried}
              oldItems={props.oldValues.otherMedications || []}
              onRemove={props.onRemove}
            />
          </div>
        </div>
      </Row>
    </BlockSection>
  );
};

export default MedicationsTriedEditor;

const createRows = (arr: any[]) => {
  let rows: any[] = [];
  while (arr.length > 3) {
    rows.push(arr.slice(0, 3));
    arr = arr.slice(3, arr.length);
  }
  rows.push(arr);
  return rows;
};
