import React, { Component } from "react";
import { Button } from "reactstrap";

import styles from "./chart.scss";

interface EditWrapperProps {
  readOnly?: boolean;
  title?: string;
  data: any;
  unsavedSections?: string[];
  onSave: () => void;
  onCancel: (restoreData: any) => void;
}

interface EditWrapperState {
  showRequiredFieldErrors: boolean;
  isEditing: boolean;
  requiredFields: string[];
  restoreData: any;
}

export default class EditWrapper extends Component<EditWrapperProps, EditWrapperState> {
  constructor(props) {
    super(props);
    this.state = {
      showRequiredFieldErrors: false,
      isEditing: false,
      restoreData: null,
      requiredFields: [],
    };
    this.onClick = this.onClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onClick() {
    if (!this.state.isEditing) {
      this.setState({ isEditing: true, restoreData: this.props.data });
    } else {
      this.setState({ showRequiredFieldErrors: false }, () => {
        if (this.state.requiredFields && this.state.requiredFields.length > 0) {
          this.setState({ showRequiredFieldErrors: true });
          return;
        }
        this.setState({ isEditing: false, restoreData: null, showRequiredFieldErrors: false });
        if (this.props.onSave) {
          this.props.onSave();
        }
      });
    }
  }

  onCancel() {
    this.setState({ isEditing: false, showRequiredFieldErrors: false, requiredFields: [] });
    if (this.state.restoreData && this.props.onCancel) {
      this.props.onCancel(this.state.restoreData);
    }
  }

  setRequiredFields = (fields) => {
    this.setState({ requiredFields: fields });
  };

  render() {
    const { children, ...other } = this.props;
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child as any, {
        isEditing: this.state.isEditing,
        restoreData: this.state.restoreData,
        showRequiredFieldErrors: this.state.showRequiredFieldErrors,
        requiredFields: this.state.requiredFields,
        setRequiredFields: this.setRequiredFields,
        ...other,
      }),
    );

    const buttonLabel = this.state.isEditing ? "Save" : "Edit";
    const cl = this.state.isEditing ? "editToggle save" : "editToggle edit";

    const editError =
      this.state.isEditing &&
      this.props.unsavedSections &&
      this.props.unsavedSections[this.props.title || ""]
        ? "info-edit-wrapper-error"
        : " ";
    return (
      <div className={styles.editWrapper}>
        {childrenWithProps}
        {!this.props.readOnly && (
          <div className="float-left" style={{ marginTop: "40px", paddingBottom: "20px" }}>
            {this.state.isEditing && (
              <Button color="link mr-1" onClick={this.onCancel}>
                Cancel
              </Button>
            )}
            <Button
              onClick={this.onClick}
              className={cl}
              style={{
                backgroundColor: this.state.isEditing ? "#4A8FE7" : "#ADC85E",
                borderRadius: "21px",
                height: "26px",
                width: "74px",
                borderColor: this.state.isEditing ? "#4A8FE7" : "#ADC85E",
                paddingBottom: "25px",
              }}
            >
              <span style={{ fontSize: "12px", fontFamily: "Open Sans", marginBottom: "5px" }}>
                {buttonLabel}
              </span>
            </Button>
          </div>
        )}
      </div>
    );
  }
}
