import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import NavBar from "../Provider/NavBar";
import Icon from "../../images/RoundingIcon.svg";
import RoundingList from "./Roundinglist";
import RoundingListSearch from "./RoundinglistSearch";
import "./rounding-tab.scss";

import { NAVIGATE_TO } from "../../constants/actionTypes";

const mapStateToProps = (state) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  redirectHome: () => dispatch({ type: NAVIGATE_TO, targetPath: "/" }),
});
function Tab(props) {
  const [updateList, setUpdate] = useState(false);
  const handleListChange = () => {
    setUpdate(!updateList);
  };

  // check to see if the currently active practice for the provider is a nusring home
  const currentUser = props.currentUser || {};
  if (!currentUser.isNursingHomeSite) {
    // not a nusring home, redirect home
    props.redirectHome();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'redirectHome' does not exist on type 'Ta... Remove this comment to see the full error message
    return null;
  }

  return (
    <>
      <NavBar />
      <div className="dashboard-container">
        <h4>
          {" "}
          <div className="header-icon float-left">
            <Icon />
          </div>{" "}
          Rounding{" "}
        </h4>
        <RoundingList updateList={updateList} handleListChange={handleListChange} />
        <RoundingListSearch handleListChange={handleListChange} />
      </div>
    </>
  );
}

Tab.propTypes = {
  currentUser: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tab);
