import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./EditorStyle.scss";

/* const config = {
  skin: "snow",
  placeholder: "start typing...",
  branding: false,
  height: 400,
  menubar: false,
  button_tile_map: true,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen textcolor ",
    "insertdatetime media table paste code help wordcount",
  ],
  textcolor_rows: "4",

  toolbar:
    "undo redo | fontsizeselect forecolor backcolor | bold italic underline strikethrough | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | table | fullscreen",
};

interface Props {
  content: string;
  onUpdate: (value: string) => void;
}

const RichTextEditor = React.memo(
  (props: Props) => {
    const handleUpdate = (editorContent) => {
      if (typeof props.onUpdate === "function") props.onUpdate(editorContent);
    };

    return (
      <div>
        <Editor
          init={config}
          onEditorChange={handleUpdate}
          outputFormat="html"
          value={props.content}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.content === nextProps.content;
  },
);

export default RichTextEditor; */

const config = {
  skin: "snow",
  placeholder: "start typing...",
  branding: false,
  height: 400,
  menubar: false,
  button_tile_map: true,
  plugins: [],
  textcolor_rows: "4",

  /*  toolbar:
    "undo redo | fontsizeselect forecolor backcolor | bold italic underline strikethrough | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | table | fullscreen", */
};

interface Props {
  content: string;
  onUpdate: (value: string) => void;
}

const RichTextEditor = React.memo(
  (props: Props) => {
    const handleUpdate = (editorContent) => {
      if (typeof props.onUpdate === "function") props.onUpdate(editorContent);
    };

    return (
      <div>
        <Editor
          init={config}
          onEditorChange={handleUpdate}
          outputFormat="html"
          value={props.content}
          /*   tinymceScriptSrc="./tinymce/tinymce.min.js" */
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.content === nextProps.content;
  },
);

export default RichTextEditor;
