import React, { useState, useEffect } from "react";

const DetectAdblock = () => {
  const [showModal, setShowModal] = useState(false);
  

  useEffect(() => {
    let testAd = document.createElement("div");
    testAd.innerHTML = "&nbsp;";
    testAd.className = "adsbox";
    document.body.appendChild(testAd);

    if (testAd.offsetHeight === 0) {
      setShowModal(true);
    } else {
      const adblock = document.getElementsByClassName("adsbox");
      for (let i = 0; i < adblock.length; i++) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        adblock[i].parentNode.removeChild(adblock[i]);
      }
    }
  }, []);

  if (showModal) {
    return (
      <div className="modal-overlay d-flex flex-column">
        <div className="ml-auto mr-auto provider-message-container d-flex flex-column justify-content-center">
          <h4 style={{ color: "#fc5b42", fontWeight: "bold" }}>
            You have an adblock running in your browser. You will need to disable it to use the
            MDBox EMR.
          </h4>
          <br></br>
          <button onClick={() => window.location.reload()} className="btn btn-success">
            Adblock Disabled
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default DetectAdblock;
