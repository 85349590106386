import React, { FC, useState } from "react";
import Select from "react-select";
import moment, { Moment } from "moment";
import { Button } from "reactstrap";
import { DayPickerSingleDateController, DayPickerSingleDateControllerShape } from "react-dates";
import { SelectComponents } from "react-select/lib/components";

import PillList from "./PillList";
import { symptomDurationMap } from "../../constants/Encounter";
import CheckToggle from "../CheckToggle";
import {
  ICatalogItem,
  ICatalogItemDetails,
  ISymptomsDetail,
  ISymptomsDetailDetail,
  IUserMedicalIntake,
} from "../../constants/Types";
import { HandleChangeFuncCurrentSymptomsType } from "./CurrentSymptoms";

type Props = {
  answer: ISymptomsDetailDetail;
  components: Partial<SelectComponents<any>>;
  detail: ICatalogItemDetails;
  detailType: string;
  details: ISymptomsDetail[] | null;
  hideSelectedOptions: boolean;
  isClearable: boolean;
  onChange: HandleChangeFuncCurrentSymptomsType;
  options: {
    label: string;
    value: string;
    key: number;
  }[];
  placeholder: string;
  symptom?: ICatalogItem;
  symptomsDetailField: keyof IUserMedicalIntake;
};

const SymptomsDetails: FC<Props> = (props) => {
  const symptomsDetailField = props.symptomsDetailField;

  const onDetailChange = (oldItem, symptomKey) => (item) => {
    let newDetails = JSON.parse(JSON.stringify(props.details));
    if (newDetails.find((symptom) => symptom.key === symptomKey).details === null) {
      newDetails.find((symptom) => symptom.key === symptomKey).details = [
        { key: oldItem.key, answer: [] },
      ];
    }
    if (
      !newDetails

        .find((symptom) => symptom.key === symptomKey)

        .details.find((detail) => detail.key === oldItem.key)
    ) {
      newDetails
        .find((symptom) => symptom.key === symptomKey)
        .details.push({ key: oldItem.key, answer: [] });
    }
    if (oldItem.type === "checkbox") {
      if (
        newDetails

          .find((symptom) => symptom.key === symptomKey)

          .details.find((detail) => detail.key === oldItem.key).answer
      ) {
        newDetails

          .find((symptom) => symptom.key === symptomKey)

          .details.find((detail) => detail.key === oldItem.key)

          .answer.push(item.key + "");
      } else {
        newDetails

          .find((symptom) => symptom.key === symptomKey)

          .details.find((detail) => detail.key === oldItem.key).answer = [item.key + ""];
      }
    } else if (
      oldItem.type === "temperature" ||
      oldItem.type === "integer" ||
      oldItem.type === "duration"
    ) {
      newDetails

        .find((symptom) => symptom.key === symptomKey)

        .details.find((detail) => detail.key === oldItem.key).answer = [item + ""];
    } else {
      newDetails

        .find((symptom) => symptom.key === symptomKey)

        .details.find((detail) => detail.key === oldItem.key).answer = [item.key + ""];
    }
    props.onChange({ [symptomsDetailField]: { symptoms: newDetails } });
  };

  const onRemoveDetail =
    (oldItem: ICatalogItemDetails, symptomKey?: number) => (item?: string | number) => {
      let newDetails = (JSON.parse(JSON.stringify(props.details)) || []) as ISymptomsDetail[];
      let answerList = newDetails
        .find((symptom) => symptom.key === symptomKey)
        ?.details.find((detail) => detail.key === oldItem.key)?.answer;
      answerList?.splice(answerList.indexOf(item + ""), 1);
      props.onChange({ [symptomsDetailField]: { symptoms: newDetails } });
    };
  let DetailComponent = symptomDetailsMap[props.detailType as keyof typeof symptomDetailsMap];
  return (
    <DetailComponent
      className="info-select"
      classNamePrefix="info-select"
      placeholder={props.placeholder}
      options={props.options}
      components={props.components}
      isClearable={false}
      hideSelectedOptions={true}
      onDetailChange={onDetailChange(props.detail, props.symptom?.key)}
      answer={props.answer}
      onRemoveDetail={onRemoveDetail(props.detail, props.symptom?.key)}
      detail={props.detail}
    />
  );
};

type DetailProps = {
  className: string;
  classNamePrefix: string;
  placeholder: string;
  options: {
    label: string;
    value: string;
    key: number;
  }[];
  components: Partial<SelectComponents<any>>;
  isClearable: boolean;
  hideSelectedOptions: boolean;
  onDetailChange: (
    item: { label?: string; value?: string; key?: number; date?: string } | string,
  ) => void;
  answer: ISymptomsDetailDetail;
  onRemoveDetail: (item?: string | number) => void;
  detail: ICatalogItemDetails;
  updateStyles?: string;
};

const DetailCheckbox: FC<DetailProps> = (props) => {
  const placeholder = props.placeholder ? props.placeholder : "Select...";
  let mapLabelToKey = (label: string) => {
    return props.detail.options?.find((detail) => detail.label == label)?.key;
  };
  return (
    <div className="d-flex">
      <div className={`add-symptom-select details align-self-flex-start ${props.updateStyles}`}>
        <Select
          className="info-select"
          classNamePrefix="info-select"
          placeholder={placeholder}
          value={placeholder}
          options={props.options}
          components={props.components}
          isClearable={props.isClearable}
          hideSelectedOptions={props.hideSelectedOptions}
          onChange={props.onDetailChange as any}
        />
      </div>
      <div className="d-flex flex-column symptom-answer-pills">
        <PillList
          customCSS={"blue-pill"}
          items={props.answer.answerAsLabel}
          onRemove={(target) => props.onRemoveDetail(mapLabelToKey(target))}
        />
      </div>
    </div>
  );
};

const DetailsSelect: FC<DetailProps> = (props) => {
  return (
    <div className={`add-symptom-select details ${props.updateStyles}`}>
      <Select
        className="info-select"
        classNamePrefix="info-select"
        placeholder={
          (props.answer.answerAsLabel && props.answer.answerAsLabel[0]) || props.placeholder
        }
        options={props.options}
        components={props.components}
        isClearable={props.isClearable}
        hideSelectedOptions={props.hideSelectedOptions}
        onChange={props.onDetailChange as any}
      />
    </div>
  );
};

const DurationSelect: FC<DetailProps> = (props) => {
  const dropDowns = props.answer.answer
    ? props.answer.answer[0].split(", ")
    : ["0 Years", "0 weeks", "0 days"];
  const handleChange = (key: number) => (selected: any) => {
    let newAnswer = dropDowns;
    newAnswer.splice(key, 1, selected.value);
    props.onDetailChange(newAnswer.join(", "));
  };
  return (
    <div className="d-flex add-symptom-select details">
      {dropDowns.map((dropDowns, key) => {
        return (
          <div key={key} className="flex-grow-1 mr-1">
            <Select
              className="info-select"
              classNamePrefix="info-select"
              placeholder={dropDowns}
              options={symptomDurationMap[key]}
              components={props.components}
              isClearable={props.isClearable}
              hideSelectedOptions={props.hideSelectedOptions}
              onChange={handleChange(key)}
            />
          </div>
        );
      })}
    </div>
  );
};

const DetailsTextField: FC<DetailProps> = (props) => {
  return (
    <div className={`add-symptom-select details ${props.updateStyles}`}>
      {props.placeholder ? `${props.placeholder}: ` : ""}
      <input
        onChange={(e) => props.onDetailChange(e.target.value)}
        value={props.answer.answer || ""}
      ></input>
    </div>
  );
};

const TemperatureTextField: FC<DetailProps> = (props) => {
  return (
    <div className="add-symptom-select details">
      <div className="text-details-input">
        <input
          onChange={(e) => props.onDetailChange(e.target.value)}
          value={props.answer.answer}
        ></input>
        {` °F (temperature)`}
      </div>
    </div>
  );
};

const ToggleSelect: FC<DetailProps> = (props) => {
  const [edited, setEdited] = useState(false);
  const cl = edited ? "yes-no-toggle d-flex edited" : "yes-no-toggle d-flex";
  const handleClick = (val: string) => {
    setEdited(true);
    props.onDetailChange({ label: val });
  };
  return (
    <div className={cl}>
      <Button
        className={
          props.answer.answerAsLabel && props.answer.answerAsLabel[0] === "Yes"
            ? "mr-3 selected"
            : "mr-3"
        }
        outline
        onClick={() => handleClick("Yes")}
      >
        Yes
      </Button>
      <Button
        className={
          props.answer.answerAsLabel && props.answer.answerAsLabel[0] === "No" ? "selected" : ""
        }
        outline
        onClick={() => handleClick("No")}
      >
        No
      </Button>
    </div>
  );
};

const DateSelect: FC<DetailProps> = (props) => {
  const day = props.answer.answer || new Date();
  const date = moment(day).utc() as DayPickerSingleDateControllerShape["date"];
  const noDateOption = props.detail.options?.find((option) => option.type === "N/A");
  const dateOption = props.detail.options?.find((option) => option.type === "date");
  const onChange: DayPickerSingleDateControllerShape["onDateChange"] = (val) => {
    if (val === null) {
      props.onDetailChange({ date: "", key: noDateOption?.key });
    } else {
      props.onDetailChange({ date: val.toISOString(), key: dateOption?.key });
    }
  };
  const isBeforeToday = (d: Moment) => {
    return !d.isBefore(moment().subtract(1, "days"));
  };
  return (
    <>
      <DayPickerSingleDateController
        focused
        onFocusChange={() => {}}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel={true}
        daySize={39}
        horizontalMonthPadding={0}
        verticalHeight={200}
        date={props.answer.answer ? date : null}
        transitionDuration={0}
        isOutsideRange={isBeforeToday}
        onDateChange={onChange}
      />
      <div className="d-flex align-items-center pt-2">
        <span className="h-50 pl-2 pr-1">N/A:</span>
        <CheckToggle checked={!props.answer.answer} onChange={() => onChange(null)} />
      </div>
    </>
  );
};

export const symptomDetailsMap = {
  radio: DetailsSelect,
  select: DetailsSelect,
  integer: DetailsTextField,
  temperature: TemperatureTextField,
  checkbox: DetailCheckbox,
  duration: DurationSelect,
  date: DateSelect,
  slider: DetailsSelect,
  button: ToggleSelect,
};

export default SymptomsDetails;
