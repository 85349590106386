import React from "react";
import { IOptionType } from "./Types";

export const coronavirusFollowupSteps = {
  testingReferred: (
    <div>
      Patient was referred to their respective regional health department to obtain COVID-19 testing
      (
      <a href="https://www.usa.gov/state-health" target="_blank">
        for reference
      </a>
      )
    </div>
  ),
  ERContactAdvised:
    "Patient was advised to contact their regional hospital emergency room for further instructions",
  stayAtHomeAdvised: (
    <div>
      Patient was advised to rest at home and follow recommended safety precautions to avoid
      transmitting their infection to others (
      <a
        href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/infection-control.html"
        target="_blank"
      >
        for reference
      </a>
      )
    </div>
  ),
  PCPContactAdvised: (
    <div>
      Patient was advised to contact their primary care provider, an urgent care, or MDBox patient
      support if they experience persistent or worsening shortness of breath, fever, uncontrolled
      cough, or other worrisome symptoms (
      <a
        href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/infection-control.html "
        target="_blank"
      >
        for reference
      </a>
      )
    </div>
  ),
  quarantineAdvised:
    "Patient was advised to quarantine themselves at home due to possible COVID-19 infection",
  notificationOfTestingSent:
    "The supervising physician and Reliant.MD Medical Associates have been notified that the patient has been referred for COVID-19 testing",
};

export const defaultRequiredDiagnosisFields = ["emCode"];
export const ltcRequiredDiagnosisFields = ["emCodes"];
export const coronavirusRequiredDiagnosisFields = ["emCode", "coronavirusFollowup"];

export const fluChoices = [
  "J11.2 Influenza due to unidentified influenza virus with GI manifestations",
  "J11.1 Influenza due to unidentified influenza virus with other respiratory manifestions (not pneumonia)",
  "J10.2 Influenza due to identified influenza virus with GI manifestations",
  "J10.1 Influenza due to identified influenza virus with other respiratory manifestions (not pneumonia)",
  "J10.89 Influenza due to identified influenza virus with other (not GI or respiratory) manifestations (fever, body aches, etc)",
  "R05 cough",
  "J02.0 Streptococcal pharyngitis",
  "J02.9 Acute Pharyngitis, unspecified",
  "B08.5 Enteroviral vesicular pharyngitis",
  "J00 Acute nasophargitis (common cold)",
  "J06.9 Acute upper respiratory infection, unspecified",
  "B30.2 Viral pharygoconjunctivitis",
  "J12.89 other viral pneumonia",
  "J20.8 acute bronchitis due to other specified organisms",
  "J40 bronchitis, not specified as acute or chronic",
  "J22 unspecified acute lower respiratory infection",
  "J98.8 other specified respiratory infection",
  "J80 acute respiratory distress syndrome",
  "R06.02 Shortness of breath",
  "R50.9 Fever, unspecified",
  "Z20.818 Contact with and (suspected) exposure to other bacterial communicable diseases",
  "Z20.828 Contact with and (suspected) exposure to other viral communicable diseases",
  "U07.1 2019-nCoV acute respiratory disease",
  "U07.2 COVID-19, virus not identified",
];

export const strepChoices = [
  "J02.0 Streptococcal pharyngitis",
  "J02.9 Acute Pharyngitis, unspecified",
  "B08.5 Enteroviral vesicular pharyngitis",
  "J00 Acute nasophargitis (common cold)",
  "J06.9 Acute upper respiratory infection, unspecified",
  "B30.2 Viral pharygoconjunctivitis",
  "R05 cough",
  "J11.2 Influenza due to unidentified influenza virus with GI manifestations",
  "J11.1 Influenza due to unidentified influenza virus with other respiratory manifestions (not pneumonia)",
  "J10.2 Influenza due to identified influenza virus with GI manifestations",
  "J10.1 Influenza due to identified influenza virus with other respiratory manifestions (not pneumonia)",
  "J10.89 Influenza due to identified influenza virus with other (not GI or respiratory) manifestations (fever, body aches, etc)",
];

export const utiChoices = [
  "N39.0 Urinary tract infection, site not specified",
  "R30.0 Dysuria (painful urination)",
  "R39.15 urinary urgency",
  "R35.0 Urinary frequency",
];

export const conjunctivitisChoices = [
  "B30.1 Conjunctivitis due to Adenovirus",
  "B30.8 Other Viral conjunctivitis",
  "B30.9 Viral conjunctivitis, unspecified",
  "H10.021 Bacterial or mucopurulent conjunctivitis, right eye",
  "H10.022 Bacterial or mucopurulent conjunctivitis, left eye",
  "H10.023 Bacterial or mucopurulent conjunctivitis, both eyes",
  "H10.10 Acute atopic conjunctivitis, unspecified eye",
  "H10.11 Acute atopic conjunctivitis, right eye",
  "H10.12 Acute atopic conjunctivitis, left eye",
  "H10.13 Acute atopic conjunctivitis, bilateral",
  "H10.5 Blepharoconjunctivitis",
  "H10.219 Toxic blepharoconjunctivitis",
  "H18.829 Contact lens keratitis",
];

export const sinusitisChoices = [
  "J01.00 Acute maxillary sinusitis",
  "J01.10 Acute frontal sinusitis",
  "J01.20 Acute ethmoidal sinusitis",
  "J01.30 Acute sphenoidal sinusitis",
  "J01.40 Acute pansinusitis",
  "J01.01 Acute recurrent maxillary sinusitis",
  "J01.11 Acute recurrent frontal sinusitis",
  "J01.21 Acute recurrent ethmoidal sinusitis",
  "J01.31 Acute recurrent sphenoidal sinusitis",
  "J01.41 Acute recurrent pansinusitis",
  "J32.0 Chronic maxillary sinusitis",
  "J32.1 Chronic frontal sinusitis",
  "J32.2 Chronic ethmoidal sinusitis",
  "J32.3 Chronic sphenoidal sinusitis",
  "J32.4 Chronic pansinusitis",
];

export const gastroenteritisChoices = [
  "A08.2 Adenoviral gastroenteritis",
  "A09  Infectious gastroenteritis, enteritis, colitis NOS (not otherwise specified)",
  "A00  Cholera",
  "A01  Typhoid and paratyphoid fevers",
  "A02  Other salmonella infections",
  "A03  Shigellosis",
  "A04  Other bacterial intestinal infections",
  "A05  Other bacterial foodborne intoxications, not elsewhere classified",
  "A06  Amebiasis",
  "A07  Other protozoal intestinal diseases",
  "A08  Viral and other specified intestinal infections",
  "A09  Infectious gastroenteritis and colitis, unspecified",
  "K52.89 Other specified noninfectious gastroenteritis and colitis",
  "R19.7 Diarrhea",
  "B96.81 Helicobacter pylori as a cause of disease",
];

export const contraceptivesChoices = [
  "Z30.011 Encounter for initial prescription of contraceptive pills",
  "Z30.012 Encounter for prescription of emergency contraception",
  "Z30.013 Encounter for initial prescription of injectable contraceptive",
  "Z30.014 Encounter for initial prescription of intrauterine contraceptive device",
  "Z30.015 Encounter for initial prescription of vaginal ring hormonal contraceptive",
  "Z30.016 Encounter for initial prescription of transdermal patch hormonal contraceptive device",
  "Z30.017 Encounter for initial prescription of implantable subdermal contraceptive",
  "Z30.018 Encounter for initial prescription of other contraceptives",
  "Z30.02 Counseling and instruction in natural family planning to avoid pregnancy",
  "Z30.09 Encounter for other general counseling and advice on contraception",
  "N94.4 Dysmenorrhea, menstrual cramps",
  "N91.0 Primary amenorrhea",
  "N94.3 Premenstrual Syndrome",
  "N92.0 Menorrhagia",
  "L70.0 Acne vulgaris",
];

export const eyeAllergyChoices = [
  "H10.10 Acute atopic conjunctivitis unspecified eye",
  "H10.11 Acute atopic conjunctivitis right eye",
  "H10.12 Acute atopic conjunctivitis left eye",
  "H10.13 Acute atopic conjunctivitis bilateral",
];

export const noseAllergyChoices = [
  "J30.0 Vasomotor rhinitis",
  "J30.1 Allergic rhinitis due to pollen",
  "J30.81 Allergic rhinitis due to animal (cat) (dog) hair and dander",
  "J30.89 Other allergic rhinitis",
  "J31.0 Chronic rhinitis",
  "J01.80 Other acute sinusitis",
  "J32.9 Chronic sinusitis, NOS",
];

export const lungAllergyChoices = [
  "J18.9 Pneumonia, unspecified organism",
  "J20.9 Acute Bronchitis, unspecified",
  "J38.3 Vocal Cord Dysfunction",
  "J45.21 Mild intermittent with (acute) exacerbation",
  "J45.31 Mild persistent with (acute) exacerbation",
  "J45.41 Moderate persistent with (acute) exacerbation",
  "J45.51 Severe persistent with (acute) exacerbation",
];

export const skinAllergyChoices = [
  "L20.9 Atopic Dermatitis, unspecified",
  "L23.9 Allergic Contact Dermatitis, unspecified",
  "L30.9 Dermatitis, unspecified",
  "L50.0 Urticaria, Allergic",
  "L50.1 Idiopathic Urticaria",
];

export const herpesChoices = ["A60.00 Herpesviral infection of urogenital system, unspecified"];

export const coronavirusChoices = [
  "J12.89 other viral pneumonia",
  "J20.8 acute bronchitis due to other specified organisms",
  "J40 bronchitis, not specified as acute or chronic",
  "J22 unspecified acute lower respiratory infection",
  "J98.8 other specified respiratory infection",
  "J80 acute respiratory distress syndrome",
  "R05 Cough",
  "R06.02 Shortness of breath",
  "R50.9 Fever, unspecified",
  "Z20.818 Contact with and (suspected) exposure to other bacterial communicable diseases",
  "Z20.828 Contact with and (suspected) exposure to other viral communicable diseases",
  "U07.1 2019-nCoV acute respiratory disease",
  "U07.2 COVID-19, virus not identified",
];

export const establistedPatientEMCodeChoices = [
  { value: "99202", label: "99202 - Limited" },
  { value: "99203", label: "99203 - Moderate" },
  { value: "99204", label: "99204 - Complex" },
  { value: "99212", label: "99212 - Limited" },
  { value: "99213", label: "99213 - Moderate" },
  { value: "99214", label: "99214 - Complex" },
];

export const zones = [
  { value: "GMT-04:00", label: "Eastern Time (GMT -04:00)" },
  { value: "GMT-05:00", label: "Central Time (GMT -05:00)" },
  { value: "GMT-06:00", label: "Mountain Time (GMT - 06:00)" },
  { value: "GMT-07:00", label: "Pacific Time (GMT - 07:00)" },
];
export const amPM = [
  { value: "AM", label: "AM" },
  { value: "PM", label: "PM" },
];

export const newPatientEMCodeChoices = [
  { value: "99202", label: "99202 - Limited" },
  { value: "99203", label: "99203 - Moderate" },
  { value: "99204", label: "99204 - Complex" },
  { value: "99212", label: "99212 - Limited" },
  { value: "99213", label: "99213 - Moderate" },
  { value: "99214", label: "99214 - Complex" },
];

export const requisitionVisitEMCodeChoices = [
  { value: "99441", label: "99441 - Telephone E/M service; 5-10 minutes" },
  { value: "99442", label: "99442 - Telephone E/M service; 11-10 minutes" },
  { value: "99443", label: "99443 - Telephone E/M service; 21-30 minutes" },
];

export const TimeZones = {
  "GMT-04:00": "America/New_York",
  "GMT-05:00": "America/Chicago",
  "GMT-06:00": "America/Phoenix",
  "GMT-07:00": "America/Los_Angeles",
  "GMT+03:00": "Europe/Moscow",
};

export const quickChoices = {
  uti: utiChoices,
  flu: fluChoices,
  strep: strepChoices,
  conjunctivitis: conjunctivitisChoices,
  sinusitis: sinusitisChoices,
  gastroenteritis: gastroenteritisChoices,
  contraceptives: contraceptivesChoices,
  herpes: herpesChoices,
  coronavirus: coronavirusChoices,
  covid_drive_through: coronavirusChoices,
  coronavirus_consult_and_testing: coronavirusChoices,
  covid_at_home_pcr: coronavirusChoices,
};

export const quickAllergyChoices = {
  eyes: eyeAllergyChoices,
  nose: noseAllergyChoices,
  skin: skinAllergyChoices,
  lungs: lungAllergyChoices,
};
