import React from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import PropTypes from "prop-types";

const RxDuration = ({ features, onChange, note, onNoteUpdate }) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'features' does not exist on type 'Props'... Remove this comment to see the full error message
  const rxDuration = findFeature(features, "rxDuration", "RxDuration", "RxDuration");

  const setAttribute = featureSetAttributes({ onChange });

  return (
    <EntryBlock title="Do you know how long you should take this medication?">
      <div>
        <FeatureEntry
          {...{
            feature: rxDuration,
            values: ["Yes", "No"],
            onChange: setAttribute,
            omitTitle: true,
            id:"qa-rxDuration-entry-rxDuration"
          }}
        />
      </div>
      <div>
        <GroupNoteEntry {...{ groupName: "RxDuration", onChange: onNoteUpdate, note, id:"qa-rxDuration-group-entry" }} />
      </div>
    </EntryBlock>
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{ groupName: string; onChange: ((...args: an... Remove this comment to see the full error message
  );
};

RxDuration.propTypes = {
  feature: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
};
export default RxDuration;
