import React, { Component } from "react";
import bugsnagClient from "../../services/bugsnag";

import api from "../../api";
import MedicationsTriedEditor from "./MedicationsTriedEditor";
import BlockSection from "./BlockSection";

import { IKeyedLabelEntry } from "../../constants/Types";

export const mapKeysToString = (items: number[], catalog: IKeyedLabelEntry[] = []) => {
  let entries = items.map((k) => catalog.find((v) => v.key === k)).filter(Boolean);
  return entries.map((l) => (l && l.labels ? l.labels["en"] : "Undefined"));
};

type Props = {
  medicationsTriedFieldName: string;
  visitType: string;
  medicationsTried: any;
  onChanged: (value: any) => void | null;

  isEditing?: boolean;
  restoreData?: any;
  requiredFields?: string[];
  setRequiredFields?: (val: string) => void;
  title?: string;
};

type State = {
  catalog?: IKeyedLabelEntry[];
  medicationsMap?: any;
};
class MedicationsTried extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.loadCatalog();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.catalog !== prevState.catalog) {
      let map = {};
      this.state.catalog?.forEach((medication) => {
        map[medication?.labels?.en || ""] = medication.key;
      });
      this.setState({ medicationsMap: map });
    }
  }

  loadCatalog = async () => {
    try {
      let result = await api.Catalog.medicationsTried({
        condition: this.props.visitType,
      });
      this.setState({ catalog: result });
    } catch (err: any) {
      bugsnagClient.notify(err);
    }
  };

  handleKeyChanged = (newValue) => {
    let keys = this.props.medicationsTried?.keys ? this.props.medicationsTried.keys.slice() : [];
    if (keys.indexOf(this.state.medicationsMap[newValue]) > -1) {
      keys.splice(keys.indexOf(this.state.medicationsMap[newValue]), 1);
    } else {
      keys.push(this.state.medicationsMap[newValue]);
    }
    let medicationsTried = {
      keys: keys,
      otherMedications: this.props.medicationsTried.otherMedications,
    };
    this.props.onChanged({
      [this.props.medicationsTriedFieldName]: medicationsTried,
    });
  };

  handleOtherMedicationChanged = (newValue) => {
    let values = this.props.medicationsTried.otherMedications
      ? this.props.medicationsTried.otherMedications.slice()
      : [];
    if (values.indexOf(newValue) > -1) {
      return null;
    } else {
      values.push(newValue);
      let medicationsTried = {
        keys: this.props.medicationsTried.keys,
        otherMedications: values,
      };
      this.props.onChanged({
        [this.props.medicationsTriedFieldName]: medicationsTried,
      });
    }
  };

  handleMedicaionRemoved = (value) => {
    let values = this.props.medicationsTried.otherMedications.slice();
    values.splice(values.indexOf(value), 1);
    let medicationsTried = {
      keys: this.props.medicationsTried.keys,
      otherMedications: values,
    };
    this.props.onChanged({
      [this.props.medicationsTriedFieldName]: medicationsTried,
    });
  };

  render() {
    let labels = mapKeysToString(this.props.medicationsTried.keys || [], this.state.catalog || []);
    labels = labels.concat(this.props.medicationsTried.otherMedications || []);
    labels = labels.length == 0 ? ["None"] : labels;
    if (this.props.isEditing && this.props.readOnly) {
      const oldData = this.props.restoreData.intakeHistory[this.props.medicationsTriedFieldName];
      return (
        <MedicationsTriedEditor
          title={this.props.title}
          labels={labels}
          medicationsMap={this.state.medicationsMap}
          medicationsTried={this.props.medicationsTried.keys || []}
          otherMedicationsTried={this.props.medicationsTried.otherMedications || []}
          onKeyValuesChange={this.handleKeyChanged}
          onOtherMedicationsChange={this.handleOtherMedicationChanged}
          oldValues={oldData}
          onRemove={this.handleMedicaionRemoved}
        />
      );
    } else {
      return (
        <BlockSection title={this.props.title}>
          <ul>
            {labels.map((l) => (
              <li key={l}>{l}</li>
            ))}
          </ul>
        </BlockSection>
      );
    }
  }
}

export default MedicationsTried;
