import React from "react";
import styled, { css } from "styled-components";
import Arrow from "./icons/arrow.svg";
import ZoomIn from "./icons/magnifyingGlassPlus.svg";
import ZoomOut from "./icons/magnifyingGlassMinus.svg";
import Trash from "./icons/trash.svg";
import Extent from "./icons/extentIcon.svg";

interface Props {
  direction?: "right" | "left";
  onClick: () => {};
}

const Button = styled.button<Props>`
  background: none;
  border: none;
  border-radius: 4;
  cursor: pointer;
  outline: none;
  padding: 5px; // increase hit area

  // disable user select
  -webkit-touch-callout: "none";
  user-select: "none";

  &:hover {
    svg path {
      stroke: #4a8fe7;
    }
  }

  ${(props) =>
    props.direction === "left" &&
    css`
      transform: scaleX(-1);
    `};
`;

export function ArrowButton({ direction, onClick }) {
  return (
    <Button
      type="button"
      direction={direction}
      onClick={onClick}
      onTouchEnd={onClick}
      style={{ padding: 20 }}
    >
      <Arrow />
    </Button>
  );
}

export function ZoomInButton({ onClick, style }) {
  return (
    <Button type="button" onClick={onClick} onTouchEnd={onClick} style={style}>
      <ZoomIn />
    </Button>
  );
}

export function ZoomOutButton({ onClick, style }) {
  return (
    <Button type="button" onClick={onClick} onTouchEnd={onClick} style={style}>
      <ZoomOut />
    </Button>
  );
}

export function DeleteButton({ onClick, style }) {
  return (
    <Button type="button" onClick={onClick} onTouchEnd={onClick} style={style}>
      <Trash />
    </Button>
  );
}

export function ExtentButton({ onClick, style }) {
  return (
    <Button type="button" onClick={onClick} onTouchEnd={onClick} style={style}>
      <Extent />
    </Button>
  );
}

