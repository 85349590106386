export const RequisitionVisitTypes = [
  "cgx",
  "cardio",
  "pgx",
  "vibrant_cardio_first_choice",
  "vibrant_diabetes_first_choice",
  "vibrant_cancer_first_choice",
  "vibrant_cardio_sonoran",
  "vibrant_diabetes_sonoran",
  "vibrant_cancer_sonoran",
  "cardio_first_choice",
  "diabetes_first_choice",
  "cancer_first_choice",
  "cardio_sonoran",
  "diabetes_sonoran",
  "cancer_sonoran",
  "neuro_bio_scientific",
  "cardio_bio_scientific",
  "diabetes_bio_scientific",
  "cancer_bio_scientific",
  "pharma_bio_scientific",
  "cardio_bio_choice",
  "cardio_principal_diagnostic",
  "cardio_geneus",
  "pgx_geneus",
  "pad_advanced_biomedical",
  "diabetes_advanced_biomedical",
  "cgx_advanced_biomedical",
  "cardio_advanced_biomedical",
  "pgx_brookside",
  "cgx_brookside",
  "cardio_brookside",
  "pgx_diax",
	"cgx_diax",
	"cardio_diax",
	"pgx_limitless",
	"cgx_limitless",
  "cardio_limitless",
  "diabetes_bio_choice",
  "cancer_bio_choice",
  "diabetes_principal_diagnostic",
  "cancer_principal_diagnostic",
  "allergy_diax",
  "covid_diax",
  "molecular_diax",
  "udt_diax"
];