import React from "react";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import { components } from "react-select";
import PropTypes from "prop-types";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 's' implicitly has an 'any' type.
const stringToSelectItem = (s) => {
  return { value: s, label: s };
};
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'so' implicitly has an 'any' type.
const selectItemToString = (so) => {
  return so.label;
};

const EmptyComponent = () => {
  return null;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const IndicatorsContainer = (props) => {
  return (
    <div style={{ background: "#E7E7E7" }}>
      {/* @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'children'. */}
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    </div>
  );
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ValueContainer = ({ children, ...props }) => (
  <div style={{ background: "#E7E7E7", width: "100%" }}>
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </div>
);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export default function CatalogSelect(props) {
  let items = props.items || [];
  const selectItems = items.map(stringToSelectItem);
  const itemListSource = props.itemListSource;
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
  const loadOptions = async (text) => {
    let result = null;
    if (!!text) {
      result = await itemListSource(text, props.userID);
    }

    return result.items.map(stringToSelectItem);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newItems' implicitly has an 'any' type.
  const onSelectChange = (newItems, event) => {
    const resultItems = newItems.map(selectItemToString);
    props.handleSelectChange(resultItems, event.action);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    // @ts-expect-error ts-migrate(2693) FIXME: 'any' only refers to a type, but is being used as ... Remove this comment to see the full error message
    if (event.keyCode === 13) {
      let newValue = items.slice();
      newValue.push(event.target.value);
      props.handleSelectChange(newValue);
    }
  };
  return (
    <AsyncCreatableSelect
      isMulti
      isClearable={false}
      defaultOptions={true}
      placeholder={"+ Type to search/add"}
      value={selectItems}
      loadOptions={loadOptions}
      onChange={onSelectChange}
      onKeyDown={handleKeyDown}
      backspaceRemovesValue={false}
      className="info-select"
      classNamePrefix="info-select"
      styles={{
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
        placeholder: (base) => ({
          ...base,
          fontSize: "12px",
          color: "#000000",
          fontStyle: "italic",
          fontWeight: 400,
          lineHeight: "14px",
        }),
      }}
      components={{
        IndicatorSeparator: EmptyComponent,
        MultiValueContainer: EmptyComponent,
        DropdownIndicator: EmptyComponent,
        /*   Placeholder,
        ValueContainer */
      }}
    />
  );
}

CatalogSelect.propTypes = {
  handleSelectChange: PropTypes.func,
  itemListSource: PropTypes.func,
  items: PropTypes.array,
  Placeholder: PropTypes.string,
  userID: PropTypes.string,
};
